/**
 * Finds the minimum/maximum values for each axis.
 * @param data The array to find the minimum/maximum values for.
 */
export const getEdges = (data) => {
  let smallestX = data[0].x
  let biggestX = data[0].x
  let smallestY = data[0].y
  let biggestY = data[0].y

  // Iterate over all data points and find the values
  data.forEach((point) => {
    smallestX = smallestX > point.x ? point.x : smallestX
    biggestX = biggestX < point.x ? point.x : biggestX
    smallestY = smallestY > point.y ? point.y : smallestY
    biggestY = biggestY < point.y ? point.y : biggestY
  })

  return {
    smallestX,
    biggestX,
    smallestY,
    biggestY
  }
}
