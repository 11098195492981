import { Circle, Group, Line, RegularPolygon, Text } from 'react-konva'
import React, { useState } from 'react'

import { getEdges } from '../../helpers'
import { CrossingLineEventTrigger } from '../../types/crossingLine'

interface ICrossingLineProps {
  onDragStart: (MouseEvent, CrossingLineEventTrigger) => void
  onDragEnd: (MouseEvent, CrossingLineEventTrigger, any) => void
  onClick: (MouseEvent, CrossingLineEventTrigger) => void
  eventTrigger: CrossingLineEventTrigger
  stage: any
  isEditable?: boolean
  isHighlighted: boolean
  isSelected: boolean
}

export const CrossingLine: React.FC<ICrossingLineProps> = ({
  onDragStart,
  onDragEnd,
  onClick,
  eventTrigger,
  stage,
  isEditable,
  isHighlighted,
  isSelected
}) => {
  // Colors
  const colorHighlight = '#00ffb4'
  const colorDefault = '#00a86b'
  const colorHighlightSpeed = '#ff3e3e'
  const colorDefaultSpeed = '#e61f1f'

  const absolutePoints = eventTrigger.convertRelativePointToPixels({
    width: stage.attrs.width,
    height: stage.attrs.height
  })

  const [point1, updatePoint1] = useState({
    x: absolutePoints[0].x || 0,
    y: absolutePoints[0].y || 0,
    draggable: !!isEditable && isHighlighted
  })

  const [point2, updatePoint2] = useState({
    x: absolutePoints[1].x || 0,
    y: absolutePoints[1].y || 0,
    draggable: !!isEditable && isHighlighted
  })

  /* These points (3 & 4) are defaulting to 0 because
   * CrossingLineEventTrigger.convertRelativePointToPixels returns an array
   * containing the crossingline points + speed estimation points.
   * Because crossing-line does not have a speedestimation object by default
   * the case exists that there are only 2 points in the array.
   * Therefore they fallback to 0 (and aren't displayed until speedestimation-object is created)
   */
  const [point3, updatePoint3] = useState({
    x: absolutePoints[2] ? absolutePoints[2].x || 0 : 0,
    y: absolutePoints[2] ? absolutePoints[2].y || 0 : 0,
    draggable: !!isEditable && isHighlighted
  })

  const [point4, updatePoint4] = useState({
    x: absolutePoints[3] ? absolutePoints[3].x || 0 : 0,
    y: absolutePoints[3] ? absolutePoints[3].y || 0 : 0,
    draggable: !!isEditable && isHighlighted
  })

  const [line1, updateLine1] = useState({
    points: [
      absolutePoints[0].x || 0,
      absolutePoints[0].y || 0,
      absolutePoints[1].x || 0,
      absolutePoints[1].y || 0
    ],
    stroke: getStroke()
  })

  const [line2, updateLine2] = useState({
    points: [
      absolutePoints[2] ? absolutePoints[2].x || 0 : 0,
      absolutePoints[2] ? absolutePoints[2].y || 0 : 0,
      absolutePoints[3] ? absolutePoints[3].x || 0 : 0,
      absolutePoints[3] ? absolutePoints[3].y || 0 : 0
    ],
    stroke: isHighlighted ? colorHighlightSpeed : colorDefaultSpeed
  })

  const dragBoundFunc = (pos) => ({
    x: pos.x < 0 ? 0 : pos.x > stage.attrs.width ? stage.attrs.width : pos.x,
    y: pos.y < 0 ? 0 : pos.y > stage.attrs.height ? stage.attrs.height : pos.y
  })

  /**
   * Handles mouse enter events.
   * @param event The event object.
   */
  const onMouseEnter = (event) => {
    if (!event.evt.target || !isEditable) {
      return
    }

    event.evt.target.style.cursor = isSelected ? 'move' : 'pointer'
  }

  /**
   * Handles mouse leave events.
   * @param event The event object.
   * @param cursor The cursor style to be used.
   */
  const onMouseLeave = (event, cursor = 'default') => {
    if (!event.evt.target) {
      return
    }

    event.evt.target.style.cursor = 'default'
  }

  const helpingLinePoints = [
    (point1.x + point2.x) / 2,
    (point1.y + point2.y) / 2,
    (point3.x + point4.x) / 2,
    (point3.y + point4.y) / 2
  ]

  function getStroke() {
    let color = isHighlighted ? colorHighlight : colorDefault
    if (eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled) {
      color = isHighlighted ? colorHighlightSpeed : colorDefaultSpeed
    }
    return color
  }
  return (
    <Group
      onDragStart={(event) => {
        onDragStart(event, eventTrigger)
      }}
      onDragMove={(event) => {
        onMouseEnter(event)
      }}
      onMouseOver={onMouseEnter}
      onMouseOut={onMouseLeave}
      onDragEnd={(event) => {
        let points =
          eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled
            ? [point1, point2, point3, point4]
            : [point1, point2]

        // Update all points if the whole group has been moved
        if (event.target.nodeType === 'Group') {
          const attrs = event.target.attrs
          points = points.map((point) => {
            point.x += attrs.x
            point.y += attrs.y
            return point
          })
        }

        onDragEnd(event, eventTrigger, points)
      }}
      onClick={(event) => {
        onClick(event, eventTrigger)
      }}
      draggable={!!isEditable && isHighlighted}
      dragBoundFunc={(pos) => {
        const edges = getEdges([point1, point2])

        if (edges.smallestX + pos.x < 0) {
          pos.x = -edges.smallestX
        }

        if (edges.biggestX + pos.x > stage.attrs.width) {
          pos.x = stage.attrs.width - edges.biggestX
        }

        if (edges.smallestY + pos.y < 0) {
          pos.y = -edges.smallestY
        }

        if (edges.biggestY + pos.y > stage.attrs.height) {
          pos.y = stage.attrs.height - edges.biggestY
        }

        return pos
      }}
    >
      <Line
        {...line1}
        stroke={getStroke()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled && (
        <Line
          {...line2}
          stroke={isHighlighted ? colorHighlightSpeed : colorDefaultSpeed}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
      <Circle x={point1.x} y={point1.y} radius={4} fill={getStroke()} />
      <Circle x={point2.x} y={point2.y} radius={4} fill={getStroke()} />
      {eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled && (
        <Circle x={point3.x} y={point3.y} radius={4} fill={getStroke()} />
      )}
      {eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled && (
        <Circle x={point4.x} y={point4.y} radius={4} fill={getStroke()} />
      )}
      <Circle
        {...point1}
        radius={10}
        stroke="white"
        strokeWidth={4}
        visible={!!isEditable && isSelected}
        onDragMove={(event) => {
          onMouseEnter(event)

          // Update point1
          updatePoint1({ ...point1, ...event.target.position() })
          const updatedLine = line1
          updatedLine.points[0] = event.target.position().x
          updatedLine.points[1] = event.target.position().y
          updateLine1(updatedLine)
        }}
        dragBoundFunc={dragBoundFunc}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Circle
        {...point2}
        radius={10}
        stroke="white"
        strokeWidth={4}
        visible={!!isEditable && isSelected}
        onDragMove={(event) => {
          onMouseEnter(event)
          // Update point2
          updatePoint2({ ...point2, ...event.target.position() })
          const updatedLine = line1
          updatedLine.points[2] = event.target.position().x
          updatedLine.points[3] = event.target.position().y
          updateLine1(updatedLine)
        }}
        dragBoundFunc={dragBoundFunc}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled && (
        <Circle
          {...point3}
          radius={10}
          stroke="white"
          strokeWidth={4}
          visible={!!isEditable && isSelected}
          onDragMove={(event) => {
            onMouseEnter(event)
            // Update point2
            updatePoint3({ ...point3, ...event.target.position() })
            const updatedLine = line2
            updatedLine.points[0] = event.target.position().x
            updatedLine.points[1] = event.target.position().y
            updateLine2(updatedLine)
          }}
          dragBoundFunc={dragBoundFunc}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
      {eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled && (
        <Circle
          {...point4}
          radius={10}
          stroke="white"
          strokeWidth={4}
          visible={!!isEditable && isSelected}
          onDragMove={(event) => {
            onMouseEnter(event)
            // Update point2
            updatePoint4({ ...point4, ...event.target.position() })
            const updatedLine = line2
            updatedLine.points[2] = event.target.position().x
            updatedLine.points[3] = event.target.position().y
            updateLine2(updatedLine)
          }}
          dragBoundFunc={dragBoundFunc}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
      {eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled && (
        <Line
          stroke={isHighlighted ? colorHighlightSpeed : colorDefaultSpeed}
          dash={[7, 7]}
          points={helpingLinePoints}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      )}
      {eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled && (
        <Text
          text={`${eventTrigger.speedEstimation.distance.toString()} M `}
          align="center"
          verticalAlign="middle"
          fontStyle="bold"
          fontSize={16}
          width={100}
          height={16}
          x={(point1.x + point2.x + point3.x + point4.x) / 4}
          y={(point1.y + point2.y + point3.y + point4.y) / 4}
          offsetY={-10}
          offsetX={50}
          fill={isHighlighted ? colorHighlightSpeed : colorDefaultSpeed}
          rotation={getRotationDegreeBetween(
            { x: helpingLinePoints[0], y: helpingLinePoints[1] },
            { x: helpingLinePoints[2], y: helpingLinePoints[3] },
            (point1.x + point2.x) / 2 < (point3.x + point4.x) / 2 ? 0 : 180
          )}
        />
      )}
      {(!eventTrigger.speedEstimation ||
        !eventTrigger.speedEstimation.enabled) && (
        <Group
          x={(point1.x + point2.x) / 2}
          y={(point1.y + point2.y) / 2}
          height={0}
          width={0}
          rotation={getRotationDegreeBetween(point1, point2)}
        >
          <Text
            text="OUT"
            align="center"
            verticalAlign="middle"
            fontStyle="bold"
            fontSize={16}
            width={34}
            height={16}
            x={40}
            y={0}
            offsetX={17}
            offsetY={8}
            fill={isHighlighted ? colorHighlight : colorDefault}
            rotation={eventTrigger.direction === 'in' ? 0 : 180}
            onClick={(evt) => evt.currentTarget.cache({ drawBorder: true })}
          />
          <Text
            text="IN"
            align="center"
            verticalAlign="middle"
            fontStyle="bold"
            fontSize={16}
            width={16}
            height={16}
            x={-30}
            y={0}
            offsetX={8}
            offsetY={8}
            fill={isHighlighted ? colorHighlight : colorDefault}
            rotation={eventTrigger.direction === 'in' ? 0 : 180}
          />
          <RegularPolygon
            sides={3}
            x={0}
            y={0}
            radius={14}
            height={14}
            width={14}
            offsetX={0}
            offsetY={10}
            fill={isHighlighted ? colorHighlight : colorDefault}
            rotation={90}
          />
          <RegularPolygon
            sides={3}
            x={0}
            y={0}
            radius={14}
            height={14}
            width={14}
            offsetX={0}
            offsetY={10}
            fill={isHighlighted ? colorHighlight : colorDefault}
            rotation={270}
          />
        </Group>
      )}
      {eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled && (
        <Group
          x={(point1.x + point2.x) / 2}
          y={(point1.y + point2.y) / 2}
          height={0}
          width={0}
          rotation={
            getRotationDegreeBetween(point1, point2) +
            getFlipViaAngle(point1, point2, helpingLinePoints)
          }
        >
          <Text
            text="OUT"
            align="center"
            verticalAlign="middle"
            fontStyle="bold"
            fontSize={16}
            width={34}
            height={16}
            x={-40}
            y={0}
            offsetX={17}
            offsetY={8}
            fill={isHighlighted ? colorHighlightSpeed : colorDefaultSpeed}
            rotation={
              (point1.y < point2.y ? 0 : 180) +
              getFlipViaAngle(point1, point2, helpingLinePoints)
            }
          />
          <RegularPolygon
            sides={3}
            x={0}
            y={0}
            radius={14}
            height={14}
            width={14}
            offsetX={0}
            offsetY={10}
            fill={isHighlighted ? colorHighlightSpeed : colorDefaultSpeed}
            rotation={270}
          />
        </Group>
      )}
      {eventTrigger.speedEstimation && eventTrigger.speedEstimation.enabled && (
        <Group
          x={(point3.x + point4.x) / 2}
          y={(point3.y + point4.y) / 2}
          height={0}
          width={0}
          rotation={
            getRotationDegreeBetween(point3, point4) +
            getFlipViaAngle(point4, point3, helpingLinePoints)
          }
        >
          <Text
            text="IN"
            align="center"
            verticalAlign="middle"
            fontStyle="bold"
            fontSize={16}
            width={16}
            height={16}
            x={-30}
            y={0}
            offsetX={8}
            offsetY={8}
            fill={isHighlighted ? colorHighlightSpeed : colorDefaultSpeed}
            rotation={
              (point3.y < point4.y ? 0 : 180) +
              getFlipViaAngle(point4, point3, helpingLinePoints)
            }
          />
          <RegularPolygon
            sides={3}
            x={0}
            y={0}
            radius={14}
            height={14}
            width={14}
            offsetX={0}
            offsetY={10}
            fill={isHighlighted ? colorHighlightSpeed : colorDefaultSpeed}
            rotation={270}
          />
        </Group>
      )}
    </Group>
  )
}

/**
 * Calculates the angle in degrees between two points.
 * @param pt1 The firts point.
 * @param pt2 The second point.
 * @param offset The offset to shift the calculated angle, in order to
 * have the labels in a 90 degree angle to the line.
 * @returns The calculated angle corrected by the offset angle.
 */
const getRotationDegreeBetween = (pt1, pt2, offset = 90) => {
  return (Math.atan2(pt2.y - pt1.y, pt2.x - pt1.x) * 180) / Math.PI - offset
}

const positiveModulo = (n, m) => {
  return ((n % m) + m) % m
}

function getFlipViaAngle(point1, point2, helpingLinePoints) {
  return positiveModulo(
    getRotationDegreeBetween(point1, point2, 0) -
      getRotationDegreeBetween(
        {
          x: helpingLinePoints[0],
          y: helpingLinePoints[1]
        },
        { x: helpingLinePoints[2], y: helpingLinePoints[3] },
        0
      ),
    360
  ) < 180
    ? 0
    : 180
}
