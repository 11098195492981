import './OnvifModal.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Space, Spin, Table } from 'antd'
import {
  CheckCircleTwoTone,
  QuestionCircleTwoTone,
  WarningTwoTone
} from '@ant-design/icons'
import { ICameraSettings } from '../../types/onvif'
import { objIsEmpty, getCameraSettingsDelta } from './OnvifHelper'

interface IBasicOnvifSettingsProps {
  fetching: boolean
  applying: boolean
  currentSettings: ICameraSettings
  recommendedSettings: ICameraSettings
  applyRecommendations: any
}

const BasicOnvifSettings: React.FC<IBasicOnvifSettingsProps> = (props) => {
  const { t } = useTranslation()

  let recommendationDelta
  let settingsTableContent

  if (!props.fetching && props.currentSettings && props.recommendedSettings) {
    recommendationDelta = getCameraSettingsDelta(
      props.currentSettings,
      props.recommendedSettings
    )
    settingsTableContent = prepareRecommendations(
      props.currentSettings,
      recommendationDelta,
      t
    )
  }

  const columns = [
    {
      title: t('camera.frame.onvifSettings.basicColumns.setting'),
      dataIndex: 'setting',
      key: 'setting'
    },
    {
      title: t('camera.frame.onvifSettings.basicColumns.current'),
      dataIndex: 'current',
      key: 'current'
    },
    {
      title: t('camera.frame.onvifSettings.basicColumns.recommendation'),
      dataIndex: 'recommendation',
      key: 'recommendation'
    },
    {
      title: '',
      dataIndex: 'state',
      key: 'state',
      width: 150
    }
  ]

  const disabledRows = (record, index) => {
    return record.current.length === 0 ? 'scc--tablerow-disabled' : ''
  }

  return (
    <div className="scc--onvif-basic">
      <Space size="large" direction="vertical">
        <p>{t('camera.frame.onvifSettings.basicExplanation')}</p>
        <Table
          dataSource={settingsTableContent}
          columns={columns}
          pagination={false}
          key="scc--onvif-basic-table"
          loading={props.fetching || props.applying}
          rowClassName={disabledRows}
        />
        <p>{t('camera.frame.onvifSettings.applyExplanation')}</p>
        {props.applying ? (
          <Button type="primary" disabled={true}>
            <Spin size="small" />
            {t('camera.frame.onvifSettings.applying')}
          </Button>
        ) : (
          <Button
            type="primary"
            disabled={objIsEmpty(recommendationDelta)}
            onClick={() => {
              props.applyRecommendations(recommendationDelta)
            }}
          >
            {t('camera.frame.onvifSettings.applyRecommendations')}
          </Button>
        )}
      </Space>
    </div>
  )
}

function prepareRecommendations(
  current: ICameraSettings,
  delta: ICameraSettings,
  t: any
) {
  const isNull = (value) => {
    return value === null || value === undefined
  }
  const getStateIcon = (recommendedValue, actualValue) => {
    if (actualValue === null) {
      return <QuestionCircleTwoTone twoToneColor="lightgray" />
    }
    return recommendedValue === null || recommendedValue === undefined ? (
      <CheckCircleTwoTone twoToneColor="#00a86b" />
    ) : (
      <WarningTwoTone twoToneColor="orange" />
    )
  }
  const formatResolution = (value) => {
    return isNull(value) ? '' : `${value.width}x${value.height}`
  }
  const formatBitrate = (value) => {
    return isNull(value) ? '' : `${value} Kbps`
  }
  const formatFramerate = (value) => {
    return isNull(value) ? '' : `${value} FPS`
  }

  const formatOverlays = (value) => {
    if (isNull(value)) {
      return ''
    }
    return value
      ? t('camera.frame.onvifSettings.settings.overlaysEnabled')
      : t('camera.frame.onvifSettings.settings.overlaysDisabled')
  }

  let curCodec = current.cameraCodec && current.cameraCodec.codec
  let recCodec = delta.cameraCodec && delta.cameraCodec.codec
  let curResolution =
    current.cameraResolutionInfo &&
    current.cameraResolutionInfo.currentResolution
  let recResolution =
    delta.cameraResolutionInfo && delta.cameraResolutionInfo.currentResolution
  let curBitrate = current.cameraBitRate && current.cameraBitRate.rate
  let recBitrate = delta.cameraBitRate && delta.cameraBitRate.rate
  let curFrameRate = current.cameraFrameRate && current.cameraFrameRate.rate
  let recFrameFrate = delta.cameraFrameRate && delta.cameraFrameRate.rate
  let curOverlays =
    current.cameraImageSettings && current.cameraImageSettings.hasOSD
  let recOverlays =
    delta.cameraImageSettings && delta.cameraImageSettings.hasOSD

  return [
    {
      setting: t('camera.frame.onvifSettings.settings.codec'),
      current: isNull(curCodec) ? '' : curCodec,
      recommendation: isNull(recCodec) ? '' : recCodec,
      state: getStateIcon(recCodec, curCodec),
      key: 'codec'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.resolution'),
      current: formatResolution(curResolution),
      recommendation: formatResolution(recResolution),
      state: getStateIcon(recResolution, curResolution),
      key: 'resolution'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.bitrate'),
      current: formatBitrate(curBitrate),
      recommendation: formatBitrate(recBitrate),
      state: getStateIcon(recBitrate, curBitrate),
      key: 'bitrate'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.framerate'),
      current: formatFramerate(curFrameRate),
      recommendation: formatFramerate(recFrameFrate),
      state: getStateIcon(recFrameFrate, curFrameRate),
      key: 'framerate'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.overlays'),
      current: formatOverlays(curOverlays),
      recommendation: formatOverlays(recOverlays),
      state: getStateIcon(recOverlays, curOverlays),
      key: 'overlays'
    }
  ]
}

export default BasicOnvifSettings
