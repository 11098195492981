import _ from 'lodash'

export function getCameraSettingsDelta(settings1: any, settings2: any) {
  let compare1 = Object.assign({}, settings1)
  let compare2 = Object.assign({}, settings2)

  // do not compare available resolutions and available frame rates
  if (compare1.cameraResolutionInfo) {
    delete compare1.cameraResolutionInfo.availableResolutions
    compare1.cameraFrameRate && delete compare1.cameraFrameRate.validRates
  }
  if (compare2.cameraResolutionInfo) {
    delete compare2.cameraResolutionInfo.availableResolutions
    compare2.cameraFrameRate && delete compare2.cameraFrameRate.validRates
  }

  const delta = compareObjects(compare2, compare1)
  delta.cameraImageSettings &&
    (delta.cameraImageSettings = compareObjects(
      compare2.cameraImageSettings,
      compare1.cameraImageSettings
    ))
  return delta
}

function compareObjects(obj1, obj2) {
  const delta: any = {}
  Object.entries(obj1).forEach(([key, value]) => {
    if (
      value !== null &&
      value !== undefined &&
      obj2[key] != null &&
      !_.isEqual(obj2[key], value)
    ) {
      delta[key] = value
    }
  })
  return delta
}

export function objIsEmpty(obj) {
  if (!obj) {
    return true
  }
  for (let key in obj) {
    if (obj[key] instanceof Object) {
      if (!objIsEmpty(obj[key])) return false
    } else {
      if (obj[key].length !== 0) return false
    }
  }
  return true
}
