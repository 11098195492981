/**
 * Finds a deep nested property by key path.
 * @param keyPath string The property key path.
 * @param object object The object to look in.
 */
export function propertyByPath(keyPath: string, object: object): any {
  const keyParts = keyPath.split('.')

  if (keyParts.length === 0) {
    return null
  }

  return keyParts.reduce(
    (obj, key) => (obj && obj[key] ? obj[key] : null),
    object
  )
}

/**
 * Trims all string attributes of the provided Object.
 * @param obj: Object The object which properties should be trimmed.
 * @returns The object with trimmed string properties.
 */
export function trimObjectValues(obj: Object) {
  return Object.keys(obj).reduce((acc, curr) => {
    acc[curr] = typeof obj[curr] === 'string' ? obj[curr].trim() : obj[curr]
    return acc
  }, {})
}
