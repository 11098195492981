import {
  ImmutableTree,
  JsonTree,
  Utils as QbUtils
} from 'react-awesome-query-builder'
import {
  EEventTriggerType,
  ERuleTemplateType,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import { clConfig, odConfig, roiConfig, vdConfig } from './config'
import { mapTriggersForQueryConfig } from './index'

export const RuleDefaultQueryCl: JsonTree = {
  id: QbUtils.uuid(),
  type: 'group',
  children1: {
    [QbUtils.uuid()]: {
      type: 'rule',
      properties: {
        field: 'cl.class',
        operator: 'select_equals',
        value: [null],
        valueSrc: ['value'],
        valueType: ['select']
      }
    }
  }
} as JsonTree

export const RuleDefaultQueryOD: JsonTree = {
  id: QbUtils.uuid(),
  type: 'group',
  children1: {
    [QbUtils.uuid()]: {
      type: 'rule',
      properties: {
        field: 'od.class',
        operator: 'select_equals',
        value: [null],
        valueSrc: ['value'],
        valueType: ['select']
      }
    }
  }
} as JsonTree

export const RuleDefaultQueryVd: JsonTree = {
  id: QbUtils.uuid(),
  type: 'group',
  children1: {
    [QbUtils.uuid()]: {
      type: 'rule',
      properties: {
        field: 'vd.class',
        operator: 'select_equals',
        value: ['null'],
        valueSrc: ['value'],
        valueType: ['select']
      }
    }
  }
} as JsonTree

export const RuleDefaultQueryRoi: JsonTree = {
  id: QbUtils.uuid(),
  type: 'group',
  children1: {
    [QbUtils.uuid()]: {
      type: 'rule',
      properties: {
        field: 'roi.state',
        operator: 'select_equals',
        value: [null],
        valueSrc: ['value'],
        valueType: ['select']
      }
    }
  }
} as JsonTree

export const getDefaultQuery = (
  eventTrigger: string | undefined,
  eventTriggerType: EEventTriggerType | undefined,
  allTriggers: ICoordinateBasedEventTrigger[]
): ImmutableTree => {
  if (!eventTrigger) {
    return getDefaultQueryGlobalTrigger(eventTriggerType, allTriggers)
  }
  switch (eventTriggerType) {
    case EEventTriggerType.crossingLine:
      return QbUtils.checkTree(QbUtils.loadTree(RuleDefaultQueryCl), clConfig)
    case EEventTriggerType.virtualDoor:
      return QbUtils.checkTree(QbUtils.loadTree(RuleDefaultQueryVd), vdConfig)
    case EEventTriggerType.regionOfInterest:
      return QbUtils.checkTree(QbUtils.loadTree(RuleDefaultQueryRoi), roiConfig)
  }
  console.error(
    'RuleEngine: Failed to load default tree for ' + eventTriggerType + 'rule'
  )
  throw Error(
    'RuleEngine: Failed to load tree config for ' + eventTriggerType + 'rule'
  )
}

const getDefaultQueryGlobalTrigger = (
  eventTriggerType: EEventTriggerType | undefined,
  allTriggers: ICoordinateBasedEventTrigger[]
): ImmutableTree => {
  // check will get relevant when further global configs are available
  if (eventTriggerType === EEventTriggerType.originDestinationZone) {
    return QbUtils.checkTree(
      QbUtils.loadTree(RuleDefaultQueryOD),
      odConfig(mapTriggersForQueryConfig(allTriggers, eventTriggerType))
    )
  }
  console.error(
    'RuleEngine: Failed to load default tree for global ' +
      eventTriggerType +
      'rule'
  )
  throw Error(
    'RuleEngine: Failed to load default tree for global ' +
      eventTriggerType +
      'rule'
  )
}

export interface RuleTriggerType {
  type: ERuleTemplateType
  triggerType: EEventTriggerType
}

export const RuleTemplateTypes: RuleTriggerType[] = [
  {
    type: ERuleTemplateType.WrongWay,
    triggerType: EEventTriggerType.crossingLine
  },
  {
    type: ERuleTemplateType.WrongWayDoor,
    triggerType: EEventTriggerType.virtualDoor
  },
  {
    type: ERuleTemplateType.Speeding,
    triggerType: EEventTriggerType.crossingLine
  },
  {
    type: ERuleTemplateType.UnexpectedClass,
    triggerType: EEventTriggerType.crossingLine
  },
  {
    type: ERuleTemplateType.HeavyTraffic,
    triggerType: EEventTriggerType.originDestinationZone
  },
  {
    type: ERuleTemplateType.TrafficJam,
    triggerType: EEventTriggerType.regionOfInterest
  },
  {
    type: ERuleTemplateType.PersonOnStreet,
    triggerType: EEventTriggerType.regionOfInterest
  },

  {
    type: ERuleTemplateType.UTurn,
    triggerType: EEventTriggerType.originDestinationZone
  }
]

export const RuleTemplates = {
  [ERuleTemplateType.WrongWayDoor]: {
    id: QbUtils.uuid(),
    type: 'group',
    children1: {
      [QbUtils.uuid()]: {
        type: 'rule',
        properties: {
          field: 'vd.direction',
          operator: 'select_equals',
          value: ['out'],
          valueSrc: ['value'],
          valueType: ['select']
        }
      },
      [QbUtils.uuid()]: {
        type: 'rule',
        properties: {
          field: 'vd.class',
          operator: 'select_equals',
          value: ['person'],
          valueSrc: ['value'],
          valueType: ['select']
        }
      }
    }
  } as JsonTree,
  [ERuleTemplateType.WrongWay]: {
    id: QbUtils.uuid(),
    type: 'group',
    children1: {
      [QbUtils.uuid()]: {
        type: 'rule',
        properties: {
          field: 'cl.direction',
          operator: 'select_equals',
          value: ['out'],
          valueSrc: ['value'],
          valueType: ['select']
        }
      },
      [QbUtils.uuid()]: {
        type: 'rule',
        properties: {
          field: 'cl.class',
          operator: 'select_not_any_in',
          value: [['bicycle', 'person', 'scooter']],
          valueSrc: ['value'],
          valueType: ['multiselect']
        }
      }
    }
  } as JsonTree,
  [ERuleTemplateType.HeavyTraffic]: {
    id: QbUtils.uuid(),
    type: 'group',
    children1: {
      [QbUtils.uuid()]: {
        type: 'rule',
        properties: {
          field: 'od.class',
          operator: 'select_any_in',
          value: [['truck', 'bus']],
          valueSrc: ['value'],
          valueType: ['multiselect']
        }
      }
    }
  } as JsonTree,
  [ERuleTemplateType.UnexpectedClass]: {
    id: QbUtils.uuid(),
    type: 'group',
    children1: {
      [QbUtils.uuid()]: {
        type: 'rule',
        properties: {
          field: 'cl.class',
          operator: 'select_equals',
          value: ['person'],
          valueSrc: ['value'],
          valueType: ['select']
        }
      }
    }
  } as JsonTree,
  [ERuleTemplateType.Speeding]: {
    id: QbUtils.uuid(),
    type: 'group',
    children1: {
      [QbUtils.uuid()]: {
        type: 'rule',
        properties: {
          field: 'cl.speedEstimate',
          operator: 'greater',
          value: [60],
          valueSrc: ['value'],
          valueType: ['number']
        }
      }
    }
  } as JsonTree,
  [ERuleTemplateType.TrafficJam]: {
    id: QbUtils.uuid(),
    type: 'group',
    children1: {
      [QbUtils.uuid()]: {
        type: 'rule_group',
        properties: {
          conjunction: 'AND',
          not: false,
          field: 'roi.objects',
          mode: 'array',
          operator: 'greater_or_equal',
          value: [1],
          valueSrc: ['value'],
          valueType: ['number']
        },
        children1: {
          [QbUtils.uuid()]: {
            type: 'rule',
            properties: {
              field: 'roi.objects.dwellTime',
              operator: 'greater',
              value: [60],
              valueSrc: ['value'],
              valueType: ['number']
            }
          },
          [QbUtils.uuid()]: {
            type: 'rule',
            properties: {
              field: 'roi.objects.class',
              operator: 'select_any_in',
              value: [['car', 'truck', 'bus']],
              valueSrc: ['value'],
              valueType: ['multiselect']
            }
          }
        }
      }
    }
  } as JsonTree,
  [ERuleTemplateType.PersonOnStreet]: {
    id: QbUtils.uuid(),
    type: 'group',
    children1: {
      [QbUtils.uuid()]: {
        type: 'rule_group',
        properties: {
          conjunction: 'AND',
          not: false,
          field: 'roi.objects',
          mode: 'array',
          operator: 'greater_or_equal',
          value: [1],
          valueSrc: ['value'],
          valueType: ['number']
        },
        children1: {
          [QbUtils.uuid()]: {
            type: 'rule',
            properties: {
              field: 'roi.objects.dwellTime',
              operator: 'greater',
              value: [5],
              valueSrc: ['value'],
              valueType: ['number']
            }
          },
          [QbUtils.uuid()]: {
            type: 'rule',
            properties: {
              field: 'roi.objects.class',
              operator: 'select_equals',
              value: ['person'],
              valueSrc: ['value'],
              valueType: ['select']
            }
          }
        }
      }
    }
  } as JsonTree,
  [ERuleTemplateType.UTurn]: {
    id: QbUtils.uuid(),
    type: 'group',
    children1: {
      [QbUtils.uuid()]: {
        type: 'rule',
        properties: {
          field: 'od.entryZoneId',
          operator: 'select_equals',
          value: ['od.exitZoneId'],
          valueSrc: ['field'],
          valueType: ['select']
        }
      }
    }
  } as JsonTree
}
