import {
  AbstractCoordinateBasedEventTrigger,
  EEventTriggerType
} from './eventTrigger'
import { uuid } from 'lodash-uuid'

export enum ERegionOfInterestTriggerType {
  time = 'time',
  change = 'change',
  rule = 'rule'
}

export enum ERegionOfInterestType {
  generic = 'generic',
  parking = 'parking',
  focus = 'focus'
}

export class RegionOfInterestEventTrigger extends AbstractCoordinateBasedEventTrigger {
  private trigger?: ERegionOfInterestTriggerType
  public roiType: ERegionOfInterestType
  public capacity?: number

  constructor(props) {
    super(props)
    this.objectType = EEventTriggerType.regionOfInterest
    if (props.roiType && props.roiType === ERegionOfInterestType.focus) {
      this.roiType = ERegionOfInterestType.focus
      this.trigger = undefined
      this.capacity = undefined
    } else {
      this.trigger = props.trigger || ERegionOfInterestTriggerType.time
      this.roiType = props.roiType || ERegionOfInterestType.generic
      this.capacity = props.capacity || 1
    }
  }

  public static default(
    roiType = ERegionOfInterestType.generic,
    capacity = 1
  ): RegionOfInterestEventTrigger {
    return new RegionOfInterestEventTrigger({
      localId: uuid(),
      trigger: ERegionOfInterestTriggerType.time,
      roiType: roiType,
      capacity: capacity,
      coordinates: [
        { x: 0.4, y: 0.3 },
        { x: 0.6, y: 0.3 },
        { x: 0.6, y: 0.6 },
        { x: 0.4, y: 0.6 }
      ]
    })
  }
}

export const isFocusArea = (event) =>
  event.objectType === EEventTriggerType.regionOfInterest &&
  (event as RegionOfInterestEventTrigger).roiType ===
    ERegionOfInterestType.focus
