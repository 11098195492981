import App from '../components/App'
import { Provider } from 'react-redux'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from '../provider/AuthProvider'
import { Config } from '../services/config'
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider
} from '@azure/msal-react'
import { EventType, InteractionType } from '@azure/msal-browser'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false
    }
  }
})

// Default to using the first account if no account is active on page load
if (
  !AuthProvider.getActiveAccount() &&
  AuthProvider.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  AuthProvider.setActiveAccount(AuthProvider.getAllAccounts()[0])
}

AuthProvider.addEventCallback((event) => {
  // @ts-ignore
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    // @ts-ignore
    const account = event.payload.account
    AuthProvider.setActiveAccount(account)
  }
})

const MsalWrapper = ({ store }) => {
  const authRequest = {
    scopes: Config.isB2C
      ? [Config.AAD_APP_CLIENT_ID]
      : ['openid', 'profile', 'offline_access'],
    authority: Config.AUTHORITY
  }
  AuthProvider.handleRedirectPromise().then((res) => {
    const account = AuthProvider.getActiveAccount()
    if (!account) {
      //  @ts-ignore
      AuthProvider.loginRedirect(authRequest)
    }
  })

  return (
    <>
      <AuthenticatedTemplate>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Provider>
      </AuthenticatedTemplate>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      />
    </>
  )
}

const Root = ({ store }) => {
  return (
    <>
      <MsalProvider instance={AuthProvider}>
        <MsalWrapper store={store} />
      </MsalProvider>
    </>
  )
}

export default Root
