import { TypeToMemoChartComponent } from '../../datadiscovery/components/ChartRenderer'
import { NamedExoticComponent } from 'react'
import { PivotConfig } from '@cubejs-client/core'
import { IBox } from '../../types/box'

export const statusValues = [
  'OPERATIONAL',
  'WARNING',
  'NOT_OPERATIONAL',
  'PENDING',
  'DISABLED',
  'NOT_CONFIGURED',
  'UNKNOWN',
  'STANDBY'
]

export const getAreaChartSeriesName = (
  key: string,
  t: (arg0: string) => any
) => {
  if (
    statusValues.some(
      (value) => healthStatusPrefix + value === key.split('.')[1]
    )
  ) {
    return t(
      'boxList.dataTable.runtimeStatus.' + key.split(healthStatusPrefix)[1]
    )
  } else if (key === 'DeviceHealthEvents.restarts_detected') {
    return t('configuration.health.device.restarts.text')
  } else if (key === 'DeviceHealthEvents.modemReconnectsDetected') {
    return t('configuration.health.device.modemReconnects.text')
  }
}

export interface IHealthWidgetProps {
  component: NamedExoticComponent<any>
  renderProps: any
  pivotConfig: PivotConfig
  width: string
  title: string | undefined
  tooltip?: string
  hideIfEmpty?: string[]
}

export const healthStatusPrefix = 'percentage_of_'

export const deviceStatusQuery = (box: IBox) => ({
  measures: statusValues
    .map((value) => 'DeviceHealthEvents.' + healthStatusPrefix + value)
    .concat([
      'DeviceHealthEvents.diskAvailablePercentage',
      'DeviceHealthEvents.restarts_detected',
      'DeviceHealthEvents.temperature',
      'DeviceHealthEvents.modemUpload',
      'DeviceHealthEvents.modemDownload',
      'DeviceHealthEvents.modemReconnectsDetected',
      'DeviceHealthEvents.modemSignalStrength'
    ]),
  dimensions: [],
  timeDimensions: [
    {
      dimension: 'DeviceHealthEvents.timestamp',
      granularity: 'hour',
      dateRange: 'from 168 hours ago to now'
    }
  ],
  timezone: 'Etc/UTC',
  filters: [
    {
      member: 'DeviceHealthEvents.deviceId',
      operator: 'equals',
      values: [box.id]
    }
  ]
})

export const deviceUptimeQuery = (box: IBox) => ({
  measures: ['DeviceUptime.uptime', 'DeviceUptime.last_seen'],
  dimensions: [],
  filters: [
    {
      member: 'DeviceUptime.deviceId',
      operator: 'equals',
      values: [box.id]
    }
  ]
})

export const streamStatusQuery = (streams: any[]) => ({
  measures: statusValues
    .map((value) => 'StreamHealthEvents.' + healthStatusPrefix + value)
    .concat([
      'StreamHealthEvents.fps',
      'StreamHealthEvents.totalEvents',
      'StreamHealthEvents.pendingEvents'
    ]),
  dimensions: ['StreamHealthEvents.streamId'],
  timeDimensions: [
    {
      dimension: 'StreamHealthEvents.timestamp',
      granularity: 'hour',
      dateRange: 'from 168 hours ago to now'
    }
  ],
  timezone: 'Etc/UTC',
  filters: [
    {
      member: 'StreamHealthEvents.streamId',
      operator: 'equals',
      values: streams.map((stream) => stream.id)
    }
  ]
})

// wrong positive
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deviceWidgetProps: (
  deviceRenderProps: any,
  t: any
) => IHealthWidgetProps[] = (deviceRenderProps, t) => [
  {
    component: TypeToMemoChartComponent['healthChartArea'],
    renderProps: deviceRenderProps,
    pivotConfig: {
      x: ['DeviceHealthEvents.timestamp'],
      y: statusValues.map(
        (value) => 'DeviceHealthEvents.' + healthStatusPrefix + value
      ),
      fillMissingDates: true
    },
    title: t('configuration.health.device.status'),
    width: '99%'
  },
  {
    component: TypeToMemoChartComponent['healthChartLine'],
    renderProps: deviceRenderProps,
    pivotConfig: {
      x: ['DeviceHealthEvents.timestamp'],
      y: ['DeviceHealthEvents.diskAvailablePercentage'],
      fillMissingDates: false,
      aliasSeries: [t('configuration.health.device.diskAvailable')]
    },
    title: t('configuration.health.device.diskAvailable'),
    width: '49%'
  },
  {
    component: TypeToMemoChartComponent['healthChartArea'],
    renderProps: deviceRenderProps,
    pivotConfig: {
      x: ['DeviceHealthEvents.timestamp'],
      y: ['DeviceHealthEvents.restarts_detected'],
      fillMissingDates: true
    },
    title: t('configuration.health.device.restarts.text'),
    width: '49%',
    tooltip: 'configuration.health.device.restarts.tooltip'
  },
  {
    component: TypeToMemoChartComponent['healthChartLine'],
    renderProps: deviceRenderProps,
    pivotConfig: {
      x: ['DeviceHealthEvents.timestamp'],
      y: ['DeviceHealthEvents.temperature'],
      fillMissingDates: false,
      aliasSeries: [t('configuration.health.device.temperature.text')]
    },
    title: t('configuration.health.device.temperature.text'),
    width: '49%',
    hideIfEmpty: ['DeviceHealthEvents.temperature']
  }
]

export const deviceWidgetModemProps: (
  deviceRenderProps: any,
  t: any
) => IHealthWidgetProps[] = (deviceRenderProps, t) => [
  {
    component: TypeToMemoChartComponent['healthChartLine'],
    renderProps: deviceRenderProps,
    pivotConfig: {
      x: ['DeviceHealthEvents.timestamp'],
      y: ['DeviceHealthEvents.modemUpload', 'DeviceHealthEvents.modemDownload'],
      fillMissingDates: true,
      aliasSeries: [
        t('configuration.health.device.modemUpload'),
        t('configuration.health.device.modemDownload')
      ]
    },
    title: t('configuration.health.device.modemTraffic.text'),
    width: '49%',
    hideIfEmpty: [
      'DeviceHealthEvents.modemUpload',
      'DeviceHealthEvents.modemDownload'
    ],
    tooltip: 'configuration.health.device.modemTraffic.tooltip'
  },
  {
    component: TypeToMemoChartComponent['healthChartArea'],
    renderProps: deviceRenderProps,
    pivotConfig: {
      x: ['DeviceHealthEvents.timestamp'],
      y: ['DeviceHealthEvents.modemReconnectsDetected'],
      fillMissingDates: true
    },
    title: t('configuration.health.device.modemReconnects.text'),
    width: '49%',
    hideIfEmpty: [
      'DeviceHealthEvents.modemUpload',
      'DeviceHealthEvents.modemDownload'
    ],
    tooltip: 'configuration.health.device.modemReconnects.tooltip'
  },
  {
    component: TypeToMemoChartComponent['healthChartLine'],
    renderProps: deviceRenderProps,
    pivotConfig: {
      x: ['DeviceHealthEvents.timestamp'],
      y: ['DeviceHealthEvents.modemSignalStrength'],
      fillMissingDates: false,
      aliasSeries: [t('configuration.health.device.modemSignalStrength.text')]
    },
    title: t('configuration.health.device.modemSignalStrength.text'),
    width: '49%',
    hideIfEmpty: ['DeviceHealthEvents.modemSignalStrength']
  }
]

export const deviceLatestWidgetProps: (
  deviceLatestRenderProps: any,
  t: any
) => IHealthWidgetProps[] = (deviceLatestRenderProps, t) => [
  {
    component: TypeToMemoChartComponent['number'],
    renderProps: deviceLatestRenderProps,
    pivotConfig: {
      x: [],
      y: ['DeviceUptime.uptime']
    },
    title: t('configuration.health.device.uptime.text'),
    width: '49%',
    tooltip: 'configuration.health.device.uptime.tooltip'
  },
  {
    component: TypeToMemoChartComponent['number'],
    renderProps: deviceLatestRenderProps,
    pivotConfig: {
      x: [],
      y: ['DeviceUptime.last_seen']
    },
    title: t('configuration.health.device.lastSeen.text'),
    width: '49%',
    tooltip: 'configuration.health.device.lastSeen.tooltip'
  }
]

export const streamWidgetProps: (
  streamRenderProps: any,
  t: any
) => IHealthWidgetProps[] = (streamRenderProps, t) => [
  {
    component: TypeToMemoChartComponent['healthChartArea'],
    renderProps: streamRenderProps,
    pivotConfig: {
      x: ['StreamHealthEvents.timestamp', 'StreamHealthEvents.streamId'],
      y: statusValues.map(
        (value) => 'StreamHealthEvents.' + healthStatusPrefix + value
      ),
      fillMissingDates: true
    },
    title: t('configuration.health.stream.status'),
    width: '99%'
  },
  {
    component: TypeToMemoChartComponent['healthChartLine'],
    renderProps: streamRenderProps,
    pivotConfig: {
      x: ['StreamHealthEvents.timestamp', 'StreamHealthEvents.streamId'],
      y: ['StreamHealthEvents.fps'],
      fillMissingDates: true,
      aliasSeries: [t('configuration.health.stream.fps')]
    },
    title: t('configuration.health.stream.fps'),
    width: '49%'
  },
  {
    component: TypeToMemoChartComponent['healthChartLine'],
    renderProps: streamRenderProps,
    pivotConfig: {
      x: ['StreamHealthEvents.timestamp', 'StreamHealthEvents.streamId'],
      y: ['StreamHealthEvents.totalEvents'],
      fillMissingDates: true,
      aliasSeries: [t('configuration.health.stream.totalEvents.text')]
    },
    title: t('configuration.health.stream.totalEvents.text'),
    width: '49%',
    tooltip: 'configuration.health.stream.totalEvents.tooltip'
  },
  {
    component: TypeToMemoChartComponent['healthChartLine'],
    renderProps: streamRenderProps,
    pivotConfig: {
      x: ['StreamHealthEvents.timestamp', 'StreamHealthEvents.streamId'],
      y: ['StreamHealthEvents.pendingEvents'],
      fillMissingDates: true,
      aliasSeries: [t('configuration.health.stream.pendingEvents.text')]
    },
    title: t('configuration.health.stream.pendingEvents.text'),
    width: '49%',
    tooltip: 'configuration.health.stream.pendingEvents.tooltip'
  }
]
