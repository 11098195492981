import './BoxList.scss'

import { ConnectionStatusCell, NameIdCell } from './cells'
import { useHistory, useLocation } from 'react-router-dom'

import EmptyBoxListText from './EmptyBoxListText'
import { EBoxUpdateStatus, IBox } from '../../types/box'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars-2'
import OperationalStatusCell from './cells/OperationalStatusCell'
import { EConnectionState } from '../../types/boxStatus'
import { Button, Input, Space, Switch, Table, Tag } from 'antd'
import {
  FilterOutlined,
  InfoCircleOutlined,
  SearchOutlined
} from '@ant-design/icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ERuntimeState } from '../../types/runtimeState'
import { Config } from '../../services/config'
import {
  getTokenInformation,
  leaveHeaderEmptyOnMissingOrMainTenant
} from '../../services/apiTokenProvider'
import { clipLongText } from '../BoxDetailsHeader/MetadataTable'

const LOCAL_STORAGE_FILTERS = 'deviceListFilter'
const LOCAL_STORAGE_SORTER = 'deviceListSorter'

const PAGE_SIZE = 15

interface IBoxListProps {
  boxes: IBox[]
  boxesFetched: boolean
  autoRefresh: boolean
  setAutoRefresh: Function
}

const BoxList: React.FC<IBoxListProps> = ({
  boxes,
  boxesFetched,
  autoRefresh,
  setAutoRefresh
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [selectedRow, setSelectedRow] = useState<string | null>(null)
  const [selectedPage, setSelectedPage] = useState<number>(1)
  const filterColumns = useMemo(
    () => ['name_id', 'metadata', 'connection', 'operationalstate'],
    []
  ) // required to load filter states from local storage
  const [appliedSorter, setAppliedSorter] = useState<
    { column: string; order: string } | undefined
  >()

  const [appliedFilters, setAppliedFilters] = useState<
    { column: string; values: string[] }[]
  >([])

  const [selectedTenant, setSelectedTenant] = useState<string | undefined>()

  function showBoxDetails(box: IBox) {
    history.push(`/boxes/${box.id}`)
  }

  //------- sorter and filter methods -------

  const sorterByColumKey = useMemo(
    () => ({
      name_id: (a, b) => {
        let nameA = a.name_id.key.toLowerCase()
        let nameB = b.name_id.key.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      },
      connection: (a, b) => a.connection.key.length - b.connection.key.length,
      operationalstate: (a, b) =>
        a.operationalstate.key.length - b.operationalstate.key.length
    }),
    []
  )

  const filterByColumnKey = useMemo(
    () => ({
      name_id: (value, record) =>
        record.name_id.key
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      metadata: (value, record) => {
        let box = boxes.find((box) => box.id === record.key)
        if (!box || !box.metadata) {
          return false
        }
        return Object.values(box.metadata).some((metadataValue) =>
          metadataValue.toString().toLowerCase().includes(value.toLowerCase())
        )
      },
      connection: (value, record) => value === record.connection.key,
      operationalstate: (value, record) => {
        return (
          value === record.operationalstate.key ||
          value === record.operationalstate.props.updateStatus
        )
      }
    }),
    [boxes]
  )

  //------- data and columns -------

  const getColumnSearchProps = (dataindex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={t('boxList.dataTable.search.' + dataindex)}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('boxList.dataTable.search.submit')}
          </Button>
          <Button onClick={() => clearFilters()} type="link" size="small">
            {t('boxList.dataTable.search.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? 'scc--boxlist--searched' : ''} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100)
      }
    },
    render: (text) => text
  })

  const getFilterAndSorterProps = (dataIndex) => ({
    onFilter: filterByColumnKey[dataIndex],
    filteredValue:
      (appliedFilters &&
        appliedFilters.find((filter) => filter.column === dataIndex)?.values) ||
      null,
    sorter: sorterByColumKey[dataIndex],
    sortOrder:
      (appliedSorter &&
        appliedSorter.column === dataIndex &&
        (appliedSorter.order === 'ascend'
          ? ('ascend' as 'ascend')
          : ('descend' as 'descend'))) ||
      null
  })

  const data: any = useMemo(() => {
    let bulidData: any = []
    boxes.forEach((box) => {
      bulidData.push({
        key: box.id,
        name_id: <NameIdCell box={box} key={box.name + box.id + box.serial} />,
        metadata:
          box.metadata && Object.entries(box.metadata).length > 0
            ? Object.entries(box.metadata)
                .sort((entry1, entry2) => entry1[0].localeCompare(entry2[0]))
                .map(([key, value]) => (
                  <Tag key={box.id + key}>
                    {clipLongText(key, 25)}: {clipLongText(value, 25)}
                  </Tag>
                ))
            : null,
        connection: (
          <ConnectionStatusCell
            boxStatus={box.boxStatus}
            key={box.boxStatus?.connectionState}
          />
        ),
        operationalstate: (
          <OperationalStatusCell
            runtimeState={
              box.boxStatus && box.boxStatus.runtimeState
                ? box.boxStatus.runtimeState
                : ERuntimeState.unknown
            }
            styleOverride={{ textAlign: 'center' }}
            key={box.boxStatus?.runtimeState}
            updateStatus={box.updateStatus}
          />
        )
      })
    })
    return bulidData
  }, [boxes])

  const columns = [
    {
      title: t('boxList.dataTable.headers.name_id'),
      dataIndex: 'name_id',
      key: 'name_id',
      width: '40%',
      ...getColumnSearchProps('name_id'),
      ...getFilterAndSorterProps('name_id')
    },
    {
      title: t('boxList.dataTable.headers.metadata'),
      dataIndex: 'metadata',
      key: 'metadata',
      width: '25%',
      ...getColumnSearchProps('metadata'),
      ...getFilterAndSorterProps('metadata')
    },
    {
      title: t('boxList.dataTable.headers.connection'),
      dataIndex: 'connection',
      key: 'connection',
      align: 'center' as 'center',
      filters: [
        {
          text: t(
            `boxList.dataTable.connectionStatus.${EConnectionState.connected.toLowerCase()}`
          ),
          value: EConnectionState.connected
        },
        {
          text: t(
            `boxList.dataTable.connectionStatus.${EConnectionState.disconnected.toLowerCase()}`
          ),
          value: EConnectionState.disconnected
        },
        {
          text: t(
            `boxList.dataTable.connectionStatus.${EConnectionState.unknown.toLowerCase()}`
          ),
          value: EConnectionState.unknown
        }
      ],
      ...getFilterAndSorterProps('connection')
    },
    {
      title: (
        <>
          {t('boxList.dataTable.headers.operationalstate')}
          {!Config.EXCLUDE_SUPPORT && (
            <Button
              type="link"
              icon={<InfoCircleOutlined />}
              onClick={(event) => {
                event.stopPropagation()
                window.open(
                  t('boxList.dataTable.headers.operationalstateUrl'),
                  '_blank'
                )
              }}
            />
          )}
        </>
      ),
      dataIndex: 'operationalstate',
      key: 'operationalstate',
      align: 'center' as 'center',
      filters: [
        {
          text: t(
            `boxList.dataTable.runtimeStatus.${ERuntimeState.operational}`
          ),
          value: ERuntimeState.operational
        },
        {
          text: t(`boxList.dataTable.runtimeStatus.${ERuntimeState.unknown}`),
          value: ERuntimeState.unknown
        },
        {
          text: t(
            `boxList.dataTable.runtimeStatus.${ERuntimeState.notconfigured}`
          ),
          value: ERuntimeState.notconfigured
        },
        {
          text: t(
            `boxList.dataTable.runtimeStatus.${ERuntimeState.notoperational}`
          ),
          value: ERuntimeState.notoperational
        },
        {
          text: t(`boxList.dataTable.runtimeStatus.${ERuntimeState.warning}`),
          value: ERuntimeState.warning
        },
        {
          text: t(`boxList.dataTable.runtimeStatus.${ERuntimeState.disabled}`),
          value: ERuntimeState.disabled
        },
        {
          text: t(`boxList.dataTable.runtimeStatus.${ERuntimeState.pending}`),
          value: ERuntimeState.pending
        },
        {
          text: t(`boxList.dataTable.runtimeStatus.${ERuntimeState.standby}`),
          value: ERuntimeState.standby
        },
        {
          text: t('configuration.group.device.updateAvailableTag'),
          value: EBoxUpdateStatus.UPDATE_AVAILABLE
        },
        {
          text: t('configuration.group.device.updateScheduledTag'),
          value: EBoxUpdateStatus.UPDATE_SCHEDULED
        }
      ],
      ...getFilterAndSorterProps('operationalstate')
    }
  ]

  //------- main part -------

  const updatePageToCurrentBox = useCallback(
    (boxId: string) => {
      let usedData = [...data]
      //apply sorter
      if (appliedSorter) {
        let sortingFunction =
          appliedSorter.order === 'ascend'
            ? sorterByColumKey[appliedSorter.column]
            : (a, b) => -sorterByColumKey[appliedSorter.column](a, b)
        usedData = usedData.sort(sortingFunction)
      }

      //apply filters
      appliedFilters.forEach(
        (filter) =>
          (usedData = usedData.filter((record) =>
            filter.values.some((value) =>
              filterByColumnKey[filter.column](value, record)
            )
          ))
      )

      //find fitting page
      boxId = boxId.trim().toLowerCase()
      let indexOfBox: number = usedData.findIndex(
        (entry) => entry.key.trim().toLowerCase() === boxId
      )
      if (indexOfBox === -1) {
        return
      }
      setSelectedPage(Math.ceil((indexOfBox + 1) / PAGE_SIZE))
    },
    [data, appliedFilters, appliedSorter, filterByColumnKey, sorterByColumKey]
  )

  const appendTenantToKey = useCallback(
    (key: string) => {
      return selectedTenant ? key.concat(`_${selectedTenant}`) : key
    },
    [selectedTenant]
  )

  useEffect(() => {
    let parts = location.pathname.split('/')
    if (parts.length < 3) {
      selectedRow && setSelectedRow(null)
    } else {
      let boxId = parts[2]
      if (!selectedRow || selectedRow !== boxId) {
        setSelectedRow(boxId)
      }
      updatePageToCurrentBox(boxId)
    }
  }, [location.pathname, selectedRow, updatePageToCurrentBox, boxes])

  // update tenant information
  useEffect(() => {
    if (!selectedTenant) {
      getTokenInformation().then((tokenInformation) => {
        setSelectedTenant(
          leaveHeaderEmptyOnMissingOrMainTenant(tokenInformation)
            ? ''
            : tokenInformation.tenantInformations!.tenantId
        )
      })
    }
  }, [selectedTenant])

  // refresh tenant + load filters from storage
  useEffect(() => {
    let filters: { column: string; values: string[] }[] = []
    filterColumns.forEach((key) => {
      let values = localStorage.getItem(
        appendTenantToKey(`${LOCAL_STORAGE_FILTERS}_${key}`)
      )
      let filterValues: string[] | null = null

      if (values) {
        if (key !== 'name_id' && key !== 'metadata') {
          filterValues = values.split(',') // for non-search fields, multiselect
        } else {
          filterValues = [values] // search fields have a single value, split with comma is problematic
        }
        filters.push({ column: key, values: filterValues })
      }
    })
    setAppliedFilters(filters)

    let cacheSorter = localStorage.getItem(
      appendTenantToKey(`${LOCAL_STORAGE_SORTER}`)
    )
    let sorter: { column: string; order: string } | undefined = undefined
    if (cacheSorter) {
      let [column, order] = cacheSorter.split(',')
      if (column && filterColumns.some((col) => col === column) && order) {
        sorter = { column: column, order: order }
      }
    }
    setAppliedSorter(sorter)
  }, [filterColumns, selectedTenant, appendTenantToKey])

  let clearAll = () => {
    handleChange(null, null, null)
  }

  const handlePageChange = (pageNum, _pageSize) => {
    pageNum && setSelectedPage(pageNum)
  }

  let handleChange = (pagination, filters, sorter) => {
    //cache filters
    filterColumns.forEach((key) => {
      let value = ''
      filters && filters[key] && (value = filters[key].toString())
      localStorage.setItem(
        appendTenantToKey(`${LOCAL_STORAGE_FILTERS}_${key}`),
        value
      )
    })

    // set filters to apply
    if (filters) {
      let newFilters = Object.entries(filters)
        .filter(
          (entry) =>
            entry[1] !== null &&
            Array.isArray(entry[1]) &&
            entry[1].every((val) => typeof val === 'string')
        )
        .map((filter) => {
          return { column: filter[0], values: filter[1] }
        })
      // @ts-ignore
      setAppliedFilters(newFilters)
    } else {
      setAppliedFilters([])
    }

    // cache and set sorter
    if (sorter && sorter.order && sorter.columnKey) {
      setAppliedSorter({ column: sorter.columnKey, order: sorter.order })
      const sorterValue = sorter.columnKey.concat(',', sorter.order)
      localStorage.setItem(
        appendTenantToKey(`${LOCAL_STORAGE_SORTER}`),
        sorterValue
      )
    }
    // when sorter === null user clicked on clearFilters -> no need to clear appliedSorter too
    // only clear applied sorter when antTable sends sorter with null filed
    else if (sorter) {
      setAppliedSorter(undefined)
      localStorage.removeItem(appendTenantToKey(`${LOCAL_STORAGE_SORTER}`))
    }

    // if something else then page is changed (e.g. sorting, set/clear filter) it just feels natural to go to first page
    if (!pagination || selectedPage === pagination.current) {
      setSelectedPage(1)
    }
  }

  let searchInput: Input | null = null

  let isFiltered = appliedFilters.length > 0

  return (
    <div className="scc--boxlist--container">
      <Scrollbars autoHide={true}>
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col">
              {isFiltered && (
                <div className="bx--col scc--boxlist--filterwarning">
                  <span>
                    <FilterOutlined /> {t('boxList.filterWarning.text')}
                  </span>
                  <Button onClick={clearAll} size={'small'} type="link">
                    {t('boxList.filterWarning.button')}
                  </Button>
                </div>
              )}
            </div>
            <div className="bx--col scc--boxlist--autorefresh">
              <span>{t('boxList.autoRefresh.text')}</span>
              <Switch
                size={'small'}
                checkedChildren={t('draw.toggle.on')}
                unCheckedChildren={t('draw.toggle.off')}
                checked={autoRefresh}
                onChange={(value) => {
                  setAutoRefresh(value)
                }}
              />
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <Table
                rowClassName={(record, _index) =>
                  record.key === selectedRow
                    ? 'scc--tablerow scc--selected'
                    : 'scc--tablerow'
                }
                columns={columns}
                dataSource={data}
                loading={!boxesFetched}
                onRow={(row) => ({
                  onClick: () => {
                    const selectedBox = boxes.find((box) => {
                      return box.id === row.key
                    })
                    if (!selectedBox) {
                      return
                    }
                    showBoxDetails(selectedBox)
                  }
                })}
                pagination={{
                  // fixed page size for table-position calculations
                  pageSize: PAGE_SIZE,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                  current: selectedPage,
                  onChange: handlePageChange
                }}
                locale={{
                  emptyText: <EmptyBoxListText />
                }}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  )
}

export default BoxList
