import {
  DELETE_GROUP_REQUEST_SUCCESS,
  LIST_GROUPS_REQUEST_SUCCESS,
  SAVE_GROUP_REQUEST_SUCCESS
} from '../actions/actionTypes'

const initialState = {
  all: []
}

const fn = function (state = initialState, action) {
  let newState
  switch (action.type) {
    case SAVE_GROUP_REQUEST_SUCCESS:
      let key = action.response.result
      let newItem = action.response.entities.group[key]
      // @ts-ignore
      let existinEntryIndex = state.all.findIndex((group) => group.id === key)
      if (existinEntryIndex >= 0) {
        let newArray = [...state.all]
        // @ts-ignore
        newArray[existinEntryIndex] = newItem
        newState = {
          ...state,
          all: newArray
        }
        return Object.assign({}, state, newState)
      } else {
        newState = {
          ...state,
          all: state.all.concat(newItem)
        }
        return Object.assign({}, state, newState)
      }
    case LIST_GROUPS_REQUEST_SUCCESS:
      newState = {
        ...state,
        all: action.response
      }
      return Object.assign({}, state, newState)

    case DELETE_GROUP_REQUEST_SUCCESS:
      const deletedGroupId = action.response.id
      newState = {
        ...state,
        all: state.all.filter((id) => deletedGroupId !== id)
      }
      return Object.assign({}, state, newState)

    default:
      return state
  }
}
export default fn
