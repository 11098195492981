import { useTranslation } from 'react-i18next'
import { Alert, AutoComplete, Button, Input, Modal } from 'antd'

import { ICoordinates } from '../../types/stream'
import CoordinatesSelectionMap from './CoordinatesSelectionMap'
import { AzureMapsProvider } from 'react-azure-maps'
import './LocationPicker.scss'
import React, { useEffect, useRef, useState } from 'react'
import { Config } from '../../services/config'

interface ILocationPickerModal {
  streamCoordinates:
    | ICoordinates
    | { latitude: string | number; longitude: string | number }
    | undefined
  setStreamCoordinates: (e: ICoordinates) => void
  userSelectedCoordinates: ICoordinates | undefined
  setUserSelectedCoordinates: (e) => void
  show: boolean
  close: Function
  resetView: boolean
  setResetView: (b: boolean) => void

  save?: () => void
  title: string
}

export interface MapViewport {
  west: number
  south: number
  east: number
  north: number
}

interface AddressSearchOption {
  value: string
  coordinates: ICoordinates
  viewport: MapViewport
}

const LocationPickerModal: React.FC<ILocationPickerModal> = (props) => {
  const { t } = useTranslation()
  const {
    streamCoordinates,
    setStreamCoordinates,
    userSelectedCoordinates,
    setUserSelectedCoordinates,
    show,
    close,
    resetView,
    setResetView,
    save,
    title
  } = props

  const AZURE_MAPS_KEY = Config.AZURE_MAPS_KEY

  const [showInvalidWarning, setShowInvalidWarning] = useState(false)
  const [addressSearchResults, setAddressSearchResults] = useState<
    AddressSearchOption[]
  >([])
  const [addressSearchInput, setAddressSearchInput] = useState<string>('')
  const [addressResultsVisible, setAddressResultsVisible] = useState(false)
  const [
    addressAPIUnavailableWarnig,
    setAddressAPIUnavailableWarning
  ] = useState(false)
  const [viewport, setViewport] = useState<MapViewport | undefined>(undefined)
  const addressSearchRef = useRef<Input | null>(null)

  const geocodeServiceUrlTemplate = () =>
    'https://atlas.microsoft.com/search/address/json?api-version=1.0&query={query}&language=NGT&limit=3&subscription-key={key}'

  async function processRequest(value) {
    let geocodeRequest = geocodeServiceUrlTemplate()
      .replace('{query}', encodeURIComponent(value))
      .replace('{key}', AZURE_MAPS_KEY)
    return fetch(geocodeRequest, {
      method: 'GET'
    })
  }

  useEffect(() => {
    if (show && addressSearchRef.current) {
      addressSearchRef.current.focus()
    }
  }, [show, addressSearchRef])

  function onFullfilled() {
    return (response) =>
      response.json().then((queryResultObject) => {
        if (queryResultObject.error) {
          setAddressAPIUnavailableWarning(true)
          return
        }
        setAddressSearchResults(
          queryResultObject.results.map((result) => ({
            value: result.address.freeformAddress,
            coordinates: {
              latitude: result.position.lat,
              longitude: result.position.lon
            },
            viewport: {
              west: result.viewport.topLeftPoint.lon,
              north: result.viewport.topLeftPoint.lat,
              east: result.viewport.btmRightPoint.lon,
              south: result.viewport.btmRightPoint.lat
            }
          }))
        )
        setAddressAPIUnavailableWarning(false)
        setAddressResultsVisible(true)
      })
  }

  const handleAddressSearch = async (value) => {
    if (value.length >= 3 && !addressResultsVisible) {
      await processRequest(value).then(onFullfilled(), () =>
        setAddressAPIUnavailableWarning(true)
      )
    }
  }

  const clearAddressResults = () => {
    setAddressSearchResults([])
    setAddressResultsVisible(false)
  }

  const handleAddressSelection = (value, option) => {
    setAddressSearchInput(value)
    clearAddressResults()
    setUserSelectedCoordinates(option.coordinates)
    setViewport(option.viewport)
    setResetView(true)
  }

  const hasValidLocation = () =>
    userSelectedCoordinates &&
    userSelectedCoordinates.latitude >= -90 &&
    userSelectedCoordinates.latitude <= 90 &&
    userSelectedCoordinates.longitude >= -180 &&
    userSelectedCoordinates.longitude <= 180

  const handleSave = () => {
    if (!hasValidLocation()) {
      setShowInvalidWarning(true)
      return
    }
    setShowInvalidWarning(false)
    setAddressSearchInput('')
    setAddressAPIUnavailableWarning(false)
    if (!!save) {
      save()
    } else {
      setResetView(true)
      setStreamCoordinates({ ...userSelectedCoordinates } as ICoordinates)
      close()
    }
  }

  const handleCancel = () => {
    setResetView(true)
    setShowInvalidWarning(false)
    setUserSelectedCoordinates(
      streamCoordinates &&
        typeof streamCoordinates.latitude === 'number' &&
        typeof streamCoordinates.longitude === 'number'
        ? ({ ...streamCoordinates } as ICoordinates)
        : undefined
    )
    setAddressSearchInput('')
    setAddressAPIUnavailableWarning(false)
    close()
  }

  return (
    <Modal
      className="scc--locationpicker"
      title={t(title)}
      visible={show}
      onCancel={handleCancel}
      width={1000}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('configuration.group.streamMeta.coordinates.picker.cancel')}
        </Button>,
        <Button type="primary" key="save" onClick={handleSave}>
          {t('configuration.group.streamMeta.coordinates.picker.save')}
        </Button>
      ]}
    >
      <div className="scc--location-map-wrapper">
        <AzureMapsProvider>
          <CoordinatesSelectionMap
            streamCoordinates={userSelectedCoordinates}
            setStreamCoordinates={setUserSelectedCoordinates}
            resetView={resetView}
            setResetView={setResetView}
            viewport={viewport}
            setViewport={setViewport}
            addressSearchRef={addressSearchRef.current}
          />
        </AzureMapsProvider>
        <AutoComplete
          open={addressResultsVisible}
          value={addressSearchInput}
          onChange={setAddressSearchInput}
          className="scc--location-address-search"
          options={addressSearchResults}
          onSelect={handleAddressSelection}
          onBlur={clearAddressResults}
          notFoundContent={t(
            'configuration.group.streamMeta.coordinates.picker.addressSearch.noMatchFound'
          )}
        >
          <Input.Search
            ref={addressSearchRef}
            onSearch={handleAddressSearch}
            size="large"
            placeholder={t(
              'configuration.group.streamMeta.coordinates.picker.addressSearch.placeholder'
            )}
            onChange={clearAddressResults}
          />
        </AutoComplete>
      </div>
      {showInvalidWarning && (
        <Alert
          message={t(
            'configuration.group.streamMeta.coordinates.picker.warning'
          )}
          type="warning"
          style={{ marginTop: '10px' }}
          showIcon
        />
      )}
      {addressAPIUnavailableWarnig && (
        <Alert
          message={t(
            'configuration.group.streamMeta.coordinates.picker.addressSearch.apiWarning'
          )}
          type="warning"
          style={{ marginTop: '10px' }}
          showIcon
        />
      )}
    </Modal>
  )
}

export default LocationPickerModal
