import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'
import {
  DELETE_IOTRIGGER_CONFIG_REQUEST,
  DELETE_IOTRIGGER_CONFIG_REQUEST_FAILURE,
  DELETE_IOTRIGGER_CONFIG_REQUEST_SUCCESS,
  IOTRIGGER_CONFIG_REQUEST,
  IOTRIGGER_CONFIG_REQUEST_FAILURE,
  IOTRIGGER_CONFIG_REQUEST_SUCCESS,
  SAVE_IOTRIGGER_CONFIG_REQUEST,
  SAVE_IOTRIGGER_CONFIG_REQUEST_FAILURE,
  SAVE_IOTRIGGER_CONFIG_REQUEST_SUCCESS
} from './actionTypes'

import { IIoTriggerConfiguration } from '../../types/eventdataOutputConfiguration'

/**
 * ==========================
 * IOTRIGGER CONFIGURATION ACTIONS
 * ==========================
 */

/**
 * Fetches a single configuration from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 * @param boxId
 * @param streamId
 */
const fetchIoTriggerConfig = (boxId, streamId) => ({
  [CALL_API]: {
    types: [
      IOTRIGGER_CONFIG_REQUEST,
      IOTRIGGER_CONFIG_REQUEST_SUCCESS,
      IOTRIGGER_CONFIG_REQUEST_FAILURE
    ],
    endpoint: `boxes/${boxId}/${streamId}/configurations/ioTrigger`,
    schema: Schemas.CONFIG,
    id: streamId
  }
})

/**
 * Fetches a single configuration from the API unless it is cached.
 * Relies on Redux Thunk middleware.
 * @param boxId
 * @param streamId
 */
export const loadIoTriggerConfig = (boxId, streamId) => (
  dispatch,
  getState
) => {
  const { ioTriggerConfigurations } = getState()

  if (!boxId || !streamId || !ioTriggerConfigurations) {
    return null
  }

  if (ioTriggerConfigurations.allIds.indexOf(streamId) > -1) {
    return Promise.resolve(ioTriggerConfigurations.byIds[streamId])
  }

  return dispatch(fetchIoTriggerConfig(boxId, streamId)).then((data) => {
    return Object.entries(
      data.response.entities.configurations[data.response.result]
    ).map(([k, v]) => v)
  })
}

/**
 * Save a configuration via the API.
 * Will use DELETE if no new config,else PUT.
 * @param boxId
 * @param streamId
 * @param config: CONFIG The configuration data to be saved.
 */
export const saveIoTriggerConfig = (
  boxId: string,
  streamId: string,
  config: IIoTriggerConfiguration
) => {
  if (!config) {
    return {
      [CALL_API]: {
        types: [
          DELETE_IOTRIGGER_CONFIG_REQUEST,
          DELETE_IOTRIGGER_CONFIG_REQUEST_SUCCESS,
          DELETE_IOTRIGGER_CONFIG_REQUEST_FAILURE
        ],
        method: HTTP_METHOD.DELETE,
        endpoint: `boxes/${boxId}/${streamId}/configurations/ioTrigger`,
        schema: Schemas.CONFIG,
        id: streamId
      }
    }
  }
  return {
    [CALL_API]: {
      types: [
        SAVE_IOTRIGGER_CONFIG_REQUEST,
        SAVE_IOTRIGGER_CONFIG_REQUEST_SUCCESS,
        SAVE_IOTRIGGER_CONFIG_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.PUT,
      payload: config,
      endpoint: `boxes/${boxId}/${streamId}/configurations/ioTrigger`,
      schema: Schemas.CONFIG,
      id: streamId
    }
  }
}
