import { client, getAuthorizationHeader } from '../middleware/api'
import {
  CREATE_USER_REQUEST,
  CREATE_USER_REQUEST_FAILURE,
  CREATE_USER_REQUEST_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_REQUEST_FAILURE,
  DELETE_USER_REQUEST_SUCCESS,
  LIST_USERS_REQUEST,
  LIST_USERS_REQUEST_FAILURE,
  LIST_USERS_REQUEST_SUCCESS,
  SAVE_USER_REQUEST,
  SAVE_USER_REQUEST_FAILURE,
  SAVE_USER_REQUEST_SUCCESS
} from './actionTypes'
import {
  IControlCenterUser,
  INewControlCenterUser
} from '../../types/controlCenterUser'

const fetchUsersBegin = () => ({
  type: LIST_USERS_REQUEST
})

const fetchUsersSuccess = (users) => ({
  type: LIST_USERS_REQUEST_SUCCESS,
  payload: { users }
})

const fetchUsersFailure = (error) => ({
  type: LIST_USERS_REQUEST_FAILURE,
  payload: { error }
})

export const callFetchUsers = () => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(fetchUsersBegin())
      client
        .get('tenantAdministration/users', header)
        .then((response) => {
          dispatch(fetchUsersSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(fetchUsersFailure(error))
        })
    })
  }
}

const deleteUserBegin = () => ({
  type: DELETE_USER_REQUEST
})

const deleteUserSuccess = (userId) => ({
  type: DELETE_USER_REQUEST_SUCCESS,
  deleted: { userId }
})

const deleteUserFailure = (error) => ({
  type: DELETE_USER_REQUEST_FAILURE,
  payload: { error }
})

export const callDeleteUser = (user: IControlCenterUser) => {
  let userId = user.id
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(deleteUserBegin())
      client
        .delete(`tenantAdministration/users/${userId}`, header)
        .then(() => {
          dispatch(deleteUserSuccess(user.id))
          return user.id
        })
        .catch((error) => {
          dispatch(deleteUserFailure(error))
        })
    })
  }
}

const saveUserBegin = () => ({
  type: SAVE_USER_REQUEST
})

const saveUserSuccess = (user) => ({
  type: SAVE_USER_REQUEST_SUCCESS,
  payload: { user }
})

const saveUserFailure = (error) => ({
  type: SAVE_USER_REQUEST_FAILURE,
  payload: { error }
})

export const callSaveUser = (user: IControlCenterUser) => {
  let userId = user.id
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(saveUserBegin())
      client
        .put(`tenantAdministration/users/${userId}`, user, header)
        .then((response) => {
          dispatch(saveUserSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(saveUserFailure(error))
        })
    })
  }
}

const createUserBegin = () => ({
  type: CREATE_USER_REQUEST
})

const createUserSuccess = (user) => ({
  type: CREATE_USER_REQUEST_SUCCESS,
  payload: { user }
})

const createUserFailure = (error) => ({
  type: CREATE_USER_REQUEST_FAILURE,
  payload: { error }
})

export const callCreateUser = (user: INewControlCenterUser) => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(createUserBegin())
      client
        .post(`tenantAdministration/users`, user, header)
        .then((response) => {
          dispatch(createUserSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(createUserFailure(error))
        })
    })
  }
}
