import React, { useState } from 'react'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

const ShowHideToggleIcon = ({ isCurrentlyShown }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      style={{ height: '100%' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={
        () =>
          setIsHovered(
            false
          ) /*feels most natural this way else there are to many icon switches happening*/
      }
    >
      {isHovered === isCurrentlyShown ? (
        <EyeOutlined />
      ) : (
        <EyeInvisibleOutlined />
      )}
    </div>
  )
}

export default ShowHideToggleIcon
