import React from 'react'
import cameraGrey from '../../theme/icons/camera_grey.svg'
import { useTranslation } from 'react-i18next'

export interface ICanvasErrorProps {
  alternateTranslationPath?: string
}

const CanvasError: React.FC<ICanvasErrorProps> = (props) => {
  const { t } = useTranslation()
  const { alternateTranslationPath } = props

  return (
    <div className="scc--camera-feed--wrapper">
      <div className="scc--camera-feed--not-configured scc--camera-feed--centered">
        <img src={cameraGrey} className="scc--camera-feed--icon" alt="logo" />
        <h3 className="scc--camera-feed--header">
          {t(
            alternateTranslationPath
              ? `camera.noFeed.alternates.${alternateTranslationPath}.header`
              : 'camera.noFeed.header'
          )}
        </h3>
        <p className="scc--camera-feed--text">
          {t(
            alternateTranslationPath
              ? `camera.noFeed.alternates.${alternateTranslationPath}.text`
              : 'camera.noFeed.text'
          )}
        </p>
      </div>
    </div>
  )
}

export default CanvasError
