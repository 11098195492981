import {
  GET_CAMERA_FRAME,
  GET_CAMERA_FRAME_FAILURE,
  GET_CAMERA_FRAME_SUCCESS
} from '../actions/actionTypes'

import { RESET_CAMERA_FRAME } from '../actions/actionTypes'
import union from 'lodash/union'

const initialState = {
  allIds: [],
  loadingIds: {},
  byIds: {},
  gotResetByIds: {}
}

const fn = function (state = initialState, action) {
  switch (action.type) {
    case RESET_CAMERA_FRAME:
      return {
        ...state,
        allIds: state.allIds.filter((id) => action.id !== id),
        byIds: { ...state.byIds, [action.id]: undefined },
        loadingIds: {
          ...state.loadingIds,
          [action.id]: false
        },
        gotResetByIds: {
          ...state.gotResetByIds,
          [action.id]: true
        }
      }

    case GET_CAMERA_FRAME:
      return {
        ...state,
        isLoading: true,
        loadingIds: {
          ...state.loadingIds,
          [action.id]: true
        },
        gotResetByIds: {
          ...state.gotResetByIds,
          [action.id]: false
        }
      }

    case GET_CAMERA_FRAME_SUCCESS:
      if (state.gotResetByIds[action.response.result]) {
        return state
      }
      return {
        ...state,
        allIds: union(state.allIds, [action.response.result]),
        byIds: Object.assign({}, state.byIds, action.response.entities.frame),
        loadingIds: {
          ...state.loadingIds,
          [action.response.result]: false
        }
      }

    case GET_CAMERA_FRAME_FAILURE:
      const newState = {
        ...state,
        allIds: state.allIds.filter((id) => action.id !== id),
        loadingIds: {
          ...state.loadingIds,
          [action.id]: false
        }
      }

      if (newState.byIds[action.id]) {
        delete newState.byIds[action.id]
      }

      return newState

    default:
      return state
  }
}
export default fn
