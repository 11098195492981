import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ReduxStore } from '../../redux/store/configureStore'
import { IBoxFeatureFlags } from '../../types/boxFeatureFlags'
import { callFetchBoxFeatureFlags } from '../../redux/actions/boxFeatureFlags'

export interface IBoxFeatureFlagsState {
  boxFeatureFlags: IBoxFeatureFlags
  error: string
  loading: boolean
}

export const useBoxFeatureFlagsState = (boxId: string) => {
  const dispatch = useDispatch()

  const boxFeatureFlagsState: IBoxFeatureFlagsState = useSelector(
    (state: ReduxStore) => state.boxFeatureFlags
  )

  useEffect(() => {
    if (
      boxFeatureFlagsState.boxFeatureFlags?.boxId !== boxId &&
      !boxFeatureFlagsState.loading
    ) {
      dispatch(callFetchBoxFeatureFlags(boxId))
    }
  }, [dispatch, boxId, boxFeatureFlagsState])

  return boxFeatureFlagsState
}
