import LanguageDetector from 'i18next-browser-languagedetector'
import common_en from './translations/en/common.json'
import datadiscovery_en from './translations/en/datadiscovery.json'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    common: common_en,
    datadiscovery: datadiscovery_en
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    defaultNS: 'common',
    returnObjects: true,

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      wait: true,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p']
    }
  })

export default i18n
