import { Layer, Rect, Stage } from 'react-konva'
import React, { useEffect, useRef, useState } from 'react'

import CameraFeedImage from './CameraFeedImage'
import CanvasError from './CanvasError'
import CanvasLoading from './CanvasLoading'
import { ICoordinateBasedEventTrigger } from '../../types/eventTrigger'
import { ECameraFrameMode, ICameraFrame } from '../../types/cameraFrame'
import refresh from '../../theme/icons/refresh.svg'
import { useResize } from '../../helpers/hooks/useResize'
import { useTranslation } from 'react-i18next'
import { AnprEventTrigger } from '../../types/anpr'
import { Button, Select, Slider, Switch, Tooltip } from 'antd'
import { QuestionOutlined } from '@ant-design/icons'
import { userIsAtLeast, UserRole } from '../../types/user_role'
import CanvasDisabled from './CanvasDisabled'
import { calculateDimensions, getEventTrigger } from './canvasHelpers'
import { isFocusArea } from '../../types/regionOfInterest'
import moment from 'moment/moment'
import { SliderMarks } from 'antd/es/slider'
import ShowHideToggleIcon from '../../helpers/ShowHideToggleIcon'
import { useInterval } from '../../helpers/hooks/useInterval'
import { useBoxActionScheduleState } from '../../helpers/hooks/useBoxActionSchedule'
import { EBoxScheduleActionType } from '../../types/boxScheduleAction'

interface ICanvasWrapperProps {
  frame?: ICameraFrame
  eventTriggers: ICoordinateBasedEventTrigger[]
  anpr?: AnprEventTrigger
  boxId: string
  events: any
  highlightedEventTrigger: string
  selectedEventTrigger: string
  isEditable: boolean
  isLoading: boolean
  calibration: ECameraFrameMode
  setCalibration: Function
  disabled: boolean
  showOnvifButton: boolean
  enableOnvifButton: boolean
  trackCalibrationRecordingEnabled?: boolean
  hiddenEventTriggers?: ICoordinateBasedEventTrigger[]
  toggleHideAllEventTriggers?: () => void
}

const DEFAULT_CANVAS_HEIGHT = 400

let defaultCanvasDimensions: any = {
  height: DEFAULT_CANVAS_HEIGHT
}

const CanvasWrapper: React.FC<ICanvasWrapperProps> = ({
  frame,
  eventTriggers,
  events,
  boxId,
  highlightedEventTrigger,
  selectedEventTrigger,
  isEditable,
  isLoading,
  calibration,
  setCalibration,
  disabled,
  showOnvifButton,
  enableOnvifButton,
  trackCalibrationRecordingEnabled,
  hiddenEventTriggers,
  toggleHideAllEventTriggers
}) => {
  // This is used to force a re-rendering of the component
  const timestamp = new Date().getTime()
  const stageEl = useRef(null)
  const containerEl = useRef(null)
  const { t } = useTranslation()

  const [dimensions, setDimensions] = useState(defaultCanvasDimensions)
  const [trackCalibrationHoursBack, setTrackCalibrationHoursBack] = useState(0)
  const [customErrorTranslation, setCustomErrorTranslation] = useState<
    string | undefined
  >(undefined)
  const [continuousRefresh, setContinuousRefresh] = useState(false)
  const [isStandby, setIsStandby] = useState(false)

  const { boxActionSchedule } = useBoxActionScheduleState(boxId)

  moment.tz.setDefault('UTC')

  useEffect(() => {
    if (boxActionSchedule && boxActionSchedule.enabled) {
      // find first action in past
      let lastAction = [...boxActionSchedule.schedule]
        .sort((a, b) => {
          return moment(a.scheduledTime).diff(moment(b.scheduledTime))
        })
        .reverse()
        .find((action) => {
          return moment(action.scheduledTime).isBefore(moment())
        })
      if (!lastAction || lastAction.action === EBoxScheduleActionType.standby) {
        setIsStandby(true)
        return
      }
    }
    setIsStandby(false)
  }, [boxActionSchedule])

  const onCalibration = (value) => {
    setCalibration(value)
    if (value !== ECameraFrameMode.trackCalibration) {
      setTrackCalibrationHoursBack(0)
      setCustomErrorTranslation(undefined)
    }
    events.onRefreshButtonClick({
      calibration: value,
      timestamp:
        value === ECameraFrameMode.trackCalibration &&
        trackCalibrationRecordingEnabled
          ? getTimestampForCalibration(trackCalibrationHoursBack, false)
          : null
    })
  }

  const [image, setImage] = useState<HTMLImageElement | null>(null)

  useEffect(() => {
    if (frame) {
      let newimage = new Image()
      newimage.src = `data:image/png;base64,${frame.encodedImage}`
      setImage(newimage)
    } else if (!isLoading) {
      setImage(null)
      setContinuousRefresh(false)
    }
  }, [frame, isLoading])

  const executeRepeatedly = () => {
    events.onRefreshButtonClick({
      calibration: calibration,
      timestamp:
        calibration === ECameraFrameMode.trackCalibration &&
        trackCalibrationRecordingEnabled
          ? getTimestampForCalibration(trackCalibrationHoursBack, false)
          : null
    })
  }

  useInterval(
    executeRepeatedly,
    1000,
    30000,
    continuousRefresh,
    setContinuousRefresh
  )

  let timeout: NodeJS.Timeout

  // Resize the canvas when the window resizes
  useResize(containerEl, () => {
    clearTimeout(timeout)

    // Use a little timeout to wait for transitions to finish
    timeout = setTimeout(() => {
      const calculatedDimensions = calculateDimensions(
        containerEl.current,
        frame,
        isLoading,
        {
          height: DEFAULT_CANVAS_HEIGHT
        }
      )

      if (
        calculatedDimensions.width !== dimensions.width ||
        calculatedDimensions.height !== dimensions.height
      ) {
        defaultCanvasDimensions = calculatedDimensions
        setDimensions(calculatedDimensions)
      }
    }, 300)
  })

  const sortedTriggers = eventTriggers
    ? eventTriggers
        .slice(0)
        .filter(
          (event) =>
            !isFocusArea(event) &&
            (hiddenEventTriggers
              ? !hiddenEventTriggers.some((e) => e.localId === event.localId)
              : true)
        )
        .sort((a, b) => (a.localId === selectedEventTrigger ? 1 : -1))
    : null

  const focusAreas = eventTriggers
    ? eventTriggers
        .slice(0)
        .filter(
          (event) =>
            isFocusArea(event) &&
            (hiddenEventTriggers
              ? !hiddenEventTriggers.some((e) => e.localId === event.localId)
              : true)
        )
    : null

  let enableOnvifDisabledTooltip = !enableOnvifButton
    ? {}
    : {
        visible: false
      }

  function getTimestampForCalibration(value: number, displayMode: boolean) {
    if (value === 0 && displayMode) {
      return 'now'
    } else if (value === 0 && !displayMode) {
      return null
    }
    return moment
      .utc()
      .add(-value + 1, 'hours')
      .startOf('hour')
      .format(displayMode ? 'HH:mm [(UTC)]' : 'YYYY-MM-DD[T]HH:mm:ss[Z]')
  }

  const getTrackCalibrationMarks = () => {
    let marks: SliderMarks = {}
    if (trackCalibrationHoursBack >= 5) {
      marks[0] = 'now'
    }
    if (trackCalibrationHoursBack <= 17) {
      marks[24] = getTimestampForCalibration(24, true)
    }
    marks[trackCalibrationHoursBack] = getTimestampForCalibration(
      trackCalibrationHoursBack,
      true
    )
    return marks
  }

  return (
    <div
      className="scc--draw--canvas--wrapper"
      ref={containerEl}
      style={{ height: dimensions.height }}
    >
      <div id="scc--draw--canvas" style={{ width: dimensions.width }}>
        {(disabled || isStandby) && <CanvasDisabled />}
        {!disabled && !isStandby && isLoading && !image && <CanvasLoading />}
        {!disabled && !isStandby && !isLoading && !image && (
          <CanvasError
            alternateTranslationPath={
              customErrorTranslation ? customErrorTranslation : undefined
            }
          />
        )}
        {!disabled && !isStandby && (
          <>
            {showOnvifButton && (
              <Tooltip
                title={
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('camera.frame.onvifDisabled')
                    }}
                  ></p>
                }
                {...enableOnvifDisabledTooltip}
              >
                <Button
                  className="ant-btn scc--camera-feed--onvif"
                  title={t('camera.frame.buttonOnvif')}
                  disabled={!enableOnvifButton}
                  onClick={(event) => {
                    event.preventDefault()
                    events.onOnvifCButtonClick()
                  }}
                >
                  {t('camera.frame.buttonOnvif')}
                </Button>
              </Tooltip>
            )}
            <button
              disabled={isLoading || continuousRefresh}
              className={
                isLoading || continuousRefresh
                  ? 'scc--camera-feed--refresh-button-spinning'
                  : 'scc--camera-feed--refresh-button'
              }
              title={t('camera.frame.buttonRefresh')}
              onClick={(event) => {
                event.preventDefault()
                events.onRefreshButtonClick({
                  calibration: calibration,
                  timestamp:
                    calibration === ECameraFrameMode.trackCalibration &&
                    trackCalibrationRecordingEnabled
                      ? getTimestampForCalibration(
                          trackCalibrationHoursBack,
                          false
                        )
                      : null
                })
              }}
            >
              <img src={refresh} alt={t('camera.frame.buttonRefresh')} />
            </button>
            <Switch
              className="scc--camera-feed--auto-refresh"
              size={'small'}
              disabled={trackCalibrationHoursBack !== 0}
              checked={continuousRefresh}
              checkedChildren={t('camera.frame.autoRefresh')}
              unCheckedChildren={t('camera.frame.autoRefresh')}
              onClick={(checked) => {
                setContinuousRefresh(checked)
                setContinuousRefresh(checked)
              }}
            />
            {eventTriggers &&
              eventTriggers.length > 0 &&
              toggleHideAllEventTriggers && (
                <Button
                  className="scc--camera-feed--show-hide-all"
                  icon={
                    <ShowHideToggleIcon
                      isCurrentlyShown={
                        !!hiddenEventTriggers && hiddenEventTriggers.length > 0
                      }
                    />
                  }
                  title={t('camera.frame.buttonShowHideAll')}
                  onClick={toggleHideAllEventTriggers}
                />
              )}
            {calibration && (
              <>
                {calibration === ECameraFrameMode.trackCalibration &&
                  trackCalibrationRecordingEnabled && (
                    <Slider
                      disabled={isLoading || continuousRefresh}
                      className={'scc--slider--trackCalibration'}
                      value={trackCalibrationHoursBack}
                      min={0}
                      max={24}
                      included={false}
                      onChange={(value) => {
                        setTrackCalibrationHoursBack(value)
                      }}
                      onAfterChange={(value) => {
                        setCustomErrorTranslation(
                          value === 0 ? undefined : 'recordedImages'
                        )
                        events.onRefreshButtonClick({
                          calibration: calibration,
                          timestamp: getTimestampForCalibration(value, false)
                        })
                      }}
                      reverse={true}
                      tooltipVisible={false}
                      marks={getTrackCalibrationMarks()}
                    />
                  )}
                {(calibration === ECameraFrameMode.calibration ||
                  calibration === ECameraFrameMode.trackCalibration) && (
                  <Tooltip
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={
                      <>
                        <h5 className="scc--tooltip--title">
                          {t('camera.frame.calibrationColors')}
                        </h5>
                        <ul>
                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#00a86b',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.car')}
                            </span>
                          </li>

                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#9f1853',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.motorbike')}
                            </span>
                          </li>

                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#ee538b',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.bicycle')}
                            </span>
                          </li>

                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#b28600',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.person')}
                            </span>
                          </li>

                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#8a3800',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.scooter')}
                            </span>
                          </li>

                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#a56eff',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.truck')}
                            </span>
                          </li>

                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#6929c4',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.bus')}
                            </span>
                          </li>
                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#333333',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.tram')}
                            </span>
                          </li>
                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#1192e8',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.other')}
                            </span>
                          </li>
                          <li
                            style={{
                              display: 'block',
                              verticalAlign: 'middle',
                              height: '1rem'
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: '#012749',
                                display: 'inline-block',
                                height: '0.8rem',
                                width: '1rem',
                                marginRight: '10px',
                                verticalAlign: 'middle'
                              }}
                            ></span>
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {t('camera.frame.classColors.unknown')}
                            </span>
                          </li>
                        </ul>
                      </>
                    }
                    placement={'bottomRight'}
                  >
                    <QuestionOutlined
                      className={'scc--camera-feed--class-color-tooltip-icon'}
                    />
                  </Tooltip>
                )}
              </>
            )}

            <Select
              disabled={isLoading || continuousRefresh}
              className="scc--radio--calibrationMode"
              defaultValue={calibration}
              onChange={onCalibration}
            >
              {[
                ECameraFrameMode.default,
                ECameraFrameMode.calibration,
                ECameraFrameMode.trackCalibration
              ].map((mode) => (
                <Select.Option key={mode} value={mode}>
                  {t(`camera.frame.mode.${mode}`)}
                </Select.Option>
              ))}
              {userIsAtLeast(UserRole.Admin) && (
                <Select.Option
                  key={ECameraFrameMode.adminView}
                  value={ECameraFrameMode.adminView}
                >
                  {t(`camera.frame.mode.${ECameraFrameMode.adminView}`)}
                </Select.Option>
              )}
            </Select>
          </>
        )}
        <Stage
          ref={stageEl}
          height={dimensions.height}
          width={dimensions.width}
        >
          {image && (
            <>
              <Layer>
                <CameraFeedImage
                  image={image}
                  width={dimensions.width}
                  height={dimensions.height}
                />
              </Layer>
              <Layer>
                {focusAreas && focusAreas.length > 0 && (
                  <Rect
                    width={dimensions.width}
                    height={dimensions.height}
                    fill="#000000"
                    opacity={0.7}
                  />
                )}
                {focusAreas &&
                  focusAreas.map((eventTrigger, index) => {
                    const key = timestamp + '-' + index
                    const isEventTriggerHighlighted =
                      eventTrigger.localId === highlightedEventTrigger
                    const isEventTriggerSelected =
                      eventTrigger.localId === selectedEventTrigger
                    return getEventTrigger(
                      eventTrigger,
                      stageEl.current,
                      events,
                      isEventTriggerHighlighted,
                      isEventTriggerSelected,
                      isEditable,
                      key,
                      true
                    )
                  })}

                {sortedTriggers &&
                  sortedTriggers.map((eventTrigger, index) => {
                    const key = timestamp + '-' + index
                    const isEventTriggerHighlighted =
                      eventTrigger.localId === highlightedEventTrigger
                    const isEventTriggerSelected =
                      eventTrigger.localId === selectedEventTrigger
                    return getEventTrigger(
                      eventTrigger,
                      stageEl.current,
                      events,
                      isEventTriggerHighlighted,
                      isEventTriggerSelected,
                      isEditable,
                      key,
                      false
                    )
                  })}
              </Layer>
            </>
          )}
        </Stage>
      </div>
    </div>
  )
}

export default CanvasWrapper
