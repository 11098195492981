import {
  TENANT_BOXES_REQUEST_SUCCESS,
  TENANT_BOXES_REQUEST_FAILURE,
  TENANT_BOXES_REQUEST,
  TENANTS_LIST_REQUEST_FAILURE,
  TENANTS_LIST_REQUEST,
  TENANTS_LIST_REQUEST_SUCCESS,
  NEWEST_EDGE_VERSION_TARGETED_REQUEST,
  NEWEST_EDGE_VERSION_TARGETED_REQUEST_SUCCESS,
  NEWEST_EDGE_VERSION_TARGETED_REQUEST_FAILURE,
  TARGET_NEWEST_EDGE_VERSION_REQUEST_SUCCESS,
  TARGET_NEWEST_EDGE_VERSION_REQUEST_FAILURE,
  EDGE_UPDATE_SCHEDULES_REQUEST,
  EDGE_UPDATE_SCHEDULES_REQUEST_SUCCESS,
  EDGE_UPDATE_SCHEDULES_REQUEST_FAILURE
} from '../actions/actionTypes'

const initialState = {
  allDeviceIds: [],
  allTenantIds: [],
  deviceByIds: {},
  tenantByIds: {},
  edgeVersionUpdated: undefined as boolean | undefined,
  edgeUpdateSchedules: [],
  edgeUpdateSchedulesFetched: false,
  tenantsFetched: false,
  devicesFetched: false,
  error: null
}

const fn = function (state = initialState, action) {
  switch (action.type) {
    case TENANT_BOXES_REQUEST:
      return {
        ...state,
        devicesFetched: false,
        error: null
      }
    case TENANTS_LIST_REQUEST:
      return {
        ...state,
        tenantsFetched: false,
        error: null
      }
    case NEWEST_EDGE_VERSION_TARGETED_REQUEST:
      return {
        ...state,
        error: null
      }
    case EDGE_UPDATE_SCHEDULES_REQUEST:
      return {
        ...state,
        edgeUpdateSchedulesFetched: false,
        error: null
      }
    case TENANT_BOXES_REQUEST_SUCCESS:
      const newState = {
        allDeviceIds: action.response.result,
        deviceByIds: action.response.entities.boxes
          ? action.response.entities.boxes
          : {},
        devicesFetched: true
      }
      return Object.assign({}, state, newState)

    case TENANTS_LIST_REQUEST_SUCCESS:
      const newTenantState = {
        allTenantIds: action.response.result,
        tenantByIds: action.response.entities.tenant
          ? action.response.entities.tenant
          : {},
        tenantsFetched: true
      }
      return Object.assign({}, state, newTenantState)
    case NEWEST_EDGE_VERSION_TARGETED_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        edgeVersionUpdated: action.payload.versionUpToDate
      })
    case TARGET_NEWEST_EDGE_VERSION_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        edgeUpdateSchedulesFetched: false
      })
    case EDGE_UPDATE_SCHEDULES_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        edgeUpdateSchedules: action.payload.edgeUpdateSchedules,
        edgeUpdateSchedulesFetched: true
      })
    case TENANT_BOXES_REQUEST_FAILURE:
      return {
        ...state,
        devicesFetched: false,
        error: action.payload.error
      }

    case TENANTS_LIST_REQUEST_FAILURE:
      return {
        ...state,
        tenantsFetched: false,
        error: action.payload.error
      }
    case NEWEST_EDGE_VERSION_TARGETED_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        edgeVersionUpdated: undefined
      }
    case TARGET_NEWEST_EDGE_VERSION_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        edgeVersionUpdated: undefined
      }
    case EDGE_UPDATE_SCHEDULES_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        edgeUpdateSchedules: [],
        edgeUpdateSchedulesFetched: false
      }
    default:
      return state
  }
}
export default fn
