import {
  CALL_API,
  client,
  getAuthorizationHeader,
  Schemas
} from '../middleware/api'
import {
  TENANT_BOXES_REQUEST,
  TENANT_BOXES_REQUEST_FAILURE,
  TENANT_BOXES_REQUEST_SUCCESS,
  NEWEST_EDGE_VERSION_TARGETED_REQUEST,
  NEWEST_EDGE_VERSION_TARGETED_REQUEST_FAILURE,
  NEWEST_EDGE_VERSION_TARGETED_REQUEST_SUCCESS,
  TARGET_NEWEST_EDGE_VERSION_REQUEST,
  TARGET_NEWEST_EDGE_VERSION_REQUEST_FAILURE,
  TARGET_NEWEST_EDGE_VERSION_REQUEST_SUCCESS,
  TENANTS_LIST_REQUEST,
  TENANTS_LIST_REQUEST_FAILURE,
  TENANTS_LIST_REQUEST_SUCCESS,
  TENANTS_MOVE_REQUEST,
  TENANTS_MOVE_REQUEST_FAILURE,
  TENANTS_MOVE_REQUEST_SUCCESS,
  EDGE_UPDATE_SCHEDULES_REQUEST,
  EDGE_UPDATE_SCHEDULES_REQUEST_SUCCESS,
  EDGE_UPDATE_SCHEDULES_REQUEST_FAILURE
} from './actionTypes'

/**
 * Fetches an array of all boxes available in any tenants from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 */
const fetchTenantBoxes = () => ({
  [CALL_API]: {
    types: [
      TENANT_BOXES_REQUEST,
      TENANT_BOXES_REQUEST_SUCCESS,
      TENANT_BOXES_REQUEST_FAILURE
    ],
    endpoint: 'tenantAdministration/devices/list',
    schema: Schemas.BOX_ARRAY
  }
})

/**
 * Fetches an array of all boxes available in any tenants from the API.
 * Relies on Redux Thunk middleware.
 */
export const loadTenantBoxes = () => (dispatch, getState) => {
  return dispatch(fetchTenantBoxes())
}

/**
 * Fetches an array of all available tenants from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 */
const fetchTenants = () => ({
  [CALL_API]: {
    types: [
      TENANTS_LIST_REQUEST,
      TENANTS_LIST_REQUEST_SUCCESS,
      TENANTS_LIST_REQUEST_FAILURE
    ],
    endpoint: 'tenantAdministration/tenants/list',
    schema: Schemas.TENANT_ARRAY
  }
})

/**
 * Fetches an array of all available tenants from the API.
 * Relies on Redux Thunk middleware.
 */
export const loadTenants = () => (dispatch, getState) => {
  return dispatch(fetchTenants())
}

/**
 * Moves a list of devices to a new tenant.
 * @param tenantId
 * @param deviceIds
 */
export const moveDevicesToTenant = (tenantId: string, deviceIds: string[]) => ({
  [CALL_API]: {
    types: [
      TENANTS_MOVE_REQUEST,
      TENANTS_MOVE_REQUEST_SUCCESS,
      TENANTS_MOVE_REQUEST_FAILURE
    ],
    endpoint: `tenantAdministration/tenants/${tenantId}/devices?clearConfig=true`,
    method: 'POST',
    payload: deviceIds
  }
})

const fetchNewestEdgeVersionTargetedBegin = () => ({
  type: NEWEST_EDGE_VERSION_TARGETED_REQUEST,
  schema: Schemas.UPDATE_SCHEDULE_ARRAY
})

const fetchNewestEdgeVersionTargetedSuccess = (versionUpToDate) => ({
  type: NEWEST_EDGE_VERSION_TARGETED_REQUEST_SUCCESS,
  payload: { versionUpToDate }
})

const fetchNewestEdgeVersionTargetedFailure = (error) => ({
  type: NEWEST_EDGE_VERSION_TARGETED_REQUEST_FAILURE,
  payload: { error }
})

export const loadIsNewestEdgeVersionTargeted = () => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(fetchNewestEdgeVersionTargetedBegin())
      client
        .get('tenantAdministration/edgeVersion', header)
        .then((response) => {
          dispatch(fetchNewestEdgeVersionTargetedSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(fetchNewestEdgeVersionTargetedFailure(error))
        })
    })
  }
}

const fetchEdgeUpdateSchedulesBegin = () => ({
  type: EDGE_UPDATE_SCHEDULES_REQUEST
})

const fetchEdgeUpdateSchedulesSuccess = (edgeUpdateSchedules) => ({
  type: EDGE_UPDATE_SCHEDULES_REQUEST_SUCCESS,
  payload: { edgeUpdateSchedules }
})

const fetchEdgeUpdateSchedulesFailure = (error) => ({
  type: EDGE_UPDATE_SCHEDULES_REQUEST_FAILURE,
  payload: { error }
})

export const loadEdgeUpdateSchedules = () => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(fetchEdgeUpdateSchedulesBegin())
      client
        .get('tenantAdministration/edgeVersion/schedules', header)
        .then((response) => {
          dispatch(fetchEdgeUpdateSchedulesSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(fetchEdgeUpdateSchedulesFailure(error))
        })
    })
  }
}

const targetNewestEdgeVersionBegin = () => ({
  type: TARGET_NEWEST_EDGE_VERSION_REQUEST
})

const targetNewestVersionSuccess = (versionUpToDate) => ({
  type: TARGET_NEWEST_EDGE_VERSION_REQUEST_SUCCESS,
  payload: { versionUpToDate }
})

const targetNewestEdgeVersionFailure = (error) => ({
  type: TARGET_NEWEST_EDGE_VERSION_REQUEST_FAILURE,
  payload: { error }
})

export const targetNewestEdgeVersion = (selectedBoxes, timestamp) => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(targetNewestEdgeVersionBegin())
      client
        .put(
          `tenantAdministration/edgeVersion/update?timestamp=${timestamp}`,
          selectedBoxes,
          header
        )
        .then((response) => {
          dispatch(targetNewestVersionSuccess(response.data))
          dispatch(loadEdgeUpdateSchedules())
          dispatch(loadIsNewestEdgeVersionTargeted())
        })
        .catch((error) => {
          dispatch(targetNewestEdgeVersionFailure(error))
        })
    })
  }
}
