import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'
import {
  DELETE_STREAM_REQUEST,
  DELETE_STREAM_REQUEST_FAILURE,
  DELETE_STREAM_REQUEST_SUCCESS,
  STREAMS_INFO_REQUEST,
  STREAMS_INFO_REQUEST_FAILURE,
  STREAMS_INFO_REQUEST_SUCCESS,
  SAVE_STREAM_REQUEST,
  SAVE_STREAM_REQUEST_FAILURE,
  SAVE_STREAM_REQUEST_SUCCESS,
  STREAMS_REQUEST,
  STREAMS_REQUEST_FAILURE,
  STREAMS_REQUEST_SUCCESS
} from './actionTypes'
import { IStream } from '../../types/stream'
import { log } from '../../services/log'

/**
 * Streams for a box from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 *
 * @param boxId: string The box id to request data for.
 */
const fetchStreams = (boxId) => ({
  [CALL_API]: {
    types: [STREAMS_REQUEST, STREAMS_REQUEST_SUCCESS, STREAMS_REQUEST_FAILURE],
    endpoint: `boxes/${boxId}/streams`,
    schema: Schemas.STREAM_ARRAY,
    id: boxId
  }
})

export const saveStream = (
  boxId: string,
  streamId: string,
  stream: IStream
) => {
  return {
    [CALL_API]: {
      types: [
        SAVE_STREAM_REQUEST,
        SAVE_STREAM_REQUEST_SUCCESS,
        SAVE_STREAM_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.PUT,
      payload: stream,
      endpoint: `boxes/${boxId}/${streamId}`,
      schema: Schemas.STREAM
    }
  }
}

export const deleteStreamConfig = (boxId: string, streamId: string) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_STREAM_REQUEST,
        DELETE_STREAM_REQUEST_SUCCESS,
        DELETE_STREAM_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.DELETE,
      endpoint: `boxes/${boxId}/${streamId}`,
      id: streamId
    }
  }
}

export const deleteStream = (boxId, streamId) => async (dispatch, getState) => {
  dispatch(deleteStreamConfig(boxId, streamId))
    .then((data) => {
      return dispatch({
        type: DELETE_STREAM_REQUEST_SUCCESS,
        payload: {
          streamId: streamId
        }
      })
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_STREAM_REQUEST_FAILURE,
        error
      })
    })
}

/**
 *
 * @param boxId: string The box id to request data for.
 */
export const loadBoxStreams = (boxId) => (dispatch, getState) => {
  return dispatch(fetchStreams(boxId))
}

/**
 * List all available camera infos
 */
export const streamInfos = () => async (dispatch, getState) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        STREAMS_INFO_REQUEST,
        STREAMS_INFO_REQUEST_SUCCESS,
        STREAMS_INFO_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.GET,
      endpoint: `streamInfos`
    }
  })
  if (result.type !== STREAMS_INFO_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}
