import React, { useMemo, useState } from 'react'
import { Tabs, Tooltip } from 'antd'
import { IBox } from '../../types/box'
import { IStream } from '../../types/stream'
import { CubejsApi } from '@cubejs-client/core'
import { EDeviceView } from '../../types/EDeviceView'
import BoxScheduleCalendar from '../BoxScheduleCalendar'
import StreamConfigurationContainer from '../../containers/StreamConfigurationContainer'
import DeviceHealth from '../DeviceHealth'
import { useTranslation } from 'react-i18next'
import { useBoxFeatureFlagsState } from '../../helpers/hooks/useBoxFeatureFlags'
import { EBoxFeatureFlags } from '../../types/boxFeatureFlags'

interface IBoxDetailsBodyProps {
  box: IBox
  streams: IStream[]
  cubejsApi: CubejsApi | null
}

const BoxDetailsBody: React.FC<IBoxDetailsBodyProps> = ({
  box,
  streams,
  cubejsApi
}) => {
  const [deviceView, setDeviceView] = useState(EDeviceView.configuration)
  const { t } = useTranslation()

  const {
    boxFeatureFlags,
    loading: featureFlagsLoading
  } = useBoxFeatureFlagsState(box.id)

  const isDeviceScheduleActive = useMemo(() => {
    return boxFeatureFlags?.featureFlags.some(
      (flag) => flag.feature === EBoxFeatureFlags.deviceStandby && flag.enabled
    )
  }, [boxFeatureFlags])

  const changeTabPane = (key) => {
    setDeviceView(EDeviceView[key])
  }

  if (featureFlagsLoading) {
    return <></>
  }

  return (
    /*
      some explanation about the following part:
      to keep the style of the SCC wanted to use the Tabs component to switch between the configuration and health view.
      the stream configuration uses react-custom-scrollbars which have issues with height-calculation inside the tab-content.
      instead use tabs to select but render streamConfigContainer outside the tabs.
      As device health is pretty heavy workload due to all the graphics we only load it when we switch to the health view.
      We keep it loaded as long as detailPage is open and just make it invisible when switching back to configuration view.
      We switch back to configuration view when closing the devicedetailpage because opening animation gets laggy when opening in health view.
      */
    <>
      <Tabs activeKey={deviceView} type="card" onChange={changeTabPane}>
        <Tabs.TabPane
          tab={t('configuration.viewMode.configuration')}
          key={EDeviceView.configuration}
        />
        <Tabs.TabPane
          tab={t('configuration.viewMode.health')}
          key={EDeviceView.health}
        />
        {isDeviceScheduleActive ? (
          <Tabs.TabPane
            tab={t('configuration.viewMode.schedule')}
            key={EDeviceView.schedule}
          />
        ) : (
          <Tabs.TabPane
            disabled={true}
            tab={
              <Tooltip
                title={t('configuration.viewMode.scheduleDisabledTooltip')}
              >
                {t('configuration.viewMode.schedule')}
              </Tooltip>
            }
            key={EDeviceView.schedule}
          />
        )}
      </Tabs>
      <div
        className={
          deviceView === EDeviceView.configuration
            ? 'scc--boxdetail-component--visible'
            : 'scc--boxdetail-component--hidden'
        }
      >
        <StreamConfigurationContainer
          box={box}
          streams={streams}
          boxFeatureFlags={boxFeatureFlags}
        />
      </div>
      {cubejsApi && deviceView === EDeviceView.health && (
        <div className={'scc--boxdetail-component--visible'}>
          <DeviceHealth
            box={box}
            streams={streams}
            cubejsApi={cubejsApi}
            key={'deviceHealth' + box.id}
          />
        </div>
      )}
      {deviceView === EDeviceView.schedule && isDeviceScheduleActive && (
        <div className={'scc--boxdetail-component--visible'}>
          <BoxScheduleCalendar boxId={box.id} />
        </div>
      )}
    </>
  )
}

export default BoxDetailsBody
