import React from 'react'
import { IScene } from '../../../../types/scene'

interface IProps {
  scene: IScene
}

const SolutionTypeCell: React.FC<IProps> = ({ scene }) => {
  return (
    <div className="scc--centered">
      <span>{scene.solutionType}</span>
    </div>
  )
}

export default SolutionTypeCell
