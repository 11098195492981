import {
  MQTT_CONFIG_REQUEST_FAILURE,
  MQTT_CONFIG_REQUEST_SUCCESS,
  SAVE_MQTT_CONFIG_REQUEST_SUCCESS
} from '../actions/actionTypes'

import union from 'lodash/union'

const initialState = {
  allIds: [],
  byIds: {}
}

const fn = function (state = initialState, action) {
  switch (action.type) {
    // the camera config is loaded in the streamDetails reducer, this state is currently unused, therefore LOAD_ALL_STREAMS_DETAILS_REQUEST_SUCCESS is not implemented

    case MQTT_CONFIG_REQUEST_SUCCESS:
      delete action.response.entities.configurations[action.response.result].id
      let configurations = Object.entries(
        action.response.entities.configurations[action.response.result]
      ).map(([k, v]) => v)

      return {
        ...state,
        allIds: union(
          state.allIds,
          Object.keys(action.response.entities.configurations)
        ),
        byIds: Object.assign({}, state.byIds, {
          [action.response.result]: configurations
        })
      }

    case MQTT_CONFIG_REQUEST_FAILURE:
      return Object.assign({}, state, { errorMessage: action.error })

    case SAVE_MQTT_CONFIG_REQUEST_SUCCESS:
      delete action.response.entities.configurations[action.response.result].id
      let resultConfig = Object.entries(
        action.response.entities.configurations[action.response.result]
      ).map(([k, v]) => v)
      let streamId = action.response.result
      if (streamId !== undefined) {
        let resultObject = {}
        resultObject[streamId] = resultConfig
        return {
          ...state,
          allIds: union(state.allIds, [streamId]),
          byIds: Object.assign({}, state.byIds, resultObject)
        }
      } else {
        return state
      }

    default:
      return state
  }
}
export default fn
