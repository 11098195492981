import moment from 'moment-timezone'

export const allTimeString = (timezone) => [
  '2022-01-01T00:00:00.000',
  moment.tz(timezone).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const today = (timezone) => [
  moment.tz(timezone).startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment.tz(timezone).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const yesterday = (timezone) => [
  moment
    .tz(timezone)
    .subtract(1, 'days')
    .startOf('day')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment
    .tz(timezone)
    .subtract(1, 'days')
    .endOf('day')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const last7Days = (timezone) => [
  moment
    .tz(timezone)
    .subtract(6, 'days')
    .startOf('day')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment.tz(timezone).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const last30Days = (timezone) => [
  moment
    .tz(timezone)
    .subtract(29, 'days')
    .startOf('day')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment.tz(timezone).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const last365Days = (timezone) => [
  moment
    .tz(timezone)
    .subtract(364, 'days')
    .startOf('day')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment.tz(timezone).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const lastWeek = (timezone) => [
  moment
    .tz(timezone)
    .subtract(1, 'weeks')
    .startOf('isoWeek')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment
    .tz(timezone)
    .subtract(1, 'weeks')
    .endOf('isoWeek')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const lastMonth = (timezone) => [
  moment
    .tz(timezone)
    .subtract(1, 'months')
    .startOf('month')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment
    .tz(timezone)
    .subtract(1, 'months')
    .endOf('month')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const thisWeek = (timezone) => [
  moment
    .tz(timezone)
    .startOf('isoWeek')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment.tz(timezone).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const thisMonth = (timezone) => [
  moment
    .tz(timezone)
    .startOf('month')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment.tz(timezone).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]

export const thisYear = (timezone) => [
  moment
    .tz(timezone)
    .startOf('year')
    .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
  moment.tz(timezone).endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
]
