import './RuleEngine.scss'

import React, { useState } from 'react'
import FormLabelWithTooltip from '../FormLabelWithToolTip'
import { FormGroup } from 'carbon-components-react'
import { useTranslation, getI18n } from 'react-i18next'
import { Button, Space } from 'antd'
import {
  EEventTriggerType,
  EEventTriggerUtil,
  ICoordinateBasedEventTrigger
} from '../../types/eventTrigger'
import CreateRuleModal from './CreateRuleModal'
import RuleTable from './RuleTable'
import {
  ITriggerRule,
  ITriggerRuleTemplate,
  TriggerRuleTemplate
} from '../../types/triggerRule'
import { ADD_LOCAL_BOX_EVENT_RULES_REQUEST } from '../../redux/actions/actionTypes'
import { useDispatch } from 'react-redux'
import { ERegionOfInterestType } from '../../types/regionOfInterest'
import { IStream } from '../../types/stream'
import { DeleteConfirmationDialog } from '../ConfirmDialog/DeleteConfirmationDialog'

interface IRuleEngineProps {
  eventTriggers: ICoordinateBasedEventTrigger[]
  selectedEventTrigger: string
  stream: IStream
  rules: ITriggerRule[]
  userTemplates: ITriggerRuleTemplate[]
  rerender: Function
  saveRule: Function
  deleteRule: Function
}

export const getCountingLines = (
  eventTriggers: ICoordinateBasedEventTrigger[]
) => {
  return eventTriggers.filter((trigger) => {
    return trigger.objectType === EEventTriggerType.crossingLine
  })
}

export const getVirtualDoors = (
  eventTriggers: ICoordinateBasedEventTrigger[]
) => {
  return eventTriggers.filter((trigger) => {
    return trigger.objectType === EEventTriggerType.virtualDoor
  })
}

export const getRegionsOfInterest = (eventTriggers) => {
  return eventTriggers.filter((trigger) => {
    return (
      trigger.objectType === EEventTriggerType.regionOfInterest &&
      trigger.roiType === ERegionOfInterestType.generic
    )
  })
}

export const getOriginDestinationZones = (
  eventTriggers: ICoordinateBasedEventTrigger[]
) => {
  return eventTriggers.filter((trigger) => {
    return trigger.objectType === EEventTriggerType.originDestinationZone
  })
}

// Even though this method only applies to OD case atm, we maybe want to extend this in the future without changing the architecture
export const mapTriggersForQueryConfig = (
  eventTriggers: ICoordinateBasedEventTrigger[],
  type: EEventTriggerType
) => {
  let mapping: any[] = []
  if (type === EEventTriggerType.originDestinationZone) {
    mapping = getOriginDestinationZones(eventTriggers).map((zone) => {
      return {
        value: zone.localId,
        title: zone.name
          ? zone.name
          : getI18n().t('draw.ruleEngine.defineRules.unnamed') +
            ' ' +
            EEventTriggerUtil.toString(type)
      }
    })
  }
  return mapping
}

const RuleEngine: React.FC<IRuleEngineProps> = ({
  eventTriggers,
  selectedEventTrigger,
  stream,
  rules,
  rerender,
  saveRule,
  userTemplates,
  deleteRule
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showRuleModal, setShowRuleModal] = useState<boolean>(false)
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(
    false
  )
  const [ruleToDelete, setRuleToDelete] = useState<ITriggerRule | null>(null)
  const [ruleToEdit, setRuleToEdit] = useState<ITriggerRule | null>(null)

  let upsertRule = (rule: ITriggerRule) => {
    let updateRule = rules.find((element) => {
      return element.localId === rule.localId
    })
    if (updateRule) {
      updateRule.name = rule.name
      updateRule.hasChanged = true
      updateRule.conditions = rule.conditions
      updateRule.ioPin = rule.ioPin
    } else {
      dispatch({
        type: ADD_LOCAL_BOX_EVENT_RULES_REQUEST,
        payload: {
          rule: rule
        }
      })
    }
    rerender()
  }

  let confirmDeleteRule = () => {
    if (!ruleToDelete) {
      return
    }
    deleteRule(ruleToDelete)
    setDeleteDialogVisible(false)
  }

  let onDeleteRule = (rule: ITriggerRule) => {
    setRuleToDelete(rule)
    setDeleteDialogVisible(true)
  }

  let editRule = (rule: ITriggerRule) => {
    setRuleToEdit(rule)
    setShowRuleModal(true)
  }

  let toggleTemplate = (rule: ITriggerRule) => {
    rule.template = !rule.template
    rule.template ? addNewTemplate(rule) : removeTemplate(rule)
    saveRule(rule)
  }

  const addNewTemplate = (rule) => {
    userTemplates.push(
      new TriggerRuleTemplate({ ...rule, streamName: stream.name })
    )
  }

  const removeTemplate = (rule) => {
    let indexToRemove = userTemplates.findIndex(
      (template) => template.id === rule.localId
    )
    userTemplates.splice(indexToRemove, 1)
  }

  return (
    <>
      <FormGroup
        legendText={t('draw.ruleEngine.title')}
        className="scc--device-config--title"
      >
        <em className="scc--required--hint">{t('draw.ruleEngine.headline')}</em>
        <FormLabelWithTooltip id="draw.ruleEngine.hint" />
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Button
            type="primary"
            onClick={() => setShowRuleModal(true)}
            disabled={!eventTriggers || !eventTriggers.length}
          >
            {t('draw.ruleEngine.newRule')}
          </Button>
          <RuleTable
            rules={rules}
            eventTriggers={eventTriggers}
            selectedEventTrigger={selectedEventTrigger}
            editRule={editRule}
            deleteRule={onDeleteRule}
            toggleTemplate={toggleTemplate}
          />
        </Space>
      </FormGroup>
      <CreateRuleModal
        eventTriggers={eventTriggers}
        show={showRuleModal}
        close={() => {
          setRuleToEdit(null)
          setShowRuleModal(false)
        }}
        upsertRule={upsertRule}
        ruleToEdit={ruleToEdit}
        userTemplates={userTemplates}
        stream={stream}
      />
      <DeleteConfirmationDialog
        onRequestClose={() => setDeleteDialogVisible(false)}
        onSecondarySubmit={() => setDeleteDialogVisible(false)}
        onRequestSubmit={confirmDeleteRule}
        open={isDeleteDialogVisible}
      />
    </>
  )
}

export default RuleEngine
