import {
  AbstractCoordinateBasedEventTrigger,
  EEventTriggerType
} from './eventTrigger'
import { uuid } from 'lodash-uuid'

export class OriginDestinationZone extends AbstractCoordinateBasedEventTrigger {
  constructor(props) {
    super(props)
    this.objectType = EEventTriggerType.originDestinationZone
  }

  public static default(): OriginDestinationZone {
    return new OriginDestinationZone({
      localId: uuid(),
      coordinates: [
        { x: 0.4, y: 0.3 },
        { x: 0.6, y: 0.3 },
        { x: 0.6, y: 0.6 },
        { x: 0.4, y: 0.6 }
      ]
    })
  }
}
