import { RESET_ERROR_MESSAGE, SHOW_ERROR_MESSAGE } from './actionTypes'

export const resetErrorMessage = () => ({
  type: RESET_ERROR_MESSAGE
})

export const showErrorMessage = (message) => ({
  type: SHOW_ERROR_MESSAGE,
  error: { message }
})
