import {
  GET_BOX_FEATURE_FLAGS_REQUEST,
  GET_BOX_FEATURE_FLAGS_REQUEST_FAILURE,
  GET_BOX_FEATURE_FLAGS_REQUEST_SUCCESS
} from '../actions/actionTypes'
import { IBoxFeatureFlags } from '../../types/boxFeatureFlags'

const initialState = {
  boxFeatureFlags: undefined as IBoxFeatureFlags | undefined,
  loading: false,
  error: null
}

const boxFeatureFlagReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOX_FEATURE_FLAGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case GET_BOX_FEATURE_FLAGS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        boxFeatureFlags: {
          boxId: action.boxId,
          featureFlags: action.payload.featureFlags
        }
      }
    case GET_BOX_FEATURE_FLAGS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        boxFeatureFlags: undefined
      }
    default:
      return state
  }
}

export default boxFeatureFlagReducer
