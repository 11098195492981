import {
  DELETE_ALERT_REQUEST,
  DELETE_ALERT_REQUEST_FAILURE,
  DELETE_ALERT_REQUEST_SUCCESS,
  LIST_ALERTS_REQUEST,
  LIST_ALERTS_REQUEST_FAILURE,
  LIST_ALERTS_REQUEST_SUCCESS,
  SAVE_ALERT_REQUEST,
  SAVE_ALERT_REQUEST_FAILURE,
  SAVE_ALERT_REQUEST_SUCCESS
} from '../actions/actionTypes'
import { IMonitoringAlert } from '../../types/monitoringAlert'

const initialState = {
  alerts: [] as any[],
  loading: true,
  error: null
}

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ALERT_REQUEST_SUCCESS:
      let alertsRemoved = Array.from(state.alerts)
      const indexToDelete = alertsRemoved.findIndex(
        (alert) => alert.id === action.deleted.alertId
      )
      if (indexToDelete > -1) {
        alertsRemoved.splice(indexToDelete, 1)
      }
      return {
        ...state,
        loading: false,
        alerts: alertsRemoved
      }
    case DELETE_ALERT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case DELETE_ALERT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case SAVE_ALERT_REQUEST_SUCCESS:
      let updatedAlerts = Array.from(state.alerts)
      const index = updatedAlerts.findIndex(
        (alert) => alert.id === action.payload.alert.id
      )
      if (index !== -1) {
        updatedAlerts[index] = action.payload.alert
      } else {
        updatedAlerts.push(action.payload.alert)
      }
      return {
        ...state,
        loading: false,
        alerts: updatedAlerts
      }
    case SAVE_ALERT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case SAVE_ALERT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case LIST_ALERTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case LIST_ALERTS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        alerts: action.payload.alerts
      }
    case LIST_ALERTS_REQUEST_FAILURE:
      let alerts: IMonitoringAlert[] = []
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        alerts: alerts
      }
    default:
      return state
  }
}

export default alertReducer
