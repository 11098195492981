import {
  BOXES_REQUEST_FAILURE,
  BOXES_REQUEST_SUCCESS,
  BOX_REQUEST_SUCCESS,
  SAVE_BOX_REQUEST_SUCCESS
} from '../actions/actionTypes'

import union from 'lodash/union'
import { ERuntimeState } from '../../types/runtimeState'

const initialState = {
  allIds: [],
  byIds: {},
  boxesFetched: false
}

const fn = function (state = initialState, action) {
  switch (action.type) {
    case BOX_REQUEST_SUCCESS:
      return {
        ...state,
        allIds: union(state.allIds, action.response.result),
        byIds: Object.assign({}, state.byIds, action.response.entities.boxes)
      }

    case BOXES_REQUEST_SUCCESS:
      const newState = {
        allIds: action.response.result,
        byIds: action.response.entities.boxes
          ? action.response.entities.boxes
          : {},
        boxesFetched: true
      }
      return Object.assign({}, state, newState)

    case BOXES_REQUEST_FAILURE:
      return Object.assign({}, state, { boxesFetched: true })

    case SAVE_BOX_REQUEST_SUCCESS:
      let boxId = action.response.result
      let boxes = action.response.entities.boxes
      let boxStatus = boxes[boxId].boxStatus
      if (boxStatus) {
        boxStatus.runtimeState = ERuntimeState.pending
      }
      boxes[boxId].boxStatus = boxStatus

      return {
        ...state,
        allIds: union(state.allIds, [boxId]),
        byIds: Object.assign({}, state.byIds, {
          ...boxes
        })
      }

    default:
      return state
  }
}
export default fn
