import {
  GET_ONVIF_RECOMMENDATIONS_REQUEST_SUCCESS,
  GET_ONVIF_RECOMMENDATIONS_REQUEST,
  PATCH_ONVIF_SETTINGS_REQUEST_SUCCESS,
  PATCH_ONVIF_SETTINGS_REQUEST,
  GET_ONVIF_RECOMMENDATIONS_REQUEST_FAILURE,
  PATCH_ONVIF_SETTINGS_REQUEST_FAILURE,
  PATCH_ONVIF_ZOOM_REQUEST_SUCCESS,
  PATCH_ONVIF_ZOOM_REQUEST_FAILURE,
  PATCH_ONVIF_ZOOM_REQUEST
} from '../actions/actionTypes'

const initialState = {
  onvifSettings: {},
  onvifRecommendations: {},
  onvifFetched: false,
  onvifApplied: true
}

const fn = function (state = initialState, action) {
  function getNewSettings(action) {
    let newSettings = state.onvifSettings
    let id = action.response ? action.response.id : action.id
    newSettings[id] = action.response
      ? action.response.currentSettings
      : undefined
    return newSettings
  }

  function getNewRecommendations(action) {
    let newRecommendations = state.onvifRecommendations
    let id = action.response ? action.response.id : action.id
    newRecommendations[id] = action.response
      ? action.response.recommendedSettings
      : undefined
    return newRecommendations
  }

  switch (action.type) {
    case PATCH_ONVIF_SETTINGS_REQUEST:
    case PATCH_ONVIF_ZOOM_REQUEST:
      return {
        ...state,
        onvifApplied: false
      }

    case PATCH_ONVIF_SETTINGS_REQUEST_FAILURE:
    case PATCH_ONVIF_ZOOM_REQUEST_SUCCESS:
    case PATCH_ONVIF_ZOOM_REQUEST_FAILURE:
      return {
        ...state,
        onvifApplied: true
      }

    case GET_ONVIF_RECOMMENDATIONS_REQUEST:
      return {
        ...state,
        onvifFetched: false
      }

    case GET_ONVIF_RECOMMENDATIONS_REQUEST_SUCCESS:
    case GET_ONVIF_RECOMMENDATIONS_REQUEST_FAILURE:
      return {
        ...state,
        onvifSettings: getNewSettings(action),
        onvifRecommendations: getNewRecommendations(action),
        onvifFetched: true
      }

    case PATCH_ONVIF_SETTINGS_REQUEST_SUCCESS:
      let patchedSettings = state.onvifSettings
      patchedSettings[action.response.id] = Object.assign(
        patchedSettings[action.response.id],
        action.response
      )
      return {
        ...state,
        onvifSettings: patchedSettings,
        onvifApplied: true
      }

    default:
      return state
  }
}
export default fn
