export enum ECameraConfigurationType {
  bmaCameraConfiguration = 'BmaCameraConfiguration',
  granularCameraConfiguration = 'GranularCameraConfiguration',
  rawCameraConfiguration = 'RawCameraConfiguration',
  usbCameraConfiguration = 'UsbCameraConfiguration'
}

export interface ICameraConfiguration {
  boxId?: string
  streamId?: string
  objectType?: string
  configurationType: ECameraConfigurationType
  rawConnectionURI?: string
  username?: string
  password?: string
  host?: string
  port?: number
  path?: string
}

export const DefaultCameraConfiguration: ICameraConfiguration = {
  objectType: 'CameraConfiguration',
  configurationType: ECameraConfigurationType.granularCameraConfiguration,
  rawConnectionURI: undefined,
  username: '',
  password: '',
  host: '',
  port: undefined,
  path: undefined
}

export const DefaultBmaCameraConfiguration: ICameraConfiguration = {
  objectType: 'CameraConfiguration',
  configurationType: ECameraConfigurationType.bmaCameraConfiguration,
  rawConnectionURI: undefined,
  username: undefined,
  password: undefined,
  host: undefined,
  port: undefined,
  path: undefined
}
