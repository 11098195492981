import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import { EEventTriggerType } from '../../../types/eventTrigger'
import { ERegionOfInterestType } from '../../../types/regionOfInterest'

const RuleTriggerSelection = ({
  eventTriggers,
  newEventTriggers,
  handleEventTriggerChange,
  invalid,
  validTriggerTypes
}) => {
  const filteredEventTriggers = eventTriggers.filter((trigger) => {
    return validTriggerTypes?.includes(trigger.objectType)
  })

  let crossingLines = filteredEventTriggers.filter((trigger) => {
    return trigger.objectType === EEventTriggerType.crossingLine
  })

  let virtualDoors = filteredEventTriggers.filter((trigger) => {
    return trigger.objectType === EEventTriggerType.virtualDoor
  })

  let regionsOfInterest = filteredEventTriggers.filter((trigger) => {
    return (
      trigger.objectType === EEventTriggerType.regionOfInterest &&
      trigger.roiType === ERegionOfInterestType.generic
    )
  })

  let originDestinationZones = filteredEventTriggers.filter((trigger) => {
    return trigger.objectType === EEventTriggerType.originDestinationZone
  })

  let isEmpty =
    !filteredEventTriggers ||
    !(
      crossingLines.length ||
      virtualDoors.length ||
      regionsOfInterest.length ||
      originDestinationZones.length
    )

  const { t } = useTranslation()
  const { Option, OptGroup } = Select

  return (
    <Select
      optionFilterProp="label"
      allowClear
      value={
        newEventTriggers?.trigger
          ? newEventTriggers?.trigger
          : newEventTriggers?.triggerType
      }
      onChange={handleEventTriggerChange}
      key="input"
      className={invalid ? 'scc--solutions--selection-invalid' : ''}
      placeholder={t(
        'solutions.scenes.overview.add.data.eventTrigger.addPlaceholder'
      )}
      style={{ width: '100%' }}
    >
      {!isEmpty ? (
        <>
          {crossingLines && crossingLines.length > 0 && (
            <OptGroup
              label={t(
                'solutions.scenes.overview.add.data.crossingLine.addLegendCL'
              )}
            >
              {crossingLines.map((crossingLine) => {
                let clName =
                  crossingLine.name && crossingLine.name.length
                    ? crossingLine.name
                    : t('draw.form.crossingLine.name.placeholder')
                return (
                  <Option
                    label={`${crossingLine.localId} - ${clName}`}
                    value={crossingLine.localId}
                    key={crossingLine.localId}
                  >
                    <span className="scc--solutions--add-stream-option">
                      {clName}
                    </span>
                  </Option>
                )
              })}
            </OptGroup>
          )}
          {virtualDoors && virtualDoors.length > 0 && (
            <OptGroup
              label={t(
                'solutions.scenes.overview.add.data.virtualDoor.addLegendVD'
              )}
            >
              {virtualDoors.map((virtualDoor) => {
                let doorName =
                  virtualDoor.name && virtualDoor.name.length
                    ? virtualDoor.name
                    : t('draw.form.door.name.placeholder')
                return (
                  <Option
                    label={`${virtualDoor.localId} - ${doorName}`}
                    value={virtualDoor.localId}
                    key={virtualDoor.localId}
                  >
                    <span className="scc--solutions--add-stream-option">
                      {doorName}
                    </span>
                  </Option>
                )
              })}
            </OptGroup>
          )}
          {regionsOfInterest && regionsOfInterest.length > 0 && (
            <OptGroup
              label={t(
                'solutions.scenes.overview.add.data.region.addLegendRegion'
              )}
            >
              {regionsOfInterest.map((region) => {
                let regionName =
                  region.name && region.name.length
                    ? region.name
                    : t('draw.form.roi.name.placeholder')
                return (
                  <Option
                    label={`${region.localId} - ${regionName}`}
                    value={region.localId}
                    key={region.localId}
                  >
                    <span className="scc--solutions--add-stream-option">
                      {regionName}
                    </span>
                  </Option>
                )
              })}
            </OptGroup>
          )}
          {originDestinationZones && originDestinationZones.length > 0 && (
            <OptGroup
              label={t(
                'solutions.scenes.overview.add.data.originDestination.addLegendOD'
              )}
            >
              <Option
                label={t(
                  `solutions.scenes.overview.add.data.originDestination.addSelectionLabel`
                )}
                value={EEventTriggerType.originDestinationZone}
                key={EEventTriggerType.originDestinationZone}
              >
                <span className="scc--solutions--add-stream-option">
                  {t(
                    `solutions.scenes.overview.add.data.originDestination.addSelectionLabel`
                  )}
                </span>
              </Option>
            </OptGroup>
          )}
        </>
      ) : (
        <Option value="default" key="default" disabled>
          {t('solutions.scenes.overview.add.data.eventTrigger.emptyLegend')}
        </Option>
      )}
    </Select>
  )
}

export default RuleTriggerSelection
