import './BoxDetailsHeader.scss'

import { EBoxUpdateStatus, IBox } from '../../types/box'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import { Button, Divider, Input, Row, Space, Tag, Tooltip } from 'antd'
import { Col } from 'antd/lib/grid'
import { saveBoxDetails } from '../../redux/actions/boxDetails'
import { useDispatch } from 'react-redux'
import { notify } from '../../services/notify'
import { getIdComponent } from '../HelperComponents'
import { clipLongText, MetadataTable } from './MetadataTable'
import { EDeviceView } from '../../types/EDeviceView'
import { useBoxActionScheduleState } from '../../helpers/hooks/useBoxActionSchedule'

interface IBoxDetailsHeaderProps {
  box: IBox
  tabPaneChanger: (tab: EDeviceView) => void
}

const BoxDetailsHeader: React.FC<IBoxDetailsHeaderProps> = ({
  box,
  tabPaneChanger
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  let [editMode, setEditMode] = useState(false)
  let [newDeviceName, setNewDeviceName] = useState<string>('')
  let [updatedMetadataArray, setUpdatedMetadataArray] = useState<
    Array<{ name: string; value: string }>
  >([])
  let [metadataArray, setMetadataArray] = useState<
    Array<{ name: string; value: string }>
  >([])
  let [invalidMetadata, setInvalidMetadata] = useState<boolean>(false)
  let [autoFocusElement, setAutoFocusElement] = useState<string>('boxname')

  const boxActionScheduleEnabled = useBoxActionScheduleState(box.id)
    .boxActionSchedule?.enabled

  useEffect(() => {
    setNewDeviceName(box.name ? box.name : '')
    if (box.metadata) {
      let array = Object.keys(box.metadata)
        .sort()
        .map((key) => ({
          name: key,
          value: box.metadata![key]
        }))
      setMetadataArray(array)
    } else {
      setMetadataArray([])
    }
  }, [box.name, box.metadata])

  const handleDeviceNameChange = (event) => {
    setNewDeviceName(event.target.value)
  }

  const handleDeviceNameKey = (event) => {
    if (event.key === 'Enter') {
      saveDeviceInfo()
    }
  }
  const editDeviceInfo = (autoFocus) => {
    setAutoFocusElement(autoFocus)
    setEditMode(true)
    setUpdatedMetadataArray(
      Array.from(metadataArray.map((item) => ({ ...item })))
    )
  }
  const cancelDeviceName = (event) => {
    setEditMode(false)
    setInvalidMetadata(false)
    setUpdatedMetadataArray([])
  }
  const ILLEGAL_METADATA_CHARS = new Set(['.', '$', '#', ' '])

  const saveDeviceInfo = async () => {
    if (
      updatedMetadataArray.some(
        (item) =>
          item.name.trim() === '' ||
          item.value.trim() === '' ||
          updatedMetadataArray.some(
            (otherItem) => item.name === otherItem.name && item !== otherItem
          ) ||
          item.name.split('').some((char) => ILLEGAL_METADATA_CHARS.has(char)) // Check for illegal chars
      )
    ) {
      setInvalidMetadata(true)
      return
    }
    setInvalidMetadata(false)

    box.name = newDeviceName
    if (updatedMetadataArray.length === 0) {
      box.metadata = undefined
    } else {
      box.metadata = {}
      updatedMetadataArray.forEach((item) => {
        box.metadata![item.name.trim()] = item.value.trim()
      })
    }
    await dispatch(
      saveBoxDetails(box.id, {
        boxConfig: box
      })
    )
    setEditMode(false)

    notify({
      title: t('notification.configuration.saved.title'),
      message: t('notification.configuration.saved.message')
    })
  }

  const cssHeadlineClasses = box.name
    ? 'scc--boxdetail__header--boxname'
    : 'scc--boxdetail__header--boxname scc--boxdetail__header--boxname__notset'

  return (
    <>
      <div className="scc--boxdetail__header">
        <Row>
          <Col span={12}>
            {editMode ? (
              <div>
                <Input
                  autoFocus
                  size="large"
                  placeholder={
                    box.serial?.toString() ||
                    t('configuration.group.device.deviceNamePlaceholder')
                  }
                  value={newDeviceName}
                  onChange={handleDeviceNameChange}
                  onKeyPress={handleDeviceNameKey}
                />
                <Space
                  style={{ justifyContent: 'space-between', width: '100%' }}
                >
                  <Button
                    onClick={cancelDeviceName}
                    className="scc--solutions--button"
                  >
                    {t('solutions.groups.cancelGroupButton')}
                  </Button>
                  <Button
                    onClick={saveDeviceInfo}
                    className="scc--solutions--button scc--solutions--save-group-button"
                    type="primary"
                    style={{
                      width: 160
                    }}
                  >
                    {t('configuration.group.device.saveDeviceInfoButton')}
                  </Button>
                </Space>
              </div>
            ) : (
              <>
                <h2 className={cssHeadlineClasses}>
                  {(box.name && clipLongText(box.name, 45)) ||
                    box.serial ||
                    t('boxList.dataTable.empty.deviceName')}
                  <Button
                    icon={<EditOutlined />}
                    className="scc--boxdetail__header--edit-button"
                    onClick={() => editDeviceInfo('boxname')}
                  />
                </h2>
                <div className="scc--boxdetail__header--boxId">
                  {getIdComponent(
                    box.id,
                    'configuration.group.device.deviceId',
                    t
                  )}
                </div>
                {box.serial && (
                  <div className="scc--boxdetail__header--serial">
                    {getIdComponent(
                      box.serial!.toString(),
                      'configuration.group.device.deviceSerial',
                      t
                    )}
                  </div>
                )}
              </>
            )}
            {boxActionScheduleEnabled && (
              <Tag
                className={
                  'scc--boxdetail__header--tag scc--boxdetail__header--tag-clickable'
                }
                color="blue"
                onClick={() => tabPaneChanger(EDeviceView.schedule)}
              >
                {t('configuration.group.device.scheduleTag')}
              </Tag>
            )}
            {box.updateStatus === EBoxUpdateStatus.UPDATE_AVAILABLE && (
              <Tooltip
                title={t('configuration.group.device.updateAvailableToolTip')}
              >
                <Tag className={'scc--boxdetail__header--tag'} color="red">
                  {t('configuration.group.device.updateAvailableTag')}
                </Tag>
              </Tooltip>
            )}
            {box.updateStatus === EBoxUpdateStatus.UPDATE_SCHEDULED && (
              <Tooltip
                title={t('configuration.group.device.updateScheduledToolTip')}
              >
                <Tag className={'scc--boxdetail__header--tag'} color="blue">
                  {t('configuration.group.device.updateScheduledTag')}
                </Tag>
              </Tooltip>
            )}
          </Col>
          <Divider type={'vertical'} style={{ height: 'auto' }} />
          <Col span={11}>
            <MetadataTable
              key={box.id + 'MetadataTable'}
              metadata={metadataArray}
              isEdit={editMode}
              newMetadata={updatedMetadataArray}
              setNewMetadata={setUpdatedMetadataArray}
              invalidMetadata={invalidMetadata}
              onEdit={editDeviceInfo}
              autoFocusElement={autoFocusElement}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default BoxDetailsHeader
