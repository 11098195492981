import { Config } from '../services/config'
import cameraBlue from '../theme/icons/camera_blue.svg'
import cameraRed from '../theme/icons/camera_red.svg'
import cameraGreen from '../theme/icons/camera_green.svg'
import cameraSwarm from '../theme/icons/camera_swarm.svg'
import cameraBlueInactive from '../theme/icons/camera_blue_inactive.svg'
import cameraRedInactive from '../theme/icons/camera_red_inactive.svg'
import cameraGreenInactive from '../theme/icons/camera_green_inactive.svg'

export const cameraIcon = () => {
  if (Config.CUSTOM_THEME) {
    if (Config.CUSTOM_COLOR && Config.CUSTOM_COLOR.toLowerCase() === 'blue') {
      return cameraBlue
    } else if (
      Config.CUSTOM_COLOR &&
      Config.CUSTOM_COLOR.toLowerCase() === 'red'
    ) {
      return cameraRed
    } else {
      return cameraGreen
    }
  }
  return cameraSwarm
}

export const cameraIconNoSwarm = () => {
  if (Config.CUSTOM_COLOR && Config.CUSTOM_COLOR.toLowerCase() === 'blue') {
    return cameraBlue
  } else if (
    Config.CUSTOM_COLOR &&
    Config.CUSTOM_COLOR.toLowerCase() === 'red'
  ) {
    return cameraRed
  } else {
    return cameraGreen
  }
}

export const cameraIconInactiveNoSwarm = () => {
  if (Config.CUSTOM_COLOR && Config.CUSTOM_COLOR.toLowerCase() === 'blue') {
    return cameraBlueInactive
  } else if (
    Config.CUSTOM_COLOR &&
    Config.CUSTOM_COLOR.toLowerCase() === 'red'
  ) {
    return cameraRedInactive
  } else {
    return cameraGreenInactive
  }
}
