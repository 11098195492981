import React from 'react'
import * as PropTypes from 'prop-types'
import { Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

const RemoveButtonGroup = ({ onRemoveClick, children, ...props }) => (
  <Button.Group
    style={{
      marginRight: 8
    }}
    {...props}
  >
    {children}
    <Button
      type="ghost"
      danger={true}
      onClick={onRemoveClick}
      icon={<CloseCircleOutlined />}
    />
  </Button.Group>
)

RemoveButtonGroup.propTypes = {
  onRemoveClick: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired
}
export default RemoveButtonGroup
