import './CameraFeed.scss'
import './DrawCanvas.scss'

import React, { Component } from 'react'

import CanvasWrapper from './CanvasWrapper'
import { ECameraFrameMode, ICameraFrame } from '../../types/cameraFrame'
import { ICoordinateBasedEventTrigger } from '../../types/eventTrigger'
import { withTranslation } from 'react-i18next'
import OnvifModal from '../OnvifModal'
import { userIsAtLeast, UserRole } from '../../types/user_role'

interface IDrawCanvasProps {
  boxId: string
  streamId: string
  frame?: ICameraFrame
  eventTriggers: ICoordinateBasedEventTrigger[]
  selectedEventTrigger: string
  highlightedEventTrigger: string
  onDragEnd?: (MouseEvent, IEventTrigger, any) => void
  onSelectEventTrigger?: (IEventTrigger) => void
  isEditable: boolean
  isLoading: boolean
  onRefreshButtonClick: (MouseEvent) => void
  calibration: ECameraFrameMode
  recordTrackCalibration?: boolean
  setCalibration: Function
  disabled: boolean
  showOnvifButton?: boolean
  enableOnvifButton?: boolean
  t: any
  hiddenEventTriggers?: ICoordinateBasedEventTrigger[]
  toggleHideAllEventTriggers?: () => void
}

interface IDrawCanvasState {
  showOnvifModal: boolean
}

class DrawCanvas extends Component<IDrawCanvasProps, IDrawCanvasState> {
  stage: any
  highlightedEventTrigger: any

  static defaultProps = {
    isEditable: false,
    showOnvif: false,
    enableOnvifButton: false
  }

  constructor(props) {
    super(props)

    this.onDragStart = this.onDragStart.bind(this)
    this.onClick = this.onClick.bind(this)
    this.state = {
      showOnvifModal: false
    }
  }

  onDragStart(event, currentEventTrigger) {
    if (currentEventTrigger.roiType !== 'focus') {
      event.currentTarget.moveToTop()
    }
    this.setSelected(currentEventTrigger)
  }

  onClick(event, currentEventTrigger) {
    if (currentEventTrigger.roiType !== 'focus') {
      event.currentTarget.moveToTop()
    }
    this.setSelected(currentEventTrigger)
  }

  setSelected(eventTrigger) {
    if (typeof this.props.onSelectEventTrigger === 'function') {
      this.props.onSelectEventTrigger(eventTrigger)
    }
  }

  /**
   * Returns the sorted list of event triggers.
   * Necessary to get the z-index for the canvas nodes right.
   * The event trigger to highlight is the last element in the array
   * and therefore gets the highest z-index.
   */
  get eventTriggers(): ICoordinateBasedEventTrigger[] {
    const sorted: ICoordinateBasedEventTrigger[] = []
    let highlightedEventTrigger: ICoordinateBasedEventTrigger | null = null

    this.props.eventTriggers.forEach((eventTrigger) => {
      if (eventTrigger.localId !== this.props.highlightedEventTrigger) {
        sorted.push(eventTrigger)
      } else {
        highlightedEventTrigger = eventTrigger
      }
    })

    if (highlightedEventTrigger) {
      sorted.push(highlightedEventTrigger)
    }

    return sorted
  }

  render() {
    return (
      <>
        <CanvasWrapper
          frame={this.state.showOnvifModal ? undefined : this.props.frame} // hide the frame if onvif settings are open
          eventTriggers={this.props.eventTriggers}
          highlightedEventTrigger={this.props.highlightedEventTrigger}
          selectedEventTrigger={this.props.selectedEventTrigger}
          isEditable={this.props.isEditable}
          isLoading={this.state.showOnvifModal ? false : this.props.isLoading}
          events={{
            onDragStart: this.onDragStart,
            onDragEnd: this.props.onDragEnd,
            onClick: this.onClick,
            onRefreshButtonClick: this.props.onRefreshButtonClick,
            onOnvifCButtonClick: () => {
              this.setState({ showOnvifModal: true })
              this.props.onRefreshButtonClick({
                calibration: ECameraFrameMode.adminView
              })
            }
          }}
          calibration={this.props.calibration}
          setCalibration={this.props.setCalibration}
          disabled={this.props.disabled}
          showOnvifButton={
            userIsAtLeast(UserRole.Admin) && this.props.showOnvifButton!
          }
          hiddenEventTriggers={this.props.hiddenEventTriggers}
          enableOnvifButton={this.props.enableOnvifButton!}
          trackCalibrationRecordingEnabled={this.props.recordTrackCalibration}
          toggleHideAllEventTriggers={this.props.toggleHideAllEventTriggers}
          boxId={this.props.boxId}
        />
        {userIsAtLeast(UserRole.Admin) &&
          this.props.showOnvifButton &&
          this.props.enableOnvifButton && (
            <OnvifModal
              boxId={this.props.boxId}
              streamId={this.props.streamId}
              show={this.state.showOnvifModal}
              hide={() => {
                this.setState({ showOnvifModal: false })
                this.props.onRefreshButtonClick({
                  calibration: this.props.calibration
                })
              }}
              frame={this.props.frame}
              isLoading={this.props.isLoading}
              events={{
                onRefreshButtonClick: this.props.onRefreshButtonClick
              }}
            />
          )}
      </>
    )
  }
}

export default withTranslation()(DrawCanvas)
