import {
  CREATE_USER_REQUEST,
  CREATE_USER_REQUEST_FAILURE,
  CREATE_USER_REQUEST_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_REQUEST_FAILURE,
  DELETE_USER_REQUEST_SUCCESS,
  LIST_USERS_REQUEST,
  LIST_USERS_REQUEST_FAILURE,
  LIST_USERS_REQUEST_SUCCESS,
  SAVE_USER_REQUEST,
  SAVE_USER_REQUEST_FAILURE,
  SAVE_USER_REQUEST_SUCCESS
} from '../actions/actionTypes'
import { IControlCenterUser } from '../../types/controlCenterUser'

const initialState = {
  users: [] as any[],
  loading: true,
  error: null
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case LIST_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users
      }
    case LIST_USERS_REQUEST_FAILURE:
      let users: IControlCenterUser[] = []
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        users: users
      }
    case SAVE_USER_REQUEST_SUCCESS:
    case CREATE_USER_REQUEST_SUCCESS:
      let updatedUsers = Array.from(state.users)
      const index = updatedUsers.findIndex(
        (user) => user.id === action.payload.user.id
      )
      if (index !== -1) {
        updatedUsers[index] = action.payload.user
      } else {
        updatedUsers.push(action.payload.user)
      }
      return {
        ...state,
        loading: false,
        users: updatedUsers
      }
    case SAVE_USER_REQUEST_FAILURE:
    case CREATE_USER_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case SAVE_USER_REQUEST:
    case CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case DELETE_USER_REQUEST_SUCCESS:
      let usersRemoved = Array.from(state.users)
      const indexToDelete = usersRemoved.findIndex(
        (user) => user.id === action.deleted.userId
      )
      if (indexToDelete > -1) {
        usersRemoved.splice(indexToDelete, 1)
      }
      return {
        ...state,
        loading: false,
        users: usersRemoved
      }
    case DELETE_USER_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    default:
      return state
  }
}

export default userReducer
