import { client, getAuthorizationHeader } from '../middleware/api'
import {
  DELETE_ALERT_REQUEST,
  DELETE_ALERT_REQUEST_FAILURE,
  DELETE_ALERT_REQUEST_SUCCESS,
  LIST_ALERTS_REQUEST,
  LIST_ALERTS_REQUEST_FAILURE,
  LIST_ALERTS_REQUEST_SUCCESS,
  SAVE_ALERT_REQUEST,
  SAVE_ALERT_REQUEST_FAILURE,
  SAVE_ALERT_REQUEST_SUCCESS
} from './actionTypes'
import { IMonitoringAlert } from '../../types/monitoringAlert'

const fetchAlertsBegin = () => ({
  type: LIST_ALERTS_REQUEST
})

const fetchAlertsSuccess = (alerts) => ({
  type: LIST_ALERTS_REQUEST_SUCCESS,
  payload: { alerts }
})

const fetchAlertsFailure = (error) => ({
  type: LIST_ALERTS_REQUEST_FAILURE,
  payload: { error }
})

export const callFetchAlerts = () => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(fetchAlertsBegin())
      client
        .get('monitoring/alerts', header)
        .then((response) => {
          dispatch(fetchAlertsSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(fetchAlertsFailure(error))
        })
    })
  }
}

const deleteAlertsBegin = () => ({
  type: DELETE_ALERT_REQUEST
})

const deleteAlertsSuccess = (alertId) => ({
  type: DELETE_ALERT_REQUEST_SUCCESS,
  deleted: { alertId }
})

const deleteAlertsFailure = (error) => ({
  type: DELETE_ALERT_REQUEST_FAILURE,
  payload: { error }
})

export const callDeleteAlert = (alert: IMonitoringAlert) => {
  let alertId = alert.id
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(deleteAlertsBegin())
      client
        .delete(`monitoring/alerts/${alertId}`, header)
        .then((response) => {
          dispatch(deleteAlertsSuccess(alert.id))
          return alert.id
        })
        .catch((error) => {
          dispatch(deleteAlertsFailure(error))
        })
    })
  }
}

const saveAlertsBegin = () => ({
  type: SAVE_ALERT_REQUEST
})

const saveAlertsSuccess = (alert) => ({
  type: SAVE_ALERT_REQUEST_SUCCESS,
  payload: { alert }
})

const saveAlertsFailure = (error) => ({
  type: SAVE_ALERT_REQUEST_FAILURE,
  payload: { error }
})

export const callSaveAlert = (alert: IMonitoringAlert) => {
  let alertId = alert.id
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(saveAlertsBegin())
      client
        .put(`monitoring/alerts/${alertId}`, alert, header)
        .then((response) => {
          dispatch(saveAlertsSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(saveAlertsFailure(error))
        })
    })
  }
}
