import { uuid } from 'lodash-uuid'
import { EEventTriggerType } from './eventTrigger'

export enum ERegionOfInterestTriggerType {
  time = 'time',
  change = 'change'
}

export enum ERegionOfInterestType {
  generic = 'generic',
  parking = 'parking'
}

export interface ITriggerRule {
  id?: string
  localId: string
  name: string
  template: boolean
  hasChanged?: boolean
  conditions: ITriggerRuleCondition[]
  ioPin?: number
}

export interface ITriggerRuleCondition {
  query: string
  trigger?: string
  triggerType: EEventTriggerType
}

export class TriggerRule implements ITriggerRule {
  id?: string
  localId: string
  name: string
  template: boolean
  hasChanged?: boolean
  conditions: ITriggerRuleCondition[]
  ioPin?: number

  constructor(props) {
    this.id = props.id || undefined
    this.localId = props.localId || props.id || uuid()
    this.name = props.name || ''
    this.template = props.template || false
    this.hasChanged = props.hasChanged || false
    this.conditions = props.conditions || []
    this.ioPin = props.ioPin || undefined
  }

  getRequestObject() {
    let object: ITriggerRule
    object = Object.assign({}, this)

    delete object.hasChanged

    // Make sure object has an id before saving it
    if (!object.id) {
      object.id = object.localId
    }

    return object
  }
}

export interface ITriggerRuleTemplate {
  id: string
  streamId: string
  streamName: string
  conditions: ITriggerRuleCondition[]
  name: string
}

export class TriggerRuleTemplate {
  id: string
  streamId: string
  streamName: string
  conditions: ITriggerRuleCondition[]
  name: string

  constructor(props) {
    this.id = props.id
    this.streamId = props.streamId
    this.streamName = props.streamName
    this.conditions = props.conditions || []
    this.name = props.name || ''
  }
}
