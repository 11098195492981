import { withRouter } from 'react-router-dom'
import MonitoringPage from './MonitoringPage'
import React, { Component } from 'react'
import { Tabs } from 'antd'
import { withTranslation } from 'react-i18next'
import LicenseManagementPage from '../components/LicenseManagement/LicenseMangementPage'
import { userIsAtLeast, UserRole } from '../types/user_role'
import TenantAllocationPage from '../components/TenantAllocation/TenantAllocationPage'
import { connect } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars-2'
import UserManagmentPage from '../components/UserManagment/UserMangementPage'
import EdgeUpdatePage from '../components/EdgeUpdate/EdgeUpdatePage'

const { TabPane } = Tabs

interface IAdminPageProps {
  t: any
  tab: string
  history: any
}

const EAdminTabs = {
  MONITORING: 'monitoring',
  LICENSES: 'licenses',
  ALLOCATION: 'allocation',
  EDGEUPDATE: 'edgeupdate',
  USERS: 'users'
}

class AdminPage extends Component<IAdminPageProps> {
  onChange = (activeGroup) => {
    this.props.history.push(`#${activeGroup}`)
  }

  render() {
    let currentTab = EAdminTabs.MONITORING
    if (this.props.tab && Object.values(EAdminTabs).includes(this.props.tab)) {
      currentTab = this.props.tab
    }

    return (
      <div className="scc--boxcontextual-container">
        <Scrollbars autoHide={true}>
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col-sm-12 bx--col-md-16 bx--col-lg-16 scc--flex--column">
                <Tabs
                  tabPosition="top"
                  size="large"
                  type="editable-card"
                  hideAdd={true}
                  activeKey={currentTab}
                  onChange={this.onChange}
                >
                  <TabPane
                    tab={this.props.t('monitoring.title')}
                    key={EAdminTabs.MONITORING}
                    closable={false}
                  >
                    <MonitoringPage />
                  </TabPane>
                  <TabPane
                    tab={this.props.t('licenses.title')}
                    key={EAdminTabs.LICENSES}
                    closable={false}
                  >
                    <LicenseManagementPage />
                  </TabPane>
                  {userIsAtLeast(UserRole.SuperAdmin) && (
                    <TabPane
                      tab={this.props.t('tenants.title')}
                      key={EAdminTabs.ALLOCATION}
                      closable={false}
                    >
                      <TenantAllocationPage />
                    </TabPane>
                  )}
                  <TabPane
                    tab={this.props.t('edgeUpdate.title')}
                    key={EAdminTabs.EDGEUPDATE}
                    closable={false}
                  >
                    <EdgeUpdatePage />
                  </TabPane>
                  {userIsAtLeast(UserRole.Admin) && (
                    <TabPane
                      tab={this.props.t('users.title')}
                      key={EAdminTabs.USERS}
                      closable={false}
                    >
                      <UserManagmentPage />
                    </TabPane>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tab: ownProps.location.hash && ownProps.location.hash.replace('#', '')
  }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(AdminPage))
)
