import {
  GET_BOX_FEATURE_FLAGS_REQUEST,
  GET_BOX_FEATURE_FLAGS_REQUEST_FAILURE,
  GET_BOX_FEATURE_FLAGS_REQUEST_SUCCESS
} from './actionTypes'
import { client, getAuthorizationHeader } from '../middleware/api'

const fetchBoxFeatureFlagsBegin = () => ({
  type: GET_BOX_FEATURE_FLAGS_REQUEST
})

const fetchBoxFeatureFlagsSuccess = (featureFlags, boxId) => ({
  type: GET_BOX_FEATURE_FLAGS_REQUEST_SUCCESS,
  payload: { featureFlags },
  boxId: boxId
})

const fetchBoxFeatureFlagsFailure = (error) => ({
  type: GET_BOX_FEATURE_FLAGS_REQUEST_FAILURE,
  payload: { error }
})

export const callFetchBoxFeatureFlags = (boxId: string) => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(fetchBoxFeatureFlagsBegin())
      client
        .get(`boxes/${boxId}/featureFlags`, header)
        .then((response) => {
          dispatch(fetchBoxFeatureFlagsSuccess(response.data, boxId))
          return response.data
        })
        .catch((error) => {
          dispatch(fetchBoxFeatureFlagsFailure(error))
        })
    })
  }
}
