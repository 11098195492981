export function updateFilter(filters, updateFilters, newFilter) {
  let isNew = true
  filters.length &&
    filters.forEach((filter) => {
      if (filter.dimension.name === newFilter.dimension.name) {
        newFilter.values.length
          ? updateFilters.update(filter, newFilter)
          : updateFilters.remove(filter)
        isNew = false
      }
    })
  isNew && updateFilters.add(newFilter)
}

export function handleElementChange(
  allElements,
  currentElements,
  updateMethods,
  newElements
) {
  const removeElements = currentElements.filter(
    (item) => !newElements.includes(item.name)
  )
  const addElements = newElements.filter(
    (item) =>
      !currentElements.find((dimension) => {
        return dimension.name === item
      })
  )
  removeElements.forEach((dimension) => {
    updateMethods.remove(dimension)
  })
  addElements.forEach((name) => {
    updateMethods.add(
      allElements.find((dimension) => {
        return dimension.name === name
      })
    )
  })
}

export function getElementsByName(names, availableElements) {
  let elements: any = []

  if (!names) {
    return elements
  }
  if (typeof names === 'string') {
    names = [names]
  }

  names.forEach((item) => {
    let element = availableElements.find((measure) => {
      return measure.name === item
    })
    element && elements.push(element.name)
  })
  return elements
}

export function compareElements(elements, list) {
  return (
    elements.length === list.length &&
    elements.every(function (x, i) {
      return x.name === list[i]
    })
  )
}
