import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'

const StreamSelection = ({
  streams,
  newStreams,
  handleNewStreamsChange,
  multi,
  placeholder
}) => {
  const { t } = useTranslation()
  const { Option } = Select

  const attributes = {}
  multi && (attributes['mode'] = 'multiple')

  return (
    <Select
      optionFilterProp="label"
      allowClear
      value={newStreams}
      onChange={handleNewStreamsChange}
      key="input"
      placeholder={placeholder}
      style={{ width: '100%' }}
      {...attributes}
    >
      <Option value="default" key="default" disabled>
        {streams.length > 0 ? (
          <>
            <span className="scc--solutions--add-stream-option">
              {t('solutions.scenes.streams.addLegendStream')}
            </span>
            {t('solutions.scenes.streams.addLegendDevice')}
          </>
        ) : (
          t('solutions.scenes.streams.emptyLegend')
        )}
      </Option>

      {streams.map((stream) => {
        let streamName =
          stream.streamName && stream.streamName.length
            ? stream.streamName
            : t('configuration.group.stream.streamname.placeholder')
        return (
          <Option
            label={`${stream.streamId} - ${streamName} - ${stream.boxName}`}
            value={stream.streamId}
            key={stream.streamId}
          >
            <span className="scc--solutions--add-stream-option">
              {streamName}
            </span>
            &nbsp;- {stream.boxName}
          </Option>
        )
      })}
    </Select>
  )
}

export default StreamSelection
