import './GroupPage.scss'
import React, { Component } from 'react'

import { Button, Col, Input, Row, Tabs } from 'antd'
import { withTranslation } from 'react-i18next'
import {
  EditOutlined,
  PushpinOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import { IGroup } from '../../types/group'
import { createUpdateGroup } from '../../redux/actions/groups'
import { connect } from 'react-redux'
import { listScenesInGroup } from '../../redux/actions/scenes'
import SceneMapView from './SceneMapView'
import SceneListView from './SceneListView'
import { IScene } from '../../types/scene'

const { TabPane } = Tabs

interface IGroupPage {
  t: any
  group: IGroup
  scenes: IScene[]
  editable: boolean
  updateTabs: Function
  createUpdateGroup: Function
  listScenesInGroup: Function
}

class GroupPage extends Component<IGroupPage> {
  state = {
    editMode: this.props.group.new,
    newGroupName: this.props.group.new ? '' : this.props.group.name,
    saveButtonEnabled: !this.props.group.new
  }

  handleGroupNameChange = (event) => {
    if (event.target.value.length === 0 && this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: false
      })
    } else if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true
      })
    }
    this.setState({
      newGroupName: event.target.value
    })
  }

  handleGroupNameKey = (event) => {
    if (event.key === 'Enter') {
      this.saveGroup()
    }
  }

  editGroupName = () => {
    this.setState({ editMode: true })
  }

  cancelGroupName = () => {
    this.setState({ editMode: false })
  }

  saveGroup = async () => {
    this.props.group.name = this.state.newGroupName
    await this.props.createUpdateGroup(this.props.group)

    this.props.group.new = false
    this.props.updateTabs()
    this.setState({ editMode: false })
  }

  render() {
    const { t, group } = this.props
    return (
      <div>
        <Row>
          {this.state.editMode ? (
            <>
              <Col span={8}>
                <Input
                  autoFocus
                  size="large"
                  placeholder={t('solutions.groups.placeholder')}
                  value={this.state.newGroupName}
                  onChange={this.handleGroupNameChange}
                  onKeyPress={this.handleGroupNameKey}
                />
                {!group.new && (
                  <Button
                    onClick={this.cancelGroupName}
                    className="scc--solutions--button"
                  >
                    {t('solutions.groups.cancelGroupButton')}
                  </Button>
                )}
                <Button
                  onClick={this.saveGroup}
                  className="scc--solutions--button scc--solutions--save-group-button"
                  type="primary"
                  disabled={!this.state.saveButtonEnabled}
                >
                  {t('solutions.groups.saveGroupButton')}
                </Button>
              </Col>
            </>
          ) : (
            <>
              <Col>
                <h3>{group.name}</h3>
              </Col>
              {this.props.editable && (
                <Col>
                  <Button
                    icon={<EditOutlined />}
                    className="scc--solutions--edit-group-button"
                    onClick={this.editGroupName}
                  />
                </Col>
              )}
            </>
          )}
        </Row>

        {!group.new && (
          <Tabs defaultActiveKey="listView" centered>
            <TabPane
              tab={
                <span>
                  <UnorderedListOutlined />
                  {t('solutions.groups.listView')}
                </span>
              }
              key="listView"
            >
              <SceneListView
                scenes={this.props.scenes}
                group={this.props.group}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <PushpinOutlined />
                  {t('solutions.groups.mapView')}
                </span>
              }
              key="mapView"
            >
              <SceneMapView scenes={this.props.scenes} />
            </TabPane>
          </Tabs>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    scenes: state.scenes.byGroup[ownProps.group.id]
  }
}

export default connect(mapStateToProps, {
  createUpdateGroup,
  listScenesInGroup
})(withTranslation()(GroupPage))
