import './DeviceHealth.scss'

import { IBox } from '../../types/box'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { notify } from '../../services/notify'
import { EConnectionState } from '../../types/boxStatus'
import DeviceLogViewerModal from '../DeviceLogViewer/DeviceLogViewerModal'
import { RebootConfirmationDialog } from '../ConfirmDialog/RebootConfirmationDialog'
import { rebootBox } from '../../redux/actions/boxes'
import { useHistory } from 'react-router-dom'
import { Button, Card, Collapse, Divider, Tooltip } from 'antd'
import {
  DownloadOutlined,
  InfoCircleOutlined,
  PoweroffOutlined
} from '@ant-design/icons'
import Scrollbars from 'react-custom-scrollbars-2'
import { useCubeQuery } from '@cubejs-client/react'
import { CubejsApi, Series } from '@cubejs-client/core'
import { renderChart } from '../../datadiscovery/components/ChartRenderer'
import { IStream } from '../../types/stream'
import {
  deviceUptimeQuery,
  deviceLatestWidgetProps,
  deviceStatusQuery,
  deviceWidgetProps,
  streamStatusQuery,
  streamWidgetProps,
  deviceWidgetModemProps,
  IHealthWidgetProps
} from './DeviceHealthWidgetConfigs'
import LoadingAnimation from '../LoadingAnimation'
interface IDeviceHealthProps {
  box: IBox
  streams: IStream[]
  cubejsApi: CubejsApi
}

const DeviceHealth: React.FC<IDeviceHealthProps> = ({
  box,
  streams,
  cubejsApi
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const healthWidgetCardHeader = (widgetProp: IHealthWidgetProps) => {
    return widgetProp.tooltip ? (
      <Tooltip title={t(widgetProp.tooltip)} placement="rightTop">
        {<>{widgetProp.title}</>}
        <InfoCircleOutlined className={'scc--tooltip--icon--no-float'} />
      </Tooltip>
    ) : (
      widgetProp.title
    )
  }

  let boxConnected: boolean = false
  if (
    box.boxStatus &&
    box.boxStatus.connectionState === EConnectionState.connected
  ) {
    boxConnected = true
  }

  let [isModalVisible, setModalVisible] = useState(false)
  let [isRebootDialogVisible, setRebootDialogVisible] = useState(false)
  let [deviceResultSetSeries, setDeviceResultSetSeries] = useState<
    Series<any>[]
  >([])

  const onCloseDialog = (event) => {
    setRebootDialogVisible(false)
  }
  const onRebootDevice = async () => {
    dispatch(rebootBox(box))
    notify({
      title: t('notification.reboot.success.title'),
      message: t('notification.reboot.success.message')
    })
    history.push('/boxes')
  }

  const deviceRenderProps = useCubeQuery(
    Object.assign(deviceStatusQuery(box)),
    {
      cubejsApi: cubejsApi
    }
  )

  const deviceUptimeRenderProps = useCubeQuery(
    Object.assign(deviceUptimeQuery(box)),
    {
      cubejsApi: cubejsApi
    }
  )

  const streamRenderProps = useCubeQuery(
    Object.assign(streamStatusQuery(streams)),
    {
      cubejsApi: cubejsApi
    }
  )

  useEffect(() => {
    if (
      deviceResultSetSeries.length === 0 &&
      !deviceRenderProps.isLoading &&
      deviceRenderProps.resultSet
    ) {
      setDeviceResultSetSeries(deviceRenderProps.resultSet.series())
    }
  }, [deviceResultSetSeries, deviceRenderProps, deviceRenderProps.isLoading])

  const filterIfEmptySeries = (widgetProp: IHealthWidgetProps) => {
    return (
      !widgetProp.hideIfEmpty ||
      deviceResultSetSeries
        .filter((s) => widgetProp.hideIfEmpty!.includes(s.key))
        .some((s) => s.series.some((result) => !!result.value))
    )
  }

  return (
    <Scrollbars>
      <Collapse defaultActiveKey={'device'}>
        <Collapse.Panel
          className={'scc--devicehealth__header--boxname'}
          extra={
            <div
              className="scc--devicehealth__header"
              onClick={(event) => event.stopPropagation()}
            >
              <Button
                icon={<DownloadOutlined />}
                className="scc--devicehealth__header--retrieveLogs-button"
                disabled={!boxConnected}
                onClick={() => {
                  setModalVisible(true)
                }}
              >
                {t('configuration.deviceLogs.retrieveLogs')}
              </Button>
              <Button
                icon={<PoweroffOutlined />}
                className="scc--devicehealth__header--rebootDevice-button"
                disabled={!boxConnected}
                onClick={() => {
                  setRebootDialogVisible(true)
                }}
              >
                {t('configuration.rebootDevice.buttonText')}
              </Button>
              <DeviceLogViewerModal
                visibleState={[isModalVisible, setModalVisible]}
                boxId={box.id}
              />
              <RebootConfirmationDialog
                onRequestClose={onCloseDialog}
                onRequestSubmit={onRebootDevice}
                open={isRebootDialogVisible}
              />
            </div>
          }
          header={t('configuration.health.device.title')}
          key={'device'}
        >
          {deviceUptimeRenderProps.isLoading ||
          deviceRenderProps.isLoading ||
          deviceResultSetSeries.length === 0 ? (
            <LoadingAnimation />
          ) : (
            <>
              {deviceLatestWidgetProps(deviceUptimeRenderProps, t).map(
                (widgetProp) => {
                  return (
                    <>
                      {!widgetProp.renderProps.isLoading &&
                      widgetProp.component ? (
                        <Card
                          title={healthWidgetCardHeader(widgetProp)}
                          style={{
                            width: widgetProp.width,
                            display: 'inline-block'
                          }}
                          className="scc--devicehealth__card"
                        >
                          {renderChart(widgetProp.component)({
                            ...widgetProp.renderProps,
                            pivotConfig: widgetProp.pivotConfig,
                            widgetTitle: widgetProp.title,
                            t
                          })}
                        </Card>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                }
              )}
              {deviceWidgetProps(deviceRenderProps, t)
                .filter(filterIfEmptySeries)
                .map((widgetProp) => {
                  return (
                    <>
                      {widgetProp.component ? (
                        <Card
                          title={healthWidgetCardHeader(widgetProp)}
                          style={{
                            width: widgetProp.width,
                            display: 'inline-block'
                          }}
                          className="scc--devicehealth__card"
                        >
                          {renderChart(widgetProp.component)({
                            ...widgetProp.renderProps,
                            pivotConfig: widgetProp.pivotConfig,
                            widgetTitle: widgetProp.title,
                            t
                          })}
                        </Card>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                })}

              {deviceWidgetModemProps(deviceRenderProps, t)
                .filter(filterIfEmptySeries)
                .map((widgetProp) => {
                  return (
                    <>
                      {!widgetProp.renderProps.isLoading &&
                      widgetProp.component ? (
                        <Card
                          title={healthWidgetCardHeader(widgetProp)}
                          style={{
                            width: widgetProp.width,
                            display: 'inline-block'
                          }}
                          className="scc--devicehealth__card"
                        >
                          {renderChart(widgetProp.component)({
                            ...widgetProp.renderProps,
                            pivotConfig: widgetProp.pivotConfig,
                            widgetTitle: widgetProp.title,
                            t
                          })}
                        </Card>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                })}
            </>
          )}
        </Collapse.Panel>
      </Collapse>
      <Divider orientation="left">
        {t('configuration.health.stream.title')}{' '}
      </Divider>
      <Collapse accordion={true} destroyInactivePanel={true}>
        {streams.map((stream, index) => {
          return (
            <Collapse.Panel
              header={
                stream.name !== undefined && stream.name.length
                  ? stream.name
                  : t('configuration.group.stream.streamname.placeholder') +
                    (index + 1)
              }
              key={stream.id}
            >
              {streamRenderProps.isLoading ? (
                <LoadingAnimation />
              ) : (
                <>
                  {streamWidgetProps(streamRenderProps, t)
                    .filter(filterIfEmptySeries)
                    .map((widgetProp) => {
                      return (
                        <>
                          {!widgetProp.renderProps.isLoading &&
                          widgetProp.component ? (
                            <Card
                              title={healthWidgetCardHeader(widgetProp)}
                              style={{
                                width: widgetProp.width,
                                display: 'inline-block'
                              }}
                              className="scc--devicehealth__card"
                            >
                              {renderChart(widgetProp.component)({
                                ...widgetProp.renderProps,
                                pivotConfig: widgetProp.pivotConfig,
                                widgetTitle: widgetProp.title,
                                t,
                                dataFilters: [stream.id]
                              })}
                            </Card>
                          ) : (
                            <></>
                          )}
                        </>
                      )
                    })}
                </>
              )}
            </Collapse.Panel>
          )
        })}
      </Collapse>
    </Scrollbars>
  )
}

export default DeviceHealth
