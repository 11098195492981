import { useTranslation } from 'react-i18next'
import { IMonitoringRecipient } from '../../types/monitoringAlert'

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, Input, Popconfirm, Table } from 'antd'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { DeleteOutlined } from '@ant-design/icons'

type AlertRecipientsListProps = {
  recipients: IMonitoringRecipient[]
  recipientChanged: (position, email) => void
  addRecipient: () => void
  removeRecipient: (key) => void
}

const EditableContext = React.createContext<FormInstance | null>(null)

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()

  // @ts-ignore
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (editing) {
      // @ts-ignore
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    // @ts-ignore
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    })
  }

  const save = async () => {
    // @ts-ignore
    const values = await form.validateFields()
    toggleEdit()
    handleSave({ ...record, ...values })
  }

  let childNode = children
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

  if (editable) {
    childNode = editing ? (
      <Form.Item
        //validateStatus={invalidEmail ? 'error' : ''}
        help={t('monitoring.alerts.modal.recipients.invalidEmail')}
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            max: 254,
            pattern: expression
          }
        ]}
      >
        <Input
          ref={inputRef}
          placeholder={t('monitoring.alerts.modal.recipients.email')}
          onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          margin: 0,
          paddingLeft: '11px',
          paddingTop: '4px',
          paddingBottom: '4px',
          minHeight: '32px',
          border: '1px solid #d9d9d9'
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return (
    <td
      {...restProps}
      style={{
        verticalAlign: 'middle'
      }}
    >
      {childNode}
    </td>
  )
}

// eslint-disable-next-line no-empty-pattern
const AlertRecipientsList = ({
  recipients,
  recipientChanged,
  addRecipient,
  removeRecipient
}: AlertRecipientsListProps) => {
  const { t } = useTranslation()
  const data: any = []
  if (recipients) {
    recipients.forEach((rec, index) => {
      data.push({
        key: index,
        email: rec.email
      })
    })
  }

  const defaultColumns = [
    {
      title: t('monitoring.alerts.modal.recipients.email'),
      dataIndex: 'email',
      width: '80%',
      editable: true
    },
    {
      dataIndex: 'operation',
      width: '5%',
      align: 'center' as 'center',
      render: (_: any, record: { key: any }) =>
        data.length >= 1 ? (
          <Popconfirm
            title={t('monitoring.alerts.modal.recipients.remove')}
            onConfirm={() => removeRecipient(record.key)}
          >
            <Button
              className="scc--table--action"
              icon={<DeleteOutlined />}
              title={t('draw.dataTable.actions.delete')}
            />
          </Popconfirm>
        ) : null
    }
  ]

  const handleSave = (row) => {
    recipientChanged(row.key, row.email)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    }
  })
  return (
    <div>
      <Button
        onClick={addRecipient}
        type="primary"
        style={{
          marginBottom: 16
        }}
      >
        {t('monitoring.alerts.modal.recipients.add')}
      </Button>
      {recipients && recipients.length > 0 && (
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={data}
          columns={columns}
        />
      )}
    </div>
  )
}

export default AlertRecipientsList
