import './MoveDevices.scss'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Button,
  Card,
  Col,
  Input,
  Result,
  Row,
  Select,
  Space,
  Table
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../redux/store/configureStore'
import { ColumnsType } from 'antd/es/table'
import { BugOutlined, SearchOutlined } from '@ant-design/icons'
import { IBox, sortBoxes } from '../../types/box'
import { NameIdCell } from '../BoxList/cells'
import {
  loadTenantBoxes,
  loadTenants,
  moveDevicesToTenant
} from '../../redux/actions/tenantAdministration'
import { ITenant } from '../../types/tenant'
import ConfirmationDialog from '../ConfirmDialog/ConfirmationDialog'
import { notify } from '../../services/notify'

const MoveDevices = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [devicesToMove, setDevicesToMove] = useState<string[]>([])
  const [targetTenant, setTargetTenant] = useState<string>()
  const [
    isConfirmationModalOpen,
    setConfirmationModalOpen
  ] = useState<boolean>()

  const { error, loading, tenantBoxes, tenants } = useSelector(
    (state: ReduxStore) => ({
      error: !!state.tenantAdministration.error,
      loading: !(
        state.tenantAdministration.devicesFetched &&
        state.tenantAdministration.tenantsFetched
      ),
      tenantBoxes: state.tenantAdministration.allDeviceIds.map(
        (id) => state.tenantAdministration.deviceByIds[id]
      ),
      tenants: state.tenantAdministration.tenantByIds
    })
  )

  useEffect(() => {
    dispatch(loadTenantBoxes())
    dispatch(loadTenants())
  }, [dispatch])

  if (error) {
    return (
      <Result
        title="An error occured"
        icon={<BugOutlined />}
        subTitle="Sorry, something went wrong."
      />
    )
  }
  const data: any = []
  if (!loading) {
    sortBoxes(tenantBoxes)
    const mainTenant: ITenant = Object.values(tenants).find(
      (tenant: any) => tenant.isMainTenant
    ) as ITenant
    tenantBoxes.forEach((box) => {
      if (!box.tenant) {
        box.tenant = mainTenant.id // add main tenant information
      }
      data.push({
        key: box.id,
        name_id: <NameIdCell box={box} key={box.name + box.id} />,
        tenant: (
          <TenantCell
            box={box}
            tenants={tenants}
            key={tenants[box.tenant].appDisplayName + box.id}
          />
        )
      })
    })
  }

  let searchInput: Input | null = null
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={t(`boxList.dataTable.search.${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('boxList.dataTable.search.submit')}
          </Button>
          <Button onClick={() => clearFilters()} type="link" size="small">
            {t('boxList.dataTable.search.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? 'scc--boxlist--searched' : ''} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].key
        ? record[dataIndex].key
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100)
      }
    },
    render: (text) => text
  })

  const columns: ColumnsType<any> = [
    {
      title: t('boxList.dataTable.headers.name_id'),
      dataIndex: 'name_id',
      key: 'name_id',
      width: '60%',
      ...getColumnSearchProps('name_id'),
      sorter: (a, b) => {
        let nameA = a.name_id.key.toLowerCase()
        let nameB = b.name_id.key.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    },
    {
      title: t('tenants.deviceList.dataTable.headers.tenant'),
      dataIndex: 'tenant',
      key: 'tenant',
      width: '40%',
      sorter: (a, b) => {
        let nameA = a.tenant.key.toLowerCase()
        let nameB = b.tenant.key.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    }
  ]

  async function moveDevices() {
    if (!validDeviceSelection() || !validMoveSelection()) {
      return
    }
    setConfirmationModalOpen(false)
    await dispatch(moveDevicesToTenant(targetTenant!, devicesToMove))
    setTargetTenant(undefined)
    dispatch(loadTenantBoxes())
    notify({
      title: t('notification.configuration.saved.title'),
      message: t('notification.configuration.saved.message')
    })
  }

  function validDeviceSelection() {
    if (!devicesToMove || !devicesToMove.length) {
      return false
    }
    return !!targetTenant
  }

  function validMoveSelection() {
    let validMove = true
    devicesToMove.forEach((deviceId) => {
      const box = tenantBoxes.find((box) => box.id === deviceId)
      if (box.tenant === targetTenant) {
        validMove = false
      }
    })
    return validMove
  }

  if (!loading && tenants && Object.keys(tenants).length < 2) {
    return (
      <div className="scc--scenelist--container">
        <div className="bx--grid bx--grid--full-width">
          <Alert
            message={t('tenants.notAvailable')}
            type="info"
            showIcon={true}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="scc--scenelist--container">
      <div className="bx--grid bx--grid--full-width">
        <Space direction="vertical">
          <div>
            <p>{t('tenants.description')}</p>
          </div>
          <Row className={'scc--tenantallocation--container'}>
            <Col span={18}>
              <Table
                rowClassName={'scc--tablerow'}
                rowSelection={{
                  selectedRowKeys: devicesToMove,
                  type: 'checkbox',
                  columnWidth: '3%',
                  onChange: (selectedRowKeys) => {
                    setDevicesToMove(selectedRowKeys as [string])
                  }
                }}
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={{ defaultPageSize: 10 }}
                locale={{
                  emptyText: t('tenants.deviceList.empty')
                }}
              />
            </Col>
            <Col span={6}>
              <Card
                title={t('tenants.move.header')}
                className="scc--tenantallocation--card"
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  <p>{t('tenants.move.description')}</p>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder={t('tenants.selectTenant')}
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={targetTenant}
                    onChange={(value) => setTargetTenant(value)}
                  >
                    {Object.values(tenants).map((tenant: any) => (
                      <Select.Option
                        label={`${tenant.appDisplayName} - ${tenant.id}`}
                        value={tenant.id}
                        key={tenant.id}
                      >
                        <span className="scc--solutions--add-stream-option">
                          {tenant.appDisplayName}
                        </span>
                        &nbsp;- {tenant.id}
                      </Select.Option>
                    ))}
                  </Select>
                  {!validMoveSelection() && (
                    <Alert
                      message={t('tenants.move.invalidMove')}
                      type="warning"
                      showIcon={true}
                    />
                  )}
                  <Button
                    type="primary"
                    key="save"
                    onClick={() => {
                      setConfirmationModalOpen(true)
                    }}
                    disabled={!validDeviceSelection() || !validMoveSelection()}
                  >
                    {t('tenants.move.button')}
                  </Button>
                </Space>
              </Card>
            </Col>
          </Row>
        </Space>
      </div>
      <ConfirmationDialog
        primaryButtonText={t('tenants.move.confirmation.primaryButton')}
        secondaryButtonText={t('tenants.move.confirmation.secondaryButton')}
        modalHeading={t('tenants.move.confirmation.header')}
        danger={false}
        size="xs"
        open={isConfirmationModalOpen}
        onRequestClose={() => setConfirmationModalOpen(false)}
        onRequestSubmit={moveDevices}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: t('tenants.move.confirmation.text', {
              tenantName: `<strong>${
                tenants && targetTenant && tenants[targetTenant!].appDisplayName
              }</strong>`,
              deviceCount: `<strong>${devicesToMove.length}</strong>`
            })
          }}
        />
      </ConfirmationDialog>
    </div>
  )
}

const TenantCell: React.FC<{
  box: IBox
  tenants: { [id: string]: ITenant }
}> = ({ box, tenants }) => {
  return (
    <>
      <span>{tenants[box.tenant!].appDisplayName}</span>
      <br />
      <span className="scc--box_id_serial">ID: {box.tenant}</span>
    </>
  )
}

export default MoveDevices
