import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'

export const RegionSelection = ({
  regions,
  newRegions,
  handleRegionChange,
  multi
}) => {
  const { t } = useTranslation()
  const { Option } = Select

  const attributes = {}
  multi && (attributes['mode'] = 'multiple')

  return (
    <Select
      optionFilterProp="label"
      allowClear
      value={newRegions}
      onChange={handleRegionChange}
      key="input"
      placeholder={t(
        'solutions.scenes.overview.add.data.region.addPlaceholder'
      )}
      style={{ width: '100%' }}
      {...attributes}
    >
      <Option value="default" key="default" disabled>
        {regions && regions.length > 0 ? (
          <>
            <span className="scc--solutions--add-stream-option">
              {t('solutions.scenes.overview.add.data.region.addLegendRegion')}
            </span>
            {t('solutions.scenes.overview.add.data.region.addLegendStream')}
            {t('solutions.scenes.overview.add.data.region.addLegendDevice')}
          </>
        ) : (
          t('solutions.scenes.overview.add.data.region.emptyLegend')
        )}
      </Option>

      {regions &&
        regions.map((region) => {
          let regionName =
            region.regionOfInterestName && region.regionOfInterestName.length
              ? region.regionOfInterestName
              : t('draw.form.roi.name.placeholder')
          let streamName =
            region.streamName && region.streamName.length
              ? region.streamName
              : t('configuration.group.stream.streamname.placeholder')
          return (
            <Option
              label={`${region.regionOfInterestId} - ${regionName} - ${streamName} - ${region.boxName}`}
              value={region.regionOfInterestId}
              key={region.regionOfInterestId}
            >
              <span className="scc--solutions--add-stream-option">
                {regionName}
              </span>
              &nbsp;- {streamName}
              &nbsp;- {region.boxName}
            </Option>
          )
        })}
    </Select>
  )
}
