import { useTranslation } from 'react-i18next'
import { Alert, Result, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  BugOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
  PauseCircleTwoTone,
  WarningTwoTone
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../redux/store/configureStore'
import {
  ELicenseState,
  ELicenseType,
  IStreamLicense
} from '../../types/licenseManagement'
import {
  callFetchLicenses,
  callFetchLicenseStatus
} from '../../redux/actions/licenses'
import {
  getTokenInformation,
  TokenInformation
} from '../../services/apiTokenProvider'

const LicenseList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [tokenInformation, setTokenInformation] = useState<
    TokenInformation | undefined
  >()

  const { licenses, licenseStatus, loading, error } = useSelector(
    (state: ReduxStore) => ({
      error: state.licenses.error,
      licenses: state.licenses.licenses,
      licenseStatus: state.licenses.status,
      loading: state.licenses.loading
    })
  )

  useEffect(() => {
    dispatch(callFetchLicenses())
    dispatch(callFetchLicenseStatus())
    getTokenInformation().then((info) => setTokenInformation(info))
  }, [dispatch])

  if (error) {
    return (
      <Result
        title="An error occured"
        icon={<BugOutlined />}
        subTitle="Sorry, something went wrong."
      />
    )
  }

  let getLicenseState = (license: IStreamLicense) => {
    let validFrom = new Date(license.validFrom)
    validFrom = new Date(
      validFrom.getUTCFullYear(),
      validFrom.getUTCMonth(),
      validFrom.getUTCDate()
    )
    let validTo = new Date(license.validTo)
    validTo = new Date(
      validTo.getUTCFullYear(),
      validTo.getUTCMonth(),
      validTo.getUTCDate()
    )

    let now = new Date()
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    if (today < validFrom) {
      return ELicenseState.INACTIVE
    }
    if (today > validTo) {
      return ELicenseState.EXPIRED
    }
    return ELicenseState.ACTIVE
  }

  function displayLicenseState(license: IStreamLicense) {
    let licenseState = getLicenseState(license)
    if (licenseState === ELicenseState.ACTIVE) {
      return (
        <>
          {<CheckCircleTwoTone twoToneColor="#00a86b" />}&nbsp;
          {t(`licenses.list.licenseState.${ELicenseState.ACTIVE}`)}
        </>
      )
    }
    if (licenseState === ELicenseState.INACTIVE) {
      return (
        <>
          {<PauseCircleTwoTone twoToneColor="#d3d3d3" />}&nbsp;
          {t(`licenses.list.licenseState.${ELicenseState.INACTIVE}`)}
        </>
      )
    }
    if (licenseState === ELicenseState.EXPIRED) {
      return (
        <>
          {<CloseCircleTwoTone twoToneColor="#d3d3d3" />}&nbsp;
          {t(`licenses.list.licenseState.${ELicenseState.EXPIRED}`)}
        </>
      )
    }
  }

  function displayLicenseProductType(license: IStreamLicense) {
    if (license.productType === ELicenseType.SPS) {
      return <>{t('licenses.list.productTypes.SPS')}</>
    } else if (license.productType === ELicenseType.SPS_NFR) {
      return <>{t('licenses.list.productTypes.SPS_NFR')}</>
    } else {
      return <>{t('licenses.list.productTypes.UNKNOWN')}</>
    }
  }

  const dataSource: any = []
  if (!loading && !error && licenses) {
    licenses.forEach((license: IStreamLicense) => {
      dataSource.push({
        productType: displayLicenseProductType(license),
        orderNumber: license.orderNumber,
        invoiceNumber: license.invoiceNumber,
        licensedStreams: license.licensedStreams,
        validFrom: license.validFrom,
        validTo: license.validTo,
        rawState: getLicenseState(license),
        state: displayLicenseState(license)
      })
    })
  }

  const columns = [
    {
      title: t('licenses.list.headers.productType'),
      dataIndex: 'productType',
      key: 'productType',
      width: '15%'
    },
    {
      title: t('licenses.list.headers.orderNumber'),
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      width: '15%'
    },
    {
      title: t('licenses.list.headers.invoiceNumber'),
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: '15%'
    },
    {
      title: t('licenses.list.headers.licensedStreams'),
      dataIndex: 'licensedStreams',
      key: 'licensedStreams',
      width: '10%'
    },
    {
      title: t('licenses.list.headers.validFrom'),
      dataIndex: 'validFrom',
      key: 'validFrom',
      width: '15%',
      sorter: (a: any, b: any) => {
        let aDate = new Date(a.validFrom)
        let bDate = new Date(b.validFrom)
        return aDate.getTime() - bDate.getTime()
      }
    },
    {
      title: t('licenses.list.headers.validTo'),
      dataIndex: 'validTo',
      key: 'validTo',
      width: '15%',
      sorter: (a: any, b: any) => {
        let aDate = new Date(a.validFrom)
        let bDate = new Date(b.validFrom)
        return aDate.getTime() - bDate.getTime()
      }
    },
    {
      title: t('licenses.list.headers.state'),
      dataIndex: 'state',
      key: 'state',
      width: '20%',
      filters: [
        {
          text: t(`licenses.list.licenseState.${ELicenseState.ACTIVE}`),
          value: ELicenseState.ACTIVE
        },
        {
          text: t(`licenses.list.licenseState.${ELicenseState.INACTIVE}`),
          value: ELicenseState.INACTIVE
        },
        {
          text: t(`licenses.list.licenseState.${ELicenseState.EXPIRED}`),
          value: ELicenseState.EXPIRED
        }
      ],
      onFilter: (value: any, record: any) => record.rawState === value
    }
  ]

  let showLicenseDetails = true
  if (
    licenseStatus &&
    licenseStatus.licensePool &&
    tokenInformation &&
    !tokenInformation.tenantInformations?.isMainTenant
  ) {
    showLicenseDetails = false
  }

  return (
    <div className="scc--scenelist--container">
      <div className="bx--grid bx--grid--full-width">
        <Space size={'large'} direction="vertical" style={{ width: '100%' }}>
          {licenseStatus && licenseStatus.licensePool && (
            <div>
              <p>
                <Alert
                  message={t('licenses.licensePooledInfo')}
                  type="info"
                  showIcon
                />
              </p>
            </div>
          )}
          {showLicenseDetails && (
            <div>
              {licenseStatus && !licenseStatus.licenseValid && (
                <Alert
                  message={t('licenses.exceededLong')}
                  type="warning"
                  showIcon
                />
              )}
              {licenseStatus ? (
                t('licenses.currentActive', {
                  used: licenseStatus.activeStreams,
                  total: licenseStatus.licensedStreams
                })
              ) : (
                <LoadingOutlined />
              )}
              &nbsp;
              {licenseStatus && !licenseStatus.licenseValid && (
                <WarningTwoTone twoToneColor="orange" />
              )}
              <Table
                rowClassName={'scc--tablerow'}
                columns={columns}
                dataSource={dataSource}
                locale={{ emptyText: t('licenses.list.empty') }}
                pagination={false}
                loading={loading}
              ></Table>
              <p>{t('licenses.description')}</p>
            </div>
          )}
        </Space>
      </div>
    </div>
  )
}

export default LicenseList
