import {
  BOX_DETAILS_REQUEST_SUCCESS,
  BOX_REQUEST,
  BOX_REQUEST_FAILURE,
  BOX_REQUEST_SUCCESS,
  SAVE_BOX_REQUEST,
  SAVE_BOX_REQUEST_FAILURE,
  SAVE_BOX_REQUEST_SUCCESS
} from './actionTypes'
import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'

import { IBox } from '../../types/box'

/**
 * ===========
 * BOX ACTIONS
 * ===========
 */

/**
 * Fetches a single box from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 *
 * @param boxId: string The box id to request data for.
 */
const fetchBox = (boxId) => ({
  [CALL_API]: {
    types: [BOX_REQUEST, BOX_REQUEST_SUCCESS, BOX_REQUEST_FAILURE],
    endpoint: `boxes/${boxId}`,
    schema: Schemas.BOX,
    id: boxId
  }
})

/**
 * Fetches a single box from the API unless it is cached.
 * Relies on Redux Thunk middleware.
 *
 * @param boxId: string The box id to request data for.
 */
export const loadBox = (boxId) => (dispatch, getState) => {
  const { boxes } = getState()

  if (!boxId) {
    return null
  }

  if (boxes.allIds.indexOf(boxId) > -1) {
    const box = boxes.byIds[boxId]
    dispatch({
      type: BOX_DETAILS_REQUEST_SUCCESS,
      payload: {
        boxconfig: box
      }
    })

    return Promise.resolve(box)
  }

  return dispatch(fetchBox(boxId)).then(
    (data) => data.response.entities.boxes[data.response.result]
  )
}

/**
 * Saves the box data via API request.
 * Will use PUT if an id is provided, POST if not.
 * @param box: Box The box data to store.
 */
export const saveBox = (box: IBox) => ({
  [CALL_API]: {
    types: [
      SAVE_BOX_REQUEST,
      SAVE_BOX_REQUEST_SUCCESS,
      SAVE_BOX_REQUEST_FAILURE
    ],
    method:
      typeof box.id !== 'undefined' && box.id !== ''
        ? HTTP_METHOD.PUT
        : HTTP_METHOD.POST,
    payload: box,
    endpoint:
      typeof box.id !== 'undefined' && box.id !== ''
        ? `boxes/${box.id}`
        : 'boxes',
    schema: Schemas.BOX
  }
})
