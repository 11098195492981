import React from 'react'
import cameraGrey from '../../theme/icons/camera_grey.svg'
import { useTranslation } from 'react-i18next'

const CanvasDisabled: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="scc--camera-feed--wrapper">
      <div className="scc--camera-feed--not-configured scc--camera-feed--centered">
        <img src={cameraGrey} className="scc--camera-feed--icon" alt="logo" />
        <h3 className="scc--camera-feed--header">
          {t('camera.disabledFeed.header')}
        </h3>
        <p className="scc--camera-feed--text">
          {t('camera.disabledFeed.text')}
        </p>
      </div>
    </div>
  )
}

export default CanvasDisabled
