import { RESET_ERROR_MESSAGE, SHOW_ERROR_MESSAGE } from '../actions/actionTypes'

// Updates error message to notify about the failed requests.
const error = (state = null, action) => {
  const { type, error } = action

  switch (type) {
    case RESET_ERROR_MESSAGE:
      return null
    case SHOW_ERROR_MESSAGE:
      return error
    default:
      if (error && typeof error === 'object') {
        return error
      }

      return state
  }
}

export default error
