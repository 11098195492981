import React, { useMemo } from 'react'
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  AzureMapsProvider,
  IAzureDataSourceChildren,
  IAzureMapOptions
} from 'react-azure-maps'
import fromIcon from '../../../theme/icons/formMarker.svg'
import fromToIcon from '../../../theme/icons/formToMarker.svg'
import toIcon from '../../../theme/icons/toMarker.svg'
import { AuthenticationType, data } from 'azure-maps-control'
import { Config } from '../../../services/config'
import { IStreamInfo } from '../../../types/stream'
import { AzureSetCameraOptions } from 'react-azure-maps/dist/types/types'
import {
  cameraIconInactiveNoSwarm,
  cameraIconNoSwarm
} from '../../../helpers/CameraSelector'

const option: IAzureMapOptions = {
  authOptions: {
    authType: AuthenticationType.subscriptionKey,
    subscriptionKey: Config.AZURE_MAPS_KEY
  },
  wheelZoomRate: 1 / 75,
  style: 'road_shaded_relief',
  showLogo: false,
  showFeedbackLink: false
}

class StreamLocation extends data.Position {
  streamName: string
  streamId: string
  selectedFrom: boolean
  selectedTo: boolean

  constructor(
    streamName: string,
    streamId: string,
    selectedFrom: boolean,
    selectedTo: boolean,
    longitude: number,
    latitude: number
  ) {
    super(longitude, latitude)
    this.streamName = streamName
    this.streamId = streamId
    this.selectedFrom = selectedFrom
    this.selectedTo = selectedTo
  }
}

const renderStreamLocationMarker = (streamLocation: StreamLocation) => {
  return (
    <>
      <AzureMapFeature
        key={
          'markerIcon-' +
          streamLocation.streamId +
          streamLocation.selectedFrom +
          streamLocation.selectedTo
        }
        id={
          'markerIcon-' +
          streamLocation.streamId +
          streamLocation.selectedFrom +
          streamLocation.selectedTo
        }
        type="Point"
        coordinate={streamLocation}
        properties={{
          featureType: 'markerIcon',
          fromSelected: streamLocation.selectedFrom,
          toSelected: streamLocation.selectedTo,
          id: streamLocation.streamId,
          name: streamLocation.streamName
        }}
      />
      <AzureMapFeature
        key={
          'streamMarker-' +
          streamLocation.streamId +
          streamLocation.selectedFrom +
          streamLocation.selectedTo
        }
        id={
          'streamMarker-' +
          streamLocation.streamId +
          streamLocation.selectedFrom +
          streamLocation.selectedTo
        }
        type="Point"
        coordinate={streamLocation}
        properties={{
          featureType: 'streamLocation',
          fromSelected: streamLocation.selectedFrom,
          toSelected: streamLocation.selectedTo,
          id: streamLocation.streamId,
          name: streamLocation.streamName
        }}
      />
    </>
  )
}

const StreamMap = ({
  streamLocations,
  handleStreamLocationsChanged
}: {
  streamLocations: StreamLocation[]
  handleStreamLocationsChanged
}) => {
  const boundingBox: data.BoundingBox = data.BoundingBox.fromPositions(
    streamLocations
  )

  const cameraOptions: AzureSetCameraOptions = {
    center: data.BoundingBox.getCenter(boundingBox),
    padding: { bottom: 50, top: 50, left: 50, right: 50 },
    bounds: boundingBox
  }

  const memoizedMarkerRender: IAzureDataSourceChildren = useMemo(
    (): any =>
      streamLocations.map((stream) => renderStreamLocationMarker(stream)),
    [streamLocations]
  )

  const symbolClicked = (e) => {
    //Make sure the event occurred on a pin.
    try {
      e.preventDefault()
      // make sure we have correct symbol(s) clicked. We only want to deal with our stuff
      if (
        e.shapes &&
        e.shapes.every(
          (element) =>
            element.getType() === 'Point' &&
            (element.getProperties().featureType === 'streamLocation' ||
              element.getProperties().featureType === 'markerIcon')
        ) &&
        e.shapes.every((element) => element.getProperties())
      ) {
        let sourceElement = e.shapes.find(
          (element) => element.getProperties().featureType === 'streamLocation'
        )
        const properties = sourceElement.getProperties()
        //make sure stream location
        const streamId = properties.id
        let fromNodes = sourceElement.dataSource.shapes
          .filter(
            (shape) =>
              shape.data.properties.fromSelected === true &&
              shape.data.properties.featureType === 'streamLocation'
          )
          .map((shape) => shape.data.properties.id)
        let toNodes = sourceElement.dataSource.shapes
          .filter(
            (shape) =>
              shape.data.properties.toSelected === true &&
              shape.data.properties.featureType === 'streamLocation'
          )
          .map((shape) => shape.data.properties.id)
        const idxFrom = fromNodes.indexOf(streamId)
        const idxTo = toNodes.indexOf(streamId)
        //rotation through all 4 options
        // both selected -> none selected
        if (idxFrom !== -1 && idxTo !== -1) {
          fromNodes.splice(idxFrom, 1)
          toNodes.splice(idxTo, 1)
        }
        // only from selected -> only to selected
        else if (idxFrom !== -1) {
          fromNodes.splice(idxFrom, 1)
          toNodes.push(streamId)
        }
        //only to selected -> both selected
        else if (idxTo !== -1) {
          fromNodes.push(streamId)
        }
        // none selected -> only from selected
        else {
          fromNodes.push(streamId)
        }
        handleStreamLocationsChanged(fromNodes, toNodes)
      }
    } catch (ex) {
      //nop, do not deal with other events from map sdk
    }
  }

  return (
    <div style={{ height: '500px' }}>
      <AzureMap
        cameraOptions={cameraOptions}
        options={option}
        imageSprites={[
          {
            id: 'swarm-camera',
            icon: cameraIconNoSwarm()
          },
          {
            id: 'swarm-camera-inactive',
            icon: cameraIconInactiveNoSwarm()
          },
          {
            id: 'from-icon',
            icon: fromIcon
          },
          {
            id: 'to-icon',
            icon: toIcon
          },
          {
            id: 'from-to-icon',
            icon: fromToIcon
          }
        ]}
      >
        <AzureMapDataSourceProvider id={'stream-location-source'}>
          <AzureMapLayerProvider
            id="stream-location-layer"
            events={{ click: symbolClicked }}
            options={{
              textOptions: {
                textField: ['get', 'name'],
                offset: [0, 2],
                allowOverlap: true,
                ignorePlacement: true
              },
              iconOptions: {
                image: [
                  //https://learn.microsoft.com/en-us/azure/azure-maps/data-driven-style-expressions-web-sdk
                  'case',
                  [
                    'all',
                    ['==', ['get', 'featureType'], 'markerIcon'],
                    ['any', ['get', 'fromSelected'], ['get', 'toSelected']]
                  ],
                  [
                    'case',
                    ['all', ['get', 'toSelected'], ['get', 'fromSelected']],
                    'from-to-icon',
                    ['get', 'toSelected'],
                    'to-icon',
                    'from-icon'
                  ],
                  ['any', ['get', 'toSelected'], ['get', 'fromSelected']],
                  'swarm-camera',
                  'swarm-camera-inactive'
                ],
                allowOverlap: true,
                ignorePlacement: true,
                offset: [
                  'case',
                  ['==', ['get', 'featureType'], 'markerIcon'],
                  ['literal', [-15, -160]],
                  ['literal', [4, 12.7]]
                ],
                size: [
                  'case',
                  ['==', ['get', 'featureType'], 'markerIcon'],
                  0.09,
                  0.8
                ]
              }
            }}
            type="SymbolLayer"
          />
          {memoizedMarkerRender}
        </AzureMapDataSourceProvider>
      </AzureMap>
    </div>
  )
}

const JourneyTimeLocationWrapper = ({
  streams,
  selectedStreamsFrom,
  selectedStreamsTo,
  handleSelectionChanged
}: {
  streams: IStreamInfo[]
  selectedStreamsFrom: string[]
  selectedStreamsTo: string[]
  handleSelectionChanged
}) => {
  let streamLocations: StreamLocation[] = []

  streams
    .filter(
      (stream) =>
        stream.streamCoordinates &&
        stream.streamCoordinates.longitude &&
        stream.streamCoordinates.latitude
    )
    .forEach((stream) => {
      streamLocations.push(
        new StreamLocation(
          stream.streamName ? stream.streamName : '',
          stream.streamId,
          selectedStreamsFrom.includes(stream.streamId),
          selectedStreamsTo.includes(stream.streamId),
          stream.streamCoordinates!.longitude,
          stream.streamCoordinates!.latitude
        )
      )
    })

  return (
    <>
      <AzureMapsProvider>
        <StreamMap
          streamLocations={streamLocations}
          handleStreamLocationsChanged={handleSelectionChanged}
        />
      </AzureMapsProvider>
    </>
  )
}

export default JourneyTimeLocationWrapper
