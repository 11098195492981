import './OnvifModal.scss'

import { Button, Modal, Tabs } from 'antd'
import React, { MutableRefObject, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import BasicOnvifSettings from './BasicSettings'
import AdvancedOnvifSettings from './AdvancedSettings'
import { ICameraFrame } from '../../types/cameraFrame'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOnvifRecommendations,
  setOnvifSettings,
  setOnvifZoom
} from '../../redux/actions/onvif'
import { ReduxStore } from '../../redux/store/configureStore'
import { ICameraSettings } from '../../types/onvif'

const { TabPane } = Tabs

interface IOnvifModalProps {
  boxId: string
  streamId: string
  show: boolean
  hide: () => void
  frame?: ICameraFrame
  isLoading: boolean
  events: any
}

const OnvifModal: React.FC<IOnvifModalProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { settings, recommendations, fetched, applied } = useSelector(
    (state: ReduxStore) => ({
      settings: state.onvif.onvifSettings[props.streamId],
      recommendations: state.onvif.onvifRecommendations[props.streamId],
      fetched: state.onvif.onvifFetched,
      applied: state.onvif.onvifApplied
    })
  )
  let revertSettings: MutableRefObject<ICameraSettings | undefined> = useRef(
    undefined
  )

  useEffect(() => {
    revertSettings.current = undefined // update the revert settings with every modal init
    props.show && dispatch(getOnvifRecommendations(props.boxId, props.streamId))
  }, [dispatch, props.show, props.boxId, props.streamId])

  settings &&
    revertSettings.current === undefined &&
    (revertSettings.current = Object.assign({}, settings))

  const setSettings = async (settingsDelta) => {
    return dispatch(
      setOnvifSettings(props.boxId, props.streamId, settingsDelta)
    )
  }

  const setZoom = async (zoomCommand) => {
    return dispatch(setOnvifZoom(props.boxId, props.streamId, zoomCommand))
  }

  return (
    <Modal
      visible={props.show}
      onCancel={props.hide}
      width={1000}
      footer={[
        <Button key="back" onClick={props.hide}>
          {t('camera.frame.onvifSettings.close')}
        </Button>
      ]}
      key="scc--onvif-modal"
    >
      <Tabs tabPosition="top" size="large" type="card">
        <TabPane tab={t('camera.frame.onvifSettings.basic')} key="basic">
          <BasicOnvifSettings
            fetching={!fetched}
            applying={!applied}
            currentSettings={settings}
            recommendedSettings={recommendations}
            applyRecommendations={setSettings}
          />
        </TabPane>
        <TabPane tab={t('camera.frame.onvifSettings.advanced')} key="advanced">
          <AdvancedOnvifSettings
            frame={props.frame}
            frameLoading={props.isLoading}
            events={props.events}
            fetching={!fetched}
            applying={!applied}
            currentSettings={settings}
            applySettings={setSettings}
            revertSettings={revertSettings.current?.cameraImageSettings}
            applyZoom={setZoom}
          />
        </TabPane>
      </Tabs>
    </Modal>
  )
}

export default OnvifModal
