import React from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Switch } from 'antd'
import FormLabelWithTooltip from '../../../components/FormLabelWithToolTip'

const SpeedToggle = ({ measures, handleSpeedToggleChange }) => {
  const { t } = useTranslation()

  return (
    <>
      <p>{t(`solutions.scenes.overview.add.data.speed.includeSpeedAverage`)}</p>
      <FormLabelWithTooltip id="solutions.scenes.overview.add.data.speed" />
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Switch
          onClick={handleSpeedToggleChange}
          checked={
            !!measures.find((entry) => {
              return entry.name === 'CrossingEvents.averageSpeed'
            })
          }
          checkedChildren={t(`draw.toggle.on`)}
          unCheckedChildren={t(`draw.toggle.off`)}
        ></Switch>
      </Space>
    </>
  )
}

export default SpeedToggle
