import React, { useEffect, useState } from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import '../../theme/react-grid-overridden-placeholder.scss'
import { Alert, Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { CodeSnippet, CodeSnippetSkeleton } from 'carbon-components-react'
import { CodeSandboxOutlined } from '@ant-design/icons'
import {
  getTokenInformation,
  leaveHeaderEmptyOnMissingOrMainTenant
} from '../../services/apiTokenProvider'
import { Config } from '../../services/config'

const API_URL = Config.DATA_API

const getApiUrl = () => {
  return `${API_URL}/cubejs-api/v1`
}

const EasyAPICallModal = ({ vizState, itemId, showState }) => {
  const { query } = vizState
  const { t } = useTranslation(['datadiscovery'])
  const [modalState, setModalState] = showState
  const [token, setToken] = useState('')
  const [tenantHeader, setTenantHeader] = useState<string | undefined>()
  const [waiting, setWaiting] = useState(false)
  const [response, setResponse] = useState('')

  useEffect(() => {
    if (!token) {
      getTokenInformation().then((tokenInformation) => {
        setToken(
          tokenInformation && tokenInformation.idToken
            ? tokenInformation.idToken
            : ''
        )
        setTenantHeader(
          leaveHeaderEmptyOnMissingOrMainTenant(tokenInformation)
            ? undefined
            : tokenInformation.tenantInformations!.tenantId
        )
      })
    }
  }, [token])

  const handleCancel = () => {
    setModalState(-1)
  }

  const getHeaders = () =>
    tenantHeader
      ? {
          authorization: token,
          Tenant: tenantHeader
        }
      : {
          authorization: token
        }

  const apiCall = `${getApiUrl()}/load?query=${encodeURI(
    JSON.stringify(query)
  )}&queryType=multi`
  let curlCommand = `curl '${apiCall}' -H 'authorization: ${token}'`
  if (tenantHeader) {
    curlCommand = `${curlCommand} -H 'Tenant: ${tenantHeader}'`
  }

  const executeCurl = () => {
    setResponse('')
    setWaiting(true)
    axios
      .get(apiCall, {
        headers: getHeaders()
      })
      .then((response) => {
        setWaiting(false)
        setResponse(JSON.stringify(response.data, null, 2))
      })
      .catch((error) => {
        setWaiting(false)
        setResponse(JSON.stringify(error.data, null, 2))
      })
  }

  return (
    <>
      <Modal
        title={t('widgets.easy-apicall.title')}
        visible={itemId === modalState}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('widgets.easy-apicall.back')}
          </Button>
        ]}
      >
        <p>{t('widgets.easy-apicall.intro')}</p>
        <br />
        <h4>{t('widgets.easy-apicall.curlheadline')}</h4>
        <p>{t('widgets.easy-apicall.curltext')}</p>
        <CodeSnippet
          type="multi"
          feedback={t('widgets.easy-apicall.clipboard')}
          wrapText={true}
          light={true}
          maxCollapsedNumberOfRows={4}
        >
          {curlCommand}
        </CodeSnippet>
        <br />
        <Button icon={<CodeSandboxOutlined />} onClick={executeCurl}>
          {t('widgets.easy-apicall.tryitoutbutton')}
        </Button>
        <br />
        {(waiting || response) && [
          <br key="br" />,
          <h5 key="h5">{t('widgets.easy-apicall.responseheadline')}</h5>
        ]}
        {waiting && (
          <CodeSnippetSkeleton type="single" style={{ marginBottom: 8 }} />
        )}
        {response && (
          <CodeSnippet
            type="multi"
            feedback={t('widgets.easy-apicall.clipboard')}
            wrapText={true}
            hideCopyButton
            light={false}
          >
            {response}
          </CodeSnippet>
        )}
        <br />
        <Alert
          message={t('widgets.easy-apicall.accesswarning')}
          type="warning"
        />
        <br />
        <br />
        <h4>{t('widgets.easy-apicall.accessheadline')}</h4>
        <p>{t('widgets.easy-apicall.accesstext')}</p>
        {!Config.EXCLUDE_SUPPORT && (
          <a
            href={t('widgets.easy-apicall.accesslink')}
            target="_blank"
            rel="noreferrer"
          >
            {t('widgets.easy-apicall.accesslinktext')}
          </a>
        )}
        <br />
        <br />
        <h4>{t('widgets.easy-apicall.docsheadline')}</h4>
        <p>{t('widgets.easy-apicall.docstext')}</p>
        <a
          href={t('widgets.easy-apicall.docslink')}
          target="_blank"
          rel="noreferrer"
        >
          {t('widgets.easy-apicall.docslinktext')}
        </a>
      </Modal>
    </>
  )
}

export default EasyAPICallModal
