import React, { useState } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Space,
  List,
  Card,
  Steps,
  Checkbox,
  Switch
} from 'antd'
import { useTranslation } from 'react-i18next'
import { AlertType, IMonitoringAlert } from '../../types/monitoringAlert'
import AlertDeviceList from './AlertDeviceList'
import AlertRecipientsList from './AlertRecipientsList'

type AlertModalProps = {
  show: boolean
  close: () => void
  saveAlert: (IMonitoringAlert) => void
  alert: IMonitoringAlert
}

const AlertTypePicker = ({
  value,
  valueChanged
}: {
  value: AlertType[]
  valueChanged: (value) => void
}) => {
  const { t } = useTranslation()

  const options = [
    {
      label: t('monitoring.alerts.modal.triggers.deviceOffline'),
      value: AlertType.DEVICE_OFFLINE
    },
    {
      label: t('monitoring.alerts.modal.triggers.deviceWarning'),
      value: AlertType.DEVICE_WARNING
    },
    {
      label: t('monitoring.alerts.modal.triggers.deviceNok'),
      value: AlertType.DEVICE_NOT_OPERATIONAL
    }
  ]

  return (
    <Checkbox.Group
      options={options}
      onChange={valueChanged}
      defaultValue={value}
    />
  )
}

const AlertModal = ({ show, close, alert, saveAlert }: AlertModalProps) => {
  const { t } = useTranslation()
  const [
    editableMonitoringAlert,
    setEditableMonitoringAlert
  ] = useState<IMonitoringAlert>({ ...alert })

  const handleCancel = () => {
    close()
  }

  const handleSave = () => {
    saveAlert(editableMonitoringAlert)
  }

  const handleTriggersChanged = (event) => {
    setEditableMonitoringAlert((prevAlert) => ({
      ...prevAlert,
      triggers: event
    }))
  }

  const handleRuleNameChange = (event) => {
    setEditableMonitoringAlert((prevAlert) => ({
      ...prevAlert,
      name: event.target.value
    }))
  }

  const handleRuleDescriptionChanged = (event) => {
    setEditableMonitoringAlert((prevAlert) => ({
      ...prevAlert,
      description: event.target.value
    }))
  }

  const handleDeviceSelectionChanged = (selectedRowKeys) => {
    setEditableMonitoringAlert((prevAlert) => ({
      ...prevAlert,
      boxes: selectedRowKeys
    }))
  }

  const removeRecipient = (position) => {
    if (editableMonitoringAlert.recipients) {
      let recipients = [...editableMonitoringAlert.recipients]
      recipients.splice(position, 1)
      setEditableMonitoringAlert((prevAlert) => ({
        ...prevAlert,
        recipients: recipients
      }))
    }
  }

  const addRecipient = () => {
    let recipients
    if (editableMonitoringAlert.recipients) {
      recipients = [...editableMonitoringAlert.recipients]
    } else {
      recipients = []
    }
    recipients.push({ email: '' })
    setEditableMonitoringAlert((prevAlert) => ({
      ...prevAlert,
      recipients: recipients
    }))
  }

  const onResolveChange = (checked) => {
    setEditableMonitoringAlert((prevAlert) => ({
      ...prevAlert,
      autoResolve: checked
    }))
  }

  const handleRecipientChanged = (position, email) => {
    if (editableMonitoringAlert.recipients) {
      let recipients = [...editableMonitoringAlert.recipients]
      recipients[position].email = email
      setEditableMonitoringAlert((prevAlert) => ({
        ...prevAlert,
        recipients: recipients
      }))
    }
  }

  const conditionDescription = [
    {
      title: t('monitoring.alerts.modal.triggers.deviceOffline'),
      description: t(
        'monitoring.alerts.modal.triggers.deviceOfflineDescription'
      )
    },
    {
      title: t('monitoring.alerts.modal.triggers.deviceNok'),
      description: t('monitoring.alerts.modal.triggers.deviceNokDescription')
    },
    {
      title: t('monitoring.alerts.modal.triggers.deviceWarning'),
      description: t(
        'monitoring.alerts.modal.triggers.deviceWarningDescription'
      )
    }
  ]

  const { Step } = Steps
  const steps = [
    {
      title: t('monitoring.alerts.modal.steps.condition'),
      content: (
        <Card title={t('monitoring.alerts.modal.conditionTitle')} key="1">
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <p>{t('monitoring.alerts.modal.conditionText')}</p>
            <List
              itemLayout="horizontal"
              dataSource={conditionDescription}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.title}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
            <br />
            <AlertTypePicker
              value={editableMonitoringAlert.triggers}
              valueChanged={handleTriggersChanged}
            />
            <br />

            <p>{t('monitoring.alerts.modal.resolutionNotifcation')}</p>
            <Switch
              checked={editableMonitoringAlert.autoResolve}
              onChange={onResolveChange}
            />
          </Space>
        </Card>
      )
    },
    {
      title: t('monitoring.alerts.modal.steps.devices'),
      content: (
        <Card title={t('monitoring.alerts.modal.deviceTitle')} key="2">
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <p>{t('monitoring.alerts.modal.deviceText')}</p>
            <AlertDeviceList
              alert={editableMonitoringAlert}
              selectedDevicesChanged={handleDeviceSelectionChanged}
            />
          </Space>
        </Card>
      )
    },
    {
      title: t('monitoring.alerts.modal.steps.recipient'),
      content: (
        <Card title={t('monitoring.alerts.modal.notificationTitle')} key="3">
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <p>{t('monitoring.alerts.modal.notificationText')}</p>
            <AlertRecipientsList
              recipients={editableMonitoringAlert.recipients}
              addRecipient={addRecipient}
              recipientChanged={handleRecipientChanged}
              removeRecipient={removeRecipient}
            />
          </Space>
        </Card>
      )
    }
  ]

  const [current, setCurrent] = useState(0)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const isValidAlert = () => {
    if (
      !editableMonitoringAlert.boxes ||
      editableMonitoringAlert.boxes.length < 1
    ) {
      return false
    }

    if (
      !editableMonitoringAlert.recipients ||
      editableMonitoringAlert.recipients.length < 1
    ) {
      return false
    }

    if (
      !editableMonitoringAlert.triggers ||
      editableMonitoringAlert.triggers.length < 1
    ) {
      return false
    }

    return !(
      !editableMonitoringAlert.name || editableMonitoringAlert.name.length < 1
    )
  }
  return (
    <Modal
      title={
        <Col span={12}>
          <Form.Item>
            <Input
              size="large"
              maxLength={254}
              placeholder={t('monitoring.alerts.modal.namePlaceholder')}
              value={editableMonitoringAlert.name}
              onChange={handleRuleNameChange}
            />
          </Form.Item>
          <Form.Item>
            <Input
              size="large"
              maxLength={254}
              placeholder={t('monitoring.alerts.modal.descriptionPlaceholder')}
              value={editableMonitoringAlert.description}
              onChange={handleRuleDescriptionChanged}
            />
          </Form.Item>
        </Col>
      }
      visible={show}
      width={1000}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('monitoring.alerts.modal.cancel')}
        </Button>,
        <Button
          type="primary"
          key="save"
          disabled={!isValidAlert()}
          onClick={handleSave}
        >
          {t('monitoring.alerts.modal.save')}
        </Button>
      ]}
    >
      <p>{t('monitoring.alerts.modal.createrule')}</p>

      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </Space>
      <div className="steps-content" style={{ marginTop: '20px' }}>
        {steps[current].content}
      </div>
      <div className="steps-action">
        {current > 0 && (
          <Button
            style={{
              margin: '8px'
            }}
            onClick={() => prev()}
          >
            {t('monitoring.alerts.modal.previous')}
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button
            style={{
              margin: '8px'
            }}
            type="primary"
            onClick={() => next()}
          >
            {t('monitoring.alerts.modal.next')}
          </Button>
        )}
      </div>
    </Modal>
  )
}
export default AlertModal
