import {
  BOXES_REBOOT_REQUEST,
  BOXES_REBOOT_REQUEST_FAILURE,
  BOXES_REBOOT_REQUEST_SUCCESS,
  BOXES_REQUEST,
  BOXES_REQUEST_FAILURE,
  BOXES_REQUEST_SUCCESS
} from './actionTypes'
import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'
import { IBox } from '../../types/box'

/**
 * =============
 * BOXES ACTIONS
 * =============
 */

/**
 * Fetches an array of boxes from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 */
const fetchBoxes = () => ({
  [CALL_API]: {
    types: [BOXES_REQUEST, BOXES_REQUEST_SUCCESS, BOXES_REQUEST_FAILURE],
    endpoint: 'boxes',
    schema: Schemas.BOX_ARRAY
  }
})

/**
 * Fetches an array of boxes from the API.
 * Relies on Redux Thunk middleware.
 */
export const loadBoxes = () => (dispatch, getState) => {
  return dispatch(fetchBoxes())
}

/**
 * Reboots the box data via API request.
 * @param box: Box The box data to store.
 */
const callRebootBox = (box: IBox) => ({
  [CALL_API]: {
    types: [
      BOXES_REBOOT_REQUEST,
      BOXES_REBOOT_REQUEST_SUCCESS,
      BOXES_REBOOT_REQUEST_FAILURE
    ],
    endpoint: `boxes/${box.id}/reboot`,
    schema: Schemas.BOX_ARRAY,
    method: HTTP_METHOD.PUT
  }
})

/**
 * Reboot box via API.
 */
export const rebootBox = (box: IBox) => (dispatch, getState) => {
  return dispatch(callRebootBox(box))
}
