import { debounce } from 'lodash'
import { useEffect } from 'react'

/**
 * Executes a function on window resize event.
 * @param containerEl: HTMLNode The element to watch for changes.
 * @param callback: Function The callback function to execute.
 */
export const useResize = (containerEl, callback) => {
  return useEffect(() => {
    const handleResize = debounce(() => {
      if (!containerEl || !containerEl.current) {
        return
      }

      callback()
    }, 150)

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [callback, containerEl])
}
