import {
  AbstractCoordinateBasedEventTrigger,
  EEventTriggerType
} from './eventTrigger'
import { uuid } from 'lodash-uuid'

export class VirtualDoorEventTrigger extends AbstractCoordinateBasedEventTrigger {
  constructor(props) {
    super(props)
    this.objectType = EEventTriggerType.virtualDoor
  }

  public static default(): VirtualDoorEventTrigger {
    return new VirtualDoorEventTrigger({
      localId: uuid(),
      coordinates: [
        { x: 0.4, y: 0.3 },
        { x: 0.6, y: 0.3 },
        { x: 0.6, y: 0.6 },
        { x: 0.4, y: 0.6 }
      ]
    })
  }
}
