import React from 'react'
import { Button, Card, Col, Form, Input, Modal, Row, Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  IControlCenterRole,
  INewControlCenterUser
} from '../../types/controlCenterUser'
import rawRequest from '../../services/apiTokenProvider'
import { useQuery } from 'react-query'
import LoadingAnimation from '../LoadingAnimation'

const { Item } = Form

type NewUserModalProps = {
  show: boolean
  close: () => void
  createUser: (user: INewControlCenterUser) => void
}

const getRoles = (): Promise<IControlCenterRole[]> =>
  rawRequest({
    url: `/tenantAdministration/roles`
  }).then((response) => response.data)

const NewUserModal = ({ show, close, createUser }: NewUserModalProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm() // Create the form instance

  const { data, isLoading } = useQuery(['roles'], getRoles, {
    enabled: show
  })

  const handleCancelCreation = () => {
    close()
  }

  const handleCreateUser = () => {
    form
      .validateFields()
      .then((values) => {
        if (!data) {
          return
        }
        const selectedRole = data.find((role) => role.id === values.userRole)
        if (selectedRole) {
          const mappedValues: INewControlCenterUser = {
            ...values,
            userRole: selectedRole
          }
          createUser(mappedValues)
        }
      })
      .catch(() => {
        //ignore
      })
  }

  if (isLoading) {
    return (
      <Modal
        visible={show}
        width={1000}
        onCancel={handleCancelCreation}
        footer={[
          <Button key="back" onClick={handleCancelCreation}>
            {t('users.modal.cancel')}
          </Button>,
          <Button
            type="primary"
            disabled={true}
            key="save"
            onClick={handleCreateUser}
          >
            {t('users.modal.create')}
          </Button>
        ]}
      >
        <LoadingAnimation />
      </Modal>
    )
  }

  if (!show) {
    return null
  }

  if (data && data.length > 0) {
    return (
      <Modal
        visible={show}
        width={1000}
        onCancel={handleCancelCreation}
        footer={[
          <Button key="back" onClick={handleCancelCreation}>
            {t('users.modal.cancel')}
          </Button>,
          <Button type="primary" key="save" onClick={handleCreateUser}>
            {t('users.modal.create')}
          </Button>
        ]}
      >
        <Card title={t('users.modal.createUser')} style={{ marginTop: '20px' }}>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Row gutter={16}>
              <Col span={18}>
                <Form
                  layout="vertical"
                  form={form}
                  initialValues={{ userRole: data[0].id }}
                >
                  <Item
                    label={t('users.modal.firstname')}
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        message: t('users.modal.errors.firstname.required')
                      },
                      {
                        max: 64,
                        message: t('users.modal.errors.firstname.max')
                      }
                    ]}
                  >
                    <Input placeholder={t('users.modal.firstname')} />
                  </Item>
                  <Item
                    label={t('users.modal.lastname')}
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: t('users.modal.errors.lastname.required')
                      },
                      {
                        max: 64,
                        message: t('users.modal.errors.lastname.max')
                      }
                    ]}
                  >
                    <Input placeholder={t('users.modal.lastname')} />
                  </Item>
                  <Item
                    label={t('users.modal.email')}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t('users.modal.errors.email.required')
                      },
                      {
                        type: 'email',
                        message: t('users.modal.errors.email.valid')
                      },
                      {
                        max: 100,
                        message: t('users.modal.errors.email.max')
                      }
                    ]}
                  >
                    <Input placeholder={t('users.modal.email')} />
                  </Item>
                  <Item
                    label={t('users.modal.role')}
                    name="userRole"
                    rules={[
                      { required: true, message: 'Please select a role' }
                    ]}
                  >
                    <Select style={{ width: 200 }} placeholder="Select a role">
                      {data.map((role) => (
                        <Select.Option key={role.id} value={role.id}>
                          {role.roleName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </Form>
              </Col>
            </Row>
          </Space>
        </Card>
      </Modal>
    )
  }
  return null //ts
}
export default NewUserModal
