import React from 'react'
import RGL, { WidthProvider } from 'react-grid-layout'
import { useMutation } from '@apollo/react-hooks'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import '../../theme/react-grid-overridden-placeholder.scss'

import {
  GET_DASHBOARD_ITEMS,
  GET_DASHBOARD_ITEMS_SCENE
} from '../graphql/queries'
import { UPDATE_DASHBOARD_ITEM } from '../graphql/mutations'
import { userIsAtLeast, UserRole } from '../../types/user_role'

const ReactGridLayout = WidthProvider(RGL)

const Dashboard = ({ children, dashboardItems, modalActive, sceneId }) => {
  const [updateDashboardItem] = useMutation(UPDATE_DASHBOARD_ITEM, {
    refetchQueries: [
      sceneId
        ? {
            query: GET_DASHBOARD_ITEMS_SCENE,
            variables: {
              sceneId: sceneId
            }
          }
        : {
            query: GET_DASHBOARD_ITEMS
          }
    ]
  })

  const onLayoutChange = (newLayout) => {
    newLayout.forEach((l) => {
      const item = dashboardItems.find((i) => i.id.toString() === l.i)
      const current = JSON.parse(item.layout)
      const toUpdate = JSON.stringify({
        x: l.x,
        y: l.y,
        w: l.w,
        h: l.h
      })

      if (
        item.layout &&
        current.x === l.x &&
        current.y === l.y &&
        current.w === l.w &&
        current.h === l.h
      ) {
        return
      }

      const variables = {
        id: item.id,
        input: {
          layout: toUpdate
        }
      }
      item.sceneId && (variables['input']['sceneId'] = item.sceneId)
      item.widgetType && (variables['input']['widgetType'] = item.widgetType)
      if (item && toUpdate !== item.layout) {
        updateDashboardItem({
          variables: variables
        })
      }
    })
  }

  return (
    <ReactGridLayout
      cols={12}
      rowHeight={50}
      onLayoutChange={onLayoutChange}
      isDraggable={!modalActive && userIsAtLeast(UserRole.User)}
      draggableHandle="#scc--solutions--item-drag-button"
      isResizable={userIsAtLeast(UserRole.User)}
    >
      {children}
    </ReactGridLayout>
  )
}

export default Dashboard
