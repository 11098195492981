import { Loading } from 'carbon-components-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CanvasLoading: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="scc--camera-feed--wrapper" style={{ height: '100%' }}>
      <div className="scc--camera-feed--loading scc--camera-feed--centered">
        <Loading active small={false} withOverlay={false} />
        <h3 className="scc--camera-feed--header">
          {t('camera.loading.header')}
        </h3>
      </div>
    </div>
  )
}

export default CanvasLoading
