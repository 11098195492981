import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'
import {
  MQTT_CONFIG_REQUEST,
  MQTT_CONFIG_REQUEST_FAILURE,
  MQTT_CONFIG_REQUEST_SUCCESS,
  SAVE_MQTT_CONFIG_REQUEST,
  SAVE_MQTT_CONFIG_REQUEST_FAILURE,
  SAVE_MQTT_CONFIG_REQUEST_SUCCESS
} from './actionTypes'

import { IMqttConfiguration } from '../../types/eventdataOutputConfiguration'

/**
 * ==========================
 * MQTT CONFIGURATION ACTIONS
 * ==========================
 */

/**
 * Fetches a single configuration from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 * @param boxId
 * @param streamId
 */
const fetchMqttConfig = (boxId, streamId) => ({
  [CALL_API]: {
    types: [
      MQTT_CONFIG_REQUEST,
      MQTT_CONFIG_REQUEST_SUCCESS,
      MQTT_CONFIG_REQUEST_FAILURE
    ],
    endpoint: `boxes/${boxId}/${streamId}/configurations/mqtt`,
    schema: Schemas.CONFIG,
    id: streamId
  }
})

/**
 * Fetches a single configuration from the API unless it is cached.
 * Relies on Redux Thunk middleware.
 * @param boxId
 * @param streamId
 */
export const loadMqttConfig = (boxId, streamId) => (dispatch, getState) => {
  const { mqttConfigurations } = getState()

  if (!boxId || !streamId) {
    return null
  }

  if (mqttConfigurations.allIds.indexOf(streamId) > -1) {
    return Promise.resolve(mqttConfigurations.byIds[streamId])
  }

  return dispatch(fetchMqttConfig(boxId, streamId)).then((data) => {
    return Object.entries(
      data.response.entities.configurations[data.response.result]
    ).map(([k, v]) => v)
  })
}

/**
 * Save a configuration via the API.
 * Will use PUT if an id is provided, POST if not.
 * @param boxId
 * @param streamId
 * @param config: CONFIG The configuration data to be saved.
 */
export const saveMqttConfig = (
  boxId: string,
  streamId: string,
  config: IMqttConfiguration[]
) => {
  return {
    [CALL_API]: {
      types: [
        SAVE_MQTT_CONFIG_REQUEST,
        SAVE_MQTT_CONFIG_REQUEST_SUCCESS,
        SAVE_MQTT_CONFIG_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.PUT,
      payload: config,
      endpoint: `boxes/${boxId}/${streamId}/configurations/mqtt`,
      schema: Schemas.CONFIG,
      id: streamId
    }
  }
}
