import './Spacer.scss'

import React from 'react'

interface ISpacerProps {
  width?: number
  height?: number
}

const Spacer: React.FC<ISpacerProps> = ({ width, height }) => {
  let styles = {
    width: width || 'auto',
    height: height || 'auto'
  }

  return <span className="scc--spacer" style={styles}></span>
}

export default Spacer
