import React from 'react'
import { Col, Row, Space } from 'antd'
import FilterGroup from '../../../../datadiscovery/components/QueryBuilder/FilterGroup'
import { FilterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { TCubeDimension } from '@cubejs-client/core'

interface IFilterData {
  filters: any
  filteredDimensions: TCubeDimension[]
  updateFilters: Function
}

const FilterData: React.FC<IFilterData> = (props) => {
  const { t } = useTranslation()

  const availableFilteredDimensions = props.filteredDimensions.filter(
    (member) => {
      return !props.filters.find((item) => {
        return item.member === member.name
      })
    }
  )
  return availableFilteredDimensions.length ? (
    <Row>
      <Space direction="horizontal">
        <Col>
          <Space direction="horizontal">
            <FilterOutlined />
            {t(`solutions.scenes.overview.add.data.filter`)}
          </Space>
        </Col>
        <Col>
          <FilterGroup
            members={props.filters}
            availableMembers={availableFilteredDimensions}
            addMemberName={t(`solutions.scenes.overview.add.data.filteradd`)}
            updateMethods={props.updateFilters}
          />
        </Col>
      </Space>
    </Row>
  ) : (
    <></>
  )
}

export default FilterData
