import { ERuntimeState } from './runtimeState'
import { EErrorReason } from './errorReason'

export interface IBoxStatus {
  connectionState: EConnectionState
  runtimeState: ERuntimeState
  errorReason: EErrorReason
  cameraConnectionState: string | null
  mqttConnectionState: string | null
}

export enum EConnectionState {
  connected = 'CONNECTED',
  disconnected = 'DISCONNECTED',
  unknown = 'UNKNOWN'
}
