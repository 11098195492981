import './SceneMap.scss'

import React, { useContext, useEffect, useState } from 'react'
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  IAzureMapOptions,
  AzureMapPopup,
  IAzureMapsContextProps,
  AzureMapsContext
} from 'react-azure-maps'
import {
  AuthenticationType,
  data,
  MapMouseEvent,
  PopupOptions
} from 'azure-maps-control'
import { NavLink, BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IScene } from '../../../types/scene'
import { cameraIcon } from '../../../helpers/CameraSelector'
import { Config } from '../../../services/config'

const AZURE_MAPS_KEY = Config.AZURE_MAPS_KEY

interface ISceneMap {
  scenes: IScene[]
}

const SceneMap: React.FC<ISceneMap> = ({ scenes }) => {
  const { t } = useTranslation()
  const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(
    AzureMapsContext
  )
  const [resetView, setResetView] = useState<boolean>(true)
  const [popupOptions, setPopupOptions] = useState<PopupOptions>({})
  const [popupProperties, setPopupProperties] = useState({})

  const allCoordinates: data.Position[] = []
  scenes &&
    scenes.forEach((scene) => {
      scene.location &&
        scene.location.longitude &&
        scene.location.latitude &&
        allCoordinates.push(
          new data.Position(scene.location!.longitude, scene.location!.latitude)
        )
    })
  const centerBoundingBox = data.BoundingBox.fromPositions(allCoordinates)
  const option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: AZURE_MAPS_KEY
    },
    wheelZoomRate: 1 / 75,
    style: 'road_shaded_relief',
    showLogo: false,
    showFeedbackLink: false
  }

  useEffect(() => {
    if (resetView && isMapReady && mapRef && centerBoundingBox) {
      // zoom camera to bounds and reduce zoom by 1 to make everything visible
      mapRef.setCamera({
        bounds: centerBoundingBox,
        center: data.BoundingBox.getCenter(centerBoundingBox)
      })
      let camera = mapRef.getCamera()
      camera &&
        camera.zoom &&
        mapRef.setCamera({ zoom: Math.min(camera.zoom - 1, 16) })
      setResetView(false)
    }
  }, [mapRef, isMapReady, centerBoundingBox, resetView])

  function renderSceneMarker(scene: IScene): any {
    if (
      !(scene.location && scene.location.longitude && scene.location.latitude)
    ) {
      return
    }
    let coordinates = new data.Position(
      scene.location!.longitude,
      scene.location!.latitude
    )
    return (
      <AzureMapFeature
        key={scene.id + coordinates.toString()}
        id={scene.id}
        type="Point"
        coordinate={coordinates}
        properties={{
          id: scene.id,
          name: scene.name,
          description: scene.description
        }}
      />
    )
  }

  return (
    <AzureMap
      imageSprites={[
        {
          id: 'swarm-camera',
          icon: cameraIcon()
        }
      ]}
      options={option}
    >
      <AzureMapDataSourceProvider
        id={'MultiplePoint AzureMapDataSourceProvider'}
      >
        <AzureMapLayerProvider
          id="stream-map-data"
          options={{
            iconOptions: {
              image: 'swarm-camera',
              allowOverlap: true,
              ignorePlacement: true,
              offset: [4, 12.7],
              size: 0.8
            }
          }}
          events={{
            mousemove: (e: MapMouseEvent) => {
              if (e.shapes && e.shapes.length > 0) {
                const prop: any = e.shapes[0]
                setPopupOptions({
                  ...popupOptions,
                  position: new data.Position(
                    prop.data.geometry.coordinates[0],
                    prop.data.geometry.coordinates[1]
                  ),
                  pixelOffset: [20, 0]
                })
                if (prop.data.properties)
                  setPopupProperties(prop.data.properties)
              }
            }
          }}
          type="SymbolLayer"
        />
        {scenes &&
          scenes.map((scene) => {
            return renderSceneMarker(scene)
          })}
      </AzureMapDataSourceProvider>
      <AzureMapPopup
        isVisible={!!popupProperties['id']}
        options={popupOptions}
        popupContent={
          popupProperties['id'] && (
            <>
              <span className="scc--scenedetail--map--marker-title">
                {popupProperties['name']}
              </span>
              {popupProperties['description'] &&
                popupProperties['description'].length > 0 && (
                  <>
                    <br />
                    <span>{popupProperties['description']}</span>
                  </>
                )}
              <br />
              <BrowserRouter>
                <NavLink to={`/solutions/${popupProperties['id']}`}>
                  {t('solutions.scenes.goto')}
                </NavLink>
              </BrowserRouter>
            </>
          )
        }
      />
    </AzureMap>
  )
}

export default SceneMap
