import './OnvifModal.scss'

import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Col,
  Row,
  Select,
  Slider,
  Space,
  Spin,
  Switch,
  Table
} from 'antd'
import { Fraction } from 'fractional'
import { ECameraFrameMode, ICameraFrame } from '../../types/cameraFrame'
import { Layer, Stage, Image } from 'react-konva'
import { useResize } from '../../helpers/hooks/useResize'
import { calculateDimensions } from '../DrawCanvas/canvasHelpers'
import refresh from '../../theme/icons/refresh.svg'
import CanvasLoading from '../DrawCanvas/CanvasLoading'
import {
  EDayNightMode,
  EXPOSURE_TIME_OPTIONS,
  EZoomCommand,
  ICameraExposureTime,
  ICameraImageSettings,
  ICameraSettings
} from '../../types/onvif'
import { objIsEmpty, getCameraSettingsDelta } from './OnvifHelper'
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'

const MICROSECOND = 1 / 1000000

const DEFAULT_CANVAS_WIDTH = 561
const DEFAULT_CANVAS_HEIGHT = 315

let defaultCanvasDimensions: any = {
  height: DEFAULT_CANVAS_HEIGHT,
  width: DEFAULT_CANVAS_WIDTH
}

interface IAdvancedOnvifSettingsProps {
  frame?: ICameraFrame
  frameLoading: boolean
  events: any
  fetching: boolean
  applying: boolean
  currentSettings: ICameraSettings
  applySettings: any
  revertSettings: ICameraImageSettings | undefined
  applyZoom: any
}

const AdvancedOnvifSettings: React.FC<IAdvancedOnvifSettingsProps> = (
  props
) => {
  const { t } = useTranslation()
  const [dimensions, setDimensions] = useState(defaultCanvasDimensions)
  const [newSettings, setNewSettings] = useState<
    ICameraImageSettings | undefined
  >(undefined)
  const containerEl = useRef(null)

  useEffect(() => {
    if (!props.fetching && props.currentSettings) {
      setNewSettings(props.currentSettings.cameraImageSettings)
    }
  }, [props.currentSettings, props.fetching])

  let settingsDelta
  let revertDelta
  let settingsTableContent
  if (!props.fetching && newSettings) {
    settingsTableContent = prepareSettings(
      props.currentSettings.cameraImageSettings,
      newSettings,
      setNewSettings,
      t
    )
    settingsDelta = getCameraSettingsDelta(
      props.currentSettings.cameraImageSettings,
      newSettings
    )
    revertDelta = getCameraSettingsDelta(
      props.currentSettings.cameraImageSettings,
      props.revertSettings
    )
  }

  const columns = [
    {
      title: t('camera.frame.onvifSettings.basicColumns.setting'),
      dataIndex: 'setting',
      key: 'setting',
      width: 150
    },
    {
      title: '',
      dataIndex: 'value',
      key: 'value'
    }
  ]

  let image
  if (props.frame) {
    image = new window.Image()
    image.src = `data:image/png;base64,${props.frame.encodedImage}`
  }

  let timeout: NodeJS.Timeout

  // Resize the canvas when the window resizes
  useResize(containerEl, () => {
    clearTimeout(timeout)

    // Use a little timeout to wait for transitions to finish
    timeout = setTimeout(() => {
      const calculatedDimensions = calculateDimensions(
        containerEl.current,
        props.frame,
        props.frameLoading,
        {
          width: DEFAULT_CANVAS_WIDTH
        }
      )

      if (
        calculatedDimensions.width !== dimensions.width ||
        calculatedDimensions.height !== dimensions.height
      ) {
        defaultCanvasDimensions = calculatedDimensions
        setDimensions(calculatedDimensions)
      }
    }, 300)
  })

  const disabledRows = (record, index) => {
    return record.rawValue === undefined ? 'scc--tablerow-disabled' : ''
  }

  function getZoomIcon(item: EZoomCommand) {
    switch (item) {
      case EZoomCommand.INCREASE:
        return <ZoomInOutlined style={{ fontSize: 10 }} />
      case EZoomCommand.DECREASE:
        return <ZoomOutOutlined style={{ fontSize: 10 }} />
      case EZoomCommand.INCREASE_STRONG:
        return <ZoomInOutlined style={{ fontSize: 16 }} />
      case EZoomCommand.DECREASE_STRONG:
        return <ZoomOutOutlined style={{ fontSize: 16 }} />
    }
    return <></>
  }

  return (
    <div className="scc--onvif-advanced">
      <Space size="large" direction="vertical">
        <p>{t('camera.frame.onvifSettings.advancedExplanation')}</p>

        <Row gutter={10}>
          <Col span={10}>
            <Table
              dataSource={settingsTableContent}
              columns={columns}
              pagination={false}
              loading={props.fetching}
              rowClassName={disabledRows}
            />
          </Col>
          <Col span={14} ref={containerEl}>
            <div
              className="scc--onvif-advanced-canvas"
              style={{ height: DEFAULT_CANVAS_HEIGHT }}
            >
              <Button
                className="scc--camera-feed--refresh-button"
                title={t('camera.frame.buttonRefresh')}
                onClick={(event) => {
                  event.preventDefault()
                  props.events.onRefreshButtonClick({
                    calibration: ECameraFrameMode.adminView
                  })
                }}
              >
                <img src={refresh} alt={t('camera.frame.buttonRefresh')} />
              </Button>
              {props.frameLoading && <CanvasLoading />}
              {!props.frameLoading && (
                <Stage height={dimensions.height} width={dimensions.width}>
                  {!props.frameLoading && image && (
                    <Layer>
                      <Image
                        image={image}
                        height={dimensions.height}
                        width={dimensions.width}
                      />
                    </Layer>
                  )}
                </Stage>
              )}
            </div>
            {!props.fetching &&
              props.currentSettings.cameraImageSettings.hasZoom && (
                <Space className="scc--onvif-advanced-zoom">
                  {t('camera.frame.onvifSettings.settings.zoom')}
                  {Object.values(EZoomCommand).map((item) => (
                    <Button
                      disabled={props.applying}
                      key={item}
                      onClick={(event) => {
                        event.preventDefault()
                        props.applyZoom({ command: item }).then(() => {
                          props.events.onRefreshButtonClick({
                            calibration: ECameraFrameMode.adminView
                          })
                        })
                      }}
                    >
                      {getZoomIcon(item)}
                    </Button>
                  ))}
                </Space>
              )}
            <Space className="scc--onvif-advanced-buttons">
              <Button
                disabled={objIsEmpty(revertDelta)}
                onClick={(event) => {
                  event.preventDefault()
                  setNewSettings(props.revertSettings)
                  props
                    .applySettings({
                      cameraImageSettings: revertDelta
                    })
                    .then(() => {
                      props.events.onRefreshButtonClick({
                        calibration: ECameraFrameMode.adminView
                      })
                    })
                }}
              >
                {t('camera.frame.onvifSettings.revertSettings')}
              </Button>
              {props.applying ? (
                <Button type="primary" disabled={true}>
                  <Spin size="small" />
                  {t('camera.frame.onvifSettings.applySettings')}
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={objIsEmpty(settingsDelta)}
                  onClick={(event) => {
                    event.preventDefault()
                    props
                      .applySettings({ cameraImageSettings: settingsDelta })
                      .then((data) => {
                        setNewSettings(data.cameraImageSettings)
                        props.events.onRefreshButtonClick({
                          calibration: ECameraFrameMode.adminView
                        })
                      })
                  }}
                >
                  {t('camera.frame.onvifSettings.applySettings')}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Space>
    </div>
  )
}

function getValidExposureOptions(cameraExposureTime: ICameraExposureTime) {
  let options = [...EXPOSURE_TIME_OPTIONS]
  if (!cameraExposureTime) {
    return options
  }
  options = options.filter((option) => {
    return (
      option.value > cameraExposureTime.minExposureTimeMicroSeconds &&
      option.value < cameraExposureTime.maxExposureTimeMicroSeconds
    )
  })

  function calculateCameraExposureLabel(value: number) {
    let fraction = new Fraction(value)
    fraction.denominator = Math.floor(fraction.denominator / fraction.numerator)
    fraction.numerator = 1
    return fraction.toString()
  }

  // if the currently selected options is not part of our defaults, add it
  if (
    !options.find((entry) => {
      return entry.value === cameraExposureTime.exposureTimeMicroSeconds
    })
  ) {
    options = [
      {
        value: cameraExposureTime.exposureTimeMicroSeconds,
        label: calculateCameraExposureLabel(
          cameraExposureTime.exposureTimeMicroSeconds * MICROSECOND
        )
      },
      ...options
    ]
  }
  return options
}

function prepareSettings(
  currentSettings: ICameraImageSettings,
  newSettings: ICameraImageSettings,
  setNewSettings: any,
  t: any
) {
  return [
    {
      setting: t('camera.frame.onvifSettings.settings.brightness'),
      rawValue: newSettings.brightness,
      value: (
        <Slider
          value={newSettings.brightness}
          min={0}
          max={10}
          disabled={newSettings.brightness === undefined}
          onChange={(value) => {
            setNewSettings({ ...newSettings, brightness: value })
          }}
        />
      ),
      key: 'brightness'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.contrast'),
      rawValue: newSettings.contrast,
      value: (
        <Slider
          value={newSettings.contrast}
          min={0}
          max={10}
          disabled={newSettings.contrast === undefined}
          onChange={(value) => {
            setNewSettings({ ...newSettings, contrast: value })
          }}
        />
      ),
      key: 'contrast'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.saturation'),
      rawValue: newSettings.saturation,
      value: (
        <Slider
          value={newSettings.saturation}
          min={0}
          max={10}
          disabled={newSettings.saturation === undefined}
          onChange={(value) => {
            setNewSettings({ ...newSettings, saturation: value })
          }}
        />
      ),
      key: 'saturation'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.sharpness'),
      rawValue: newSettings.sharpness,
      value: (
        <Slider
          value={newSettings.sharpness}
          min={0}
          max={10}
          disabled={newSettings.sharpness === undefined}
          onChange={(value) => {
            setNewSettings({ ...newSettings, sharpness: value })
          }}
        />
      ),
      key: 'sharpness'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.shutter'),
      rawValue:
        newSettings.cameraExposureTime &&
        newSettings.cameraExposureTime.exposureTimeMicroSeconds,
      value: (
        <Select
          value={
            newSettings.cameraExposureTime &&
            newSettings.cameraExposureTime.exposureTimeMicroSeconds
          }
          disabled={!newSettings.cameraExposureTime}
          onChange={(value) => {
            setNewSettings({
              ...newSettings,
              cameraExposureTime: {
                ...newSettings.cameraExposureTime,
                exposureTimeMicroSeconds: value
              }
            })
          }}
          options={getValidExposureOptions(newSettings.cameraExposureTime)}
        />
      ),
      key: 'shutter'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.dayNightMode.label'),
      rawValue: newSettings.dayNightMode,
      value: (
        <Select
          value={newSettings.dayNightMode}
          disabled={!newSettings.dayNightMode}
          onChange={(value) => {
            setNewSettings({
              ...newSettings,
              dayNightMode: value
            })
          }}
          options={Object.values(EDayNightMode).map((item) => {
            return {
              label: t(
                `camera.frame.onvifSettings.settings.dayNightMode.${item}`
              ),
              value: item
            }
          })}
        />
      ),
      key: 'dayNightMode'
    },
    {
      setting: t('camera.frame.onvifSettings.settings.wdr'),
      rawValue: newSettings.wdrSettings,
      value: (
        <Space className="scc--onvif-advanced-wdr">
          <Switch
            checked={
              newSettings.wdrSettings && newSettings.wdrSettings.wdrEnabled
            }
            disabled={
              !newSettings.wdrSettings ||
              newSettings.wdrSettings.wdrEnabled === null ||
              newSettings.wdrSettings.wdrEnabled === undefined
            }
            onClick={() => {
              setNewSettings({
                ...newSettings,
                wdrSettings: {
                  ...newSettings.wdrSettings,
                  wdrEnabled: !newSettings.wdrSettings.wdrEnabled
                }
              })
            }}
            checkedChildren={t(`draw.toggle.on`)}
            unCheckedChildren={t(`draw.toggle.off`)}
          ></Switch>
          {currentSettings.wdrSettings &&
            currentSettings.wdrSettings.wdrEnabled &&
            newSettings.wdrSettings.wdrEnabled && (
              <Slider
                value={
                  newSettings.wdrSettings
                    ? newSettings.wdrSettings.wdrValue
                    : undefined
                }
                min={0}
                max={10}
                disabled={
                  !newSettings.wdrSettings ||
                  newSettings.wdrSettings.wdrValue === null ||
                  !newSettings.wdrSettings.wdrEnabled
                }
                onChange={(value) => {
                  setNewSettings({
                    ...newSettings,
                    wdrSettings: {
                      ...newSettings.wdrSettings,
                      wdrValue: value
                    }
                  })
                }}
              />
            )}
        </Space>
      ),
      key: 'hlc'
    }
  ]
}

export default AdvancedOnvifSettings
