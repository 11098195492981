import {
  BrowserCacheLocation,
  PublicClientApplication
} from '@azure/msal-browser'

import { Config } from '../services/config'

const config = {
  auth: {
    authority: Config.AUTHORITY,
    clientId: Config.AAD_APP_CLIENT_ID,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: false,
    knownAuthorities: [Config.AUTHORITY]
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false
  }
}

export const AuthProvider = new PublicClientApplication(config)
