import {
  AbstractCoordinateBasedEventTrigger,
  EEventTriggerType,
  IBaseObject,
  ICoordinateBasedEventTrigger,
  ICoordinates
} from './eventTrigger'
import { uuid } from 'lodash-uuid'
import { SpeedEstimationTrigger } from './speedEstimation'

export class CrossingLineEventTrigger extends AbstractCoordinateBasedEventTrigger {
  speedEstimation?: SpeedEstimationTrigger
  multipleCrossings: boolean
  inLabel?: string
  outLabel?: string

  constructor(props) {
    super(props)
    this.objectType = EEventTriggerType.crossingLine
    this.multipleCrossings = props.multipleCrossings
    if (props.speedEstimation) {
      this.speedEstimation = new SpeedEstimationTrigger(props.speedEstimation)
    }

    if (props.inLabel) {
      this.inLabel = props.inLabel
    }

    if (props.outLabel) {
      this.outLabel = props.outLabel
    }
  }

  public static default(): CrossingLineEventTrigger {
    return new CrossingLineEventTrigger({
      localId: uuid(),
      coordinates: [
        { x: 0.4, y: 0.25 },
        { x: 0.4, y: 0.75 }
      ]
    })
  }

  convertRelativePointToPixels(baseObject: IBaseObject): ICoordinates[] {
    if (this.speedEstimation && this.speedEstimation.coordinates) {
      return this.coordinates
        .concat(this.speedEstimation.coordinates)
        .map((relativePoint) => ({
          x: baseObject.width * relativePoint.x,
          y: baseObject.height * relativePoint.y
        }))
    } else {
      return this.coordinates.map((relativePoint) => ({
        x: baseObject.width * relativePoint.x,
        y: baseObject.height * relativePoint.y
      }))
    }
  }

  getRequestObject() {
    let speedObject, object: ICoordinateBasedEventTrigger
    if (this.speedEstimation) {
      speedObject = Object.assign(
        {},
        this.speedEstimation,
        this.getSpeedEstimationForApi()
      )
      object = Object.assign({}, this, this.getCoordinatesForApi(), {
        speedEstimation: speedObject
      })
    } else {
      object = Object.assign({}, this, this.getCoordinatesForApi())
    }

    delete object.hasChanged

    // Make sure object has an id before saving it
    if (!object.id) {
      object.id = object.localId
    }

    return object
  }

  getCoordinatesForApi() {
    const response = { coordinates: {} }
    this.coordinates.forEach((coordinate, index) => {
      const character = String.fromCharCode(index + 65) // Use character code to map letters
      response.coordinates[`point${character}`] = {
        x: coordinate.x,
        y: coordinate.y
      }
    })

    return response
  }

  getSpeedEstimationForApi() {
    if (this.speedEstimation) {
      const response = { coordinates: {} }
      this.speedEstimation.coordinates.forEach((coordinate, index) => {
        const character = String.fromCharCode(index + 65) // Use character code to map letters
        response.coordinates[`point${character}`] = {
          x: coordinate.x,
          y: coordinate.y
        }
      })
      return response
    }
    return
  }

  /**
   * Determines the direction based on the 2 points.
   * @returns 'IN' for clockwise, 'OUT' for counter clockwise.
   */
  get direction(): string {
    return this.coordinates[0].y < this.coordinates[1].y ? 'in' : 'out'
  }
}
