import { ICoordinateBasedEventTrigger } from '../types/eventTrigger'
import { NavLink, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import {
  deleteStreamEventTrigger,
  resetBoxEventTriggersLocally,
  setHighlightedStreamEventTrigger,
  setSelectedStreamEventTrigger
} from '../redux/actions/eventTriggers'
import { loadBoxDetails, resetBoxDetails } from '../redux/actions/boxDetails'
import { loadBoxStreams } from '../redux/actions/streams'
import BoxDetailsHeader from '../components/BoxDetailsHeader'
import { CloseOutline24 } from '@carbon/icons-react'
import { IBox } from '../types/box'
import { IBoxDetails } from '../types/boxDetails'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { IStream } from '../types/stream'
import { EDeviceView } from '../types/EDeviceView'
import cubejs, { CubejsApi, CubeJSApiOptions } from '@cubejs-client/core'
import {
  getTokenInformation,
  leaveHeaderEmptyOnMissingOrMainTenant
} from '../services/apiTokenProvider'
import { getApiUrl } from '../solutions/components/SceneOverview'
import './BoxDetailPage.scss'
import BoxDetailsBody from '../components/BoxDetailsBody'

interface IBoxDetailPageProps {
  children?
  match: any
  history: any
  box: IBox
  eventTriggers: ICoordinateBasedEventTrigger[]
  boxDetails: IBoxDetails
  streams: IStream[]
  updateBoxEventTrigger: any
  addBoxEventTrigger: (EEventTriggerType) => void
  deleteStreamEventTrigger: Function
  setSelectedBoxEventTrigger: (IEventTrigger) => void
  setHighlightedBoxEventTrigger: (IEventTrigger) => void
  highlightedEventTrigger: string
  selectedEventTrigger: string
  resetBoxDetails: Function
  loadBoxDetails: (string) => void
  loadBoxStreams: (string) => void
  resetBoxEventTriggers: Function
  t: any
  i18n: any
  tReady: any
}

interface IBoxDetailPageState {
  isDeleteConfirmationDialogVisible: boolean
  eventTriggerToDelete: ICoordinateBasedEventTrigger | null
  deviceView: EDeviceView
  cubejsApi: CubejsApi | null
}

class BoxDetailPage extends Component<
  IBoxDetailPageProps,
  IBoxDetailPageState
> {
  constructor(props) {
    super(props)

    this.state = {
      isDeleteConfirmationDialogVisible: false,
      eventTriggerToDelete: null,
      deviceView: EDeviceView.configuration,
      cubejsApi: null
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    // Prevent loading same data twice
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadData()
    }
  }

  loadData() {
    const { id } = this.props.match.params

    this.props.resetBoxDetails()
    this.props.resetBoxEventTriggers()

    if (id) {
      this.props.loadBoxDetails(id)
      this.props.loadBoxStreams(id)
    }
    if (!this.state.cubejsApi) {
      getTokenInformation().then((tokenInformation) => {
        const multiTenantHeaders = leaveHeaderEmptyOnMissingOrMainTenant(
          tokenInformation
        )
          ? undefined
          : {
              Tenant: tokenInformation.tenantInformations!.tenantId
            }
        const options: CubeJSApiOptions = !multiTenantHeaders
          ? {
              apiUrl: getApiUrl()
            }
          : {
              apiUrl: getApiUrl(),
              headers: multiTenantHeaders
            }
        const cubejsApi = cubejs(async () => {
          return (await getTokenInformation()).idToken
        }, options)
        this.setState({ cubejsApi: cubejsApi })
      })
    }
  }

  render() {
    const containerClassNames = this.props.box
      ? 'scc--boxdetail--container shown'
      : 'scc--boxdetail--container'

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    })

    const changeTabPane = (key) => {
      this.setState({
        deviceView: EDeviceView[key]
      })
    }

    const { box, streams } = this.props

    streams.sort((a, b) => {
      if (a.name === undefined) {
        return 1
      }
      if (b.name === undefined) {
        return -1
      }
      return collator.compare(a.name, b.name)
    })

    return (
      <div className={containerClassNames}>
        <div onClick={() => changeTabPane(EDeviceView.configuration)}>
          <NavLink to="/boxes" className="scc--close-button">
            <CloseOutline24 className="scc--fill-main" />
          </NavLink>
        </div>
        <div className="scc--deviceconfig-container">
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col-sm-16 bx--col-md-16 bx--col-lg-16 scc--device-config--triggers scc--flex--column">
                {this.props.box && (
                  <>
                    <BoxDetailsHeader
                      box={box}
                      tabPaneChanger={changeTabPane}
                    />
                    <BoxDetailsBody
                      box={box}
                      streams={streams}
                      cubejsApi={this.state.cubejsApi}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    box: state.boxes.byIds[ownProps.match.params.id],
    boxDetails: state.boxDetails,
    streams: state.streams.allIds.map((id) => state.streams.byIds[id]),
    selectedEventTrigger: state.eventTriggers.selectedEventTrigger,
    highlightedEventTrigger: state.eventTriggers.highlightedEventTrigger
  }
}

export default withRouter(
  connect(mapStateToProps, {
    loadBoxDetails,
    loadBoxStreams,
    deleteStreamEventTrigger,
    resetBoxDetails,
    resetBoxEventTriggers: resetBoxEventTriggersLocally,
    setSelectedBoxEventTrigger: setSelectedStreamEventTrigger,
    setHighlightedBoxEventTrigger: setHighlightedStreamEventTrigger
  })(withTranslation()(BoxDetailPage))
)
