import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { getAppInsights } from './telemetry'
const isDevelopment = process.env.NODE_ENV === 'development'

const appInsights = getAppInsights()

export const log = {
  error: function (...args: any) {
    // Development mode
    if (isDevelopment) {
      args.forEach((arg) => console.error(arg))
    } else {
      // Production mode
      if (appInsights) {
        appInsights.trackException({
          error: new Error(args.join(' ')),
          severityLevel: SeverityLevel.Error
        })
      }
    }
  },
  warn: function (...args: any) {
    // Development mode
    if (isDevelopment) {
      args.forEach((arg) => console.warn(arg))
    } else {
      // Production mode
      if (appInsights) {
        appInsights.trackException({
          error: new Error(args.join(' ')),
          severityLevel: SeverityLevel.Warning
        })
      }
    }
  }
}
