import {
  AbstractCoordinateBasedEventTrigger,
  IRelativePoint
} from './eventTrigger'

export interface ISpeedEstimationProps {
  distance: number
  enabled: boolean
  coordinates: IRelativePoint[]
}

export class SpeedEstimationTrigger implements ISpeedEstimationProps {
  constructor(props) {
    this.distance = props.distance ? props.distance : 10
    this.enabled = props.enabled
    if (!Array.isArray(props.coordinates)) {
      this.coordinates = AbstractCoordinateBasedEventTrigger.transformCoordinatesToArray(
        props.coordinates
      )
    } else {
      this.coordinates = props.coordinates
    }
  }

  public static default(): SpeedEstimationTrigger {
    return new SpeedEstimationTrigger({
      enabled: true,
      distance: 10,
      coordinates: [
        { x: 0.6, y: 0.25 },
        { x: 0.6, y: 0.75 }
      ]
    })
  }

  distance: number
  enabled: boolean
  coordinates: IRelativePoint[]

  get direction(): string {
    return this.coordinates[0].y < this.coordinates[1].y ? 'in' : 'out'
  }
}
