import { applyMiddleware, createStore } from 'redux'

import api from '../middleware/api'
import rootReducer from '../reducers'
import thunk from 'redux-thunk'

const configureStore = (preloadedState) =>
  createStore(rootReducer, preloadedState, applyMiddleware(thunk, api))

export default configureStore
