import { log } from './log'

const getEnvironmentVariable = (variable) => {
  const global = window as any
  const value = global._env_[variable]

  if (value) {
    return value
  }

  log.warn(
    `You need to set ${variable} in your .env file before starting the server`
  )
}

export class Config {
  static get DATA_API(): string {
    return getEnvironmentVariable('DATA_API_URL')
  }

  static get API_ROOT(): string {
    return getEnvironmentVariable('REACT_APP_API_URL')
  }

  static get AAD_APP_CLIENT_ID(): string {
    return getEnvironmentVariable('REACT_APP_AAD_APP_CLIENT_ID')
  }

  static get isB2C(): boolean {
    return this.AUTHORITY.toUpperCase().includes('B2C_')
  }
  static get AZURE_MAPS_KEY(): string {
    return getEnvironmentVariable('AZURE_MAPS_KEY')
  }

  static get AUTHORITY(): string {
    return getEnvironmentVariable('REACT_APP_AUTHORITY')
  }

  static get VERSION_INFO(): string {
    return process.env.REACT_APP_VERSION_INFO || ''
  }

  static get GLOBAL_VERSION_INFO(): string {
    return getEnvironmentVariable('GLOBAL_VERSION_INFO') || ''
  }

  static get GIT_COMMIT_SHA(): string {
    return process.env.REACT_APP_GIT_COMMIT_SHA || ''
  }

  static get INSTRUMENTATION_KEY(): string {
    return getEnvironmentVariable('REACT_APP_INSTRUMENTATION_KEY')
  }

  static get CAMERA_FRAME_CACHE_TIME(): number {
    return (
      (getEnvironmentVariable('REACT_APP_CAMERA_FRAME_CACHE_TIME') || 60) * 1000
    )
  }

  static get LOG_CACHE_TIME(): number {
    return (getEnvironmentVariable('REACT_APP_LOG_CACHE_TIME') || 60) * 1000
  }

  static get CAMERA_FRAME_REQUEST_INTERVAL(): number {
    return (
      (getEnvironmentVariable('REACT_APP_CAMERA_FRAME_REQUEST_INTERVAL') || 5) *
      1000
    )
  }

  static get LOG_REQUEST_INTERVAL(): number {
    return (
      (getEnvironmentVariable('REACT_APP_CAMERA_FRAME_REQUEST_INTERVAL') || 5) *
      1000
    )
  }

  static get LOG_REQUEST_MAX_RETRIES(): number {
    return getEnvironmentVariable('REACT_APP_LOG_REQUEST_MAX_RETRIES') || 6
  }

  static get CAMERA_FRAME_REQUEST_MAX_RETRIES(): number {
    return (
      getEnvironmentVariable('REACT_APP_CAMERA_FRAME_REQUEST_MAX_RETRIES') || 10
    )
  }

  static get MAX_STREAMS_PER_DEVICE_ARM64_VSI(): number {
    return getEnvironmentVariable('MAX_STREAMS_PER_DEVICE_ARM64_VSI') || 4
  }
  static get MAX_STREAMS_PER_DEVICE_X64_NVIDIA(): number {
    return (
      getEnvironmentVariable('MAX_STREAMS_PER_DEVICE_X64_NVIDIA') ||
      Number.MAX_VALUE
    )
  }
  static get MAX_STREAMS_PER_DEVICE_JETSON_NANO(): number {
    return getEnvironmentVariable('MAX_STREAMS_PER_DEVICE_JETSON_NANO') || 4
  }
  static get MAX_STREAMS_PER_DEVICE_JETSON_AGX(): number {
    return (
      getEnvironmentVariable('MAX_STREAMS_PER_DEVICE_JETSON_AGX') ||
      Number.MAX_VALUE
    )
  }
  static get MAX_STREAMS_PER_DEVICE_JETSON_NX(): number {
    return (
      getEnvironmentVariable('MAX_STREAMS_PER_DEVICE_JETSON_NX') ||
      Number.MAX_VALUE
    )
  }

  static get CUSTOM_THEME(): string {
    return getEnvironmentVariable('CUSTOM_THEME')
  }

  static get CUSTOM_COLOR(): string {
    return getEnvironmentVariable('CUSTOM_COLOR')
  }

  static get EXCLUDE_SUPPORT(): boolean {
    return !!getEnvironmentVariable('EXCLUDE_SUPPORT')
  }
}
