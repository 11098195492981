import React from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

interface IFormLabelWithTooltipProps {
  id: string
}

const FormLabelWithTooltip: React.FC<IFormLabelWithTooltipProps> = ({ id }) => {
  const { t } = useTranslation()
  const labelKey = `${id}.labelText`
  const tooltipTitleKey = `${id}.tooltipTitle`
  const tooltipTextKey = `${id}.tooltipText`

  const tooltipText = t(tooltipTextKey)

  return (
    <>
      <Tooltip
        arrowPointAtCenter={true}
        trigger={'click'}
        title={
          <>
            <h4 className="scc--tooltip--title">{t(tooltipTitleKey)}</h4>
            <p
              className="scc--tooltip--text"
              dangerouslySetInnerHTML={{
                __html: tooltipText
              }}
            ></p>
          </>
        }
        placement={'bottomRight'}
        overlayClassName={'scc--tooltip--card'}
      >
        <InfoCircleOutlined className={'scc--tooltip--icon'} />
      </Tooltip>
      <Space>
        <p className="scc--tooltip--label">{t(labelKey)}</p>
      </Space>
    </>
  )
}

export default FormLabelWithTooltip
