import {
  LICENSE_STATUS_REQUEST,
  LICENSE_STATUS_REQUEST_FAILURE,
  LICENSE_STATUS_REQUEST_SUCCESS,
  LIST_LICENSES_REQUEST,
  LIST_LICENSES_REQUEST_FAILURE,
  LIST_LICENSES_REQUEST_SUCCESS
} from '../actions/actionTypes'
import { ILicenseStatus, IStreamLicense } from '../../types/licenseManagement'

const initialState = {
  licenses: [] as any[],
  status: undefined as ILicenseStatus | undefined,
  loading: true,
  error: null
}

const licensesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_LICENSES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case LIST_LICENSES_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        licenses: action.payload.licenses
      }
    case LIST_LICENSES_REQUEST_FAILURE:
      let licenses: IStreamLicense[] = []
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        licenses: licenses
      }
    case LICENSE_STATUS_REQUEST:
      return {
        ...state,
        error: null
      }
    case LICENSE_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        status: action.payload.status
      }
    case LICENSE_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        status: undefined
      }
    default:
      return state
  }
}

export default licensesReducer
