import {
  GET_ONVIF_RECOMMENDATIONS_REQUEST,
  GET_ONVIF_RECOMMENDATIONS_REQUEST_FAILURE,
  GET_ONVIF_RECOMMENDATIONS_REQUEST_SUCCESS,
  PATCH_ONVIF_SETTINGS_REQUEST,
  PATCH_ONVIF_SETTINGS_REQUEST_FAILURE,
  PATCH_ONVIF_SETTINGS_REQUEST_SUCCESS,
  PATCH_ONVIF_ZOOM_REQUEST,
  PATCH_ONVIF_ZOOM_REQUEST_FAILURE,
  PATCH_ONVIF_ZOOM_REQUEST_SUCCESS
} from './actionTypes'
import { CALL_API, HTTP_METHOD } from '../middleware/api'
import { ICameraSettings, ICameraZoomCommand } from '../../types/onvif'

/**
 * ==============
 * ONVIF SETTINGS
 * ==============
 */

export const callSetOnvifSettings = (
  boxId: string,
  streamId: string,
  settings: ICameraSettings
) => {
  return {
    [CALL_API]: {
      types: [
        PATCH_ONVIF_SETTINGS_REQUEST,
        PATCH_ONVIF_SETTINGS_REQUEST_SUCCESS,
        PATCH_ONVIF_SETTINGS_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.PATCH,
      payload: settings,
      endpoint: `boxes/${boxId}/${streamId}/onvif/settings`,
      id: streamId
    }
  }
}

export const callGetOnvifRecommendations = (
  boxId: string,
  streamId: string
) => {
  return {
    [CALL_API]: {
      types: [
        GET_ONVIF_RECOMMENDATIONS_REQUEST,
        GET_ONVIF_RECOMMENDATIONS_REQUEST_SUCCESS,
        GET_ONVIF_RECOMMENDATIONS_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.GET,
      endpoint: `boxes/${boxId}/${streamId}/onvif/recommendations`,
      id: streamId
    }
  }
}

export const callSetOnvifZoom = (
  boxId: string,
  streamId: string,
  zoomCommand: ICameraZoomCommand
) => {
  return {
    [CALL_API]: {
      types: [
        PATCH_ONVIF_ZOOM_REQUEST,
        PATCH_ONVIF_ZOOM_REQUEST_SUCCESS,
        PATCH_ONVIF_ZOOM_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.PATCH,
      payload: zoomCommand,
      endpoint: `boxes/${boxId}/${streamId}/onvif/zoom`,
      id: streamId
    }
  }
}

export const setOnvifSettings = (boxId, streamId, settings) => async (
  dispatch,
  getState
) => {
  return dispatch(callSetOnvifSettings(boxId, streamId, settings)).then(
    (data) => data.response
  )
}

export const getOnvifRecommendations = (boxId, streamId) => async (
  dispatch,
  getState
) => {
  return dispatch(callGetOnvifRecommendations(boxId, streamId)).then(
    (data) => data.response
  )
}

export const setOnvifZoom = (boxId, streamId, zoomCommand) => async (
  dispatch,
  getState
) => {
  return dispatch(callSetOnvifZoom(boxId, streamId, zoomCommand))
}
