import './ConfirmationDialog.scss'

import { Modal } from 'carbon-components-react'
import React from 'react'

const ConfirmationDialog: React.FC<any> = (props) => {
  return (
    <Modal
      modalHeading="Confirmation"
      aria-label="Confirmation Dialog"
      open={true}
      size="sm"
      {...props}
    >
      {props.children}
    </Modal>
  )
}

export default ConfirmationDialog
