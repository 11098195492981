/* eslint-disable jsx-a11y/role-supports-aria-props */
import './AccountInfo.scss'

import React from 'react'

import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import { getUserRole } from '../../types/user_role'
import { Menu } from 'antd'
import {
  getAllTenantsFromClaims,
  getTenantInfoFromToken,
  TENANT_STORAGE_NAME,
  TokenClaims
} from '../../services/apiTokenProvider'
import { DownOutlined, LogoutOutlined, SwapOutlined } from '@ant-design/icons'

interface IAccountInfoProps {
  children?
}

const AccountInfo: React.FC<IAccountInfoProps> = (props) => {
  const instance = useMsal().instance
  const userAccount = useMsal().accounts[0]

  const { t } = useTranslation()

  const tokenClaims: TokenClaims | undefined = userAccount?.idTokenClaims

  let tenantinformation = tokenClaims
    ? getTenantInfoFromToken(tokenClaims)
    : undefined

  let allTenants = tokenClaims ? getAllTenantsFromClaims(tokenClaims) : []

  return (
    <div className="scc--account__info">
      {userAccount && (
        <Menu mode={'horizontal'} theme={'light'}>
          {!!tenantinformation && (
            <Menu.SubMenu
              disabled={allTenants.length <= 1}
              className="scc--account__menu-item scc--account__disabled_without_effect"
              title={
                <>
                  {t('accountInfo.tenant') +
                    ': ' +
                    tenantinformation?.tenantName}
                  &nbsp;
                  {allTenants.length > 1 && <DownOutlined />}
                </>
              }
            >
              {allTenants.length > 1 &&
                allTenants
                  .filter(
                    (info) => info.tenantId !== tenantinformation!.tenantId
                  )
                  .sort((a, b) => {
                    if (a.isMainTenant && !b.isMainTenant) return -1
                    else if (b.isMainTenant && !a.isMainTenant) return 1
                    else return a.tenantName.localeCompare(b.tenantName)
                  })
                  .map((info) => (
                    <Menu.Item
                      className="scc--account__change-tenant"
                      onClick={() => {
                        info.isMainTenant
                          ? localStorage.removeItem(TENANT_STORAGE_NAME)
                          : localStorage.setItem(
                              TENANT_STORAGE_NAME,
                              info.tenantId
                            )
                        // redirect to base path because this can e.g. be done from boxView
                        // which results in errors because box does not exist on other tenant
                        window.location.href = '/'
                      }}
                      key={TENANT_STORAGE_NAME + info.tenantId}
                    >
                      <SwapOutlined />
                      {info.tenantName}
                    </Menu.Item>
                  ))}
            </Menu.SubMenu>
          )}
          <Menu.SubMenu
            className="scc--account__menu-item"
            title={
              <>
                {(userAccount.name || userAccount.username) +
                  (getUserRole() ? ' - ' + getUserRole() : '')}
                &nbsp;
                <DownOutlined />
              </>
            }
          >
            <Menu.Item
              className="scc--account__logout"
              onClick={async () => {
                await instance.logout()
              }}
              icon={<LogoutOutlined />}
            >
              {t('accountInfo.logout')}
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      )}
    </div>
  )
}

export default AccountInfo
