import styled from 'styled-components'
import cubeImage from '../theme/icons/cubeloader.svg'

const POSITIONS = [
  [49, 0.5],
  [0, 25],
  [49, 49],
  [98, 25]
]

function pos(index) {
  return `transform: translate(${POSITIONS[index][0]}%, ${POSITIONS[index][1]}%);`
}

const RawImg = styled.img(
  ({ index }) => `
  display: block;
  position: absolute;
  width: 50%;
  height: 50%;

  ${
    index != null
      ? `
  animation-name: dice${index};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  z-index: 0;

  @keyframes dice${index} {
    ${
      index === 0
        ? `
    from {
      ${pos(0)}
      z-index: 0;
    }

    25% {
      ${pos(0)}
      z-index: 0;
    }

    50% {
      ${pos(1)}
      z-index: 0;
    }

    51% {
      z-index: 1;
    }

    75% {
      z-index: 1;
    }
    to {
      ${pos(1)}
      z-index: 1;
    }
    `
        : ''
    }

    ${
      index === 1
        ? `
    from {
      ${pos(1)}
      z-index: 3;
    }

    25% {
      ${pos(2)}
      z-index: 3;
    }

    75% {
      ${pos(2)}
      z-index: 2;
    }

    to {
      ${pos(3)}
      z-index: 1;
    }
    `
        : ''
    }

    ${
      index === 2
        ? `
    from {
      ${pos(3)}
      z-index: 1;
    }

    50% {
      ${pos(3)}
      z-index: 0;
    }

    75% {
      ${pos(0)}
      z-index: 0;
    }
    to {
      ${pos(0)}
      z-index: 0;
    }
    `
        : ''
    }
  }
  `
      : ''
  }
`
)

const Cube = (props) => {
  return <RawImg role="presentation" src={cubeImage} alt="" {...props} />
}

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

function LoadingAnimation({ size = 80 }) {
  const img = (
    <div
      key="loader"
      role="img"
      aria-label="Loading animation"
      style={{
        position: 'relative',
        width: size,
        height: size * 1.1388888889,
        margin: '0 auto'
      }}
    >
      <Cube style={{ transform: 'translate(0%, 72.5%)' }} />
      <Cube style={{ transform: 'translate(98%, 72.5%)' }} />
      <Cube style={{ transform: 'translate(49%, 96.5%)' }} />
      <Cube index={0} />
      <Cube index={1} />
      <Cube index={2} />
    </div>
  )

  return <Centered>{img}</Centered>
}

export default LoadingAnimation
