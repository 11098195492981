import { client, getAuthorizationHeader } from '../middleware/api'
import {
  LICENSE_STATUS_REQUEST,
  LICENSE_STATUS_REQUEST_FAILURE,
  LICENSE_STATUS_REQUEST_SUCCESS,
  LIST_LICENSES_REQUEST,
  LIST_LICENSES_REQUEST_FAILURE,
  LIST_LICENSES_REQUEST_SUCCESS
} from './actionTypes'

const fetchLicensesBegin = () => ({
  type: LIST_LICENSES_REQUEST
})

const fetchLicensesSuccess = (licenses) => ({
  type: LIST_LICENSES_REQUEST_SUCCESS,
  payload: { licenses }
})

const fetchLicensesFailure = (error) => ({
  type: LIST_LICENSES_REQUEST_FAILURE,
  payload: { error }
})

export const callFetchLicenses = () => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(fetchLicensesBegin())
      client
        .get('license/list', header)
        .then((response) => {
          dispatch(fetchLicensesSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(fetchLicensesFailure(error))
        })
    })
  }
}

const fetchLicenseStatusBegin = () => ({
  type: LICENSE_STATUS_REQUEST
})

const fetchLicenseStatusSuccess = (status) => ({
  type: LICENSE_STATUS_REQUEST_SUCCESS,
  payload: { status }
})

const fetchLicenseStatusFailure = (error) => ({
  type: LICENSE_STATUS_REQUEST_FAILURE,
  payload: { error }
})

export const callFetchLicenseStatus = () => {
  return (dispatch) => {
    getAuthorizationHeader().then((header) => {
      dispatch(fetchLicenseStatusBegin())
      client
        .get('license', header)
        .then((response) => {
          dispatch(fetchLicenseStatusSuccess(response.data))
          return response.data
        })
        .catch((error) => {
          dispatch(fetchLicenseStatusFailure(error))
        })
    })
  }
}
