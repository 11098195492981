import {
  ADD_LOCAL_BOX_EVENT_RULES_REQUEST,
  BOX_EVENT_RULES_REQUEST_SUCCESS,
  BOX_EVENT_RULES_TEMPLATES_REQUEST_SUCCESS,
  DELETE_BOX_EVENT_RULES_REQUEST_SUCCESS,
  DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS,
  RESET_BOX_EVENT_TRIGGERS_REQUEST_SUCCESS,
  SAVE_BOX_EVENT_RULE_REQUEST,
  SAVE_BOX_EVENT_RULE_REQUEST_FAILURE,
  SAVE_BOX_EVENT_RULE_REQUEST_SUCCESS
} from '../actions/actionTypes'

import { union } from 'lodash'
import { TriggerRule, TriggerRuleTemplate } from '../../types/triggerRule'
import { EEventTriggerType } from '../../types/eventTrigger'

const initialState = {
  allIds: [],
  byIds: {},
  templates: [],
  isSubmitting: false
}

const removeTemplate = (templates, templateId) => {
  const newTemplates: TriggerRuleTemplate[] = []
  templates.forEach((template: TriggerRuleTemplate) => {
    if (template.id !== templateId) {
      newTemplates.push(template)
    }
  })
  return newTemplates
}

const removeTemplateByStreamId = (templates, streamId) => {
  const newTemplates: TriggerRuleTemplate[] = []
  templates.forEach((template: TriggerRuleTemplate) => {
    if (template.streamId !== streamId) {
      newTemplates.push(template)
    }
  })
  return newTemplates
}

const fn = function (state = initialState, action) {
  switch (action.type) {
    case BOX_EVENT_RULES_REQUEST_SUCCESS:
      const fetchedRules =
        action.response.entities.eventRules[action.response.result]

      let newRules = {}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(fetchedRules) as [
        string,
        any
      ]) {
        newRules[value.id] = new TriggerRule(value)
      }

      return {
        ...state,
        allIds: Object.keys(newRules),
        byIds: Object.assign({}, newRules)
      }

    case SAVE_BOX_EVENT_RULE_REQUEST:
      return {
        ...state,
        isSubmitting: true
      }

    case SAVE_BOX_EVENT_RULE_REQUEST_SUCCESS:
      const fetchedRule =
        action.response.entities.eventRules[action.response.result]
      const rules = Object.assign({}, state.byIds)
      rules[fetchedRule.id] = new TriggerRule(fetchedRule)

      return {
        ...state,
        allIds: union(state.allIds, [fetchedRule.id]),
        byIds: rules,
        isSubmitting: false
      }

    case ADD_LOCAL_BOX_EVENT_RULES_REQUEST:
      // add new local (unsaved) rule to state
      const rule = action.payload.rule
      const nextRules = Object.assign({}, state.byIds)
      nextRules[rule.localId] = rule
      return {
        ...state,
        allIds: union(state.allIds, [rule.localId]),
        byIds: nextRules
      }

    case SAVE_BOX_EVENT_RULE_REQUEST_FAILURE:
      return {
        ...state,
        isSubmitting: false
      }

    case DELETE_BOX_EVENT_RULES_REQUEST_SUCCESS:
      const deletedEventRuleId = action.response.id
      const newState = {
        ...state,
        templates: removeTemplate(state.templates, deletedEventRuleId),
        allIds: state.allIds.filter((id) => deletedEventRuleId !== id)
      }

      delete newState.byIds[deletedEventRuleId]
      return newState

    case DELETE_BOX_EVENT_TRIGGER_REQUEST_SUCCESS:
      // remove connected rules if a trigger is deleted
      const deletedEventTriggerId = action.response.id
      const wasODZone = action.response.triggerType
        ? action.response.triggerType ===
          EEventTriggerType.originDestinationZone
        : action.response.config.url.includes(
            EEventTriggerType.originDestinationZone
          )
      let reducedIds: string[] = []
      let reducedRules = {}
      let reducedTemplates: TriggerRuleTemplate[] = Object.assign(
        [],
        state.templates
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(state.byIds) as [string, any]) {
        if (
          !value.conditions.some(
            (condition) => condition.trigger === deletedEventTriggerId
          ) &&
          !(
            wasODZone &&
            value.conditions.some(
              (condition) =>
                condition.triggerType ===
                EEventTriggerType.originDestinationZone
            )
          )
        ) {
          reducedIds.push(value.localId)
          reducedRules[value.localId] = value
        } else {
          reducedTemplates = removeTemplate(reducedTemplates, value.localId)
        }
      }
      return {
        ...state,
        allIds: reducedIds,
        byIds: Object.assign({}, reducedRules),
        templates: reducedTemplates
      }

    case RESET_BOX_EVENT_TRIGGERS_REQUEST_SUCCESS:
      let url = action.response.config.url
      let streamId = url.split('/')[2]
      return {
        ...initialState,
        templates: removeTemplateByStreamId(state.templates, streamId)
      }

    case BOX_EVENT_RULES_TEMPLATES_REQUEST_SUCCESS:
      const fetchedTemplates =
        action.response.entities.eventRules[action.response.result]
      const templates: TriggerRuleTemplate[] = []
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(fetchedTemplates) as [
        string,
        any
      ]) {
        templates.push(new TriggerRuleTemplate(value))
      }
      return {
        ...state,
        templates: templates
      }

    default:
      return state
  }
}
export default fn
