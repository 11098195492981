import boxDetails from './boxDetails'
import boxes from './boxes'
import cameraConfigurations from './cameraConfigurations'
import cameraFrames from './cameraFrames'
import { combineReducers } from 'redux'
import error from './error'
import eventTriggers from './eventTriggers'
import mqttConfigurations from './mqttConfigurations'
import streamDetails from './streamDetails'
import streams from './streams'
import groups from './groups'
import scenes from './scenes'
import eventRules from './eventRules'
import alerts from './alerts'
import onvif from './onvif'
import licenses from './licenses'
import tenantAdministration from './tenantAdministration'
import users from './users'
import boxActionSchedule from './boxActionSchedule'
import boxFeatureFlags from './boxFeatureFlags'

export default combineReducers({
  boxes,
  boxDetails,
  streams,
  streamDetails,
  cameraConfigurations,
  mqttConfigurations,
  eventTriggers,
  eventRules,
  cameraFrames,
  error,
  groups,
  scenes,
  alerts,
  users,
  boxActionSchedule,
  licenses,
  onvif,
  tenantAdministration,
  boxFeatureFlags
})
