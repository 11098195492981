import {
  EEventTriggerType,
  EEventTriggerUtil,
  IEventTrigger
} from './eventTrigger'

export class HeatMapEventTrigger implements IEventTrigger {
  enabled: boolean
  localId: string = 'heatMap'
  objectType: EEventTriggerType

  get typeShort(): string {
    return EEventTriggerUtil.toString(this.objectType)
  }

  constructor(props) {
    this.objectType = EEventTriggerType.heatMap
    this.enabled = props.enabled
  }

  public static default(): HeatMapEventTrigger {
    return new HeatMapEventTrigger({
      localId: 'heatMap',
      enabled: false
    })
  }

  getRequestObject() {
    let object: any = Object.assign({}, this)

    delete object.objectType
    delete object.isSelected
    delete object.hasChanged
    delete object.coordinates
    delete object.name
    delete object.id
    delete object.localId

    return object
  }
}
