// @ts-nocheck
// noinspection ES6CheckImport

import React from 'react'

import { equals, fromPairs, indexBy, prop, uniqBy } from 'ramda'
import {
  defaultOrder,
  flattenFilters,
  getQueryMembers,
  moveItemInArray,
  ResultSet
} from '@cubejs-client/core'

import { CubeContext, QueryRenderer } from '@cubejs-client/react'

const granularities = [
  { name: undefined, title: 'w/o grouping' },
  { name: 'second', title: 'Second' },
  { name: 'minute', title: 'Minute' },
  { name: 'hour', title: 'Hour' },
  { name: 'day', title: 'Day' },
  { name: 'week', title: 'Week' },
  { name: 'month', title: 'Month' },
  { name: 'year', title: 'Year' }
]

//this is based on https://github.com/cube-js/cube.js/blob/d4286da4c671410e95e4537ac41ca028b2d6b103/packages/cubejs-client-react/src/QueryBuilder.jsx#L18
//v0.26.45 but fixes an updateStateViz bug that delays correct rendering by one state update
export default class QueryBuilderBase extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const nextState = {
      ...state,
      ...(props.vizState || {})
    }
    props.vizState.query && (nextState.validatedQuery = props.vizState.query)
    if (Array.isArray(props.query)) {
      throw new Error('Array of queries is not supported.')
    }

    return {
      ...nextState,
      query: {
        ...nextState.query,
        ...(props.query || {})
      }
    }
  }

  static resolveMember(type, { meta, query }) {
    if (!meta) {
      return []
    }

    if (Array.isArray(query)) {
      return query.reduce(
        (memo, currentQuery) =>
          memo.concat(
            QueryBuilderBase.resolveMember(type, {
              meta,
              query: currentQuery
            })
          ),
        []
      )
    }

    if (type === 'timeDimensions') {
      return (query.timeDimensions || []).map((m, index) => ({
        ...m,
        dimension: {
          ...meta.resolveMember(m.dimension, 'dimensions'),
          granularities
        },
        index
      }))
    }

    return (query[type] || []).map((m, index) => ({
      index,
      ...meta.resolveMember(m, type)
    }))
  }

  static getOrderMembers(state) {
    const { query, meta } = state

    if (!meta) {
      return []
    }

    const toOrderMember = (member) => ({
      id: member.name,
      title: member.title
    })

    return uniqBy(
      prop('id'),
      [
        ...QueryBuilderBase.resolveMember('measures', state).map(toOrderMember),
        ...QueryBuilderBase.resolveMember('dimensions', state).map(
          toOrderMember
        ),
        ...QueryBuilderBase.resolveMember('timeDimensions', state).map((td) =>
          toOrderMember(td.dimension)
        )
      ].map((member) => ({
        ...member,
        order: query.order?.[member.id] || 'none'
      }))
    )
  }

  constructor(props) {
    super(props)

    this.state = {
      query: props.query,
      chartType: 'line',
      orderMembers: [],
      pivotConfig: null,
      validatedQuery: props.query,
      missingMembers: [],
      isFetchingMeta: false,
      ...props.vizState
    }

    this.mutexObj = {}
  }

  async componentDidMount() {
    await this.fetchMeta()
  }

  async componentDidUpdate(prevProps) {
    const { schemaVersion, onSchemaChange } = this.props
    const { meta } = this.state

    if (prevProps.schemaVersion !== schemaVersion) {
      try {
        const newMeta = await this.cubejsApi().meta()
        if (!equals(newMeta, meta) && typeof onSchemaChange === 'function') {
          onSchemaChange({
            schemaVersion,
            refresh: async () => {
              await this.fetchMeta()
            }
          })
        }
      } catch (error) {
        // eslint-disable-next-line
        this.setState({ metaError: error })
      }
    }
  }

  fetchMeta = async () => {
    const { query, pivotConfig } = this.state
    let dryRunResponse
    let missingMembers = []
    let meta
    let metaError = null

    try {
      this.setState({ isFetchingMeta: true })
      meta = await this.cubejsApi().meta()
    } catch (error) {
      metaError = error
    }

    if (this.isQueryPresent()) {
      missingMembers = this.getMissingMembers(query, meta)

      if (missingMembers.length === 0) {
        dryRunResponse = this.cubejsApi().dryRun(query)
      }
    }

    this.setState({
      meta,
      metaError,
      orderMembers: QueryBuilderBase.getOrderMembers({ meta, query }),
      pivotConfig: ResultSet.getNormalizedPivotConfig(
        dryRunResponse?.pivotQuery || {},
        pivotConfig
      ),
      missingMembers,
      isFetchingMeta: false
    })
  }

  cubejsApi() {
    const { cubejsApi } = this.props
    // eslint-disable-next-line react/destructuring-assignment
    return cubejsApi || (this.context && this.context.cubejsApi)
  }

  getMissingMembers(query, meta) {
    if (!meta) {
      return []
    }

    return getQueryMembers(query)
      .map((member) => {
        const resolvedMember = meta.resolveMember(member, [
          'measures',
          'dimensions',
          'segments'
        ])
        if (resolvedMember.error) {
          return member
        }
        return false
      })
      .filter(Boolean)
  }

  isQueryPresent() {
    const { query } = this.state
    return QueryRenderer.isQueryPresent(query)
  }

  prepareRenderProps(queryRendererProps) {
    const getName = (member) => member.name
    const toTimeDimension = (member) => {
      const rangeSelection = member.compareDateRange
        ? { compareDateRange: member.compareDateRange }
        : { dateRange: member.dateRange }
      return {
        dimension: member.dimension.name,
        granularity: member.granularity,
        ...rangeSelection
      }
    }
    const toFilter = (member) => ({
      member: member.member?.name || member.dimension?.name,
      operator: member.operator,
      values: member.values
    })

    const updateMethods = (memberType, toQuery = getName) => ({
      add: (member) => {
        const { query } = this.state
        let members
        if (Array.isArray(member)) {
          let multipleMembers = []
          member.forEach((m) => multipleMembers.push(toQuery(m)))
          members = (query[memberType] || []).concat(multipleMembers)
        } else {
          members = (query[memberType] || []).concat(toQuery(member))
        }
        this.updateQuery({
          [memberType]: members
        })
      },
      remove: (member) => {
        const { query } = this.state
        let members = (query[memberType] || []).concat([])
        if (Array.isArray(member)) {
          let removelIndices = []
          member.forEach((m) => removelIndices.push(m.index))
          members = members.filter((value, index) => {
            return removelIndices.indexOf(index) === -1
          })
        } else {
          members.splice(member.index, 1)
        }
        return this.updateQuery({
          [memberType]: members
        })
      },
      replaceAll: (replaceWith) => {
        let members = []
        if (Array.isArray(replaceWith)) {
          replaceWith.forEach((m) => members.push(toQuery(m)))
        } else {
          members = [].concat(toQuery(replaceWith))
        }
        this.updateQuery({
          [memberType]: members
        })
      },
      update: (member, updateWith) => {
        const { query } = this.state
        const members = (query[memberType] || []).concat([])
        members.splice(member.index, 1, toQuery(updateWith))
        return this.updateQuery({
          [memberType]: members
        })
      }
    })

    const {
      meta,
      metaError,
      query,
      orderMembers = [],
      chartType,
      pivotConfig,
      validatedQuery,
      missingMembers,
      isFetchingMeta
    } = this.state

    const flatFilters = uniqBy(
      prop('member'),
      flattenFilters((meta && query.filters) || []).map((filter) => ({
        ...filter,
        member: filter.member || filter.dimension
      }))
    )

    const filters = flatFilters.map((m, i) => ({
      ...m,
      dimension: meta.resolveMember(m.member || m.dimension, [
        'dimensions',
        'measures'
      ]),
      operators: meta.filterOperatorsForMember(m.member || m.dimension, [
        'dimensions',
        'measures'
      ]),
      index: i
    }))

    return {
      meta,
      metaError,
      query,
      validatedQuery,
      isQueryPresent: this.isQueryPresent(),
      chartType,
      measures: QueryBuilderBase.resolveMember('measures', this.state),
      dimensions: QueryBuilderBase.resolveMember('dimensions', this.state),
      timeDimensions: QueryBuilderBase.resolveMember(
        'timeDimensions',
        this.state
      ),
      segments: ((meta && query.segments) || []).map((m, i) => ({
        index: i,
        ...meta.resolveMember(m, 'segments')
      })),
      filters,
      orderMembers,
      availableMeasures:
        (meta && meta.membersForQuery(query, 'measures')) || [],
      availableDimensions:
        (meta && meta.membersForQuery(query, 'dimensions')) || [],
      availableTimeDimensions: (
        (meta && meta.membersForQuery(query, 'dimensions')) ||
        []
      ).filter((m) => m.type === 'time'),
      availableSegments:
        (meta && meta.membersForQuery(query, 'segments')) || [],
      updateQuery: (queryUpdate) => this.updateQuery(queryUpdate),
      updateMeasures: updateMethods('measures'),
      updateDimensions: updateMethods('dimensions'),
      updateSegments: updateMethods('segments'),
      updateTimeDimensions: updateMethods('timeDimensions', toTimeDimension),
      updateFilters: updateMethods('filters', toFilter),
      updateChartType: (newChartType) =>
        this.updateVizState({ chartType: newChartType }),
      updateOrder: {
        set: (memberId, order = 'asc') => {
          this.updateVizState({
            orderMembers: orderMembers.map((orderMember) => ({
              ...orderMember,
              order: orderMember.id === memberId ? order : orderMember.order
            }))
          })
        },
        update: (order) => {
          this.updateQuery({
            order
          })
        },
        reorder: (sourceIndex, destinationIndex) => {
          if (sourceIndex == null || destinationIndex == null) {
            return
          }

          this.updateVizState({
            orderMembers: moveItemInArray(
              orderMembers,
              sourceIndex,
              destinationIndex
            )
          })
        }
      },
      pivotConfig,
      updatePivotConfig: {
        moveItem: ({
          sourceIndex,
          destinationIndex,
          sourceAxis,
          destinationAxis
        }) => {
          const nextPivotConfig = {
            ...pivotConfig,
            x: [...pivotConfig.x],
            y: [...pivotConfig.y]
          }
          const id = pivotConfig[sourceAxis][sourceIndex]
          const lastIndex = nextPivotConfig[destinationAxis].length - 1

          if (id === 'measures') {
            destinationIndex = lastIndex + 1
          } else if (
            destinationIndex >= lastIndex &&
            nextPivotConfig[destinationAxis][lastIndex] === 'measures'
          ) {
            destinationIndex = lastIndex - 1
          }

          nextPivotConfig[sourceAxis].splice(sourceIndex, 1)
          nextPivotConfig[destinationAxis].splice(destinationIndex, 0, id)

          this.updateVizState({
            pivotConfig: nextPivotConfig
          })
        },
        update: (config) => {
          const { limit } = config

          if (limit == null) {
            this.updateVizState({
              pivotConfig: {
                ...pivotConfig,
                ...config
              }
            })
          } else {
            this.updateQuery({ limit })
          }
        }
      },
      missingMembers,
      refresh: this.fetchMeta,
      isFetchingMeta,
      ...queryRendererProps
    }
  }

  updateQuery(queryUpdate) {
    const { query } = this.state

    this.updateVizState({
      query: {
        ...query,
        ...queryUpdate
      }
    })
  }

  async updateVizState(state) {
    const { setQuery, setVizState } = this.props
    const {
      query: stateQuery,
      pivotConfig: statePivotConfig,
      meta
    } = this.state

    let finalState = this.applyStateChangeHeuristics(state)
    const query = { ...(finalState.query || stateQuery) }

    const runSetters = (currentState) => {
      if (setVizState) {
        const { meta: _, validatedQuery, ...toSet } = currentState
        setVizState(toSet)
      }
      if (currentState.query && setQuery) {
        setQuery(currentState.query)
      }
    }

    if (finalState.shouldApplyHeuristicOrder) {
      query.order = defaultOrder(query)
    }

    const updatedOrderMembers = indexBy(
      prop('id'),
      QueryBuilderBase.getOrderMembers({
        ...this.state,
        ...finalState
      })
    )
    const currentOrderMemberIds = (finalState.orderMembers || []).map(
      ({ id }) => id
    )
    const currentOrderMembers = (
      finalState.orderMembers || []
    ).filter(({ id }) => Boolean(updatedOrderMembers[id]))

    Object.entries(updatedOrderMembers).forEach(([id, orderMember]) => {
      if (!currentOrderMemberIds.includes(id)) {
        currentOrderMembers.push(orderMember)
      }
    })

    const nextQuery = {
      ...query,
      order: fromPairs(
        currentOrderMembers
          .map(({ id, order }) => (order !== 'none' ? [id, order] : false))
          .filter(Boolean)
      )
    }

    finalState.pivotConfig = ResultSet.getNormalizedPivotConfig(
      nextQuery,
      finalState.pivotConfig !== undefined
        ? finalState.pivotConfig
        : statePivotConfig
    )

    const missingMembers = this.getMissingMembers(nextQuery, meta)

    runSetters({
      ...state,
      query: nextQuery,
      orderMembers: currentOrderMembers
    })

    this.setState({
      ...finalState,
      query: nextQuery,
      orderMembers: currentOrderMembers,
      missingMembers
    })

    let pivotQuery = {}
    if (QueryRenderer.isQueryPresent(query) && missingMembers.length === 0) {
      try {
        const response = await this.cubejsApi().dryRun(query, {
          mutexObj: this.mutexObj
        })
        pivotQuery = response.pivotQuery

        if (finalState.shouldApplyHeuristicOrder) {
          nextQuery.order = (response.queryOrder || []).reduce(
            (memo, current) => ({ ...memo, ...current }),
            {}
          )
        }

        if (QueryRenderer.isQueryPresent(finalState.query)) {
          finalState = {
            ...finalState,
            query: nextQuery,
            pivotConfig: ResultSet.getNormalizedPivotConfig(
              pivotQuery,
              finalState.pivotConfig
            )
          }

          this.setState({
            ...finalState,
            validatedQuery: this.validatedQuery(finalState)
          })
          runSetters({
            ...this.state,
            ...finalState
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  validatedQuery(state) {
    const { query } = state || this.state

    return {
      ...query,
      filters: (query.filters || []).filter((f) => f.operator)
    }
  }

  defaultHeuristics(newState) {
    const { query, sessionGranularity } = this.state
    const defaultGranularity = sessionGranularity || 'day'

    if (Array.isArray(query)) {
      return newState
    }

    if (newState.query) {
      const oldQuery = query
      let newQuery = newState.query

      const { meta } = this.state

      if (
        (oldQuery.timeDimensions || []).length === 1 &&
        (newQuery.timeDimensions || []).length === 1 &&
        newQuery.timeDimensions[0].granularity &&
        oldQuery.timeDimensions[0].granularity !==
          newQuery.timeDimensions[0].granularity
      ) {
        newState = {
          ...newState,
          sessionGranularity: newQuery.timeDimensions[0].granularity
        }
      }

      if (
        ((oldQuery.measures || []).length === 0 &&
          (newQuery.measures || []).length > 0) ||
        ((oldQuery.measures || []).length === 1 &&
          (newQuery.measures || []).length === 1 &&
          oldQuery.measures[0] !== newQuery.measures[0])
      ) {
        const defaultTimeDimension = meta.defaultTimeDimensionNameFor(
          newQuery.measures[0]
        )
        newQuery = {
          ...newQuery,
          timeDimensions: defaultTimeDimension
            ? [
                {
                  dimension: defaultTimeDimension,
                  granularity: defaultGranularity
                }
              ]
            : []
        }

        return {
          ...newState,
          pivotConfig: null,
          shouldApplyHeuristicOrder: true,
          query: newQuery,
          chartType: defaultTimeDimension ? 'line' : 'number'
        }
      }

      if (
        (oldQuery.dimensions || []).length === 0 &&
        (newQuery.dimensions || []).length > 0
      ) {
        newQuery = {
          ...newQuery,
          timeDimensions: (newQuery.timeDimensions || []).map((td) => ({
            ...td,
            granularity: undefined
          }))
        }

        return {
          ...newState,
          pivotConfig: null,
          shouldApplyHeuristicOrder: true,
          query: newQuery,
          chartType: 'table'
        }
      }

      if (
        (oldQuery.dimensions || []).length > 0 &&
        (newQuery.dimensions || []).length === 0
      ) {
        newQuery = {
          ...newQuery,
          timeDimensions: (newQuery.timeDimensions || []).map((td) => ({
            ...td,
            granularity: td.granularity || defaultGranularity
          }))
        }

        return {
          ...newState,
          pivotConfig: null,
          shouldApplyHeuristicOrder: true,
          query: newQuery,
          chartType: (newQuery.timeDimensions || []).length ? 'line' : 'number'
        }
      }

      if (
        ((oldQuery.dimensions || []).length > 0 ||
          (oldQuery.measures || []).length > 0) &&
        (newQuery.dimensions || []).length === 0 &&
        (newQuery.measures || []).length === 0
      ) {
        newQuery = {
          ...newQuery,
          timeDimensions: [],
          filters: []
        }

        return {
          ...newState,
          pivotConfig: null,
          shouldApplyHeuristicOrder: true,
          query: newQuery,
          sessionGranularity: null
        }
      }
      return newState
    }

    if (newState.chartType) {
      const newChartType = newState.chartType
      if (
        (newChartType === 'line' || newChartType === 'area') &&
        (query.timeDimensions || []).length === 1 &&
        !query.timeDimensions[0].granularity
      ) {
        const [td] = query.timeDimensions
        return {
          ...newState,
          pivotConfig: null,
          query: {
            ...query,
            timeDimensions: [{ ...td, granularity: defaultGranularity }]
          }
        }
      }

      if (
        (newChartType === 'pie' ||
          newChartType === 'table' ||
          newChartType === 'number') &&
        (query.timeDimensions || []).length === 1 &&
        query.timeDimensions[0].granularity
      ) {
        const [td] = query.timeDimensions
        return {
          ...newState,
          pivotConfig: null,
          shouldApplyHeuristicOrder: true,
          query: {
            ...query,
            timeDimensions: [{ ...td, granularity: undefined }]
          }
        }
      }
    }

    return newState
  }

  applyStateChangeHeuristics(newState) {
    const { stateChangeHeuristics, disableHeuristics } = this.props
    if (disableHeuristics) {
      return newState
    }
    return (
      (stateChangeHeuristics && stateChangeHeuristics(this.state, newState)) ||
      this.defaultHeuristics(newState)
    )
  }

  render() {
    const { query } = this.state
    const { cubejsApi, render, wrapWithQueryRenderer } = this.props

    if (wrapWithQueryRenderer) {
      return (
        <QueryRenderer
          query={query}
          cubejsApi={cubejsApi}
          resetResultSetOnChange={false}
          render={(queryRendererProps) => {
            if (render) {
              return render(this.prepareRenderProps(queryRendererProps))
            }
            return null
          }}
        />
      )
    } else {
      if (render) {
        return render(this.prepareRenderProps())
      }
      return null
    }
  }
}

QueryBuilderBase.contextType = CubeContext

QueryBuilderBase.defaultProps = {
  cubejsApi: null,
  query: {},
  setQuery: null,
  setVizState: null,
  stateChangeHeuristics: null,
  disableHeuristics: false,
  render: null,
  wrapWithQueryRenderer: true,
  vizState: {}
}
