import { loadBoxes } from '../../redux/actions/boxes'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../redux/store/configureStore'
import React, { useEffect } from 'react'
import { Button, Input, Space, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { NameIdCell } from '../BoxList/cells'
import { IMonitoringAlert } from '../../types/monitoringAlert'
import { sortBoxes } from '../../types/box'

type AlertDeviceListProps = {
  alert: IMonitoringAlert
  selectedDevicesChanged: (selectedRowKeys) => void
}

// eslint-disable-next-line no-empty-pattern
const AlertDeviceList = ({
  alert,
  selectedDevicesChanged
}: AlertDeviceListProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading, boxes } = useSelector((state: ReduxStore) => ({
    loading: !state.boxes.boxesFetched,
    boxes: state.boxes.allIds.map((id) => state.boxes.byIds[id])
  }))

  useEffect(() => {
    dispatch(loadBoxes())
  }, [dispatch])

  const data: any = []
  if (!loading) {
    sortBoxes(boxes)
    boxes.forEach((box) => {
      data.push({
        key: box.id,
        name_id: <NameIdCell box={box} key={box.name + box.id} />
      })
    })
  }

  let searchInput: Input | null = null
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={t('boxList.dataTable.search.name')}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('boxList.dataTable.search.submit')}
          </Button>
          <Button onClick={() => clearFilters()} type="link" size="small">
            {t('boxList.dataTable.search.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? 'scc--boxlist--searched' : ''} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].key
        ? record[dataIndex].key
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100)
      }
    },
    render: (text) => text
  })

  const columns = [
    {
      title: t('boxList.dataTable.headers.name_id'),
      dataIndex: 'name_id',
      key: 'name_id',
      width: '40%',
      ...getColumnSearchProps('name_id'),
      sorter: (a, b) => {
        let nameA = a.name_id.key.toLowerCase()
        let nameB = b.name_id.key.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    }
  ]

  return (
    <div className="scc--boxlist--container">
      <div className="bx--grid bx--grid--full-width">
        <div className="bx--row">
          <div className="bx--col">
            <Table
              rowClassName={'scc--tablerow'}
              rowSelection={{
                selectedRowKeys: alert.boxes,
                type: 'checkbox',
                columnWidth: '3%',
                onChange: selectedDevicesChanged
              }}
              columns={columns}
              dataSource={data}
              loading={loading}
              pagination={{ defaultPageSize: 10 }}
              locale={{
                emptyText: t('monitoring.alerts.modal.deviceList.empty')
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertDeviceList
