import { store } from 'react-notifications-component'

const defaultParameters = {
  type: 'success',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: {
    duration: 3000,
    pauseOnHover: true
  }
}

/**
 * Wraps the notifications component.
 * @param params: Object: The configuration for the notifications.
 */
export const notify = (params) => {
  const config = Object.assign({}, defaultParameters, params)
  return store.addNotification(config)
}
