import { EWidgetType } from '../../../../types/solution'
import { NodeExpandOutlined, PicCenterOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import React from 'react'

export const GeneralWidgetTypes = [
  EWidgetType.RuleTrigger,
  EWidgetType.LicensePlates
]

export const GeneralWidgetTypeIcons = {
  [EWidgetType.RuleTrigger]: <NodeExpandOutlined />,
  [EWidgetType.LicensePlates]: <PicCenterOutlined />
}

export const GeneralChartTypesByWidgetType = {
  [EWidgetType.RuleTrigger]: ['table', 'bar', 'number'],
  [EWidgetType.LicensePlates]: ['table']
}

export const GeneralMeasureByWidgetType = {
  [EWidgetType.RuleTrigger]: 'RuleEvents.count',
  [EWidgetType.LicensePlates]: 'CrossingEvents.count'
}

export const GeneralTimeDimensionsByWidgetType = {
  [EWidgetType.RuleTrigger]: 'RuleEvents.timestamp',
  [EWidgetType.LicensePlates]: 'CrossingEvents.timestamp'
}

export const GeneralDimensionsByWidgetType = {
  [EWidgetType.RuleTrigger]: ['RuleEvents.ruleName'],
  [EWidgetType.LicensePlates]: [
    'CrossingEvents.numberPlateOrigin',
    'CrossingEvents.numberPlateArea',
    'CrossingEvents.plateImage',
    'CrossingEvents.classification',
    'CrossingEvents.direction',
    'CrossingEvents.directionName',
    'CrossingEvents.lineName',
    'CrossingEvents.speed',
    'CrossingEvents.timestamp'
  ]
}

export const GeneralDefaultDimensionsByWidgetType = {
  [EWidgetType.RuleTrigger]: ['RuleEvents.ruleName'],
  [EWidgetType.LicensePlates]: [
    'CrossingEvents.numberPlate',
    'CrossingEvents.timestamp',
    'CrossingEvents.numberPlateOrigin',
    'CrossingEvents.numberPlateArea',
    'CrossingEvents.plateImage'
  ]
}

export const GeneralDataFilterByWidgetType = {
  [EWidgetType.RuleTrigger]: 'RuleEvents.ruleId',
  [EWidgetType.LicensePlates]: 'CrossingEvents.lineId'
}

export const GeneralSegmentsByWidgetType = {
  [EWidgetType.LicensePlates]: ['CrossingEvents.includingLicensePlate']
}

export const GeneralFilterByWidgetType = {
  [EWidgetType.LicensePlates]: [
    'CrossingEvents.classification',
    'CrossingEvents.direction',
    'CrossingEvents.directionName',
    'CrossingEvents.lineName'
  ]
}

export const getRuleTriggerWarning = (t) => (
  <Alert
    message={t('solutions.scenes.overview.add.ruleWarning.message')}
    type="info"
    style={{ marginTop: '10px' }}
  />
)
