import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerServiceOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Config } from '../../services/config'

const EmptyBoxListText: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="scc--boxlist__empty center">
      <h4 className="scc--boxlist__empty--headline">
        <span className="scc--bg--doves">
          {t('boxList.dataTable.empty.headline')}
        </span>
      </h4>
      <p>{t('boxList.dataTable.empty.subHeadline')}</p>
      {!Config.EXCLUDE_SUPPORT && (
        <div>
          <p>
            <Button
              className="scc--boxlist__empty--support"
              type="primary"
              size="large"
              href={t('general.supportPortal')}
              target="_blank"
              rel="noreferrer"
              icon={<CustomerServiceOutlined />}
            >
              {t('sidebar.supportLinkText')}
            </Button>
          </p>

          <a
            href={t('general.website')}
            className="silent"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('general.website')}
          </a>
        </div>
      )}
    </div>
  )
}

export default EmptyBoxListText
