export enum ESolutionType {
  TRAFFIC = 'TRAFFIC',
  PARKING = 'PARKING',
  GENERIC = 'GENERIC'
}

export enum EWidgetType {
  TrafficCounting = 'TrafficCounting',
  OriginDestinationAnalysis = 'OriginDestinationAnalysis',
  SpeedEstimation = 'SpeedEstimation',
  CurrentParkingUtilization = 'CurrentParkingUtilization',
  HistoricParkingUtilization = 'HistoricParkingUtilization',
  EntryExitFrequency = 'EntryExitFrequency',
  ParkingTime = 'ParkingTime',
  OccupancyState = 'OccupancyState',
  HistoricParkingTime = 'HistoricParkingTime',
  ParkingTimeViolations = 'ParkingTimeViolations',
  Generic = 'Generic',
  RuleTrigger = 'RuleTrigger',
  JourneyTimes = 'JourneyTimes',
  JourneyDistributions = 'JourneyDistributions',
  LicensePlates = 'LicensePlates'
}
