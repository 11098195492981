import { useTranslation } from 'react-i18next'
import { Button, Input, Result, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  BugOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  SearchOutlined
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../redux/store/configureStore'
import { IControlCenterUser } from '../../types/controlCenterUser'
import { callFetchUsers } from '../../redux/actions/user'
import Spacer from '../Spacer'
import { UserRole } from '../../types/user_role'

type UserListViewProps = {
  addUser: () => void
  deleteUser: (userId: IControlCenterUser) => void
  changePermissionsForUser: (user: IControlCenterUser) => void
}

const UserList = ({
  addUser,
  deleteUser,
  changePermissionsForUser
}: UserListViewProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [filteredInfo, setFilteredInfo] = useState<any>(null)

  useEffect(() => {
    dispatch(callFetchUsers())
  }, [dispatch])

  const { users, loading, error } = useSelector((state: ReduxStore) => ({
    error: state.users.error,
    users: state.users.users,
    loading: state.users.loading
  }))

  if (error) {
    return (
      <Result
        title="An error occured"
        icon={<BugOutlined />}
        subTitle="Sorry, could not load users."
      />
    )
  }

  let clearAll = () => {
    handleChange(null, null, null)
  }
  let handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)
  }

  let searchInput: Input | null = null

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('boxList.dataTable.search.submit')}
          </Button>
          <Button onClick={() => clearFilters()} type="link" size="small">
            {t('boxList.dataTable.search.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? 'scc--boxlist--searched' : ''} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: (filteredInfo && filteredInfo[dataIndex]) || null,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100)
      }
    },
    render: (text) => text
  })

  const dataSource: any = []
  if (!loading && !error && users) {
    users.forEach((user: IControlCenterUser) => {
      dataSource.push({
        key: user.id,
        name: user.userDisplayName,
        email: user.email,
        role: user.userRole.roleName,
        action: user.isEditable ? (
          <>
            <Button
              className="scc--table--action"
              title={t('draw.dataTable.actions.edit')}
              icon={<EditOutlined />}
              onClick={() => {
                changePermissionsForUser(user)
              }}
            />
            <Spacer width={5} />
            <Button
              className="scc--table--action"
              icon={<DeleteOutlined />}
              title={t('draw.dataTable.actions.delete')}
              onClick={() => {
                deleteUser(user)
              }}
            />
          </>
        ) : null
      })
    })
  }
  const columns = [
    {
      title: t('users.list.headers.name'),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      defaultSortOrder: 'ascend' as 'ascend', //typescript is awesome
      ...getColumnSearchProps('name'),
      sorter: (a, b) => {
        let nameA = a.name.toLowerCase()
        let nameB = b.name.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    },
    {
      title: t('users.list.headers.email'),
      ...getColumnSearchProps('email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('users.list.headers.userrole'),
      dataIndex: 'role',
      key: 'role',
      filters: [
        {
          text: t(`users.list.headers.roles.${UserRole.SuperAdmin}`),
          value: UserRole.SuperAdmin
        },
        {
          text: t(`users.list.headers.roles.${UserRole.Admin}`),
          value: UserRole.Admin
        },
        {
          text: t(`users.list.headers.roles.${UserRole.User}`),
          value: UserRole.User
        },
        {
          text: t(`users.list.headers.roles.${UserRole.Viewer}`),
          value: UserRole.Viewer
        }
      ],
      // Intended comparison via string equality
      // eslint-disable-next-line eqeqeq
      onFilter: (value: any, record: any) => record.role == value,
      filteredValue: (filteredInfo && filteredInfo['role']) || null
    },
    {
      title: t('users.list.headers.action'),
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      align: 'center' as 'center'
    }
  ]

  let isFiltered = false
  filteredInfo &&
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key]) {
        isFiltered = true
      }
    })
  return (
    <div className="scc--scenelist--container">
      <div className="bx--grid bx--grid--full-width">
        <div className="bx--row">
          <div className="bx--col">
            {isFiltered && (
              <div className="bx--col scc--boxlist--filterwarning">
                <span>
                  <FilterOutlined /> {t('boxList.filterWarning.text')}
                </span>
                <Button onClick={clearAll} size={'small'} type="link">
                  {t('boxList.filterWarning.button')}
                </Button>
              </div>
            )}
            <Button
              className="scc--solutions--button"
              type="primary"
              size="large"
              style={{
                width: 160
              }}
              onClick={() => {
                addUser()
              }}
            >
              {t('users.add')}
            </Button>
          </div>
        </div>
        <Table
          rowClassName={'scc--tablerow'}
          columns={columns}
          dataSource={dataSource}
          locale={{ emptyText: t('users.list.empty') }}
          pagination={{ defaultPageSize: 10 }}
          loading={loading}
          onChange={handleChange}
        ></Table>
      </div>
    </div>
  )
}

export default UserList
