import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'

export const RuleSelection = ({
  rules,
  newRules,
  handleRuleTriggersChange
}) => {
  const { t } = useTranslation()
  const { Option } = Select

  return (
    <Select
      optionFilterProp="label"
      allowClear
      value={newRules}
      onChange={handleRuleTriggersChange}
      key="input"
      placeholder={t('solutions.scenes.overview.add.data.rule.addPlaceholder')}
      style={{ width: '100%' }}
      mode="multiple"
    >
      <Option value="default" key="default" disabled>
        {rules && rules.length > 0 ? (
          <>
            <span className="scc--solutions--add-stream-option">
              {t('solutions.scenes.overview.add.data.rule.addLegendRule')}
            </span>
            {t('solutions.scenes.overview.add.data.rule.addLegendStream')}
            {t('solutions.scenes.overview.add.data.rule.addLegendDevice')}
          </>
        ) : (
          t('solutions.scenes.overview.add.data.rule.emptyLegend')
        )}
      </Option>

      {rules &&
        rules.map((rule) => {
          let streamName =
            rule.streamName && rule.streamName.length
              ? rule.streamName
              : t('configuration.group.stream.streamname.placeholder')
          return (
            <Option
              label={`${rule.ruleId} - ${rule.ruleName} - ${streamName} - ${rule.boxName}`}
              value={rule.ruleId}
              key={rule.ruleId}
            >
              <span className="scc--solutions--add-stream-option">
                {rule.ruleName}
              </span>
              &nbsp;- {streamName}
              &nbsp;- {rule.boxName}
            </Option>
          )
        })}
    </Select>
  )
}
