import React, { FunctionComponent, memo, useCallback, useState } from 'react'
import './ResultSetOverride'
import PropTypes from 'prop-types'
import { useCubeQuery } from '@cubejs-client/react'

import { Col, Empty, Row, Slider, Space, Statistic, Switch, Table } from 'antd'
import { ResponsiveChord } from '@nivo/chord'
import { ComputedCell, ResponsiveHeatMap } from '@nivo/heatmap'
import { Chip, TableTooltip as ChordTooltip } from '@nivo/tooltip'
import { useTheme } from '@nivo/core'
import '../../solutions/components/SceneOverview/SceneOverview.scss'

import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ReferenceArea,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { ChartPivotRow, PivotConfig, ResultSet } from '@cubejs-client/core'
import moment from 'moment-timezone'
import LoadingAnimation from '../../components/LoadingAnimation'
import {
  addCustomRenderMethods,
  AnprSampleExport,
  calculateTimeSeriesHeatmap,
  CSVExport,
  dataSizeFormatter,
  dateFormatter,
  durationParser,
  DurationUnit,
  fillMissingTimeslotValuesWithZero,
  getFormattedTableData,
  renderActiveShape,
  smallestToLargestUnitDurationFormatter
} from './ChartRendererHelper'
import { useTranslation } from 'react-i18next'
import { DatabaseOutlined } from '@ant-design/icons'
import { EWidgetType } from '../../types/solution'
import Scrollbars from 'react-custom-scrollbars-2'
import { DefaultLegendContent } from 'recharts/lib/component/DefaultLegendContent'
import { getAreaChartSeriesName } from '../../components/DeviceHealth/DeviceHealthWidgetConfigs'
import { AxisDomainItem } from 'recharts/types/util/types'

const WIDGET_DATAPOINTS_LIMIT = 750 // around 31 days

const getCustomizedXAxisTick = (granularity, timezone, useTimestampOnly?) => {
  return ({ x, y, stroke, payload }) => {
    return (
      <Text
        x={x}
        y={y}
        width={5}
        fontSize="0.90em"
        textAnchor="middle"
        verticalAnchor="start"
      >
        {xAxisTickFormatter(
          granularity,
          payload.value,
          timezone,
          useTimestampOnly
        )}
      </Text>
    )
  }
}

const getFormattedNumber = (value, seriesKey) => {
  if (seriesKey && seriesKey.includes('DeviceUptime.uptime')) {
    return smallestToLargestUnitDurationFormatter(
      moment.duration(value.totalRow()[seriesKey], 'seconds'),
      DurationUnit.Second,
      DurationUnit.Day
    )
  } else if (seriesKey && seriesKey.includes('DeviceUptime.last_seen')) {
    return (
      smallestToLargestUnitDurationFormatter(
        moment.duration(value.totalRow()[seriesKey], 'seconds'),
        DurationUnit.Second,
        DurationUnit.Day
      ) + ' ago'
    )
  }
  return value.totalRow()[seriesKey]
}

const getCustomizedYAxisTickFormatter = (measures, pivotConfig, isTooltip) => {
  if (measures.every((measure) => measure.startsWith('JourneySegments'))) {
    return (seconds) => Math.floor(seconds / 60) + 'm' + (seconds % 60) + 's'
  }
  if (pivotConfig && pivotConfig.y) {
    if (
      pivotConfig.y.every((key) => key.toLowerCase().includes('percentage'))
    ) {
      return (value) => value + '%'
    } else if (
      pivotConfig.y.every(
        (key) => key.includes('modemUpload') || key.includes('modemDownload')
      )
    ) {
      return dataSizeFormatter
    } else if (
      pivotConfig.y.length === 1 &&
      (pivotConfig.y[0] === 'DeviceHealthEvents.restarts_detected' ||
        pivotConfig.y[0] === 'DeviceHealthEvents.modemReconnectsDetected')
    ) {
      return (value) => {
        if (value === 0) {
          return 'No'
        } else if (value === 1) {
          return 'Yes'
        }
        return ''
      }
    } else if (
      pivotConfig.y.length === 1 &&
      pivotConfig.y[0] === 'DeviceHealthEvents.modemSignalStrength'
    ) {
      if (isTooltip) {
        return (value: number) => {
          if (value === 1) {
            return 'Poor'
          } else if (value === 2) {
            return 'Fair'
          } else if (value === 3) {
            return 'Medium'
          } else if (value === 4) {
            return 'Good'
          } else if (value === 5) {
            return 'Excellent'
          } else {
            return ''
          }
        }
      } else {
        return () => ''
      }
    } else if (
      pivotConfig.y.length === 1 &&
      pivotConfig.y[0] === 'DeviceHealthEvents.temperature'
    ) {
      if (isTooltip) {
        return (value) => {
          if (value <= 90) {
            return value + '°C (full performance - no throttling)'
          } else if (value <= 95) {
            return value + '°C (full performance - soon throttled)'
          } else {
            return value + '°C (reduced performance - throttled)'
          }
        }
      } else {
        return (value) => value + '°C'
      }
    }
  }
}

const getYAxisDomain: (PivotConfig) => [AxisDomainItem, AxisDomainItem] = (
  pivotConfig
) => {
  if (pivotConfig && pivotConfig.y) {
    if (pivotConfig.y.length === 1) {
      if (
        pivotConfig.y[0] === 'DeviceHealthEvents.restarts_detected' ||
        pivotConfig.y[0] === 'DeviceHealthEvents.modemReconnectsDetected'
      ) {
        return [0, 1]
      } else if (pivotConfig.y[0] === 'DeviceHealthEvents.temperature') {
        return ['auto', 'auto']
      } else if (
        pivotConfig.y[0] === 'DeviceHealthEvents.modemSignalStrength'
      ) {
        return [0, 6]
      }
    }
    if (pivotConfig.y.every((key) => key.includes('percentage'))) {
      return [0, 100]
    }
  }
  return [0, 'auto']
}

const xAxisTickFormatter = (granularity, item, timezone, useTimestampOnly) => {
  if (moment(item).isValid()) {
    return dateFormatter(granularity, item, timezone)
  } else if (useTimestampOnly) {
    return item
      .split(',')
      .filter((x) => moment(x).isValid())
      .map((x) => dateFormatter(granularity, x, timezone))
      .join(' ')
  } else {
    return item.split(',').join(' ')
  }
}

const getLabelFormatter = (granularity, timezone, useTimestampOnly) => {
  return (item) => {
    if (moment(item).isValid()) {
      return dateFormatter(granularity, item, timezone)
    } else if (useTimestampOnly) {
      return item
        .split(',')
        .filter((x) => moment(x).isValid())
        .map((x) => dateFormatter(granularity, x, timezone))
        .join(' ')
    } else {
      return item
    }
  }
}

type CartesianChartProps = {
  ChartComponent: any
  resultSet: ResultSet
  pivotConfig?: PivotConfig
  dataFilters?: [string]
}

const scrollableLegend = (props) => {
  const newProps = { ...props }
  return (
    <Scrollbars autoHeight autoHeightMax={100}>
      <DefaultLegendContent {...newProps} />
    </Scrollbars>
  )
}

const CartesianChart: FunctionComponent<CartesianChartProps> = ({
  resultSet,
  children,
  ChartComponent,
  pivotConfig,
  dataFilters
}) => {
  const granularity =
    resultSet['loadResponse'].pivotQuery.timeDimensions[0].granularity

  let data: ChartPivotRow[] = resultSet.chartPivot(pivotConfig)

  const isCategorigal =
    data &&
    data.length > 0 &&
    !(
      moment(data[0]['x']).isValid() ||
      (data[0]['x'].includes(',') &&
        data[0]['x'].split(',').some((x) => moment(x).isValid()))
    )

  const isDeviceHealth = resultSet
    .query()
    ?.measures?.some(
      (measure) =>
        measure.startsWith('DeviceHealthEvents') ||
        measure.startsWith('StreamHealthEvents')
    )

  if (dataFilters) {
    data = data.filter((item) => {
      return dataFilters.every((filter) =>
        item.xValues.some(
          (value) => value.toLowerCase() === filter.toLowerCase()
        )
      )
    })
  }

  if (
    pivotConfig &&
    pivotConfig.fillMissingDates &&
    pivotConfig.x &&
    pivotConfig.x.length > 1
  ) {
    data = fillMissingTimeslotValuesWithZero(
      resultSet,
      data,
      granularity,
      pivotConfig
    )
  }

  return (
    <>
      <ResponsiveContainer height={350}>
        <ChartComponent data={data}>
          <XAxis
            dataKey="x"
            minTickGap={20}
            tick={getCustomizedXAxisTick(
              granularity,
              resultSet.query().timezone,
              !!dataFilters
            )}
            height={40}
            interval={isCategorigal ? 0 : 'preserveStartEnd'}
          />
          <YAxis
            style={isDeviceHealth ? { fontSize: '0.8em' } : {}}
            tickFormatter={getCustomizedYAxisTickFormatter(
              resultSet.query().measures,
              pivotConfig,
              false
            )}
            domain={getYAxisDomain(pivotConfig)}
          />
          <CartesianGrid />
          {children}
          {!isDeviceHealth ||
            (pivotConfig?.y?.length !== 1 && (
              <Legend
                content={scrollableLegend}
                wrapperStyle={{
                  height: '15%'
                }}
                iconSize={20}
                iconType="plainline"
              />
            ))}
          <Tooltip
            labelFormatter={getLabelFormatter(
              granularity,
              resultSet.query().timezone,
              !!dataFilters
            )}
            formatter={getCustomizedYAxisTickFormatter(
              resultSet.query().measures,
              pivotConfig,
              true
            )}
            cursor={{ stroke: 'none', fill: 'rgba(211,211,211,0.8' }}
          />
          {isDeviceHealth && <Brush height={20} tickFormatter={() => ''} />}
        </ChartComponent>
      </ResponsiveContainer>
      {!isDeviceHealth && <CSVExport resultSet={resultSet} />}
    </>
  )
}

type OccupancyChartProps = {
  resultSet: ResultSet
}

const OccupancyChart: FunctionComponent<OccupancyChartProps> = ({
  resultSet
}) => {
  const rawData = resultSet.tablePivot()
  if (rawData.length === 0) {
    return <Empty />
  }
  const nameKey = 'SpacebasedParkingUtilization.roiName'
  const occupiedKey = 'SpacebasedParkingUtilization.occupied'
  const capacityKey = 'SpacebasedParkingUtilization.capacity'

  rawData.sort((a, b) =>
    a[nameKey].toString().localeCompare(b[nameKey].toString())
  )

  //max 10 column
  const maxColumns = 10
  const blocksColumns = Math.min(
    Math.ceil(Math.sqrt(rawData.length)),
    maxColumns
  )
  const blocksRows = Math.ceil(rawData.length / blocksColumns)
  let chartData: any[] = []
  let dataIndex = 0
  for (let rowIndex = 1; rowIndex <= blocksRows; rowIndex++) {
    let data: any[] = []
    let chartRow = {
      id: rowIndex,
      data: data
    }
    for (let columnIndex = 1; columnIndex <= blocksColumns; columnIndex++) {
      if (dataIndex > rawData.length - 1) {
        chartRow.data.push({
          x: columnIndex
        })
        continue
      }

      const entry = rawData[dataIndex]
      let occ = entry[occupiedKey] ? (entry[occupiedKey] as number) : 0
      let capacity = entry[capacityKey] ? (entry[capacityKey] as number) : 0
      let usage
      if (capacity === 0 && occ > 0) {
        usage = 100.0
        capacity = 0
      } else if (capacity === 0 && occ === 0) {
        usage = 0.0
        capacity = 0
        occ = 0
      } else {
        usage = (occ / capacity) * 100.0
      }
      let dataEntry = {
        x: columnIndex,
        y: usage,
        capacity: capacity as number,
        occupancy: occ as number,
        name: entry[nameKey] as string
      }
      chartRow.data.push(dataEntry)
      dataIndex = dataIndex + 1
    }
    chartData.push(chartRow)
  }

  const parkingLabel = (value) => {
    if (!value.data.name) {
      return ''
    }
    if (value.data.name.length > 15) {
      return value.data.name.substring(0, 3) + '...'
    } else {
      return value.data.name
    }
  }

  const OccupancyTooltip = ({ cell }: { cell: ComputedCell<any> }) => {
    return (
      <div
        style={{
          backgroundColor: '#ffffff',
          padding: '6px 9px',
          borderRadius: '2px',
          minWidth: '160px',
          boxShadow: '0 3px 5px rgba(0, 0, 0, .25)',
          whiteSpace: 'pre'
        }}
      >
        {'Name '}&nbsp;<strong>{cell.label}</strong>
        <br />
        {'Capacity '}&nbsp;<strong>{cell.data.capacity}</strong>
        <br />
        {'Occupancy '}&nbsp;<strong>{cell.formattedValue}</strong>
        {'%'}&nbsp;
        <br />
        {'Occupancy (absolute)'}&nbsp;<strong>{cell.data.occupancy}</strong>
        <br />
      </div>
    )
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={350}>
        <ResponsiveHeatMap
          data={chartData}
          margin={{ top: 10, right: 10, bottom: 60, left: 10 }}
          axisTop={null}
          axisLeft={null}
          axisRight={null}
          valueFormat=">-.2s"
          colors={{
            type: 'diverging',
            scheme: 'red_yellow_green',
            minValue: 100,
            maxValue: 0,
            divergeAt: 0.5
          }}
          emptyColor="#ffffff"
          labelTextColor="black"
          label={parkingLabel}
          opacity={0.9}
          inactiveOpacity={0.7}
          cellComponent={'circle'}
          forceSquare
          hoverTarget="cell"
          tooltip={OccupancyTooltip}
          legends={[
            {
              anchor: 'bottom',
              translateX: 0,
              translateY: 30,
              length: 300,
              thickness: 8,
              direction: 'row',
              tickPosition: 'after',
              tickSize: 3,
              tickSpacing: 4,
              tickOverlap: false,
              tickFormat: ' >-.2s',
              title: 'Occupancy %',
              titleAlign: 'start',
              titleOffset: 4
            }
          ]}
        />
      </ResponsiveContainer>
      <CSVExport resultSet={resultSet} />
    </>
  )
}

type HeatmapProps = {
  resultSet: ResultSet
}

const HeatmapChart: FunctionComponent<HeatmapProps> = ({ resultSet }) => {
  const [sliderIndex, setSliderIndex] = useState<number>(0)
  const [useRelativeValues, setUseRelativeValues] = useState(false)
  const { t } = useTranslation(['datadiscovery'])

  const timeDimensions = resultSet.query().timeDimensions
  const requestedDimensions = resultSet.query().dimensions
  const requestedMeasures = resultSet.query().measures

  const rawData = resultSet.rawData()

  // if we have no measures, no time dimension , and not exactly 2 dimension heatmap isn't usefully
  if (
    !timeDimensions ||
    timeDimensions.length === 0 ||
    !timeDimensions[0].dimension ||
    !requestedDimensions ||
    requestedDimensions.length !== 2 ||
    !requestedMeasures ||
    requestedMeasures.length !== 1
  ) {
    return <Empty />
  }

  const timeGranularity = timeDimensions[0].granularity

  let {
    absoluteTimeSeries,
    relativeTimeSeries,
    maxValue
  } = calculateTimeSeriesHeatmap(
    rawData,
    timeDimensions,
    requestedDimensions,
    requestedMeasures,
    t('widgets.heatmap.allDestinations'),
    t('widgets.heatmap.allOrigins')
  )

  if (!relativeTimeSeries || relativeTimeSeries.length === 0) {
    return <Empty />
  }

  const amountTimestamps = absoluteTimeSeries.length
  const cutLongWords = (value: string) => {
    if (value.length > 17) {
      return value.substring(0, 14) + '...'
    }
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={320}>
        <ResponsiveHeatMap
          data={
            useRelativeValues
              ? relativeTimeSeries[sliderIndex].data
              : absoluteTimeSeries[sliderIndex].data
          }
          margin={{ top: 40, right: 80, bottom: 0, left: 120 }}
          axisTop={{
            tickSize: 15,
            tickPadding: 5,
            tickRotation: -12,
            format: cutLongWords,
            legend: 'Destination',
            legendOffset: 9,
            legendPosition: 'middle'
          }}
          axisRight={null}
          axisLeft={{
            tickSize: 15,
            tickPadding: 5,
            tickRotation: 0,
            format: cutLongWords,
            legend: 'Origin',
            legendPosition: 'middle',
            legendOffset: 9
          }}
          colors={{
            type: 'sequential',
            scheme: 'greens',
            minValue: 0,
            maxValue: useRelativeValues ? 1 : maxValue
          }}
          xInnerPadding={0.1}
          yInnerPadding={0.1}
          xOuterPadding={0.3}
          yOuterPadding={0.3}
          borderColor="#000000"
          borderRadius={1}
          borderWidth={2}
          valueFormat={useRelativeValues ? ' >-.2~%' : ' >-.3~s'}
          emptyColor="#ffffff"
          labelTextColor="black"
          opacity={0.8}
          inactiveOpacity={0.3}
          cellComponent={'rect'}
          hoverTarget="rowColumn"
          legends={[
            {
              anchor: 'right',
              translateX: 20,
              translateY: 0,
              length: 200,
              thickness: 8,
              direction: 'column',
              tickPosition: 'after',
              tickSize: 3,
              tickSpacing: 8,
              tickOverlap: false,
              tickFormat: useRelativeValues ? ' >-.2~%' : ' >-.3~s',
              title: useRelativeValues
                ? t('widgets.heatmap.legendRelative')
                : t('widgets.heatmap.legendAbsolute'),
              titleAlign: 'start',
              titleOffset: 4
            }
          ]}
        />
      </ResponsiveContainer>
      <>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space direction="vertical" align="center" style={{ width: '100%' }}>
            <Switch
              className="scc--solutions--heatmap-toggle"
              onChange={setUseRelativeValues}
              checked={useRelativeValues}
              checkedChildren={t('widgets.heatmap.modeToggle.checked')}
              unCheckedChildren={t('widgets.heatmap.modeToggle.unchecked')}
            />
          </Space>
          {amountTimestamps > 1 && (
            <Slider
              style={{
                width: '70%',
                marginLeft: '15%',
                marginBottom: '20px'
              }}
              min={0}
              max={amountTimestamps - 1}
              value={sliderIndex}
              onChange={setSliderIndex}
              marks={{
                0: dateFormatter(
                  timeGranularity,
                  absoluteTimeSeries[0].id,
                  resultSet.query().timezone
                ),

                [amountTimestamps - 1]: dateFormatter(
                  timeGranularity,
                  absoluteTimeSeries[amountTimestamps - 1].id,
                  resultSet.query().timezone
                )
              }}
              included={false}
              tipFormatter={(i) =>
                `${dateFormatter(
                  timeGranularity,
                  absoluteTimeSeries[i!].id,
                  resultSet.query().timezone
                )}`
              }
            />
          )}
          <CSVExport resultSet={resultSet} />
        </Space>
      </>
    </>
  )
}

type UtilizationChartProps = {
  resultSet: ResultSet
}

const UtilizationChart: FunctionComponent<UtilizationChartProps> = ({
  resultSet
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )

  const chartKeys = ['free', 'occupied']
  const chartColors = { free: colors[0], occupied: colors[2] }
  const numberKey = 'capacity'

  let chartData: any = []
  let numberValue: any = undefined
  let numberTitle: any = undefined

  const rawData = resultSet.tablePivot()[0]
  Object.keys(rawData).forEach((key) => {
    let subKey = key.split('.')[1]
    if (chartKeys.includes(subKey)) {
      chartData.push({
        key: subKey,
        name: resultSet.annotation().measures[key]['shortTitle'],
        description: resultSet.annotation().measures[key]['description'],
        value: Math.max(parseInt(String(rawData[key])), 0),
        fill: chartColors[subKey]
      })
    }
    if (numberKey === subKey) {
      numberValue = rawData[key]
      numberTitle = resultSet.annotation().measures[key].shortTitle
    }
  })

  if (isNaN(chartData[0].value) || isNaN(chartData[1].value)) {
    return <Empty />
  }
  return (
    <>
      <ResponsiveContainer width="100%" height={250}>
        <PieChart>
          <Pie
            startAngle={180}
            endAngle={0}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={chartData}
            cy="80%"
            innerRadius="70%"
            outerRadius="100%"
            dataKey="value"
            onMouseEnter={onPieEnter}
          />
        </PieChart>
      </ResponsiveContainer>
      {numberValue && (
        <Row
          justify="center"
          align="middle"
          style={{
            height: '100%'
          }}
        >
          <Col>
            <Statistic
              title={numberTitle}
              value={numberValue}
              style={{ textAlign: 'center' }}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

// https://www.carbondesignsystem.com/data-visualization/color-palettes/
const colors = [
  '#00a86b',
  '#1192e8',
  '#9f1853',
  '#fa4d56',
  '#6929c4',
  '#002d9c',
  '#ee538b',
  '#b28600',
  '#009d9a',
  '#005d5d',
  '#012749',
  '#8a3800',
  '#a56eff'
]

const colorsArea = {
  percentage_of_DISABLED: '#808080',
  percentage_of_NOT_CONFIGURED: '#a56eff',
  percentage_of_NOT_OPERATIONAL: '#f80e1a',
  percentage_of_OPERATIONAL: '#00a86b',
  percentage_of_PENDING: '#1192e8',
  percentage_of_UNKNOWN: '#8a3800',
  percentage_of_WARNING: '#b28600',
  percentage_of_STANDBY: '#c9c9c9',
  restarts_detected: '#00a86b',
  modemReconnectsDetected: '#00a86b'
}

const dashes = ['', '12 2 6 2', '4 5', '15 2']

const TypeToChartComponent = {
  line: ({ resultSet }) => {
    // Special dash/color logic for journey time stuff
    if (
      resultSet
        .query()
        .measures.every((measure) => measure.startsWith('JourneySegments'))
    ) {
      // get requested-dimension and measures
      let requestedDimensions = resultSet.query().dimensions
      let requestedMeasures = resultSet.query().measures

      // if there is no dimension split or only 1 measure requested normal color coding is sufficient
      if (requestedDimensions.length > 0 && requestedMeasures.length > 1) {
        // get actual dimension splits that really are in the data
        let combinations = resultSet
          .pivot({
            x: requestedDimensions,
            y: ['JourneySegments.timestamp']
          })
          .map((pivotItem) => pivotItem.xValues)
        // if only 1 actual dimension split exists normal color coding is sufficient
        if (combinations.length > 1) {
          return (
            <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
              {resultSet.completeSeriesNames().map((series) => {
                let seriesYValues = [...series.yValues]
                // last value is the measure -> assign it to a dash index
                let dashIndex = requestedMeasures.indexOf(seriesYValues.pop())
                // find index if identical entry for series in combinations (including ordering)
                let strokeIndex = combinations.findIndex((combinations) =>
                  combinations.every(
                    (combination, index) => combination === seriesYValues[index]
                  )
                )
                return (
                  <Line
                    key={series.key}
                    dataKey={series.key}
                    name={series.title}
                    strokeDasharray={dashes[dashIndex]}
                    stroke={colors[strokeIndex]}
                    strokeWidth={2}
                  />
                )
              })}
            </CartesianChart>
          )
        }
      }
    }
    return (
      <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
        {resultSet.completeSeriesNames().map((series, i) => (
          <Line
            key={series.key}
            dataKey={series.key}
            name={series.title}
            stroke={colors[i]}
            strokeWidth={2}
          />
        ))}
      </CartesianChart>
    )
  },

  healthChartLine: ({ resultSet, pivotConfig, dataFilters }) => {
    return (
      <CartesianChart
        resultSet={resultSet}
        ChartComponent={LineChart}
        pivotConfig={pivotConfig}
        dataFilters={dataFilters}
      >
        {resultSet
          .tableColumns()
          .filter((name: { key: string | any[] }) =>
            pivotConfig
              ? pivotConfig.y.some((y: string) => name.key.includes(y))
              : true
          )
          .map((series, i) => (
            <Line
              key={series.key}
              dataKey={dataFilters ? pivotConfig.y[0] : series.key}
              name={
                pivotConfig &&
                pivotConfig.aliasSeries &&
                pivotConfig.aliasSeries.length > i
                  ? pivotConfig.aliasSeries[i]
                  : series.title
              }
              dot={false}
              connectNulls={true}
              stroke={colors[i]}
              strokeWidth={2}
            />
          ))}
        {/* Special logic for drawing reference areas in some device metrics */}
        {pivotConfig?.y?.length === 1 &&
          pivotConfig.y[0].includes('diskAvailablePercentage') &&
          [
            { y1: 0, y2: 3, color: 'red' },
            { y1: 3, y2: 100, color: 'green' }
          ].map((area, i) => (
            <ReferenceArea
              key={i + 'referenceAreaDisk'}
              y1={area.y1}
              y2={area.y2}
              strokeWidth={0}
              fill={area.color}
              fillOpacity={0.3}
              ifOverflow={'hidden'}
              isFront={false}
            />
          ))}
        {pivotConfig?.y?.length === 1 &&
          pivotConfig.y[0].includes('modemSignalStrength') &&
          [
            //  "Signal strength (1=weak to 5=strong)",see https://github.com/hal9000-swarm/watchdog-module/blob/a9fa4dcbfe6cd799775d1a5015d1c090e06c58af/src/modem.py#L68
            // we make bigger green and orange area for beauty reasons
            { y1: 0.5, y2: 2.5, color: 'red' }, // 2 space
            { y1: 2.5, y2: 3.5, color: 'orange' }, //1 space
            { y1: 3.5, y2: 5.5, color: 'green' } //2 space
          ].map((area, i) => (
            <ReferenceArea
              key={i + 'referenceAreaModemSignalStrength'}
              y1={area.y1}
              y2={area.y2}
              strokeWidth={0}
              fill={area.color}
              fillOpacity={0.3}
              ifOverflow={'hidden'}
              isFront={false}
            />
          ))}
        {pivotConfig?.y?.length === 1 &&
          pivotConfig.y[0].includes('temperature') &&
          [
            { y1: 95, y2: 150, color: 'red' },
            { y1: 90, y2: 95, color: 'orange' },
            { y1: -50, y2: 90, color: 'green' }
          ].map((area, i) => (
            <ReferenceArea
              key={i + 'referenceArea'}
              y1={area.y1}
              y2={area.y2}
              strokeWidth={0}
              fill={area.color}
              fillOpacity={0.3}
              ifOverflow={'hidden'}
              isFront={false}
            />
          ))}
      </CartesianChart>
    )
  },
  chord: ({ resultSet, pivotConfig }) => {
    const odData = resultSet.tablePivot(pivotConfig)
    const chordEndpointFields = resultSet
      .tableColumns(pivotConfig)
      .filter(
        (column) =>
          column.key === 'OriginDestinationEvents.exitZone' ||
          column.key === 'OriginDestinationEvents.entryZone'
      )
    const connectionValueFields = resultSet
      .tableColumns(pivotConfig)
      .filter((column) => column.key === 'OriginDestinationEvents.count')

    // wrong data setup. an OD chord visualzation is only useful between at least two nodes with a numeric connection
    if (
      !(chordEndpointFields.length === 2 && connectionValueFields.length === 1)
    ) {
      return <Empty />
    }

    //first build up all possible values for the key combinations
    let keys = new Set<string>()
    let values = new Map<string, Map<string, number>>()
    odData.forEach((entry) => {
      let firstEndpoint = chordEndpointFields[0]
      let secondEndpoint = chordEndpointFields[1]
      let firstEndpointValue = entry[firstEndpoint.key]
      let secondEndpointValue = entry[secondEndpoint.key]
      let connectionValue = Number(entry[connectionValueFields[0].key])

      keys.add(firstEndpointValue)
      keys.add(secondEndpointValue)

      if (!values.has(firstEndpointValue)) {
        values.set(firstEndpointValue, new Map<string, number>())
      }
      let assignmentMap = values.get(firstEndpointValue)
      if (assignmentMap && !assignmentMap.has(secondEndpointValue)) {
        assignmentMap.set(secondEndpointValue, connectionValue)
      }
    })

    //build flow matrix
    let keyArray = Array.from(keys.values())
    let valueMatrix: number[][] = [[]]
    for (let keyIndex = 0; keyIndex < keyArray.length; keyIndex++) {
      valueMatrix[keyIndex] = []
      let key = keyArray[keyIndex]
      for (
        let combinationIndex = 0;
        combinationIndex < keyArray.length;
        combinationIndex++
      ) {
        let combination = keyArray[combinationIndex]
        valueMatrix[keyIndex][combinationIndex] =
          values.get(key)?.get(combination) || 0
      }
    }

    //most nivo components do not have nice typescript definitions yet :(
    // @ts-ignore
    const ChordRibbonTooltip = memo(({ ribbon }) => {
      const theme = useTheme()
      return (
        <ChordTooltip
          // @ts-ignore
          theme={theme}
          rows={[
            [
              <Chip key="chip" color={ribbon.source.color} />,
              <strong key="id">
                {ribbon.source.label} / {ribbon.target.label}
              </strong>,
              ribbon.source.formattedValue
            ],
            [
              <Chip key="chip" color={ribbon.target.color} />,
              <strong key="id">
                {ribbon.target.label} / {ribbon.source.label}
              </strong>,
              ribbon.target.formattedValue
            ]
          ]}
        />
      )
    })

    if (keyArray) {
      // limit zone names to 50 characters
      keyArray = keyArray.map((item) => {
        if (item.length > 50) {
          return item.substring(0, 50) + '...'
        }
        return item
      })
    }

    return (
      <>
        {odData.length > 0 ? (
          <ResponsiveContainer width="95%" height={350}>
            <ResponsiveChord
              data={valueMatrix}
              keys={keyArray}
              margin={{ top: 0, right: 10, bottom: 75, left: 10 }}
              padAngle={0.02}
              enableLabel={false}
              innerRadiusRatio={0.96}
              innerRadiusOffset={0.02}
              ribbonTooltip={ChordRibbonTooltip}
              arcBorderWidth={1}
              arcBorderColor={{ from: 'color', modifiers: [['darker', 0.4]] }}
              ribbonOpacity={0.5}
              ribbonBorderWidth={1}
              ribbonBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.4]]
              }}
              colors={{ scheme: 'category10' }}
              isInteractive={true}
              activeArcOpacity={1}
              arcOpacity={0.25}
              activeRibbonOpacity={0.75}
              inactiveRibbonOpacity={0.25}
              animate={true}
              legends={[
                {
                  anchor: 'bottom',
                  direction: 'column',
                  justify: false,
                  translateX: -100,
                  translateY: 70,
                  itemWidth: 100,
                  itemHeight: 14,
                  itemsSpacing: 10,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  symbolSize: 12,
                  symbolShape: 'square',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000'
                      }
                    }
                  ]
                }
              ]}
            />
          </ResponsiveContainer>
        ) : (
          <Empty />
        )}

        <CSVExport resultSet={resultSet} />
      </>
    )
  },
  bar: ({ resultSet }) => {
    return (
      <CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
        {resultSet.completeSeriesNames().map((series, i) => (
          <Bar
            key={series.key}
            stackId="a"
            dataKey={series.key}
            name={series.title}
            fill={colors[i]}
          />
        ))}
      </CartesianChart>
    )
  },
  healthChartArea: ({ resultSet, pivotConfig, t, dataFilters }) => {
    let columns = pivotConfig
      ? resultSet.tableColumns().filter((s) => pivotConfig.y.includes(s.key))
      : resultSet.tableColumns()
    return (
      <CartesianChart
        resultSet={resultSet}
        ChartComponent={AreaChart}
        pivotConfig={pivotConfig}
        dataFilters={dataFilters}
      >
        {columns.map((series) => (
          <Area
            key={series.key}
            stackId="a"
            type={'step'}
            dataKey={series.key}
            name={getAreaChartSeriesName(series.key, t)}
            stroke={colorsArea[series.key.split('.')[1]]}
            strokeWidth={series.key.includes('percentage') ? 0 : 2}
            fill={colorsArea[series.key.split('.')[1]]}
            isAnimationActive={false}
          />
        ))}
      </CartesianChart>
    )
  },
  pie: ({ resultSet, pivotConfig, widgetTitle, t }) => {
    if (!resultSet.completeSeriesNames().length) {
      return <Empty />
    }
    return (
      <>
        <ResponsiveContainer width="95%" height={350}>
          {resultSet.chartPivot().length <= 16 ? (
            <PieChart>
              <Pie
                isAnimationActive={false}
                data={resultSet.chartPivot()}
                nameKey="x"
                dataKey={resultSet.completeSeriesNames()[0].key}
                fill="#8884d8"
              >
                {resultSet.chartPivot().map((e, index) => (
                  <Cell key={index} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Legend />
              <Tooltip />
            </PieChart>
          ) : (
            <p className={'scc--centered'}>{t('widgets.tooManyDimensions')}</p>
          )}
        </ResponsiveContainer>
        <CSVExport resultSet={resultSet} />
      </>
    )
  },
  number: ({ resultSet, pivotConfig }) => {
    return (
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100%'
        }}
      >
        <Col>
          {resultSet
            .completeSeriesNames()
            .filter(
              (s) =>
                !pivotConfig ||
                !pivotConfig.y ||
                pivotConfig.y[0] === 'measures' ||
                pivotConfig.y.includes(s.key)
            )
            .map((s, index) => (
              <Statistic
                key={s.key}
                value={
                  resultSet.totalRow()[s.key] !== null
                    ? getFormattedNumber(resultSet, s.key)
                    : 'No Data'
                }
                title={
                  pivotConfig &&
                  pivotConfig.aliasSeries &&
                  pivotConfig.aliasSeries.length > index
                    ? pivotConfig.aliasSeries[index]
                    : undefined
                }
              />
            ))}
        </Col>
      </Row>
    )
  },
  table: ({ resultSet, pivotConfig, widgetTitle }) => {
    let normalizedResult = getFormattedTableData(resultSet, pivotConfig)
    let tableColums = resultSet.tableColumns(pivotConfig)

    if (
      tableColums.some(
        (c) =>
          c.key === 'CrossingEvents.timestamp' ||
          c.key === 'CrossingEvents.plateImage'
      )
    ) {
      tableColums = tableColums.filter((c) => c.key !== 'CrossingEvents.count')
    }

    tableColums.map((c) => {
      c.ellipsis = {
        showTitle: false
      }
      c = addCustomRenderMethods(c)
      return c
    })

    return (
      <>
        <Table
          columns={tableColums}
          dataSource={normalizedResult}
          pagination={{
            position: ['bottomCenter'],
            pageSize: 10,
            showSizeChanger: false
          }}
          rowKey={(record) => JSON.stringify(record)}
        />
        <CSVExport resultSet={resultSet} />
        <AnprSampleExport resultSet={resultSet} widgetTitle={widgetTitle} />
      </>
    )
  },
  utilization: ({ resultSet }) => {
    return <UtilizationChart resultSet={resultSet} />
  },
  occupancymap: ({ resultSet }) => {
    return <OccupancyChart resultSet={resultSet} />
  },
  heatmap: ({ resultSet }) => {
    return <HeatmapChart resultSet={resultSet} />
  }
}
export const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key])
  }))
  .reduce((a, b) => ({ ...a, ...b }))

export const renderChart = (Component) => ({
  resultSet,
  error,
  pivotConfig,
  widgetTitle,
  t,
  dataFilters
}) =>
  (resultSet && (
    <Component
      resultSet={resultSet}
      pivotConfig={pivotConfig}
      widgetTitle={widgetTitle}
      t={t}
      dataFilters={dataFilters}
    />
  )) ||
  (error && error.toString()) || <LoadingAnimation />

interface IChartRenderer {
  vizState: any
  overrideTimeRange?: any
  overrideTimeZone?: any
  widgetTitle?: string // widget title is needed for the ANPR image download
  widgetType?: EWidgetType
}

const estimateDatapoints = function (timeDimension) {
  if (!timeDimension) return 0
  if (!timeDimension.granularity) return 0

  let duration = durationParser(timeDimension.dateRange)
  return Math.ceil(duration.as(timeDimension.granularity))
}

const ChartRenderer = (props: IChartRenderer) => {
  const { t } = useTranslation(['datadiscovery'])
  const { query, chartType, pivotConfig } = props.vizState

  if (props.overrideTimeRange) {
    query.timeDimensions.forEach((dimension) => {
      dimension.dateRange = props.overrideTimeRange
    })
  }
  query.timezone = props.overrideTimeZone ? props.overrideTimeZone : 'Etc/UTC'

  if (estimateDatapoints(query.timeDimensions[0]) > WIDGET_DATAPOINTS_LIMIT) {
    return (
      <div className="scc--datadiscovery--datapoints">
        <p>
          <DatabaseOutlined />
          &nbsp;
          {t('widgets.tooManyDatapoints')}
        </p>
        <p>{t('widgets.tooManyDatapointsHint')}</p>
      </div>
    )
  }

  return (
    <QubeRenderer
      widgetTitle={props.widgetTitle}
      query={query}
      chartType={chartType}
      pivotConfig={pivotConfig}
      widgetType={props.widgetType}
    />
  )
}

const QubeRenderer = ({
  widgetTitle,
  query,
  chartType,
  pivotConfig,
  widgetType
}) => {
  const { t } = useTranslation(['datadiscovery'])
  const component = TypeToMemoChartComponent[chartType]

  const renderProps = useCubeQuery(query, {
    subscribe:
      !!widgetType &&
      (widgetType === EWidgetType.CurrentParkingUtilization ||
        widgetType === EWidgetType.OccupancyState)
  })
  return (
    component &&
    renderChart(component)({
      ...renderProps,
      pivotConfig,
      widgetTitle,
      t,
      dataFilters: []
    })
  )
}

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object
}
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null
}
export default ChartRenderer
