// @ts-nocheck
/*
This   overrides  the pivot()-method from cubejs Resultset. To use this, simply import ResultSetOverride.

For this purpose it extracts some stuff, that is not availably otherwise (const / non exported methods).
All of this is inherently unsafe from a typing standpoint.
 */
import { PivotConfig, ResultSet } from '@cubejs-client/core'
import {
  groupBy,
  pipe,
  uniq,
  map,
  unnest,
  dropLast,
  equals,
  pluck,
  mergeAll,
  flatten
} from 'ramda'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

const groupByToPairs = (keyFn) => {
  const acc = new Map()

  return (data) => {
    data.forEach((row) => {
      const key = keyFn(row)

      if (!acc.has(key)) {
        acc.set(key, [])
      }

      acc.get(key).push(row)
    })

    return Array.from(acc.entries())
  }
}

export const QUERY_TYPE = {
  REGULAR_QUERY: 'regularQuery',
  COMPARE_DATE_RANGE_QUERY: 'compareDateRangeQuery',
  BLENDING_QUERY: 'blendingQuery'
}

ResultSet.prototype.completeAxisValuesString = function (
  axisValues,
  delimiter,
  isTitle
) {
  const formatValue = (v) => {
    if (v == null) {
      return v // overwrite from original ResultSet.js
    } else if (v === '') {
      return '[Empty string]'
    } else {
      return v
    }
  }
  let values = axisValues.map(formatValue)
  if (isTitle) {
    values = values.filter((n) => n) // remove empty elements
  }
  return values.join(delimiter || ', ')
}

ResultSet.prototype.completeSeriesNames = function (pivotConfig) {
  pivotConfig = this.normalizePivotConfig(pivotConfig)
  const measures = pipe(
    pluck('annotation'),
    pluck('measures'),
    mergeAll
  )(this.loadResponses)

  const seriesNames = unnest(
    this.loadResponses.map((_, index) =>
      pipe(
        map(this.axisValues(pivotConfig.y, index)),
        unnest,
        uniq
      )(this.timeDimensionBackwardCompatibleData(index))
    )
  )
  const duplicateMeasures = new Set()
  if (this.queryType === QUERY_TYPE.BLENDING_QUERY) {
    const allMeasures = flatten(
      this.loadResponses.map(({ query }) => query.measures)
    )
    allMeasures
      .filter((e, i, a) => a.indexOf(e) !== i)
      .forEach((m) => duplicateMeasures.add(m))
  }

  const aliasSeries = (yValues, i) => {
    if (pivotConfig && pivotConfig.aliasSeries && pivotConfig.aliasSeries[i]) {
      return [pivotConfig.aliasSeries[i], ...yValues]
    } else if (duplicateMeasures.has(yValues[0])) {
      return [i, ...yValues]
    }
    return yValues
  }

  return seriesNames.map((axisValues, i) => {
    const aliasedAxis = aliasSeries(axisValues, i)
    return {
      title: this.completeAxisValuesString(
        pivotConfig.y.find((d) => d === 'measures')
          ? dropLast(1, aliasedAxis).concat(
              measures[ResultSet.measureFromAxis(axisValues)].title
            )
          : aliasedAxis,
        ', ',
        true
      ),
      key: this.completeAxisValuesString(aliasedAxis, ','),
      yValues: axisValues
    }
  })
}

ResultSet.prototype.pivot = function (pivotConfig: PivotConfig | undefined) {
  pivotConfig = this.normalizePivotConfig(pivotConfig)
  const { pivotQuery: query } = this.loadResponse

  const pivotImpl = (resultIndex = 0) => {
    let groupByXAxis = groupByToPairs(({ xValues }) =>
      this.axisValuesString(xValues)
    )

    let measureValue = (row, measure) => row[measure]

    if (
      pivotConfig.fillMissingDates &&
      equals(
        pivotConfig.x,
        (query.timeDimensions || [])
          .filter((td) => !!td.granularity)
          .map((td) => ResultSet.timeDimensionMember(td))
      )
    ) {
      const series = this.loadResponses.map((loadResponse) => {
        if (loadResponse.query.timeDimensions.length) {
          let timeDimension = loadResponse.query.timeDimensions[0]
          let timeZone = loadResponse.query.timezone
          if (
            timeDimension.granularity === 'hour' &&
            timeDimension.dateRange[1] >
              moment().tz(timeZone).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
          ) {
            return this.timeSeries({
              ...loadResponse.query.timeDimensions[0],
              dateRange: [
                timeDimension.dateRange[0],
                moment().tz(timeZone).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
              ]
            })
          }
          return this.timeSeries(timeDimension)
        }
        return undefined
      })

      if (series && series[0]) {
        groupByXAxis = (rows) => {
          const byXValues = groupBy(
            ({ xValues }) =>
              moment(xValues[0]).format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
            rows
          )
          return series[resultIndex].map((d) => [
            d,
            byXValues[d] || [{ xValues: [d], row: {} }]
          ])
        }

        measureValue = (row, measure) => row[measure] || 0
      }
    }

    const xGrouped = pipe(
      map((row) =>
        this.axisValues(
          pivotConfig.x,
          resultIndex
        )(row).map((xValues) => ({ xValues, row }))
      ),
      unnest,
      groupByXAxis
    )(this.timeDimensionBackwardCompatibleData(resultIndex))

    const allYValues = pipe(
      map(([, rows]) =>
        unnest(
          rows.map(({ row }) =>
            this.axisValues(pivotConfig.y, resultIndex)(row)
          )
        )
      ),
      unnest,
      uniq
    )(xGrouped)

    return xGrouped.map(([, rows]) => {
      const { xValues } = rows[0]
      const yGrouped = pipe(
        map(({ row }) =>
          this.axisValues(
            pivotConfig.y,
            resultIndex
          )(row).map((yValues) => ({ yValues, row }))
        ),
        unnest,
        groupBy(({ yValues }) => this.axisValuesString(yValues))
      )(rows)
      return {
        xValues,
        yValuesArray: unnest(
          allYValues.map((yValues) => {
            const measure = pivotConfig.x.find((d) => d === 'measures')
              ? ResultSet.measureFromAxis(xValues)
              : ResultSet.measureFromAxis(yValues)
            return (
              yGrouped[this.axisValuesString(yValues)] || [{ row: {} }]
            ).map(({ row }) => [yValues, measureValue(row, measure)])
          })
        )
      }
    })
  }

  const pivots =
    this.loadResponses.length > 1
      ? this.loadResponses.map((_, index) => pivotImpl(index))
      : []

  return pivots.length
    ? this.mergePivots(pivots, pivotConfig.joinDateRange)
    : pivotImpl()
}
