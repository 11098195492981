import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { IScene } from '../../../types/scene'

import { Button, Input, Space, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { ESolutionType } from '../../../types/solution'
import NameIdCell from './cells/NameIdCell'
import DescriptionCell from './cells/DescriptionCell'
import SolutionTypeCell from './cells/SolutionTypeCell'

interface ISceneListProps {
  scenes: IScene[]
  scenesLoading: boolean
}

const SceneList: React.FC<ISceneListProps> = ({ scenes, scenesLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const [selectedRow, setSelectedRow] = useState<string | null>(null)

  function showSceneDetails(scene: IScene) {
    history.push(`/solutions/${scene.id}`)
  }

  useEffect(() => {
    let parts = location.pathname.split('/')
    if (parts.length < 3) {
      selectedRow && setSelectedRow(null)
    } else {
      let sceneId = parts[2]
      if (!selectedRow || selectedRow !== sceneId) {
        setSelectedRow(sceneId)
      }
    }
  }, [location.pathname, selectedRow])

  let searchInput: Input | null = null
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={t('boxList.dataTable.search.name')}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('boxList.dataTable.search.submit')}
          </Button>
          <Button onClick={() => clearFilters()} type="link" size="small">
            {t('boxList.dataTable.search.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined className={filtered ? 'scc--boxlist--searched' : ''} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].key
        ? record[dataIndex].key
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100)
      }
    },
    render: (text) => text
  })

  const data: any = []
  !scenesLoading &&
    scenes &&
    scenes.forEach((scene) => {
      data.push({
        key: scene.id,
        name_id: <NameIdCell scene={scene} key={scene.name + scene.id} />,
        description: <DescriptionCell scene={scene} key={scene.description} />,
        solution: <SolutionTypeCell scene={scene} key={scene.solutionType} />
      })
    })
  const columns = [
    {
      title: t('solutions.scenes.list.headers.name_id'),
      dataIndex: 'name_id',
      key: 'name_id',
      width: '30%',
      ...getColumnSearchProps('name_id'),
      sorter: (a, b) => {
        let nameA = a.name_id.key.toLowerCase()
        let nameB = b.name_id.key.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }
    },
    {
      title: t('solutions.scenes.list.headers.description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('solutions.scenes.list.headers.solution'),
      dataIndex: 'solution',
      key: 'solution',
      width: '10%',
      filters: [
        {
          text: t(
            `solutions.scenes.config.formSolutionType.values.${ESolutionType.TRAFFIC}`
          ),
          value: ESolutionType.TRAFFIC
        },
        {
          text: t(
            `solutions.scenes.config.formSolutionType.values.${ESolutionType.PARKING}`
          ),
          value: ESolutionType.PARKING
        },
        {
          text: t(
            `solutions.scenes.config.formSolutionType.values.${ESolutionType.GENERIC}`
          ),
          value: ESolutionType.GENERIC
        }
      ],
      onFilter: (value, record) => record.solution.key === value,
      sorter: (a, b) => {
        if (a.solution.key < b.solution.key) {
          return -1
        }
        if (a.solution.key > b.solution.key) {
          return 1
        }
        return 0
      }
    }
  ]

  return (
    <>
      <Table
        rowClassName={(record, index) =>
          record.key === selectedRow
            ? 'scc--tablerow scc--selected'
            : 'scc--tablerow'
        }
        columns={columns}
        dataSource={data}
        loading={scenesLoading}
        onRow={(row) => ({
          onClick: () => {
            const selectedBox = scenes.find((scene) => {
              return scene.id === row.key
            })
            if (!selectedBox) {
              return
            }
            showSceneDetails(selectedBox)
          }
        })}
        locale={{ emptyText: t('solutions.scenes.list.empty') }}
        pagination={{ defaultPageSize: 10 }}
      />
    </>
  )
}

export default SceneList
