import {
  BOX_EVENT_RULES_REQUEST,
  BOX_EVENT_RULES_REQUEST_FAILURE,
  BOX_EVENT_RULES_REQUEST_SUCCESS,
  BOX_EVENT_RULES_TEMPLATES_REQUEST,
  BOX_EVENT_RULES_TEMPLATES_REQUEST_FAILURE,
  BOX_EVENT_RULES_TEMPLATES_REQUEST_SUCCESS,
  DELETE_BOX_EVENT_RULES_REQUEST,
  DELETE_BOX_EVENT_RULES_REQUEST_FAILURE,
  DELETE_BOX_EVENT_RULES_REQUEST_SUCCESS,
  SAVE_BOX_EVENT_RULE_REQUEST,
  SAVE_BOX_EVENT_RULE_REQUEST_FAILURE,
  SAVE_BOX_EVENT_RULE_REQUEST_SUCCESS,
  SAVE_BOX_EVENT_RULES_REQUEST,
  SAVE_BOX_EVENT_RULES_REQUEST_FAILURE,
  SAVE_BOX_EVENT_RULES_REQUEST_SUCCESS
} from './actionTypes'
import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'

import axios from 'axios'
import { ITriggerRule, TriggerRule } from '../../types/triggerRule'

/**
 * ======================
 * EVENT RULE ACTIONS
 * ======================
 */

/**
 * Fetches all event rules for a specific box and stream from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 * @param boxId: string The uuid of the box to load event rules for.
 * @param streamId: string The uuid of the stream to load event rules for.
 */
const fetchStreamEventRules = (boxId, streamId) => ({
  [CALL_API]: {
    types: [
      BOX_EVENT_RULES_REQUEST,
      BOX_EVENT_RULES_REQUEST_SUCCESS,
      BOX_EVENT_RULES_REQUEST_FAILURE
    ],
    endpoint: `boxes/${boxId}/${streamId}/eventTriggers/rules`,
    schema: Schemas.EVENT_RULES
  }
})

/**
 * Fetches all event rule templates from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 */
const fetchStreamEventRuleTemplates = () => ({
  [CALL_API]: {
    types: [
      BOX_EVENT_RULES_TEMPLATES_REQUEST,
      BOX_EVENT_RULES_TEMPLATES_REQUEST_SUCCESS,
      BOX_EVENT_RULES_TEMPLATES_REQUEST_FAILURE
    ],
    endpoint: `boxes/eventTriggers/ruleTemplates`,
    schema: Schemas.EVENT_RULES
  }
})

/**
 * Fetches all event rules for a specific box and stream from the API.
 */
export const loadStreamEventRules = (boxId, streamId) => (dispatch) => {
  if (!boxId || !streamId) {
    return null
  }
  return dispatch(fetchStreamEventRules(boxId, streamId)).then(
    (data) => data.response.entities.eventRules
  )
}

/**
 * Fetches all event rule templates from the API.
 */
export const loadStreamEventRulesTemplates = () => (dispatch) => {
  return dispatch(fetchStreamEventRuleTemplates()).then(
    (data) => data.response.entities.eventRules
  )
}

export const saveStreamEventRule = (
  boxId: string,
  streamId: string,
  eventRule: TriggerRule
) => {
  const transformedEventTrigger = eventRule.getRequestObject()
  return {
    [CALL_API]: {
      types: [
        SAVE_BOX_EVENT_RULE_REQUEST,
        SAVE_BOX_EVENT_RULE_REQUEST_SUCCESS,
        SAVE_BOX_EVENT_RULE_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.PUT,
      payload: transformedEventTrigger,
      endpoint: `boxes/${boxId}/${streamId}/eventTriggers/rules/${eventRule.localId}`,
      schema: Schemas.EVENT_RULES
    }
  }
}

/**
 * Saves a set of event rules.
 * @param boxId string The uuid of the box to save the event rules to.
 * @param streamId string The uuid of the stream to save the event rules to.
 * @param eventRules ITriggerRule[] The set of event rules to save.
 */
export const saveStreamEventRules = (
  boxId: string,
  streamId: string,
  eventRules: TriggerRule[]
) => (dispatch) => {
  dispatch({
    type: SAVE_BOX_EVENT_RULES_REQUEST
  })

  // Save only triggers that have changed
  let dispatchedEventTriggers = eventRules
    .filter((eventRule) => eventRule.hasChanged)
    .map((eventRule) =>
      dispatch(saveStreamEventRule(boxId, streamId, eventRule))
    )

  return axios
    .all(dispatchedEventTriggers)
    .then(() =>
      dispatch({
        type: SAVE_BOX_EVENT_RULES_REQUEST_SUCCESS
      })
    )
    .catch((error) => {
      dispatch({
        type: SAVE_BOX_EVENT_RULES_REQUEST_FAILURE,
        error
      })
    })
}

/**
 * Deletes an event rule.
 * @param boxId string The uuid of the box to delete the event rule from.
 * @param streamId string The uuid of the stream to delete the event rule from.
 * @param eventRule ITriggerRule The event rule to delete
 */
export const deleteStreamEventRule = (
  boxId: string,
  streamId: string,
  eventRule: ITriggerRule
) => {
  // Delete the event rule locally if it has not yet been saved
  // The ID is only set if the object has been saved via API request
  if (!eventRule.id) {
    return {
      type: DELETE_BOX_EVENT_RULES_REQUEST_SUCCESS,
      response: { id: eventRule.localId }
    }
  }

  return {
    [CALL_API]: {
      types: [
        DELETE_BOX_EVENT_RULES_REQUEST,
        DELETE_BOX_EVENT_RULES_REQUEST_SUCCESS,
        DELETE_BOX_EVENT_RULES_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.DELETE,
      endpoint: `boxes/${boxId}/${streamId}/eventTriggers/rules/${eventRule.id}`,
      schema: Schemas.EVENT_RULES,
      id: eventRule.id
    }
  }
}
