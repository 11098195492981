import React from 'react'
import { IScene } from '../../../../types/scene'

interface IProps {
  scene: IScene
}

const DescriptionCell: React.FC<IProps> = ({ scene }) => {
  return <span>{scene.description}</span>
}

export default DescriptionCell
