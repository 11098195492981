export class TenantInfo {
  isMainTenant: boolean
  tenantName: string
  userRoles?: string[]
  tenantId: string

  constructor(props: {
    isMainTenant: boolean
    tenantName: string
    userRoles: string[] | undefined
    tenantId: string
  }) {
    this.isMainTenant = props.isMainTenant
    this.tenantName = props.tenantName
    this.userRoles = props.userRoles
    this.tenantId = props.tenantId
  }
}
