import '../components/SceneList/SceneList.scss'

import React, { Component } from 'react'
import { IScene } from '../../types/scene'
import { AzureMapsProvider } from 'react-azure-maps'
import SceneMap from '../components/SceneMap'

interface ISceneListView {
  scenes: IScene[]
}

class SceneMapView extends Component<ISceneListView> {
  render() {
    return (
      <AzureMapsProvider>
        <div style={{ height: '800px' }}>
          <SceneMap scenes={this.props.scenes} />
        </div>
      </AzureMapsProvider>
    )
  }
}

export default SceneMapView
