import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ServerError,
  ServerParseError
} from '@apollo/client'
import { Config } from '../../services/config'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getTokenInformation } from '../../services/apiTokenProvider'
import { Observable } from 'apollo-client/util/Observable'

const httpLink = createHttpLink({
  uri: Config.API_ROOT + 'graphql'
})

const httpLinkGet = createHttpLink({
  uri: Config.API_ROOT + 'graphql',
  useGETForQueries: true
})

// cached storage for the user token
let token
const authLink = (additionalHeaders) =>
  setContext((_, { headers }) => {
    if (token) {
      return {
        headers: {
          ...additionalHeaders,
          ...headers,
          authorization: `Bearer ${token}`
        }
      }
    }

    return getTokenInformation().then((tokenInformation) => {
      token = tokenInformation.idToken
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`
        }
      }
    })
  })

const promiseToObservable = (promise) => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return
        }
        subscriber.next(value)
        subscriber.complete()
      },
      (err) => {
        subscriber.error(err)
      }
    )
  })
}

// @ts-ignore https://github.com/apollographql/apollo-link/issues/646 (also applies to following)
const resetToken = (additionalHeaders) =>
  // @ts-ignore
  onError(({ networkError, operation, forward }) => {
    if (!networkError) return
    if (
      (networkError.name === 'ServerError' &&
        (networkError as ServerError).statusCode === 401) ||
      (networkError.name === 'ServerParseError' &&
        (networkError as ServerParseError).statusCode === 401)
    ) {
      let oldHeaders = operation.getContext().headers
      if (additionalHeaders) {
        oldHeaders = { ...oldHeaders, ...additionalHeaders }
      }

      const promise = getTokenInformation().then((info) => info.idToken)
      // @ts-ignore
      return promiseToObservable(promise).flatMap((newToken) => {
        token = newToken
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: newToken
          }
        })
        // retry the request, returning the new observable
        return forward(operation)
      })
    }
  })

const link = (additionalHeaders) =>
  resetToken(additionalHeaders)
    .concat(authLink(additionalHeaders))
    .concat(httpLink)
const linkGet = (additionalHeaders) =>
  resetToken(additionalHeaders)
    .concat(authLink(additionalHeaders))
    .concat(httpLinkGet)

const apollo = (additionalHeaders) => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: link(additionalHeaders)
  })
}

export const apolloGet = (additionalHeaders) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: linkGet(additionalHeaders)
  })

export default apollo
