import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../redux/store/configureStore'
import React, { useEffect, useState } from 'react'
import { callFetchLicenseStatus } from '../../redux/actions/licenses'
import { useTranslation } from 'react-i18next'

const LicenseWarning: React.FC = () => {
  const { t } = useTranslation()
  const { licenseStatus } = useSelector((state: ReduxStore) => ({
    licenseStatus: state.licenses.status
  }))
  const dispatch: any = useDispatch()
  const [messageVisible, setMessageVisible] = useState<boolean>(false)

  useEffect(() => {
    dispatch(callFetchLicenseStatus())
  }, [dispatch])

  useEffect(() => {
    if (!licenseStatus) {
      return
    }
    const licenseExceededMessage = () => {
      message.warning({
        key: 'licenseExceeded',
        content: (
          <span
            dangerouslySetInnerHTML={{
              __html: t('licenses.exceeded')
            }}
          ></span>
        ),
        duration: 0 // infinite
      })
    }

    if (!licenseStatus.licenseValid) {
      if (messageVisible) {
        return
      }
      setMessageVisible(true)
      licenseExceededMessage()
    } else {
      if (!messageVisible) {
        return
      }
      setMessageVisible(false)
      message.destroy('licenseExceeded')
    }
  }, [licenseStatus, t, messageVisible])

  return <></>
}

export default LicenseWarning
