import { Col, Row, Space } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import LicenseList from './LicenseList'

const LicenseManagementPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Row>
          <Col>
            <h3>{t('licenses.title')}</h3>
          </Col>
        </Row>
        <LicenseList />
      </Space>
    </>
  )
}

export default LicenseManagementPage
