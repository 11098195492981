import { uuid } from 'lodash-uuid'

export interface IMonitoringRecipient {
  email: string
}

export interface IMonitoringAlert {
  id: string
  name?: string
  description?: string
  triggers: AlertType[]
  autoResolve: boolean
  boxes: string[]
  recipients: IMonitoringRecipient[]
}

export enum AlertType {
  DEVICE_OFFLINE = 'deviceOffline',
  DEVICE_NOT_OPERATIONAL = 'deviceNok',
  DEVICE_WARNING = 'deviceWarning'
}

export class MonitoringAlert implements IMonitoringAlert {
  constructor(props) {
    this.id = props.id
    this.name = props.name || ''
    this.description = props.template || ''
    this.triggers = props.triggers
    this.autoResolve = props.autoResolve
    this.recipients = props.recipients
    this.boxes = props.boxes
  }

  public static default(): MonitoringAlert {
    return new MonitoringAlert({
      id: uuid(),
      name: '',
      description: '',
      triggers: [],
      autoResolve: false,
      recipients: [],
      boxes: []
    })
  }

  autoResolve: boolean
  description: string
  id: string
  name: string
  triggers: AlertType[]
  boxes: string[]
  recipients: IMonitoringRecipient[]
}
