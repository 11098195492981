import { Image } from 'react-konva'
import React, { useEffect, useState } from 'react'

interface ICameraFeedImageProps {
  image: HTMLImageElement
  width: number
  height: number
}

const CameraFeedImage: React.FC<ICameraFeedImageProps> = ({
  image,
  width,
  height
}) => {
  const [konvaImage, setKonvaImage] = useState<HTMLImageElement | null>(null)

  useEffect(() => {
    // Directly use the input HTML image
    setKonvaImage(image)
  }, [image])

  //we must only update the konva image component, once the html image is completely loaded. otherwise konva breaks on ff
  if (!konvaImage) {
    return null
  }

  return <Image image={konvaImage} width={width} height={height} />
}

export default CameraFeedImage
