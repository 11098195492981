import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, Slider, Space } from 'antd'
import FormLabelWithTooltip from '../../../../components/FormLabelWithToolTip'
import { JourneyTimePercentileMeasurePrefix } from '../QueryBuilder/TrafficQueryBuilder'

const PercentileMeasurementSelection = ({
  availableMeasurements,
  newMeasures,
  allMeasures,
  handleFieldChange,
  multi,
  percentilePrefix,
  measures
}) => {
  const { t } = useTranslation()
  const { Option } = Select

  const percentilesValueString = 'Percentiles'

  const attributes = {}
  multi && (attributes['mode'] = 'multiple')

  const existingPercentileValues =
    measures &&
    measures
      .filter((entry) => {
        return entry.name.startsWith(percentilePrefix)
      })
      .map((entry) => {
        return parseInt(
          entry.name.substring(
            entry.name.lastIndexOf(percentilePrefix) + percentilePrefix.length
          )
        )
      })
  let percentileDefaults

  const hasExistingPercentiles =
    existingPercentileValues && existingPercentileValues.length === 2

  if (hasExistingPercentiles) {
    // @ts-ignore
    percentileDefaults = []
    percentileDefaults.push(Math.min(...existingPercentileValues))
    // @ts-ignore
    percentileDefaults.push(Math.max(...existingPercentileValues))
  }

  const selectedFields = () => {
    let filteredPercentileMeasures = newMeasures.filter(
      (item) => !item.startsWith(percentilePrefix)
    )
    if (hasExistingPercentiles) {
      return filteredPercentileMeasures.concat([percentilesValueString])
    }
    return filteredPercentileMeasures
  }

  const handleMeasurementsChanged = (data) => {
    let hasPercentileFieldSelected = data.some(
      (item) => item === percentilesValueString
    )
    let percentiles = existingPercentileValues
    if (hasExistingPercentiles && !hasPercentileFieldSelected) {
      percentiles = []
    } else if (!hasExistingPercentiles && hasPercentileFieldSelected) {
      percentiles = [5, 95]
    }
    updateMeasurementsWithPercentiles(data, percentiles)
  }

  const updateMeasurementsWithPercentiles = (data, percentiles) => {
    if (percentiles.length === 2) {
      const first: number = Math.min(percentiles[0], percentiles[1])
      const second: number = Math.max(percentiles[0], percentiles[1])
      data.push(
        JourneyTimePercentileMeasurePrefix + `${first}`.padStart(2, '0')
      )
      data.push(
        JourneyTimePercentileMeasurePrefix + `${second}`.padStart(2, '0')
      )
    }

    handleFieldChange(data.filter((item) => item !== percentilesValueString))
  }
  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <div>
        <p className="scc--tooltip--label">
          {t(
            `solutions.scenes.overview.add.data.journeyTime.measures.headline`
          )}
        </p>
        <Select
          optionFilterProp="label"
          value={selectedFields()}
          onChange={handleMeasurementsChanged}
          key="input"
          placeholder={t(
            'solutions.scenes.overview.add.data.selectMeasurement'
          )}
          style={{ width: '100%' }}
          {...attributes}
        >
          {availableMeasurements.map((measureName) => {
            let measure = allMeasures.find((item) => {
              return item.name === measureName
            })
            return (
              measure && (
                <Option
                  label={measure.title}
                  value={measureName}
                  key={measureName}
                  disabled={
                    selectedFields().includes(measureName) &&
                    selectedFields().length === 1
                  }
                >
                  {measure.shortTitle}
                </Option>
              )
            )
          })}
          <Option
            label={percentilesValueString}
            value={percentilesValueString}
            key={percentilesValueString}
            disabled={
              selectedFields().includes(percentilesValueString) &&
              selectedFields().length === 1
            }
          >
            {t(`solutions.scenes.overview.add.data.percentile`)}
          </Option>
        </Select>
      </div>
      {existingPercentileValues.length === 2 && (
        <div>
          <FormLabelWithTooltip id="solutions.scenes.overview.add.data.journeyTime.journeyTimePercentage" />
          <Slider
            range
            onChange={(percentiles) =>
              updateMeasurementsWithPercentiles(
                newMeasures.filter((e) => !e.startsWith(percentilePrefix)),
                percentiles
              )
            }
            included={true}
            value={percentileDefaults}
            defaultValue={[5, 95]}
            min={5}
            max={95}
            style={{ width: '50%' }}
            step={5}
            marks={{ 5: '5%', 25: '25%', 50: '50%', 75: '75%', 95: '95%' }}
          />
        </div>
      )}
    </Space>
  )
}

export default PercentileMeasurementSelection
