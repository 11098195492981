import './NavItem.scss'

import { NavLink } from 'react-router-dom'
import React from 'react'

interface INavItemProps {
  children?
  route: string
  tokenInformation?
}

const NavItem: React.FC<INavItemProps> = ({
  children,
  route,
  tokenInformation
}) => {
  return (
    <li className="scc--navitem">
      <NavLink
        to={route}
        className="scc--navitem--link"
        activeClassName="active"
        onClick={() => {
          if (tokenInformation) {
            localStorage.setItem(
              'control-center-tab_' +
                tokenInformation.tenantInformations.tenantId,
              route
            )
          }
        }}
      >
        {children}
      </NavLink>
    </li>
  )
}

export default NavItem
