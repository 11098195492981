import {
  BOX_DETAILS_REQUEST_FAILURE,
  BOX_DETAILS_REQUEST_SUCCESS,
  RESET_BOX_DETAILS,
  SAVE_BOX_DETAILS_REQUEST_FAILURE,
  SAVE_BOX_DETAILS_REQUEST_SUCCESS
} from './actionTypes'
import { DefaultBox, IBox } from '../../types/box'
import { loadBox, saveBox } from './box'

import axios from 'axios'
import { trimObjectValues } from '../../helpers'

/**
 * ==================
 * BOX DETAIL ACTIONS
 * ==================
 */

/**
 * Resets the box details.
 */
export const resetBoxDetails = () => ({
  type: RESET_BOX_DETAILS
})

/**
 * Fetches all necessary information for displaying the box configuration.
 * @param boxId: string The box id to load the details for.
 */
export const loadBoxDetails = (boxId) => async (dispatch, getState) => {
  // 1. Fetch box data
  dispatch(loadBox(boxId))
    .then((box) => {
      // Notify UI with box data
      return dispatch({
        type: BOX_DETAILS_REQUEST_SUCCESS,
        payload: {
          boxConfig: box
        }
      })
    })
    .catch((error) => {
      // Handle errors
      return dispatch({
        type: BOX_DETAILS_REQUEST_FAILURE,
        error
      })
    })
}

type BoxConfigurationData = {
  boxConfig: IBox
}

/**
 * Saves all box configurations.
 * @param boxId: string The id of the box to save the configuration for.
 * @param data: BoxConfigurationData The configuration to be saved.
 */
export const saveBoxDetails = (boxId, data: BoxConfigurationData) => (
  dispatch,
  getState
) => {
  const state = getState()
  const box = state.boxes.byIds[boxId] || DefaultBox // Fallback if new box config needs to be created
  const updatedBox = Object.assign({}, box, trimObjectValues(data.boxConfig))

  return axios
    .all([dispatch(saveBox(updatedBox))])
    .then((response) => {
      const boxId = response[0].response.result
      const boxConfig = response[0].response.entities.boxes[boxId]
      return dispatch({
        type: SAVE_BOX_DETAILS_REQUEST_SUCCESS,
        payload: {
          boxConfig
        }
      })
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_BOX_DETAILS_REQUEST_FAILURE,
        error
      })
    })
}
