import {
  LOAD_ALL_STREAMS_DETAILS_REQUEST,
  LOAD_ALL_STREAMS_DETAILS_REQUEST_FAILURE,
  LOAD_ALL_STREAMS_DETAILS_REQUEST_SUCCESS,
  SAVE_STREAMS_DETAILS_REQUEST_FAILURE,
  SAVE_STREAMS_DETAILS_REQUEST_SUCCESS,
  STREAMS_DETAILS_REQUEST_FAILURE,
  STREAMS_DETAILS_REQUEST_SUCCESS
} from './actionTypes'
import { loadCameraConfig, saveCameraConfig } from './cameraConfigurations'
import { loadMqttConfig, saveMqttConfig } from './mqttConfigurations'

import axios from 'axios'
import { DefaultStream } from '../../types/stream'
import { trimObjectValues } from '../../helpers'
import { DefaultCameraConfiguration } from '../../types/camera_configuration'
import { saveStream } from './streams'
import { CALL_API, HTTP_METHOD } from '../middleware/api'
import {
  loadIoTriggerConfig,
  saveIoTriggerConfig
} from './ioTriggerConfigurations'

/**
 * ==================
 * Stream DETAIL ACTIONS
 * ==================
 */

export const loadStreamDetails = (boxId, streamId) => async (
  dispatch,
  getState
) => {
  return axios
    .all([
      dispatch(loadMqttConfig(boxId, streamId)),
      dispatch(loadCameraConfig(boxId, streamId)),
      dispatch(loadIoTriggerConfig(boxId, streamId))
    ])
    .then((data) => {
      // Notify UI with configuration data
      return dispatch({
        type: STREAMS_DETAILS_REQUEST_SUCCESS,
        payload: {
          streamId: streamId,
          mqttConfig: data[0],
          cameraConfig: data[1]
        }
      })
    })
    .catch((error) => {
      return dispatch({
        type: STREAMS_DETAILS_REQUEST_FAILURE,
        error
      })
    })
}

export const loadAllStreamDetails = (boxId) => async (dispatch, getState) => {
  return dispatch(callAllStreamDetails(boxId)).then((data) => data.response)
}

export const callAllStreamDetails = (boxId: string) => {
  return {
    [CALL_API]: {
      types: [
        LOAD_ALL_STREAMS_DETAILS_REQUEST,
        LOAD_ALL_STREAMS_DETAILS_REQUEST_SUCCESS,
        LOAD_ALL_STREAMS_DETAILS_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.GET,
      endpoint: `boxes/${boxId}/configurations`
    }
  }
}

/**
 * Saves all box configurations.
 * @param boxId: string The id of the box to save the configuration for.
 * @param streamId
 * @param data
 */
export const saveStreamDetails = (boxId: string, streamId: string, data) => (
  dispatch,
  getState
) => {
  const hasStreamMeta = !!data.streamMeta
  const state = getState()

  const cameraConfig =
    (state.streamDetails.byIds[streamId] &&
      state.streamDetails.byIds[streamId].cameraConfig) ||
    DefaultCameraConfiguration
  const updatedCameraConfig = Object.assign(
    {},
    cameraConfig,
    trimObjectValues(data.cameraConfig)
  )

  const updatedMqttConfig = data.mqttConfig.map((mqttConfig) =>
    trimObjectValues(mqttConfig)
  )

  const ioTriggerConfig =
    (state.streamDetails.byIds[streamId] &&
      state.streamDetails.byIds[streamId].ioTriggerConfig) ||
    null

  let updatedIoTriggerConfig = { ...ioTriggerConfig }

  if (data.ioTriggerConfig) {
    if (updatedIoTriggerConfig) {
      updatedIoTriggerConfig = Object.assign(
        {},
        ioTriggerConfig,
        trimObjectValues(data.ioTriggerConfig)
      )
    } else {
      updatedIoTriggerConfig = trimObjectValues(data.ioTriggerConfig)
    }
  } else {
    updatedIoTriggerConfig = null
  }

  let updatedStreamMeta
  if (hasStreamMeta) {
    const streamMeta = state.streams.byIds[streamId] || DefaultStream
    updatedStreamMeta = Object.assign(
      {},
      streamMeta,
      trimObjectValues(data.streamMeta)
    )
  }

  if (!state.streams.byIds[streamId]) {
    // it's a new stream
    if (hasStreamMeta) {
      updatedStreamMeta.id = streamId
    }
    updatedCameraConfig.streamId = streamId
    updatedCameraConfig.boxId = boxId
  }

  updatedCameraConfig.port = parseInt(updatedCameraConfig.port) || null
  updatedMqttConfig.forEach((mqttConfig) => {
    mqttConfig.port = parseInt(mqttConfig.port) || null
  })

  return axios
    .all(
      hasStreamMeta
        ? [
            dispatch(saveCameraConfig(updatedCameraConfig)),
            dispatch(saveMqttConfig(boxId, streamId, updatedMqttConfig)),
            dispatch(
              saveIoTriggerConfig(boxId, streamId, updatedIoTriggerConfig)
            ),
            dispatch(saveStream(boxId, streamId, updatedStreamMeta))
          ]
        : [
            dispatch(saveCameraConfig(updatedCameraConfig)),
            dispatch(saveMqttConfig(boxId, streamId, updatedMqttConfig)),
            dispatch(
              saveIoTriggerConfig(boxId, streamId, updatedIoTriggerConfig)
            )
          ]
    )
    .then((response) => {
      return dispatch({
        type: SAVE_STREAMS_DETAILS_REQUEST_SUCCESS,
        payload: {
          streamId: streamId,
          mqttConfig: Object.values(
            Object.values(
              response[1].response.entities.configurations
            )[0] as Object
          ),
          cameraConfig: Object.values(
            response[0].response.entities.configurations
          )[0]
        }
      })
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_STREAMS_DETAILS_REQUEST_FAILURE,
        error
      })
    })
}
