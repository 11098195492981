import './Sidebar.scss'

import ImageContainer from '../ImageContainer'
import NavItem from '../NavItem'
import NavMenu from '../NavMenu'
import React, { useEffect, useState } from 'react'
import swarmLogo from './logo.svg'
import bernardLogo from './logo-bernard.svg'
import swisstrafficLogo from './logo-swisstraffic.svg'
import swarcoLogo from './logo-swarco.svg'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CustomerServiceOutlined } from '@ant-design/icons'
import { Config } from '../../services/config'
import { userIsAtLeast, UserRole } from '../../types/user_role'
import {
  getTokenInformation,
  TokenInformation
} from '../../services/apiTokenProvider'

interface ISidebarProps {
  children?
  className: string
}
const GlobalVersionInfo = Config.GLOBAL_VERSION_INFO

let logo = swarmLogo
if (Config.CUSTOM_THEME && Config.CUSTOM_THEME.toUpperCase() === 'BERNARD') {
  logo = bernardLogo
} else if (
  Config.CUSTOM_THEME &&
  Config.CUSTOM_THEME.toUpperCase() === 'SWISSTRAFFIC'
) {
  logo = swisstrafficLogo
} else if (
  Config.CUSTOM_THEME &&
  Config.CUSTOM_THEME.toUpperCase() === 'SWARCO'
) {
  logo = swarcoLogo
}

const Sidebar: React.FC<ISidebarProps> = (props) => {
  const { t } = useTranslation()

  const match = useRouteMatch({
    path: '/boxes/:id/setup'
  })

  const classNames = [
    'scc--sidebar',
    props.className,
    match ? 'scc--sidebar__closed' : ''
  ]

  const [tokenInformation, setTokenInformation] = useState<TokenInformation>()

  useEffect(() => {
    ;(async () => {
      setTokenInformation(await getTokenInformation())
    })()
  }, [])

  return (
    <div className={classNames.join(' ')}>
      <ImageContainer
        image={logo}
        altText="Control Center"
        className="scc--logo"
      />
      <NavMenu>
        {userIsAtLeast(UserRole.User) && (
          <NavItem route="/boxes" tokenInformation={tokenInformation}>
            {t('sidebar.navItemBoxes')}
          </NavItem>
        )}
        <NavItem route="/solutions" tokenInformation={tokenInformation}>
          {t('sidebar.navItemSolutions')}
        </NavItem>
        {userIsAtLeast(UserRole.Admin) && (
          <NavItem route="/admin" tokenInformation={tokenInformation}>
            {t('sidebar.navItemAdmin')}
          </NavItem>
        )}
      </NavMenu>
      {!Config.EXCLUDE_SUPPORT && (
        <div>
          <a
            className="scc--support--link"
            href={t('general.supportPortal')}
            target="_blank"
            rel="noreferrer"
          >
            <CustomerServiceOutlined />
            <span>{t('sidebar.supportLinkText')}</span>
          </a>
        </div>
      )}
      {GlobalVersionInfo && (
        <div className="scc--software--version">
          {t('sidebar.installedSoftwareVersion')}
          {GlobalVersionInfo}
        </div>
      )}
      {props.children}
    </div>
  )
}

export default Sidebar
