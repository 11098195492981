import React, { useState } from 'react'
import AlertList from '../components/MonitoringAlerts/AlertList'
import AlertModal from '../components/MonitoringAlerts/AlertModal'
import { IMonitoringAlert, MonitoringAlert } from '../types/monitoringAlert'
import { callDeleteAlert, callSaveAlert } from '../redux/actions/alerts'
import { useDispatch } from 'react-redux'
import { Col, Row, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteConfirmationDialog } from '../components/ConfirmDialog/DeleteConfirmationDialog'

const MonitoringPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
  const [
    monitoringAlert,
    setMonitoringAlert
  ] = useState<IMonitoringAlert | null>(null)

  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(
    false
  )

  const handleSaveAlert = (alert: IMonitoringAlert) => {
    dispatch(callSaveAlert(alert))
    setShowAlertModal(false)
    setMonitoringAlert(null)
  }

  const handleAddAlert = () => {
    setMonitoringAlert(MonitoringAlert.default)
    setShowAlertModal(true)
  }

  const handleConfirmDeleteAlert = () => {
    if (!monitoringAlert) {
      return
    }
    dispatch(callDeleteAlert(monitoringAlert))
    setDeleteDialogVisible(false)
    setMonitoringAlert(null)
  }

  const handleDeleteAlert = (alert: IMonitoringAlert) => {
    setMonitoringAlert(JSON.parse(JSON.stringify(alert)))
    setDeleteDialogVisible(true)
  }

  const handleEditAlert = (alert: IMonitoringAlert) => {
    setMonitoringAlert(JSON.parse(JSON.stringify(alert)))
    setShowAlertModal(true)
  }

  return (
    <>
      <Space size="large" direction="vertical">
        <Row>
          <Col>
            <h3>{t('monitoring.title')}</h3>
          </Col>
        </Row>
        <AlertList
          addAlert={handleAddAlert}
          deleteAlert={handleDeleteAlert}
          editAlert={handleEditAlert}
        />
      </Space>
      {monitoringAlert && showAlertModal && (
        <AlertModal
          show={showAlertModal}
          close={() => {
            setShowAlertModal(false)
            setMonitoringAlert(null)
          }}
          alert={monitoringAlert}
          saveAlert={handleSaveAlert}
        />
      )}

      <DeleteConfirmationDialog
        onRequestClose={() => {
          setDeleteDialogVisible(false)
          setMonitoringAlert(null)
        }}
        onSecondarySubmit={() => {
          setDeleteDialogVisible(false)
          setMonitoringAlert(null)
        }}
        onRequestSubmit={handleConfirmDeleteAlert}
        open={isDeleteDialogVisible}
      />
    </>
  )
}

export default MonitoringPage
