import React, { Fragment } from 'react'
import { DatePicker, Menu } from 'antd'
import moment from 'moment-timezone'
import ButtonDropdown from './ButtonDropdown'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { last7Days } from './DateRanges'

const { RangePicker } = DatePicker

const Label = styled.div`
  color: var(--dark-04-color);
  line-height: 32px;
`

const SectionRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-right: -8px;
  margin-bottom: -8px;

  && > * {
    margin-right: 8px !important;
    margin-bottom: 8px !important;
  }
`

const TimeGroup = ({
  members = [],
  updateTimeRange,
  timezone,
  isCustomDateRange,
  setCustomDateRange,
  dateRanges
}) => {
  const { t } = useTranslation(['datadiscovery'])

  let dateformat = 'YYYY-MM-DD HH:mm'
  if (!timezone || timezone === 'Etc/UTC') {
    dateformat += ' [(UTC)]'
  }

  function onDateRangeSelect(m, dateRange) {
    if (dateRange && !dateRange.some((d) => !d)) {
      updateTimeRange({
        ...m,
        dateRange: [
          dateRange[0]
            .startOf('minute')
            .format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
          dateRange[1]
            .endOf('minute')
            .format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
        ]
      })
    }
  }

  const dateRangeMenu = (onClick) => (
    <Menu>
      {dateRanges.map((m) => (
        <Menu.Item key={m.title} onClick={() => onClick(m)}>
          {m.title}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <SectionRow>
      {members.map((m, index) => (
        <Fragment key={index}>
          <Label>{t('explore.builder.timelabel')}</Label>

          <ButtonDropdown
            overlay={dateRangeMenu((dateRange) => {
              if (dateRange.title === t('explore.builder.dateRanges.custom')) {
                updateTimeRange({
                  ...m,
                  dateRange: last7Days(timezone)
                })
                setCustomDateRange(true)
              } else {
                updateTimeRange(
                  {
                    ...m,
                    dateRange: dateRange.value(timezone)
                  },
                  dateRange.title
                )
                setCustomDateRange(false)
              }
            })}
          >
            {m.timeFilterString}
          </ButtonDropdown>

          {isCustomDateRange ? (
            <RangePicker
              format={dateformat}
              showTime={{ format: 'HH:mm' }}
              defaultValue={members[0].dateRange.map((date) =>
                moment.tz(date, timezone)
              )}
              onCalendarChange={(dateRange) => onDateRangeSelect(m, dateRange)}
            />
          ) : null}
        </Fragment>
      ))}
    </SectionRow>
  )
}

export default TimeGroup
