import './App.scss'
import 'react-notifications-component/dist/theme.css'

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import StreamContextualPage from '../../containers/StreamContextualPage'
import BoxDetailPage from '../../containers/BoxDetailPage'
import BoxesPage from '../../containers/BoxesPage'
import ErrorBoundary from '../ErrorBoundary'
import ErrorDialog from '../ErrorDialog'
import Header from '../Header'
import React from 'react'
import ReactNotification from 'react-notifications-component'
import Sidebar from '../Sidebar'
import { connect } from 'react-redux'
import { resetErrorMessage } from '../../redux/actions/error'
import SolutionApp from '../../solutions/SolutionsApp'
import SceneDetailPage from '../../solutions/containers/SceneDetailPage'
import { userIsAtLeast, UserRole } from '../../types/user_role'
import AdminPage from '../../containers/AdminPage'
import CachedLandingPageRedirect from './CachedLandingPageRedirect'

interface IAppProps {
  onErrorModalClose(): void
}

class App extends React.Component<IAppProps> {
  render() {
    return (
      <Router>
        <div className="bx--grid scc--full-height">
          <div className="bx--row scc--full-height">
            <div className="bx--col scc--full-height">
              <div className="scc--app scc--full-height">
                <Sidebar className="fixed" />
                <div className="scc--main">
                  <Header />
                  <div className="scc--content">
                    <ErrorBoundary>
                      <Switch>
                        {userIsAtLeast(UserRole.User) && (
                          <Route path={['/boxes/:id', '/boxes']} exact={true}>
                            <BoxesPage />
                            <BoxDetailPage />
                          </Route>
                        )}
                        {userIsAtLeast(UserRole.User) && (
                          <Route
                            path="/boxes/:id/:streamId/setup"
                            exact={true}
                            component={StreamContextualPage}
                          />
                        )}
                        {userIsAtLeast(UserRole.Admin) && (
                          <Route path={['/admin']} exact={true}>
                            <AdminPage />
                          </Route>
                        )}
                        <Route
                          path={[
                            '/solutions/:id',
                            '/solutions/:id/:group',
                            '/solutions'
                          ]}
                          exact={true}
                        >
                          <SolutionApp />
                          <SceneDetailPage />
                        </Route>
                        <CachedLandingPageRedirect />
                      </Switch>
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
              <ReactNotification />
            </div>
          </div>
        </div>
        <ErrorDialog onRequestClose={this.props.onErrorModalClose} />
      </Router>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onErrorModalClose: () => {
      dispatch(resetErrorMessage())
    }
  }
}

export default connect(() => {
  return {}
}, mapDispatchToProps)(App)
