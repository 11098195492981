import { Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import copy from 'copy-to-clipboard'
import React from 'react'

export const getIdComponent = (id: string, titleKey: string, t: any) => {
  return (
    <>
      <span>
        {t(titleKey, {
          id: id
        })}
      </span>
      <Button
        icon={<CopyOutlined />}
        className="scc--boxdetail--copy-id-serial-button"
        onClick={() => {
          copy(id)
        }}
      />
    </>
  )
}
