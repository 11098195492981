import { IBox } from '../../../types/box'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  box: IBox
}

const NameIdCell: React.FC<IProps> = ({ box }) => {
  const { t } = useTranslation()
  return (
    <>
      <span className="scc--box_name">
        {box.name || box.serial || t('boxList.dataTable.empty.deviceName')}
      </span>
      <br />
      {box.serial ? (
        <span className="scc--box_id_serial">
          ID: {box.id} / Serial: {box.serial}
        </span>
      ) : (
        <span className="scc--box_id_serial">ID: {box.id}</span>
      )}
    </>
  )
}

export default NameIdCell
