import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { getTokenInformation } from '../../services/apiTokenProvider'
import { userIsAtLeast, UserRole } from '../../types/user_role'

const CachedLandingPageRedirect: React.FC<any> = () => {
  const [redirectPath, setRedirectPath] = useState<string | undefined>()

  const getCachedTabPathOrDefault = async () => {
    const tokenInformation = await getTokenInformation()
    if (!tokenInformation || !tokenInformation.tenantInformations) return

    const tenantId = tokenInformation.tenantInformations.tenantId
    const lastTab = localStorage.getItem('control-center-tab_' + tenantId)

    if (
      lastTab &&
      ((lastTab === '/admin' && userIsAtLeast(UserRole.Admin)) ||
        (lastTab === '/boxes' && userIsAtLeast(UserRole.User)) ||
        lastTab === '/solutions')
    ) {
      return lastTab
    }

    const landingTab = userIsAtLeast(UserRole.User) ? '/boxes' : '/solutions'
    localStorage.setItem('control-center-tab_' + tenantId, landingTab)
    return landingTab
  }

  useEffect(() => {
    ;(async () => {
      const path = await getCachedTabPathOrDefault()
      setRedirectPath(path)
    })()
  }, [])

  return redirectPath ? <Redirect to={redirectPath} /> : <></>
}

export default CachedLandingPageRedirect
