import './NavMenu.scss'

import React from 'react'

interface INavMenuProps {
  children?
}

const NavMenu: React.FC<INavMenuProps> = (props) => {
  return (
    <nav className="scc--navmenu">
      <ul className="scc--navmenu--list">{props.children}</ul>
    </nav>
  )
}

export default NavMenu
