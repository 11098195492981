import { Circle, Group, Line, Text } from 'react-konva'
import React, { useState } from 'react'

import {
  ERegionOfInterestType,
  RegionOfInterestEventTrigger
} from '../../types/regionOfInterest'
import { getEdges } from '../../helpers'
import { EEventTriggerType } from '../../types/eventTrigger'

interface IRegionProps {
  onDragStart: (MouseEvent, RegionOfInterestEventTrigger) => void
  onDragEnd: (MouseEvent, RegionOfInterestEventTrigger, any) => void
  onClick: (MouseEvent, RegionOfInterestEventTrigger) => void
  eventTrigger: RegionOfInterestEventTrigger
  stage: any
  isEditable?: boolean
  isHighlighted: boolean
  isSelected: boolean
  isFocusArea: boolean
}

export const Region: React.FC<IRegionProps> = ({
  onDragStart,
  onDragEnd,
  onClick,
  eventTrigger,
  stage,
  isEditable,
  isHighlighted,
  isSelected,
  isFocusArea
}) => {
  // Colors
  const colorSelection = () => {
    if (
      eventTrigger.objectType === EEventTriggerType.regionOfInterest &&
      eventTrigger.roiType === ERegionOfInterestType.generic
    ) {
      // ROI generic
      return {
        colorHighlight: '#00ffb4',
        colorHighlightAlpha: 'rgba(0,255,180, 0.25)',
        colorDefault: '#00a86b',
        colorDefaultAlpha: 'rgba(0,168,107, 0.25)'
      }
    } else if (
      eventTrigger.objectType === EEventTriggerType.regionOfInterest &&
      eventTrigger.roiType === ERegionOfInterestType.focus
    ) {
      // ROI focus area
      return {
        colorHighlight: '#ff9967',
        colorHighlightAlpha: 'rgba(255,0,0,0)',
        colorDefault: '#ff9967',
        colorDefaultAlpha: 'rgba(0,255,0,0)'
      }
    } else if (
      eventTrigger.objectType === EEventTriggerType.regionOfInterest &&
      eventTrigger.capacity &&
      eventTrigger.capacity > 1
    ) {
      // ROI multispace
      return {
        colorHighlight: '#b65ef9',
        colorHighlightAlpha: 'rgba(182,94,248, 0.25)',
        colorDefault: '#8e41c8',
        colorDefaultAlpha: 'rgba(142,65,200, 0.25)'
      }
    } else if (eventTrigger.objectType === EEventTriggerType.regionOfInterest) {
      // ROI singlespace
      return {
        colorHighlight: '#00c4c0',
        colorHighlightAlpha: 'rgba(0,195,192, 0.25)',
        colorDefault: '#00a8a5',
        colorDefaultAlpha: 'rgba(0,168,164, 0.25)'
      }
    } else if (eventTrigger.objectType === EEventTriggerType.virtualDoor) {
      return {
        colorDefault: '#FFCC5F',
        colorDefaultAlpha: 'rgba(255,205,76, 0.25)',
        colorHighlight: '#fff75f',
        colorHighlightAlpha: 'rgba(255,247,95, 0.25)'
      }
    } else if (
      eventTrigger.objectType === EEventTriggerType.originDestinationZone
    ) {
      return {
        colorDefault: '#77c8cb',
        colorDefaultAlpha: 'rgba(119,200,203, 0.25)',
        colorHighlight: '#779ecb',
        colorHighlightAlpha: 'rgba(119,158,203, 0.25)'
      }
    } else {
      return {
        colorHighlight: '#00ffb4',
        colorHighlightAlpha: 'rgba(0,255,180, 0.25)',
        colorDefault: '#00a86b',
        colorDefaultAlpha: 'rgba(0,168,107, 0.25)'
      }
    }
  }
  const colors = colorSelection()

  const absolutePoints = eventTrigger.convertRelativePointToPixels({
    width: stage.attrs.width,
    height: stage.attrs.height
  })

  const [point1, updatePoint1] = useState({
    x: absolutePoints[0].x || 0,
    y: absolutePoints[0].y || 0,
    draggable: !!isEditable && isHighlighted
  })

  const [point2, updatePoint2] = useState({
    x: absolutePoints[1].x || 0,
    y: absolutePoints[1].y || 0,
    draggable: !!isEditable && isHighlighted
  })

  const [point3, updatePoint3] = useState({
    x: absolutePoints[2].x || 0,
    y: absolutePoints[2].y || 0,
    draggable: !!isEditable && isHighlighted
  })

  const [point4, updatePoint4] = useState({
    x: absolutePoints[3].x || 0,
    y: absolutePoints[3].y || 0,
    draggable: !!isEditable && isHighlighted
  })

  const [line, updateLine] = useState({
    points: [
      absolutePoints[0].x || 0,
      absolutePoints[0].y || 0,
      absolutePoints[1].x || 0,
      absolutePoints[1].y || 0,
      absolutePoints[2].x || 0,
      absolutePoints[2].y || 0,
      absolutePoints[3].x || 0,
      absolutePoints[3].y || 0
    ],
    fill: isHighlighted ? colors.colorHighlightAlpha : colors.colorDefaultAlpha,
    stroke: isHighlighted ? colors.colorHighlight : colors.colorDefault,
    closed: true
  })

  const dragBoundFunc = (pos) => ({
    x: pos.x < 0 ? 0 : pos.x > stage.attrs.width ? stage.attrs.width : pos.x,
    y: pos.y < 0 ? 0 : pos.y > stage.attrs.height ? stage.attrs.height : pos.y
  })

  /**
   * Handles mouse enter events.
   * @param event The event object.
   */
  const onMouseEnter = (event) => {
    if (!event.evt.target || !isEditable) {
      return
    }

    event.evt.target.style.cursor = isSelected ? 'move' : 'pointer'
  }

  /**
   * Handles mouse leave events.
   * @param event The event object.
   * @param cursor The cursor style to be used.
   */
  const onMouseLeave = (event, cursor = 'default') => {
    if (!event.evt.target) {
      return
    }

    event.evt.target.style.cursor = 'default'
  }

  // Calculate text position based on average coordinates
  const textPosition = (() => {
    const minX = Math.min(point1.x, point2.x, point3.x, point4.x)
    const maxX = Math.max(point1.x, point2.x, point3.x, point4.x)
    const minY = Math.min(point1.y, point2.y, point3.y, point4.y)
    const maxY = Math.max(point1.y, point2.y, point3.y, point4.y)

    return {
      minX,
      minY,
      maxX,
      maxY
    }
  })()

  let polygon = isFocusArea ? (
    <>
      <Line
        {...line}
        globalCompositeOperation={'destination-out'}
        fill={colors.colorHighlight}
        stroke={isHighlighted ? colors.colorHighlight : colors.colorDefault}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Line
        {...line}
        stroke={isHighlighted ? colors.colorHighlight : colors.colorDefault}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </>
  ) : (
    <Line
      {...line}
      stroke={isHighlighted ? colors.colorHighlight : colors.colorDefault}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )

  return (
    <Group
      onDragStart={(event) => {
        onDragStart(event, eventTrigger)
      }}
      onMouseOver={onMouseEnter}
      onMouseOut={onMouseLeave}
      onDragEnd={(event) => {
        let points = [point1, point2, point3, point4]

        // Update all points if the whole group has been moved
        if (event.target.nodeType === 'Group') {
          const attrs = event.target.attrs
          points = points.map((point) => {
            point.x += attrs.x
            point.y += attrs.y
            return point
          })
        }
        onDragEnd(event, eventTrigger, points)
      }}
      onClick={(event) => {
        onClick(event, eventTrigger)
      }}
      onMouseMove={onMouseEnter}
      draggable={!!isEditable && isHighlighted}
      dragBoundFunc={(pos) => {
        // Find the coordinates in each direction closest to the boundary
        const edges = getEdges([point1, point2, point3, point4])

        if (edges.smallestX + pos.x < 0) {
          pos.x = -edges.smallestX
        }

        if (edges.biggestX + pos.x > stage.attrs.width) {
          pos.x = stage.attrs.width - edges.biggestX
        }

        if (edges.smallestY + pos.y < 0) {
          pos.y = -edges.smallestY
        }

        if (edges.biggestY + pos.y > stage.attrs.height) {
          pos.y = stage.attrs.height - edges.biggestY
        }
        return pos
      }}
    >
      {polygon}
      {!!eventTrigger.name && eventTrigger.name.trim().length > 0 && (
        <Text
          text={eventTrigger.name}
          x={textPosition.minX + (textPosition.maxX - textPosition.minX) / 2}
          y={textPosition.minY + (textPosition.maxY - textPosition.minY) / 2}
          height={12}
          // this is an approximation for fontsize 12 to center the name horizontal because react-konva allows
          // only pixel values and the real width of the text element is unknown
          offsetX={3 * eventTrigger.name.length}
          offsetY={6}
          fontSize={12}
          fill="#ffffff"
          fontStyle="bold"
        />
      )}
      <Circle
        x={point1.x}
        y={point1.y}
        radius={4}
        fill={isHighlighted ? colors.colorHighlight : colors.colorDefault}
      />
      <Circle
        x={point2.x}
        y={point2.y}
        radius={4}
        fill={isHighlighted ? colors.colorHighlight : colors.colorDefault}
      />
      <Circle
        x={point3.x}
        y={point3.y}
        radius={4}
        fill={isHighlighted ? colors.colorHighlight : colors.colorDefault}
      />
      <Circle
        x={point4.x}
        y={point4.y}
        radius={4}
        fill={isHighlighted ? colors.colorHighlight : colors.colorDefault}
      />
      <Circle
        {...point1}
        radius={10}
        stroke="white"
        strokeWidth={4}
        visible={!!isEditable && isSelected}
        onDragMove={(event) => {
          onMouseEnter(event)

          // Update point1
          updatePoint1({ ...point1, ...event.target.position() })
          const updatedLine = line
          updatedLine.points[0] = event.target.position().x
          updatedLine.points[1] = event.target.position().y
          updateLine(updatedLine)
        }}
        dragBoundFunc={dragBoundFunc}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Circle
        {...point2}
        radius={10}
        stroke="white"
        strokeWidth={4}
        visible={!!isEditable && isSelected}
        onDragMove={(event) => {
          onMouseEnter(event)

          // Update point2
          updatePoint2({ ...point2, ...event.target.position() })
          const updatedLine = line
          updatedLine.points[2] = event.target.position().x
          updatedLine.points[3] = event.target.position().y
          updateLine(updatedLine)
        }}
        dragBoundFunc={dragBoundFunc}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Circle
        {...point3}
        radius={10}
        stroke="white"
        strokeWidth={4}
        visible={!!isEditable && isSelected}
        onDragMove={(event) => {
          onMouseEnter(event)

          // Update point3
          updatePoint3({ ...point3, ...event.target.position() })
          const updatedLine = line
          updatedLine.points[4] = event.target.position().x
          updatedLine.points[5] = event.target.position().y
          updateLine(updatedLine)
        }}
        dragBoundFunc={dragBoundFunc}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Circle
        {...point4}
        radius={10}
        stroke="white"
        strokeWidth={4}
        visible={!!isEditable && isSelected}
        onDragMove={(event) => {
          onMouseEnter(event)

          // Update point4
          updatePoint4({ ...point4, ...event.target.position() })
          const updatedLine = line
          updatedLine.points[6] = event.target.position().x
          updatedLine.points[7] = event.target.position().y
          updateLine(updatedLine)
        }}
        dragBoundFunc={dragBoundFunc}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </Group>
  )
}
