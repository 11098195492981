import './ErrorBoundary.scss'

import React, { Component } from 'react'

import { log } from '../../services/log'
import { withTranslation } from 'react-i18next'

interface IErrorBoundaryProps {
  i18n: any
  tReady: any
  t: any
}

interface IErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    log.error(error, info)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="scc--error-boundary scc--error-boundary--generic">
          <h2>{this.props.t('general.error.defaultMessage')}</h2>
        </div>
      )
    }

    return this.props.children
  }
}

export default withTranslation()(ErrorBoundary)
