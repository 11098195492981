import React, { useState } from 'react'
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space
} from 'antd'
import { useTranslation } from 'react-i18next'
import {
  IControlCenterRole,
  IControlCenterUser
} from '../../types/controlCenterUser'
import rawRequest from '../../services/apiTokenProvider'
import { useQuery } from 'react-query'
import LoadingAnimation from '../LoadingAnimation'

const { Item } = Form

type UserModalProps = {
  show: boolean
  close: () => void
  saveUser: (user: IControlCenterUser) => void
  user: IControlCenterUser
}

const getRoles = (): Promise<IControlCenterRole[]> =>
  rawRequest({
    url: `/tenantAdministration/roles`
  }).then((response) => response.data)

const UserModal = ({ show, close, saveUser, user }: UserModalProps) => {
  const { t } = useTranslation()
  const [editableUser, setEditableUser] = useState<IControlCenterUser>({
    ...user
  })

  const { data, isLoading } = useQuery(['roles'], getRoles, {
    enabled: !!user && show
  })

  const handleCancel = () => {
    close()
  }

  const handleChangeRole = (roleId) => {
    if (data) {
      const newRole = data.find((value) => value.id === roleId)
      if (newRole) {
        const updatedUser = { ...editableUser, userRole: newRole }
        setEditableUser(updatedUser)
      }
    }
  }

  const handleSave = () => {
    saveUser(editableUser)
  }

  const getInitials = (displayName: string) => {
    // Extract only the alphabetic parts and space from displayName
    const cleanName = displayName ? displayName.match(/[A-Za-z ]+/g) : ''

    if (!cleanName || cleanName.length === 0) return ''

    const trimmedName = cleanName[0].trim()

    const splitName = trimmedName.split(' ')
    const firstNameInitial = splitName[0] ? splitName[0].charAt(0) : ''
    const lastNameInitial =
      splitName.length > 1 ? splitName[splitName.length - 1].charAt(0) : ''

    return firstNameInitial + lastNameInitial
  }

  if (isLoading) {
    return (
      <Modal
        visible={show}
        width={1000}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('users.modal.cancel')}
          </Button>,
          <Button
            type="primary"
            disabled={true}
            key="save"
            onClick={handleSave}
          >
            {t('users.modal.save')}
          </Button>
        ]}
      >
        <LoadingAnimation />
      </Modal>
    )
  }

  if (!show) {
    return null
  }

  if (data && editableUser) {
    return (
      <Modal
        visible={show}
        width={1000}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('users.modal.cancel')}
          </Button>,
          <Button type="primary" key="save" onClick={handleSave}>
            {t('users.modal.save')}
          </Button>
        ]}
      >
        <Card title={t('users.modal.edit')} style={{ marginTop: '20px' }}>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Row gutter={16}>
              <Col span={4}>
                <Avatar size={100}>
                  {getInitials(editableUser.userDisplayName)}
                </Avatar>
              </Col>
              <Col span={18}>
                <Form layout="vertical">
                  <Item label={t('users.modal.username')}>
                    <span>{editableUser.userDisplayName}</span>
                  </Item>
                  <Item label={t('users.modal.email')}>
                    <span>
                      {editableUser.email || t('users.modal.noEmail')}
                    </span>
                  </Item>
                  <Item label={t('users.modal.role')}>
                    <Select
                      style={{ width: 200 }}
                      value={editableUser.userRole.id}
                      onChange={(value) => handleChangeRole(value)}
                    >
                      {data.map((role) => (
                        <Select.Option key={role.id} value={role.id}>
                          {role.roleName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </Form>
              </Col>
            </Row>
          </Space>
        </Card>
      </Modal>
    )
  }
  return null //ts
}
export default UserModal
