import ConfirmationDialog from './ConfirmationDialog'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const ConfigurationConfirmationDialog = ({
  onCloseDialog,
  onRequestSubmit,
  open
}) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      primaryButtonText={t('modal.configuration.primaryButton.title')}
      secondaryButtonText={t('modal.configuration.secondaryButton.title')}
      onRequestClose={onCloseDialog}
      onSecondarySubmit={onCloseDialog}
      onRequestSubmit={onRequestSubmit}
      modalLabel={t('modal.configuration.confirmStreamLabel')}
      modalHeading={t('modal.configuration.confirmStreamConfigHeading')}
      open={open}
    />
  )
}
