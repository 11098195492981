import React, { useCallback, useEffect, useState } from 'react'

import './SceneStreams.scss'
import { IScene } from '../../../types/scene'
import { useTranslation } from 'react-i18next'
import { Button, Col, Collapse, Row, Tooltip } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import { streamInfos } from '../../../redux/actions/streams'
import { IStreamInfo } from '../../../types/stream'
import { NavLink } from 'react-router-dom'
import DrawCanvas from '../../../components/DrawCanvas'
import {
  EEventTriggerType,
  ICoordinateBasedEventTrigger
} from '../../../types/eventTrigger'
import { ECameraFrameMode, ICameraFrame } from '../../../types/cameraFrame'
import {
  loadCameraFrame,
  resetCameraFrame
} from '../../../redux/actions/cameraFrame'
import { DeleteOutlined } from '@ant-design/icons'
import StreamSelection from '../Helper/StreamSelection'
import OperationalStatusCell from '../../../components/BoxList/cells/OperationalStatusCell'
import { ERuntimeState } from '../../../types/runtimeState'
import { EErrorReason } from '../../../types/errorReason'
import { userIsAtLeast, UserRole } from '../../../types/user_role'
import { DeleteConfirmationDialog } from '../../../components/ConfirmDialog/DeleteConfirmationDialog'
import { ReduxStore } from '../../../redux/store/configureStore'
import { loadStreamEventTriggers } from '../../../redux/actions/eventTriggers'

interface ISceneStreams {
  scene: IScene
  allStreams: IStreamInfo[]
  saveScene: Function
  cameraFrames: ICameraFrame[]
  loadingIds: { [key: string]: boolean }
  loadCameraFrame: Function
  resetCameraFrame: Function
}

const SceneStreams: React.FC<ISceneStreams> = (props) => {
  const { t } = useTranslation()
  const { Panel } = Collapse

  const [newStreams, setNewStreams] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const [deleteSceneDetail, setDeleteSceneDetail] = useState('')
  const [calibration, setCalibration] = useState(ECameraFrameMode.default)

  const onStreamRemove = async () => {
    if (!deleteSceneDetail || deleteSceneDetail.length === 0) {
      return
    }
    await removeStream(deleteSceneDetail)
    setIsDeleteDialogVisible(false)
  }

  const dispatch = useDispatch()
  const { eventTriggers, fetchedStreamId } = useSelector(
    (state: ReduxStore) => {
      return {
        eventTriggers: state.eventTriggers.byIds,
        fetchedStreamId: state.eventTriggers.fetchedStreamId
      }
    }
  )

  const [
    coordinateBasedEventTriggersPerStream,
    setCoordinateBasedEventTriggersPerStream
  ] = useState<Map<string, ICoordinateBasedEventTrigger[]>>(
    new Map<string, ICoordinateBasedEventTrigger[]>()
  )

  const [
    hiddenEventTriggersPerStream,
    setHiddenEventTriggersPerStream
  ] = useState({})

  // To update the state of a specific element
  const updateHiddenEventTriggerPerStream = (streamId, hiddenEventTrigger) => {
    setHiddenEventTriggersPerStream((prevState) => ({
      ...prevState,
      [streamId]: hiddenEventTrigger
    }))
  }

  const getHiddenEventTriggerPerStream = (streamId) => {
    return hiddenEventTriggersPerStream[streamId]
  }

  const callStreamEventTriggers = useCallback(
    (boxId, streamId) => {
      dispatch(loadStreamEventTriggers(boxId, streamId))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!coordinateBasedEventTriggersPerStream.has(fetchedStreamId)) {
      // @ts-ignore
      let newEventTriggers: ICoordinateBasedEventTrigger[] = Object.values(
        eventTriggers
      ).filter((trigger) =>
        [
          EEventTriggerType.virtualDoor,
          EEventTriggerType.originDestinationZone,
          EEventTriggerType.crossingLine,
          EEventTriggerType.regionOfInterest
          // @ts-ignore
        ].includes(trigger.objectType)
      )
      setCoordinateBasedEventTriggersPerStream(
        coordinateBasedEventTriggersPerStream.set(
          fetchedStreamId,
          newEventTriggers
        )
      )
    }
  }, [eventTriggers, fetchedStreamId, coordinateBasedEventTriggersPerStream])

  const toggleHideAllEventTriggers = (streamid) => {
    if (
      !getHiddenEventTriggerPerStream(streamid) ||
      getHiddenEventTriggerPerStream(streamid)!.length === 0
    ) {
      updateHiddenEventTriggerPerStream(
        streamid,
        coordinateBasedEventTriggersPerStream.get(streamid)!
      )
    } else {
      updateHiddenEventTriggerPerStream(streamid, [])
    }
  }

  const onStreamsAdd = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)

    const newStreamData: IStreamInfo[] = []
    newStreams.forEach((streamId: string) => {
      let newStream = props.allStreams.find((item) => {
        return item.streamId === streamId
      })
      newStream && newStreamData.push(newStream)
    })
    await addStreams(newStreamData)
    setNewStreams([])
    setIsSubmitting(false)
  }

  const addStreams = async (streams) => {
    const sceneData = Object.assign({}, props.scene)
    !sceneData.boxStreams && (sceneData.boxStreams = [])
    streams.forEach((stream) => {
      sceneData.boxStreams.push(stream)
    })
    await props.saveScene(sceneData)
  }

  const removeStream = async (streamId) => {
    const sceneData = Object.assign({}, props.scene)
    sceneData.boxStreams = sceneData.boxStreams.filter((item) => {
      return item.streamId !== streamId
    })
    await props.saveScene(sceneData)
  }

  let handleNewStreamsChange = (data) => {
    setNewStreams(data)
  }

  const onCloseDialog = (event) => {
    if (event.target.classList.contains('bx--modal')) {
      return false
    }
    setIsDeleteDialogVisible(false)
  }

  const [openKeys, setOpenKeys] = useState<string[]>([])
  const onOpenCallback = (keys: string[] | string) => {
    if (!Array.isArray(keys)) {
      keys = [keys]
    }
    keys.forEach((key) => {
      if (!openKeys.includes(key)) {
        let stream = props.allStreams.find((stream) => {
          return stream.streamId === key
        })
        stream &&
          stream.enabled &&
          props.loadCameraFrame(
            stream.boxId,
            stream.streamId,
            true,
            calibration
          ) &&
          callStreamEventTriggers(stream.boxId, stream.streamId)
      }
    })
    openKeys.forEach((key) => {
      if (!keys.includes(key)) {
        let stream = props.allStreams.find((stream) => {
          return stream.streamId === key
        })
        stream && props.resetCameraFrame(stream.streamId)
      }
    })
    setOpenKeys(keys)
  }

  let filteredStreams: IStreamInfo[] = props.allStreams.filter((stream) => {
    return (
      !props.scene.boxStreams ||
      !props.scene.boxStreams.find((boxStream) => {
        return boxStream.streamId === stream.streamId
      })
    )
  })

  props.scene.boxStreams &&
    props.scene.boxStreams.sort((x, y) => {
      if (!x.streamName) {
        return 1
      }
      if (!y.streamName) {
        return -1
      }
      return x.streamName.localeCompare(y.streamName)
    })

  const userCanEdit = userIsAtLeast(UserRole.User)

  return (
    <div className="bx--grid bx--grid--full-width scc--scenedetail--tabcontent">
      {userCanEdit && (
        <Row gutter={[16, 16]}>
          <Col flex="auto" className="scc--flex--column">
            <StreamSelection
              placeholder={t('solutions.scenes.streams.addPlaceholder')}
              streams={filteredStreams}
              newStreams={newStreams}
              handleNewStreamsChange={handleNewStreamsChange}
              multi={true}
            />
          </Col>
          <Col flex="100px">
            <Button
              type="primary"
              onClick={onStreamsAdd}
              disabled={isSubmitting}
            >
              {t('solutions.scenes.streams.add')}
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col flex="auto">
          <Collapse
            className="scc--solutions--stream--collapse scc--solutions--streams"
            onChange={onOpenCallback}
            destroyInactivePanel={true}
          >
            {props.scene.boxStreams &&
              props.scene.boxStreams.map((stream, streamIndex) => (
                <Panel // It would be way nicer to move this panel to a separate FC, unfortunately this is not easy because of the Collapse functionality
                  key={stream.streamId}
                  className="scc--solutions--stream--collapse-item"
                  collapsible={stream.readOnly ? 'disabled' : undefined}
                  showArrow={!stream.readOnly}
                  header={
                    stream.streamName && stream.streamName.length > 0
                      ? stream.streamName
                      : t('configuration.group.stream.streamname.placeholder') +
                        (streamIndex + 1)
                  }
                  extra={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <OperationalStatusCell
                        runtimeState={
                          stream.streamStatus && stream.streamStatus.state
                            ? stream.streamStatus.state
                            : ERuntimeState.unknown
                        }
                        key={stream.streamStatus?.state}
                        errorReason={
                          stream.streamStatus &&
                          stream.streamStatus.state === 'NOT_OPERATIONAL'
                            ? stream.streamStatus.errorReason
                            : EErrorReason.unknown
                        }
                      />
                      {userCanEdit && (
                        <Tooltip
                          title={t('solutions.scenes.streams.remove')}
                          placement="bottomRight"
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            className="scc--solutions--remove-stream-button"
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              setIsDeleteDialogVisible(true)
                              setDeleteSceneDetail(stream.streamId)
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  }
                >
                  <div className="bx--col-lg-12 scc--boxdetail--canvas">
                    <DrawCanvas
                      boxId={stream.boxId}
                      streamId={stream.streamId}
                      frame={props.cameraFrames[stream.streamId]}
                      eventTriggers={
                        coordinateBasedEventTriggersPerStream.has(
                          stream.streamId
                        )
                          ? coordinateBasedEventTriggersPerStream.get(
                              stream.streamId
                            )!
                          : ([] as ICoordinateBasedEventTrigger[])
                      }
                      highlightedEventTrigger={''}
                      selectedEventTrigger={''}
                      onSelectEventTrigger={function () {}}
                      isEditable={false}
                      isLoading={props.loadingIds[stream.streamId]}
                      onRefreshButtonClick={(event) => {
                        props.loadCameraFrame(
                          stream.boxId,
                          stream.streamId,
                          true,
                          event.calibration,
                          event.timestamp
                        )
                      }}
                      hiddenEventTriggers={getHiddenEventTriggerPerStream(
                        stream.streamId
                      )}
                      toggleHideAllEventTriggers={() =>
                        toggleHideAllEventTriggers(stream.streamId)
                      }
                      calibration={calibration}
                      recordTrackCalibration={stream.recordTrackCalibration}
                      setCalibration={setCalibration}
                      disabled={!stream.enabled}
                    />
                    {props.cameraFrames[stream.streamId] && stream.enabled && (
                      <NavLink
                        to={`/boxes/${stream.boxId}/${stream.streamId}/setup?sceneId=${props.scene.id}`}
                        onClick={() => {
                          props.resetCameraFrame(stream.streamId)
                        }}
                      >
                        {userCanEdit && (
                          <button className="bx--btn bx--btn--primary">
                            {t('camera.frame.buttonDraw')}
                          </button>
                        )}
                      </NavLink>
                    )}
                  </div>
                </Panel>
              ))}
            {(!props.scene.boxStreams ||
              props.scene.boxStreams.length === 0) && (
              <p className="scc--solutions--streams-empty">
                {t('solutions.scenes.streams.empty')}
              </p>
            )}
          </Collapse>
        </Col>
      </Row>
      <DeleteConfirmationDialog
        onRequestClose={onCloseDialog}
        onRequestSubmit={onStreamRemove}
        onSecondarySubmit={onCloseDialog}
        open={isDeleteDialogVisible}
        customText={t('modal.remove.text')}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    allStreams: state.streams.all,
    loadingIds: state.cameraFrames.loadingIds,
    cameraFrames: state.cameraFrames.byIds
  }
}

export default connect(mapStateToProps, {
  listStreams: streamInfos,
  loadCameraFrame,
  resetCameraFrame
})(SceneStreams)
