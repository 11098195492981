export const DEFAULT_DATE_RANGE = `from 6 days ago to now`
export const DEFAULT_GRANULARITY = 'day'
export const DEFAULT_CHART_TYPE = 'table'

const StateChangeheuristics = (oldState, newState) => {
  const { query, sessionGranularity, chartType } = oldState
  const defaultGranularity = sessionGranularity || DEFAULT_GRANULARITY
  const defaultChartType = chartType || DEFAULT_CHART_TYPE

  if (newState.query) {
    const oldQuery = query
    let newQuery = newState.query

    const { meta } = oldState

    if (!newQuery.measures || newQuery.measures.length === 0) {
      return {
        ...newState,
        query: {},
        chartType: defaultChartType
      }
    }

    if (
      (oldQuery.timeDimensions || []).length === 1 &&
      (newQuery.timeDimensions || []).length === 1 &&
      newQuery.timeDimensions[0].granularity &&
      oldQuery.timeDimensions[0].granularity !==
        newQuery.timeDimensions[0].granularity
    ) {
      newState = {
        ...newState,
        sessionGranularity: newQuery.timeDimensions[0].granularity
      }
    }

    if (
      // eslint-disable-next-line no-mixed-operators
      ((oldQuery.measures || []).length === 0 &&
        (newQuery.measures || []).length > 0) ||
      // eslint-disable-next-line no-mixed-operators
      ((oldQuery.measures || []).length === 1 &&
        (newQuery.measures || []).length === 1 &&
        oldQuery.measures[0] !== newQuery.measures[0])
    ) {
      const defaultTimeDimension = meta.defaultTimeDimensionNameFor(
        newQuery.measures[0]
      )
      newQuery = {
        ...newQuery,
        timeDimensions: defaultTimeDimension
          ? [
              {
                dimension: defaultTimeDimension,
                granularity: defaultGranularity,
                dateRange: DEFAULT_DATE_RANGE
              }
            ]
          : []
      }
      return {
        ...newState,
        query: newQuery,
        chartType: defaultChartType
      }
    }

    if (
      (oldQuery.dimensions || []).length > 0 &&
      (newQuery.dimensions || []).length === 0
    ) {
      newQuery = {
        ...newQuery,
        timeDimensions: (newQuery.timeDimensions || []).map(
          (timeDimension) => ({
            ...timeDimension,
            granularity: timeDimension.granularity || defaultGranularity
          })
        )
      }
      return {
        ...newState,
        query: newQuery,
        chartType: defaultChartType
      }
    }

    if (
      ((oldQuery.dimensions || []).length > 0 ||
        (oldQuery.measures || []).length > 0) &&
      (newQuery.dimensions || []).length === 0 &&
      (newQuery.measures || []).length === 0
    ) {
      newQuery = {
        ...newQuery,
        timeDimensions: [],
        filters: []
      }
      return {
        ...newState,
        query: newQuery,
        sessionGranularity: null
      }
    }
    return newState
  }

  if (newState.chartType) {
    const newChartType = newState.chartType
    if (
      (newChartType === 'line' || newChartType === 'area') &&
      (query.timeDimensions || []).length === 1 &&
      !query.timeDimensions[0].granularity
    ) {
      const [timeDimensions] = query.timeDimensions
      return {
        ...newState,
        query: {
          ...query,
          timeDimensions: [
            { ...timeDimensions, granularity: defaultGranularity }
          ]
        }
      }
    }

    if (
      (newChartType === 'pie' ||
        newChartType === 'table' ||
        newChartType === 'number') &&
      (query.timeDimensions || []).length === 1 &&
      query.timeDimensions[0].granularity
    ) {
      const [timeDimensions] = query.timeDimensions
      return {
        ...newState,
        query: {
          ...query,
          timeDimensions: [
            { ...timeDimensions, granularity: defaultGranularity }
          ]
        }
      }
    }

    //chord makes no sense for other data options but OD
    if (newChartType === 'chord' && (query.timeDimensions || []).length === 1) {
      const [timeDimensions] = query.timeDimensions
      return {
        ...newState,
        query: {
          ...query,
          measures: ['OriginDestinationEvents.count'],
          timeDimensions: [{ ...timeDimensions, granularity: undefined }],
          dimensions: [
            'OriginDestinationEvents.entryZone',
            'OriginDestinationEvents.exitZone'
          ]
        }
      }
    }

    if (
      newChartType === 'heatmap' &&
      (query.timeDimensions || []).length === 1
    ) {
      const [timeDimensions] = query.timeDimensions
      return {
        ...newState,
        query: {
          ...query,
          measures: ['JourneySegments.count'],
          timeDimensions: [
            { ...timeDimensions, granularity: defaultGranularity }
          ],
          dimensions: [
            'JourneySegments.startLocationName',
            'JourneySegments.endLocationName'
          ]
        }
      }
    }
  }

  return newState
}
export default StateChangeheuristics
