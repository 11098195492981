import {
  GET_CAMERA_FRAME,
  GET_CAMERA_FRAME_FAILURE,
  GET_CAMERA_FRAME_SUCCESS,
  RESET_CAMERA_FRAME
} from './actionTypes'
import axios from 'axios'
import { Config } from '../../services/config'
import { client, Schemas } from '../middleware/api'
import { normalize } from 'normalizr'
import {
  getTokenInformation,
  leaveHeaderEmptyOnMissingOrMainTenant,
  TokenInformation
} from '../../services/apiTokenProvider'
import { ECameraFrameMode } from '../../types/cameraFrame'

/**
 * ============================
 * CAMERA FRAME ACTIONS
 * ============================
 */

/**
 * Resets the camera frame.
 */
export const resetCameraFrame = (streamId) => async (dispatch) => {
  dispatch({ type: RESET_CAMERA_FRAME, id: streamId })
}

/**
 * Fetches a single camera frame from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 */

const getHeaders = (tokenInformation: TokenInformation) =>
  leaveHeaderEmptyOnMissingOrMainTenant(tokenInformation)
    ? {
        Authorization: `Bearer ${tokenInformation.idToken}`
      }
    : {
        Authorization: `Bearer ${tokenInformation.idToken}`,
        Tenant: tokenInformation.tenantInformations!.tenantId
      }

const fetchCameraFrame = async (
  boxId,
  streamId,
  calibration,
  requestedTimestamp
) => {
  const timestamp = new Date().getTime()
  const tokenInformation = await getTokenInformation()
  let url = `boxes/${boxId}/${streamId}/getFrame?t=${timestamp}`
  if (calibration === ECameraFrameMode.trackCalibration && requestedTimestamp) {
    url = `${url}&calibration=${calibration}&requestedTimestamp=${requestedTimestamp}`
  } else if (calibration) {
    url = `${url}&calibration=${calibration}`
  }
  return client({
    url: url,
    headers: getHeaders(tokenInformation)
  })
}

// Timeout function after which the request will be retried
let retryTimeout: NodeJS.Timeout | undefined

const ongoingRequests = {}

/**
 * Fetches a single camera frame from the API unless it is cached.
 * Relies on Redux Thunk middleware.
 */
export const loadCameraFrame = (
  boxId,
  streamId,
  forceRefresh: boolean,
  calibration?: boolean,
  timestamp?: string,
  retries?: number
) => async (dispatch, getState) => {
  if (!boxId || !streamId) {
    return null
  }

  if (retryTimeout) {
    clearTimeout(retryTimeout)
  }

  // Set max number of retries
  if (typeof retries === 'undefined') {
    retries = Config.CAMERA_FRAME_REQUEST_MAX_RETRIES
  }

  const currentTimestamp = new Date().getTime()
  const { cameraFrames } = getState()

  // Check cache
  if (!forceRefresh && cameraFrames.allIds.indexOf(streamId) > -1) {
    const frame = cameraFrames.byIds[streamId]

    // Check if cached frame is expired
    if (frame.timestamp > currentTimestamp - Config.CAMERA_FRAME_CACHE_TIME) {
      return Promise.resolve(frame)
    }
  }

  // Check if there's an ongoing request for the same box/stream id
  if (
    ongoingRequests[`${boxId}-${streamId}`] &&
    !cameraFrames.gotResetByIds[streamId]
  ) {
    return
  }

  // Mark the request as ongoing
  ongoingRequests[`${boxId}-${streamId}`] = true

  dispatch({ type: GET_CAMERA_FRAME, id: streamId })

  return fetchCameraFrame(boxId, streamId, calibration, timestamp)
    .then((response) => {
      // Frame received => continue
      if (response.status === 200) {
        const data = response.data
        data.id = streamId

        const payload = Object.assign({}, normalize(data, Schemas.FRAME))
        return dispatch({ type: GET_CAMERA_FRAME_SUCCESS, response: payload })
      }

      // Retry request in case the API responded with 204 and the
      // maximum amount of retries has not been exceeded
      if (response.status === 204 && retries && retries > 0) {
        retries = retries - 1

        // Try reloading after timeout as box is not ready
        retryTimeout = setTimeout(() => {
          dispatch(
            loadCameraFrame(
              boxId,
              streamId,
              forceRefresh,
              calibration,
              timestamp,
              retries
            )
          )
        }, Config.CAMERA_FRAME_REQUEST_INTERVAL)

        return
      }

      // server could not produce valid image response in retry window, i.e. device not fully synced for reasons
      if (response.status === 204 && retries && retries === 0) {
        dispatch({
          type: GET_CAMERA_FRAME_FAILURE,
          id: streamId
        })
        return
      }

      throw Error(`Retrieving camera frame failed unexpectedly`)
    })
    .catch((error) => {
      // Prevent cancelled requests from updating the state
      if (axios.isCancel(error)) {
        return
      }

      // this also handles (axios error) 404. This happens in cases the deviceconfig is fully synced, but no image can be retrieved (wrong camera config?)
      dispatch({
        type: GET_CAMERA_FRAME_FAILURE,
        id: streamId
      })
    })
    .finally(() => {
      // Mark the request as finished
      delete ongoingRequests[`${boxId}-${streamId}`]
    })
}
