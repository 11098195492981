import { useEffect, useRef } from 'react'

export const useInterval = (
  callback,
  delay,
  stopAfter,
  intervalActive,
  setIntervalActive
) => {
  const savedCallback = useRef<Function>()
  const intervalId = useRef<NodeJS.Timeout>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (
        savedCallback.current !== null &&
        savedCallback.current !== undefined
      ) {
        savedCallback.current()
      }
    }
    if (delay !== null && intervalActive) {
      intervalId.current = setInterval(tick, delay)
      return () => {
        if (intervalId.current !== undefined) {
          clearInterval(intervalId.current)
          if (setIntervalActive) {
            setIntervalActive(false)
          }
        }
      }
    }
  }, [delay, intervalActive, setIntervalActive])

  // Clear the interval after stopAfter milliseconds
  useEffect(() => {
    if (stopAfter !== null && intervalActive) {
      const timeoutId = setTimeout(() => {
        if (intervalId.current !== undefined) {
          clearInterval(intervalId.current)
          if (setIntervalActive) {
            setIntervalActive(false)
          }
        }
      }, stopAfter)
      return () => clearTimeout(timeoutId)
    }
  }, [stopAfter, intervalActive, setIntervalActive])
}
