import React from 'react'
import { IScene } from '../../../../types/scene'

interface IProps {
  scene: IScene
}

const NameIdCell: React.FC<IProps> = ({ scene }) => {
  return (
    <>
      <span className="scc--box_name">{scene.name}</span>
    </>
  )
}

export default NameIdCell
