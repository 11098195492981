export interface IStreamLicense {
  productType: ELicenseType
  orderNumber: string
  invoiceNumber: string
  validFrom: Date
  validTo: Date
  licensedStreams: number
}

export enum ELicenseType {
  SPS = 'SPS',
  SPS_NFR = 'SPS_NFR'
}
export enum ELicenseState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED'
}

export interface ILicenseStatus {
  licensedStreams: number
  activeStreams: number
  licensePool: boolean
  streamsAvailable: boolean
  licenseValid: boolean
}
