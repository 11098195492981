export interface IGroup {
  id: string
  name: string
  new?: boolean
}

export const DefaultGroup: IGroup = {
  id: 'default',
  name: 'Default'
}
