import { ICameraFrame } from '../../types/cameraFrame'
import { EEventTriggerType } from '../../types/eventTrigger'
import { CrossingLine } from './CrossingLine'
import { CrossingLineEventTrigger } from '../../types/crossingLine'
import { Region } from './Region'
import React from 'react'

/**
 * Calculates the proper dimensions based on the available space
 * and the image size.
 *
 * @param container: HTMLElement The HTML element to place the canvas in.
 * @param frame
 * @param isLoading
 * @param defaultCanvasDimensions
 */
export const calculateDimensions = (
  container,
  frame: ICameraFrame | undefined,
  isLoading,
  defaultCanvasDimensions
) => {
  if (!container || !frame) {
    return defaultCanvasDimensions
  }

  const parentColumn = container.closest('.scc--flex--column')
  const maxContainerHeight = parentColumn
    ? (parentColumn.offsetHeight * 2) / 3
    : 0
  const containerWidth = container.offsetWidth
  const { width, height } = frame
  const ratio = height / width

  // Get max possible width
  let calculatedWidth = Math.min(containerWidth, width)
  let calculatedHeight = calculatedWidth * ratio

  // Make sure the calculated canvas is not higher than
  // 2/3 of the column in order to properly show the table beneath
  if (parentColumn && calculatedHeight > maxContainerHeight) {
    calculatedHeight = maxContainerHeight
    calculatedWidth = maxContainerHeight / ratio
  }

  return {
    height: calculatedHeight,
    width: calculatedWidth
  }
}

/**
 * Helper to return the event trigger component.
 * @param eventTrigger The event trigger data object.
 * @param stage
 * @param events
 * @param isHighlighted
 * @param isSelected
 * @param isEditable
 * @param key The key to set as reference.
 */
export const getEventTrigger = (
  eventTrigger,
  stage,
  events,
  isHighlighted,
  isSelected,
  isEditable,
  key,
  isFocusArea
) => {
  if (!stage) {
    return null
  }

  if (eventTrigger.objectType === EEventTriggerType.crossingLine) {
    return (
      <CrossingLine
        key={`${eventTrigger.coordinates}-${key}`}
        eventTrigger={eventTrigger as CrossingLineEventTrigger}
        stage={stage}
        onDragStart={events.onDragStart}
        onDragEnd={events.onDragEnd}
        onClick={events.onClick}
        isEditable={isEditable}
        isHighlighted={isHighlighted}
        isSelected={isSelected}
      />
    )
  } else if (
    eventTrigger.objectType === EEventTriggerType.regionOfInterest ||
    eventTrigger.objectType === EEventTriggerType.virtualDoor ||
    eventTrigger.objectType === EEventTriggerType.originDestinationZone
  ) {
    return (
      <Region
        key={`${eventTrigger.coordinates}-${key}`}
        eventTrigger={eventTrigger}
        stage={stage}
        onDragStart={events.onDragStart}
        onDragEnd={events.onDragEnd}
        onClick={events.onClick}
        isEditable={isEditable}
        isHighlighted={isHighlighted}
        isSelected={isSelected}
        isFocusArea={isFocusArea}
      />
    )
  }

  return null
}
