import { EConnectionState, IBoxStatus } from '../../../types/boxStatus'
import React from 'react'
import { ReactComponent as WifiIcon } from '../../../theme/icons/wifi_off.svg'
import { useTranslation } from 'react-i18next'

interface IConnectionStatusCellProps {
  boxStatus: IBoxStatus | undefined
}

const ConnectionStatusCell: React.FC<IConnectionStatusCellProps> = ({
  boxStatus
}) => {
  const { t } = useTranslation()

  let connectionState = boxStatus
    ? boxStatus.connectionState.toLowerCase()
    : EConnectionState.unknown.toLowerCase()
  let className = `scc--${connectionState}`

  return (
    <div className={className + ' scc--centered'}>
      <WifiIcon />
      <br />
      <span>{t(`boxList.dataTable.connectionStatus.${connectionState}`)}</span>
    </div>
  )
}

export default ConnectionStatusCell
