import React from 'react'

interface IImageContainerProps {
  className?: string
  image: string
  altText?: string
}

const ImageContainer: React.FC<IImageContainerProps> = (props) => {
  return (
    <div className={props.className}>
      <img src={props.image} alt={props.altText} />
    </div>
  )
}

export default ImageContainer
