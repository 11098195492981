import { Col, Row, Space } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IControlCenterUser,
  INewControlCenterUser
} from '../../types/controlCenterUser'
import UserList from './UserList'
import {
  callCreateUser,
  callDeleteUser,
  callSaveUser
} from '../../redux/actions/user'
import { useDispatch } from 'react-redux'
import UserModal from './UserModal'
import NewUserModal from './NewUserModal'
import { DeleteConfirmationDialog } from '../ConfirmDialog/DeleteConfirmationDialog'

const UserManagmentPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [showUserModal, setShowUserModal] = useState<boolean>(false)
  const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false)

  const [
    isDeleteConfirmationVisible,
    setDeleteConfirmationVisible
  ] = useState<boolean>(false)

  const [selectedUser, setSelectedUser] = useState<IControlCenterUser | null>(
    null
  )

  //----------------------------------------------------------------------------
  //deletion
  //----------------------------------------------------------------------------
  const handleConfirmDeleteUser = () => {
    if (!selectedUser) {
      return
    }
    dispatch(callDeleteUser(selectedUser))
    setDeleteConfirmationVisible(false)
    setSelectedUser(null)
  }

  const handleCreateUser = () => {
    //set initial
    setShowUserModal(false)
    setShowNewUserModal(true)
  }

  const handleSaveNewUser = (user: INewControlCenterUser) => {
    dispatch(callCreateUser(user))
    setShowNewUserModal(false)
  }

  const handleDeleteUser = (user: IControlCenterUser) => {
    setSelectedUser(JSON.parse(JSON.stringify(user))) //prevent leaking of reference
    setDeleteConfirmationVisible(true)
  }
  //----------------------------------------------------------------------------

  const handleSaveUser = (user: IControlCenterUser) => {
    dispatch(callSaveUser(user))
    setShowUserModal(false)
    setSelectedUser(null)
  }

  const handleEditUser = (user: IControlCenterUser) => {
    setSelectedUser(JSON.parse(JSON.stringify(user)))
    setShowUserModal(true)
    setShowNewUserModal(false)
  }

  return (
    <>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Row>
          <Col>
            <h3>{t('users.title')}</h3>
          </Col>
        </Row>
        <UserList
          addUser={function (): void {
            handleCreateUser()
          }}
          deleteUser={handleDeleteUser}
          changePermissionsForUser={handleEditUser}
        />
      </Space>
      {selectedUser && showUserModal && (
        <UserModal
          show={showUserModal}
          close={() => {
            setShowUserModal(false)
            setSelectedUser(null)
          }}
          user={selectedUser}
          saveUser={handleSaveUser}
        />
      )}

      {showNewUserModal && (
        <NewUserModal
          show={showNewUserModal}
          close={() => {
            setShowNewUserModal(false)
          }}
          createUser={handleSaveNewUser}
        />
      )}

      <DeleteConfirmationDialog
        onRequestClose={() => {
          setDeleteConfirmationVisible(false)
          setSelectedUser(null)
        }}
        onSecondarySubmit={() => {
          setDeleteConfirmationVisible(false)
          setSelectedUser(null)
        }}
        customText={t('users.modal.confirmDelete')}
        onRequestSubmit={handleConfirmDeleteUser}
        open={isDeleteConfirmationVisible}
      />
    </>
  )
}

export default UserManagmentPage
