export enum ERuntimeState {
  operational = 'OPERATIONAL',
  notoperational = 'NOT_OPERATIONAL',
  notconfigured = 'NOT_CONFIGURED',
  unknown = 'UNKNOWN',
  pending = 'PENDING',
  disabled = 'DISABLED',
  warning = 'WARNING',
  standby = 'STANDBY'
}
