export interface ICameraSettings {
  cameraResolutionInfo: ICameraResolutionInfo
  cameraCodec: ICameraCodec
  cameraBitRate: ICameraBitRate
  cameraFrameRate: ICameraFrameRate
  cameraImageSettings: ICameraImageSettings
}

export interface IResolution {
  width: number
  height: number
}

export interface ICameraResolutionInfo {
  currentResolution: IResolution
  availableResolutions: [IResolution]
}

export interface ICameraCodec {
  codec: string
}

export interface ICameraBitRate {
  rate: number
}

export interface ICameraFrameRate {
  rate: number
}

export enum EDayNightMode {
  AUTO = 'AUTO',
  DAY = 'DAY',
  NIGHT = 'NIGHT'
}

export enum EZoomCommand {
  DECREASE = 'DECREASE',
  DECREASE_STRONG = 'DECREASE_STRONG',

  INCREASE_STRONG = 'INCREASE_STRONG',
  INCREASE = 'INCREASE'
}

export interface ICameraImageSettings {
  brightness: number
  contrast: number
  saturation: number
  sharpness: number
  cameraExposureTime: ICameraExposureTime
  dayNightMode: EDayNightMode
  hlcStatus: boolean
  hlc: number
  hasOSD: boolean
  hasZoom: boolean
  wdrSettings: IWDRSettings
}

export interface IWDRSettings {
  wdrEnabled: boolean
  wdrValue: number
}

export interface ICameraExposureTime {
  exposureTimeMicroSeconds: number
  minExposureTimeMicroSeconds: number
  maxExposureTimeMicroSeconds: number
}

export interface ICameraZoomCommand {
  command: EZoomCommand
}

export const EXPOSURE_TIME_OPTIONS = [
  {
    value: 40000,
    label: '1/25'
  },
  {
    value: 20000,
    label: '1/50'
  },
  {
    value: 10000,
    label: '1/100'
  },
  {
    value: 5000,
    label: '1/200'
  },
  {
    value: 2000,
    label: '1/500'
  },
  {
    value: 1000,
    label: '1/1000'
  }
]
