import './EdgeUpdate.scss'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { loadIsNewestEdgeVersionTargeted } from '../../redux/actions/tenantAdministration'
import { useTranslation } from 'react-i18next'
import { CloseCircleOutlined } from '@ant-design/icons'
import { ReduxStore } from '../../redux/store/configureStore'

const EdgeUpdateWarning: React.FC = () => {
  const { t } = useTranslation()
  const { edgeUpToDate } = useSelector((state: ReduxStore) => ({
    edgeUpToDate: state.tenantAdministration.edgeVersionUpdated
  }))

  const dispatch = useDispatch()
  const [messageVisible, setMessageVisible] = useState<boolean>(false)

  useEffect(() => {
    dispatch(loadIsNewestEdgeVersionTargeted())
  }, [dispatch])

  useEffect(() => {
    if (edgeUpToDate === undefined) {
      return
    }
    const licenseExceededMessage = () => {
      message.warning({
        key: 'edgeOutdated',
        content: (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: t('edgeUpdate.warningFloat')
              }}
            ></span>
            &nbsp;
            <CloseCircleOutlined
              onClick={() =>
                message.warning({
                  key: 'edgeOutdated',
                  content: (
                    <span className="scc--edgeUpdate--warning-float-permanent">
                      {t('edgeUpdate.warningFloatHidden')}
                    </span>
                  ),
                  duration: 0, // infinite
                  onClick: licenseExceededMessage
                })
              }
            />
          </>
        ),
        duration: 0 // infinite
      })
    }

    if (!edgeUpToDate) {
      if (messageVisible) {
        return
      }
      setMessageVisible(true)
      licenseExceededMessage()
    } else {
      if (!messageVisible) {
        return
      }
      setMessageVisible(false)
      message.destroy('edgeOutdated')
    }
  }, [edgeUpToDate, t, messageVisible])

  return <></>
}

export default EdgeUpdateWarning
