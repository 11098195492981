import '../../solutions/components/SceneMap/SceneMap.scss'

import React, { useContext, useEffect } from 'react'
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  IAzureMapOptions,
  IAzureMapsContextProps,
  AzureMapsContext
} from 'react-azure-maps'
import { AuthenticationType, data, MapMouseEvent } from 'azure-maps-control'
import { Config } from '../../services/config'
import { ICoordinates } from '../../types/stream'
import { cameraIcon } from '../../helpers/CameraSelector'
import { MapViewport } from './LocationPickerModal'
import { Input } from 'antd'

const AZURE_MAPS_KEY = Config.AZURE_MAPS_KEY

interface ICoordinatesPicker {
  streamCoordinates: ICoordinates | undefined
  setStreamCoordinates: (e: ICoordinates) => void
  resetView: boolean
  setResetView: (b: boolean) => void
  viewport: MapViewport | undefined
  setViewport: (e: MapViewport | undefined) => void
  addressSearchRef: Input | null
}

const CoordinatesSelectionMap: React.FC<ICoordinatesPicker> = (props) => {
  const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(
    AzureMapsContext
  )

  const {
    streamCoordinates,
    setStreamCoordinates,
    resetView,
    setResetView,
    viewport,
    setViewport,
    addressSearchRef
  } = props

  /* ---------------------------------------
  CAUTION!!! azure switches latitude and longitude around -> will result in wrong cooridnates if not hnadled carefully
  ------------------------------------------ */

  const centerBoundingBox = data.BoundingBox.fromLatLngs(
    streamCoordinates
      ? [[streamCoordinates.longitude, streamCoordinates.latitude]]
      : [[0, 0]]
  )

  const option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: AZURE_MAPS_KEY
    },
    wheelZoomRate: 1 / 75,
    style: 'road_shaded_relief',
    showLogo: false,
    showFeedbackLink: false
  }

  const renderSceneMarker = (): any => {
    if (!streamCoordinates) {
      return
    }
    return (
      <AzureMapFeature
        key={
          'streamMarker' +
          streamCoordinates.latitude +
          '.' +
          streamCoordinates.longitude
        }
        id={
          'streamMarker' +
          streamCoordinates.latitude +
          '.' +
          streamCoordinates.longitude
        }
        type="Point"
        coordinate={data.Position.fromLatLng(
          streamCoordinates.longitude,
          streamCoordinates.latitude
        )}
        properties={{
          id: 'streamMarker',
          name: 'Geo Position',
          description: 'Position of the Stream'
        }}
      />
    )
  }

  useEffect(() => {
    if (isMapReady && mapRef && centerBoundingBox && streamCoordinates) {
      if (resetView) {
        if (viewport) {
          mapRef.setCamera({
            bounds: data.BoundingBox.fromEdges(
              viewport.west,
              viewport.south,
              viewport.east,
              viewport.north
            )
          })
        } else {
          let centerPoint = data.Position.fromLatLng([
            streamCoordinates.longitude,
            streamCoordinates.latitude
          ])
          mapRef.setCamera({ center: centerPoint })
          let cameraZoom = mapRef.getCamera().zoom
          mapRef.setCamera({
            center: centerPoint,
            /* some explaination - the goal her is to zoom in a click.
             * depending on how far we zoomed out it should zoom in more
             * because fully zummed out is around 0.* this results in to big jumps for completely zoomed out
             * therefore the Math.max
             * also it should stop zooming in further at some point therefore Math.min */
            zoom: cameraZoom
              ? Math.min(cameraZoom + 10 / Math.max(cameraZoom, 2), 16)
              : 16
          })
        }
        setViewport(undefined)
        setResetView(false)
      }
      // always stay focused on addressSearch
      if (addressSearchRef) {
        addressSearchRef.focus()
      }
    }
  }, [
    mapRef,
    isMapReady,
    centerBoundingBox,
    resetView,
    streamCoordinates,
    setResetView,
    viewport,
    setViewport,
    addressSearchRef
  ])

  return (
    <AzureMap
      imageSprites={[
        {
          id: 'swarm-camera',
          icon: cameraIcon()
        }
      ]}
      options={option}
      events={{
        click: (e: MapMouseEvent) => {
          if (e.position) {
            e.preventDefault()
            setStreamCoordinates({
              latitude: e.position[1],
              longitude: e.position[0]
            })
            setResetView(true)
          }
        }
      }}
    >
      <AzureMapDataSourceProvider id={'Point AzureMapDataSourceProvider'}>
        <AzureMapLayerProvider
          id="stream-map-data"
          options={{
            iconOptions: {
              image: 'swarm-camera',
              allowOverlap: true,
              ignorePlacement: true,
              offset: [4, 12.7],
              size: 0.8
            }
          }}
          type="SymbolLayer"
        />
        {renderSceneMarker()}
      </AzureMapDataSourceProvider>
    </AzureMap>
  )
}

export default CoordinatesSelectionMap
