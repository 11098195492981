import React from 'react'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { LazyLog } from 'react-lazylog'

import '../../theme/react-grid-overridden-placeholder.scss'
import { Button, Modal, Result } from 'antd'
import { useTranslation } from 'react-i18next'
import { BugOutlined, DownloadOutlined } from '@ant-design/icons'
import FileSaver from 'file-saver'
import { useQuery } from 'react-query'
import rawRequest from '../../services/apiTokenProvider'
import LoadingAnimation from '../LoadingAnimation'

const getLog = async ({ queryKey }) => {
  const [, boxId] = queryKey

  if (boxId) {
    const timestamp = new Date().getTime()
    return rawRequest({
      url: `/boxes/${boxId}/log?t=${timestamp}`
    })
  }
}

const ModalSkeleton = ({
  children,
  isModalVisible,
  setModalVisible,
  boxId,
  downloadLogs
}: {
  children?: React.ReactNode
  isModalVisible: boolean
  setModalVisible: (boolean) => void
  boxId: string
  downloadLogs?: () => void
}) => {
  const handleCancel = () => {
    setModalVisible(false)
  }

  const { t } = useTranslation()
  return (
    <Modal
      title={t('configuration.deviceLogs.logsheader') + boxId}
      visible={isModalVisible}
      onCancel={handleCancel}
      width={1000}
      footer={[
        <div key="logmodalFooter">
          <Button
            key="download"
            onClick={downloadLogs}
            type="primary"
            disabled={!downloadLogs}
            icon={<DownloadOutlined />}
          >
            {t('configuration.deviceLogs.download')}
          </Button>
          <Button key="back" onClick={handleCancel}>
            {t('configuration.deviceLogs.back')}
          </Button>
        </div>
      ]}
    >
      <div key="logview" style={{ height: '40rem', width: '100%' }}>
        {children}
      </div>
    </Modal>
  )
}

const DeviceLogViewerModal = ({
  visibleState,
  boxId
}: {
  visibleState
  boxId: string
}) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = visibleState
  const { data, error, isLoading } = useQuery(['getLog', boxId], getLog, {
    enabled: !!boxId && isModalVisible
  })
  let inCompleteData = false

  if (!isModalVisible) {
    return null
  }

  if (isLoading) {
    return (
      <ModalSkeleton
        boxId={boxId}
        isModalVisible={isModalVisible}
        setModalVisible={setIsModalVisible}
      >
        <LoadingAnimation />
      </ModalSkeleton>
    )
  }

  if (data) {
    if (data.data && data.data.rawLog) {
      const downloadFunction = () => {
        const blob = new Blob([data.data.rawLog], {
          type: 'text/plain;charset=utf-8'
        })
        const filename = boxId + '_logs.txt'
        FileSaver.saveAs(blob, filename, { autoBom: true })
      }

      return (
        <ModalSkeleton
          boxId={boxId}
          isModalVisible={isModalVisible}
          setModalVisible={setIsModalVisible}
          downloadLogs={downloadFunction}
        >
          <LazyLog
            extraLines={1}
            enableSearch
            selectableLines
            text={data.data.rawLog}
            caseInsensitive
          />
        </ModalSkeleton>
      )
    } else {
      inCompleteData = true
    }
  }

  if (error || inCompleteData) {
    return (
      <ModalSkeleton
        boxId={boxId}
        isModalVisible={isModalVisible}
        setModalVisible={setIsModalVisible}
      >
        <Result
          style={{
            height: '40rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
          title={t('configuration.deviceLogs.errorLoading')}
          icon={<BugOutlined />}
          subTitle={t('configuration.deviceLogs.sorryError')}
        />
      </ModalSkeleton>
    )
  }

  return null
}

export default DeviceLogViewerModal
