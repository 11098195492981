import { AuthProvider } from '../provider/AuthProvider'
import { TENANT_STORAGE_NAME, TokenClaims } from '../services/apiTokenProvider'

export enum UserRole {
  SuperAdmin = 'Superadmin',
  Admin = 'Admin',
  User = 'User',
  Viewer = 'Viewer'
}

export const getUserRole = () => {
  let claims: TokenClaims | undefined = AuthProvider.getActiveAccount()
    ?.idTokenClaims

  if (!claims || !claims.aud) {
    return
  }

  let tenant = localStorage.getItem(TENANT_STORAGE_NAME)

  if (!tenant || tenant === claims.aud) {
    return claims.roles?.sort((a, b) => getRolePower(b) - getRolePower(a))[0]
  } else {
    if (!claims.tenantInfo) {
      return
    }
    let subTenantRoleInformation: {
      tenant: string
      roles: string[]
    }[] = JSON.parse(claims.tenantInfo)
    return subTenantRoleInformation
      .find((info) => info.tenant === tenant)
      ?.roles.sort((a, b) => getRolePower(b) - getRolePower(a))[0]
  }
}

export const getRolePower = (role) => {
  switch (role) {
    case UserRole.SuperAdmin:
      return 3
    case UserRole.Admin:
      return 2
    case UserRole.User:
      return 1
    case UserRole.Viewer:
      return 0
    default:
      return -1
  }
}

export const userIsAtLeast = (role: UserRole) => {
  return !!getUserRole() && getRolePower(getUserRole()) >= getRolePower(role)
}
