import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'
import {
  CAMERA_CONFIG_REQUEST,
  CAMERA_CONFIG_REQUEST_FAILURE,
  CAMERA_CONFIG_REQUEST_SUCCESS,
  SAVE_CAMERA_CONFIG_REQUEST,
  SAVE_CAMERA_CONFIG_REQUEST_FAILURE,
  SAVE_CAMERA_CONFIG_REQUEST_SUCCESS
} from './actionTypes'

import { ICameraConfiguration } from '../../types/camera_configuration'

/**
 * ============================
 * CAMERA CONFIGURATION ACTIONS
 * ============================
 */

/**
 * Fetches a single configuration from the API.
 * Relies on the custom API middleware defined in ../middleware/api.ts.
 * @param boxId
 * @param streamId
 */
const fetchCameraConfig = (boxId, streamId) => ({
  [CALL_API]: {
    types: [
      CAMERA_CONFIG_REQUEST,
      CAMERA_CONFIG_REQUEST_SUCCESS,
      CAMERA_CONFIG_REQUEST_FAILURE
    ],
    endpoint: `boxes/${boxId}/${streamId}/configurations/camera`,
    schema: Schemas.CONFIG,
    id: streamId
  }
})

/**
 * Fetches a single configuration from the API unless it is cached.
 * Relies on Redux Thunk middleware.
 * @param boxId
 * @param streamId
 */
export const loadCameraConfig = (boxId, streamId) => (dispatch, getState) => {
  const { cameraConfigurations } = getState()

  if (!boxId || !streamId) {
    return null
  }

  if (cameraConfigurations.allIds.indexOf(streamId) > -1) {
    return Promise.resolve(cameraConfigurations.byIds[streamId])
  }

  return dispatch(fetchCameraConfig(boxId, streamId)).then(
    (data) => data.response.entities.configurations[data.response.result]
  )
}

/**
 * Save a configuration via the API.
 * Will use PUT if an id is provided, POST if not.
 * @param config: CONFIG The configuration data to be saved.
 */
export const saveCameraConfig = (config: ICameraConfiguration) => {
  return {
    [CALL_API]: {
      types: [
        SAVE_CAMERA_CONFIG_REQUEST,
        SAVE_CAMERA_CONFIG_REQUEST_SUCCESS,
        SAVE_CAMERA_CONFIG_REQUEST_FAILURE
      ],
      method:
        typeof config.boxId !== 'undefined' &&
        config.boxId !== '' &&
        typeof config.streamId !== 'undefined' &&
        config.streamId !== ''
          ? HTTP_METHOD.PUT
          : HTTP_METHOD.POST,
      payload: config,
      endpoint:
        typeof config.boxId !== 'undefined' &&
        config.boxId !== '' &&
        typeof config.streamId !== 'undefined' &&
        config.streamId !== ''
          ? `boxes/${config.boxId}/${config.streamId}/configurations/camera`
          : 'configurations',
      schema: Schemas.CONFIG
    }
  }
}
