import {
  FormGroup,
  FormLabel,
  Select,
  SelectItem
} from 'carbon-components-react'
import { EBoxModel } from '../../types/boxModel'
import { EEventTriggerType } from '../../types/eventTrigger'
import FormLabelWithTooltip from '../FormLabelWithToolTip'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ERegionOfInterestType
} from '../../types/regionOfInterest'
import { Menu, Button, Space, Typography, Divider, Collapse } from 'antd'
import {
  CarOutlined,
  ClockCircleOutlined,
  SettingOutlined,
  TeamOutlined,
  ToolOutlined
} from '@ant-design/icons'
import { Config } from '../../services/config'
import { GlobalTriggerSettings, TriggerSettings } from './TriggerSettings'

const { SubMenu } = Menu

const excludeSupport = Config.EXCLUDE_SUPPORT

const { Title, Paragraph, Text, Link } = Typography
const { Panel } = Collapse

const SceneSetup = ({
  heatMap,
  anpr,
  shouldShowLPMode,
  anprOptionaAvailable,
  anonymisedAnprOptionAvailable,
  enableRoiInterval,
  onButtonClick,
  eventTrigger,
  hasRuleAttached,
  register,
  onValueChanged,
  anprStorageDurationChanged,
  onToggleHeatmap,
  onLPModeChange,
  onToggleDirection,
  onToggleSpeedEstimate,
  onToggleMultipleCrossings,
  onToggleRoiTrigger,
  onToggleRoiType,
  onChangeRoiCapacity,
  model,
  lpMode,
  roiInterval,
  errors,
  enableFocusArea,
  onToggleAttachLicensePlateImage,
  hasCustomMqttConfig,
  attachLicensePlateImage
}) => {
  const { t } = useTranslation()
  const personModelSelected =
    model &&
    (model === EBoxModel.personDetectorDemoStandardFast ||
      model === EBoxModel.headDetectorRetailStandard)

  let clTemplate = (
    <>
      <FormLabel id="draw.form.buttonAddCl" />
      <div className="draw-config-form__buttons">
        <Button
          onClick={(event) => {
            onButtonClick(event, EEventTriggerType.crossingLine)
          }}
        >
          {t('draw.form.buttonNewCrossingLine')}
        </Button>
      </div>
    </>
  )

  let odTempalte = (
    <>
      <FormLabel id="draw.form.buttonAddZone" />
      <div className="draw-config-form__buttons">
        <Button
          className="scc--full-width"
          onClick={(event) => {
            onButtonClick(event, EEventTriggerType.originDestinationZone)
          }}
        >
          {t('draw.form.buttonNewZone')}
        </Button>
      </div>
    </>
  )

  let roiTemplate = (
    <>
      <FormLabel id="draw.form.buttonAddRoi" />
      <div className="draw-config-form__buttons">
        <Button
          onClick={(event) => {
            onButtonClick(event, EEventTriggerType.regionOfInterest)
          }}
        >
          {t('draw.form.buttonNewRegionOfInterest')}
        </Button>
      </div>
    </>
  )

  function TrafficTriggers() {
    return (
      <>
        {clTemplate}
        {odTempalte}
        {roiTemplate}
      </>
    )
  }

  function PeopleTriggers() {
    return (
      <>
        {clTemplate}
        {odTempalte}
        {roiTemplate}
        <FormLabel id="draw.form.buttonAddDoor" />
        <div className="draw-config-form__buttons">
          <Button
            className="scc--full-width"
            onClick={(event) => {
              onButtonClick(event, EEventTriggerType.virtualDoor)
            }}
          >
            {t('draw.form.buttonAddDoor')}
          </Button>
        </div>
      </>
    )
  }

  function ParkingTriggers() {
    return (
      <>
        <FormLabel id="draw.form.buttonAddCl" />
        <div className="draw-config-form__buttons">
          <Button
            onClick={(event) => {
              onButtonClick(event, EEventTriggerType.crossingLine)
            }}
          >
            {t(
              shouldShowLPMode
                ? 'draw.form.buttonNewBarrierAnpr'
                : 'draw.form.buttonNewBarrier'
            )}
          </Button>
        </div>
        <FormLabel id="draw.form.buttonAddRoi" />
        <div className="draw-config-form__buttons">
          <Button
            onClick={(event) => {
              onButtonClick(
                event,
                EEventTriggerType.regionOfInterest,
                ERegionOfInterestType.parking,
                1
              )
            }}
          >
            {t('draw.form.buttonNewSingleSpace')}
          </Button>
        </div>
        <FormLabel id="draw.form.buttonAddRoi" />
        <div className="draw-config-form__buttons">
          <Button
            onClick={(event) => {
              onButtonClick(
                event,
                EEventTriggerType.regionOfInterest,
                ERegionOfInterestType.parking,
                5
              )
            }}
          >
            {t('draw.form.buttonNewMultiSpace')}
          </Button>
        </div>
      </>
    )
  }

  const classes = t(`draw.form.box.model.models.${model}.classes.items`, {
    returnObjects: true
  })

  const usecases_groups = t(
    `draw.form.box.model.models.${model}.usecases.items`,
    { returnObjects: true }
  )

  return (
    <>
      <FormGroup
        legendText={t('draw.form.title')}
        className="scc--device-config--title"
      >
        <Space direction="vertical">
          <em className="scc--required--hint">{t('draw.form.hint')}</em>
          <Select
            id="draw.form.box.model"
            className="scc--formfield__value-set"
            inline={false}
            invalid={errors['box.model'] !== undefined}
            invalidText={t('draw.form.box.model.invalidText')}
            labelText={false}
            name="box.model"
            value={model || ''}
            onChange={onValueChanged}
            ref={register({ required: true })}
          >
            <SelectItem
              key="empty"
              value=""
              text={t('draw.form.box.model.placeholder')}
            />
            {Object.values(EBoxModel)
              .filter(
                (item) =>
                  item !== EBoxModel.trafficDetectorUrbanFisheyeFast ||
                  model === EBoxModel.trafficDetectorUrbanFisheyeFast
              )
              .map((item) => (
                <SelectItem
                  key={item}
                  value={item}
                  text={t(`draw.form.box.model.models.${item}.name`)}
                />
              ))}
          </Select>

          {model && (
            <Collapse>
              <Panel header="Description" key="1">
                <Typography className={'scc--model--description'}>
                  <Paragraph>
                    <Trans
                      i18nKey={`draw.form.box.model.models.${model}.description`}
                    />
                  </Paragraph>
                  <Divider />
                  <Paragraph>
                    <Title level={4}>Classes</Title>
                    <ul>
                      {classes.map((item) => (
                        <li>
                          <Text code>{item}</Text>
                        </li>
                      ))}
                    </ul>
                  </Paragraph>
                  <Divider />
                  <Title level={4}>
                    {t(`draw.form.box.model.models.${model}.usecases.name`)}
                  </Title>
                  <Paragraph>
                    <ul>
                      {usecases_groups.map((usecase_group) => (
                        <li>
                          <text>{usecase_group.name}</text>
                          {usecase_group.items.map((usecases_subgroup) => (
                            <li>
                              {Config.EXCLUDE_SUPPORT ? (
                                <text>{usecases_subgroup.name}</text>
                              ) : (
                                <Link href={usecases_subgroup.link}>
                                  {usecases_subgroup.name}
                                </Link>
                              )}
                            </li>
                          ))}
                        </li>
                      ))}
                    </ul>
                  </Paragraph>
                  {!Config.EXCLUDE_SUPPORT && (
                    <>
                      <Divider />
                      <Paragraph>
                        <Link
                          href={t(
                            `draw.form.box.model.models.${model}.doculink`
                          )}
                        >
                          {t(`draw.form.box.model.models.${model}.docutext`)}
                        </Link>
                      </Paragraph>
                    </>
                  )}
                </Typography>
              </Panel>
            </Collapse>
          )}
        </Space>
      </FormGroup>
      <FormGroup
        legendText={t('draw.form.eventtypes.title')}
        className="scc--device-config--title"
      >
        <em className="scc--required--hint">
          {t('draw.form.eventtypes.hint')}
        </em>

        <Menu
          style={{ width: 256 }}
          defaultOpenKeys={['parameter']}
          mode={'inline'}
          className="scc--device-config--eventtypes--menu"
        >
          {!excludeSupport && (
            <FormLabelWithTooltip id="draw.form.eventtypes" />
          )}
          {!personModelSelected && (
            <>
              <SubMenu
                key="parking"
                icon={<ClockCircleOutlined />}
                title={t('draw.form.eventtypes.parking')}
                className="scc--device-config--eventtypes--menu"
              >
                <ParkingTriggers />
              </SubMenu>
              <SubMenu
                key="traffic"
                icon={<CarOutlined />}
                title={t('draw.form.eventtypes.traffic')}
                className="scc--device-config--eventtypes--menu"
              >
                <TrafficTriggers />
              </SubMenu>
            </>
          )}
          {personModelSelected && (
            <SubMenu
              key="people"
              icon={<TeamOutlined />}
              title={t('draw.form.eventtypes.people')}
              className="scc--device-config--eventtypes--menu"
            >
              <PeopleTriggers />
            </SubMenu>
          )}
          <SubMenu
            key="globalSettings"
            icon={<SettingOutlined />}
            title={t('draw.form.eventtypes.globalsettings')}
            className="scc--device-config--eventtypes--menu"
          >
            <GlobalTriggerSettings
              onValueChanged={onValueChanged}
              heatMap={heatMap}
              onToggleHeatmap={onToggleHeatmap}
              shouldShowLPMode={shouldShowLPMode && !personModelSelected}
              anprOptionaAvailable={anprOptionaAvailable}
              anonymisedAnprOptionAvailable={anonymisedAnprOptionAvailable}
              enableRoiInterval={enableRoiInterval}
              anpr={anpr}
              onToggleAttachLicensePlateImage={onToggleAttachLicensePlateImage}
              hasCustomMqttConfig={hasCustomMqttConfig}
              roiInterval={roiInterval}
              lpMode={lpMode}
              onLPModeChange={onLPModeChange}
              anprStorageDurationChanged={anprStorageDurationChanged}
              register={register}
              onButtonClick={onButtonClick}
              enableFocusArea={enableFocusArea}
              attachLicensePlateImage={attachLicensePlateImage}
            />
          </SubMenu>
          <SubMenu
            key="parameter"
            icon={<ToolOutlined />}
            title={t('draw.form.eventtypes.settings')}
            className="scc--device-config--eventtypes--menu"
          >
            <TriggerSettings
              eventTrigger={eventTrigger}
              hasRuleAttached={hasRuleAttached}
              onToggleSpeedEstimate={onToggleSpeedEstimate}
              onValueChanged={onValueChanged}
              personModelSelected={personModelSelected}
              onToggleRoiType={onToggleRoiType}
              onToggleRoiTrigger={onToggleRoiTrigger}
              onToggleMultipleCrossings={onToggleMultipleCrossings}
              onChangeRoiCapacity={onChangeRoiCapacity}
              onToggleDirection={onToggleDirection}
              errors={errors}
              register={register}
            />
          </SubMenu>
        </Menu>
      </FormGroup>
    </>
  )
}

export default SceneSetup
