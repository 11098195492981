import './SceneOverview.scss'
import React, { useEffect, useMemo, useReducer, useState } from 'react'

import { IScene } from '../../../types/scene'
import { ApolloProvider, NormalizedCacheObject } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import cubejs, { CubejsApi, CubeJSApiOptions } from '@cubejs-client/core'
import { CubeProvider } from '@cubejs-client/react'
import { Config } from '../../../services/config'
import {
  getTokenInformation,
  leaveHeaderEmptyOnMissingOrMainTenant
} from '../../../services/apiTokenProvider'
import apollo from '../../../datadiscovery/graphql/client'
import DashboardPage from '../../../datadiscovery/pages/DashboardPage'
import { Button, Col, Layout, Row, Tooltip } from 'antd'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { FullscreenOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import TimeGroup from '../../../datadiscovery/components/QueryBuilder/TimeGroup'
import CreateWidgetModal from './CreateWidgetModal'
import { IDashboardItem } from '../../../types/dashboard'
import Scrollbars from 'react-custom-scrollbars-2'
import {
  allTimeString,
  last30Days,
  last365Days,
  last7Days,
  lastMonth,
  lastWeek,
  thisMonth,
  thisWeek,
  thisYear,
  today,
  yesterday
} from '../../../datadiscovery/components/QueryBuilder/DateRanges'
import { userIsAtLeast, UserRole } from '../../../types/user_role'
import { ApolloClient } from '@apollo/client'

interface ISceneOverview {
  scene: IScene
}

const API_URL = Config.DATA_API

export const getApiUrl = () => {
  return `${API_URL}/cubejs-api/v1`
}

const LOCAL_STORAGE_TIME_KEY = 'globalTimeFilter'
const TIME_FILTER_DEFAULT = last7Days

const SceneOverview: React.FC<ISceneOverview> = (props) => {
  const { t } = useTranslation(['common', 'datadiscovery'])
  const dd_ns = useMemo(
    () => ({
      ns: 'datadiscovery'
    }),
    []
  )
  const handle = useFullScreenHandle()

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [cubejsApi, setCubejsApi] = useState<CubejsApi>()
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()
  const [isCustomDateRange, setCustomDateRange] = useState<boolean>(false)
  const [timeFilterString, setTimeFilterString] = useState<string | undefined>()
  const [widget, setWidget] = useState<IDashboardItem | undefined>()
  const [timeFilter, setTimeFilter] = useState<string | string[] | null>()

  const [dateRanges] = useState([
    { title: t('explore.builder.dateRanges.custom', dd_ns) },
    {
      title: t('explore.builder.dateRanges.allTime', dd_ns),
      value: allTimeString
    },
    { title: t('explore.builder.dateRanges.today', dd_ns), value: today },
    {
      title: t('explore.builder.dateRanges.yesterday', dd_ns),
      value: yesterday
    },
    { title: t('explore.builder.dateRanges.thisWeek', dd_ns), value: thisWeek },
    {
      title: t('explore.builder.dateRanges.thisMonth', dd_ns),
      value: thisMonth
    },
    { title: t('explore.builder.dateRanges.thisYear', dd_ns), value: thisYear },
    {
      title: t('explore.builder.dateRanges.last7Days', dd_ns),
      value: last7Days
    },
    {
      title: t('explore.builder.dateRanges.last30Days', dd_ns),
      value: last30Days
    },
    {
      title: t('explore.builder.dateRanges.last365Days', dd_ns),
      value: last365Days
    },
    { title: t('explore.builder.dateRanges.lastWeek', dd_ns), value: lastWeek },
    {
      title: t('explore.builder.dateRanges.lastMonth', dd_ns),
      value: lastMonth
    }
  ])

  useEffect(() => {
    let timeFilterFromStorage: string | string[] | null = localStorage.getItem(
      `${LOCAL_STORAGE_TIME_KEY}_${props.scene?.id}`
    )
    let timezone = props.scene.location
      ? props.scene.location.timezone
      : 'Etc/UTC'
    if (
      timeFilterFromStorage &&
      timeFilterFromStorage.split(',').length === 2
    ) {
      // is custom date range
      setTimeFilter(timeFilterFromStorage.split(','))
      setTimeFilterString(t('explore.builder.dateRanges.custom', dd_ns))
      // prevent infinity-loop of setting custom DateRange to true
      // (needed because the rerenderCallback-hack used for force-rerender)
      !isCustomDateRange && setCustomDateRange(true)
    } else {
      let timeFilterObject = dateRanges.find(
        (x) => x.title === timeFilterFromStorage
      )
      setTimeFilter(
        timeFilterObject && timeFilterObject.value
          ? timeFilterObject.value(timezone)
          : TIME_FILTER_DEFAULT(timezone)
      )
      setTimeFilterString(
        timeFilterObject && timeFilterObject.title
          ? timeFilterObject.title
          : t('explore.builder.dateRanges.last7Days', dd_ns)
      )
    }
    if (!cubejsApi || !client) {
      getTokenInformation().then((tokenInformation) => {
        const multiTenantHeaders = leaveHeaderEmptyOnMissingOrMainTenant(
          tokenInformation
        )
          ? undefined
          : {
              Tenant: tokenInformation.tenantInformations!.tenantId
            }
        const options: CubeJSApiOptions = !multiTenantHeaders
          ? {
              apiUrl: getApiUrl()
            }
          : {
              apiUrl: getApiUrl(),
              headers: multiTenantHeaders
            }
        setCubejsApi(
          cubejs(async () => {
            return (await getTokenInformation()).idToken
          }, options)
        )
        setClient(apollo(multiTenantHeaders))
      })
    }
  }, [
    setTimeFilter,
    isCustomDateRange,
    props.scene.location,
    props.scene.id,
    t,
    dateRanges,
    dd_ns,
    cubejsApi,
    client
  ])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, rerenderCallback] = useReducer((x) => x + 1, 0) // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate

  const updateTimeRange = (newValue, dateRangeStorage?) => {
    localStorage.setItem(
      `${LOCAL_STORAGE_TIME_KEY}_${props.scene.id}`,
      dateRangeStorage ? dateRangeStorage : newValue.dateRange
    )
    setTimeFilterString(
      dateRangeStorage
        ? dateRangeStorage
        : t('explore.builder.dateRanges.custom', dd_ns)
    )
    setTimeFilter(newValue.dateRange)
  }

  const onWidgetAdd = (event) => {
    event.preventDefault()
    showModal()
  }

  const showModal = (item?) => {
    setWidget(item)
    setShowCreateModal(true)
  }

  if (!props.scene.boxStreams || !props.scene.boxStreams.length) {
    return (
      <div className="bx--grid bx--grid--full-width scc--scenedetail--tabcontent scc--scenedetail--emptyoverview">
        <p>{t('solutions.scenes.streams.empty')}</p>
        <Link to="#streams">
          {t('solutions.scenes.overview.emptyStreamsLink')}
        </Link>
      </div>
    )
  }

  return (
    <>
      <div className="bx--grid bx--grid--full-width scc--scenedetail--tabcontent">
        <Row>
          <Col flex="auto" className="scc--flex--column">
            <div className="scc--solutions--overview-timebox">
              <TimeGroup
                members={[
                  {
                    dateRange: timeFilter,
                    timeFilterString: timeFilterString
                  }
                ]}
                dateRanges={dateRanges}
                isCustomDateRange={isCustomDateRange}
                setCustomDateRange={setCustomDateRange}
                updateTimeRange={updateTimeRange}
                timezone={props.scene.location?.timezone}
              />
            </div>
          </Col>
          <Col flex="100px">
            {userIsAtLeast(UserRole.User) && (
              <Button
                className="scc--solutions--add-widget-button"
                type="primary"
                onClick={onWidgetAdd}
              >
                {t('solutions.scenes.overview.add.button')}
              </Button>
            )}
          </Col>
          <Col flex="50px">
            <Tooltip
              placement="bottomLeft"
              title={t('solutions.scenes.overview.tooltipFullscreen')}
            >
              <FullscreenOutlined
                className="scc--solutions--scenedetail--fullscreen"
                onClick={handle.enter}
              />
            </Tooltip>
          </Col>
        </Row>
      </div>

      {cubejsApi && client && (
        <CubeProvider cubejsApi={cubejsApi}>
          <ApolloProvider client={client}>
            <FullScreen handle={handle}>
              {handle.active ? ( // in fullscreen mode, we need to add scrollbars here
                <Scrollbars autoHide={true}>
                  {getDashboard(props, timeFilter, showModal)}
                </Scrollbars>
              ) : (
                getDashboard(props, timeFilter, showModal)
              )}
            </FullScreen>
            <CreateWidgetModal
              key={widget ? widget.id : Math.random()}
              show={showCreateModal}
              widget={widget}
              setWidget={setWidget}
              setShow={setShowCreateModal}
              scene={props.scene}
              timeFilter={timeFilter}
              rerenderCallback={rerenderCallback}
            />
          </ApolloProvider>
        </CubeProvider>
      )}
    </>
  )
}

const getDashboard = (props, timeFilter, showModal) => {
  return (
    <Layout style={{ minHeight: '100vh', height: 'auto' }}>
      {props.scene && (
        <DashboardPage
          scene={props.scene}
          overrideTimeRange={timeFilter}
          showModal={showModal}
        />
      )}
    </Layout>
  )
}

export default SceneOverview
