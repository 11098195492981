import { IBoxStatus } from './boxStatus'

export interface IBox {
  id: string // uuid
  serial?: string //Serial Number if present
  name?: string // Friendly name of the box
  tags?: [string?]
  statusId?: string // The uuid for the status item
  type: DeviceType
  productInfo?: IProductInfo
  mode?: string
  online?: boolean
  boxStatus?: IBoxStatus
  updateStatus?: EBoxUpdateStatus
  tenant?: string // tenant uuid
  metadata?: Object
}

export interface IProductInfo {
  model: ProductModels
  version?: string
}

export enum DeviceType {
  X64_NVIDIA = 'x64-nvidia',
  JETSON_NANO = 'jetson-nano',
  JETSON_NX = 'jetson-nx',
  JETSON_AGX = 'jetson-agx',
  JETPACK_5 = 'arm64-jetpack5',
  ARM64_VSI = 'arm64-vsi',
  UNSPECIFIED = 'unspecified'
}

export enum ProductModels {
  BMA = 'BMA'
}

export enum EBoxUpdateStatus {
  UPDATE_APPLIED = 'updateApplied',
  UPDATE_SCHEDULED = 'updateScheduled',
  UPDATE_AVAILABLE = 'updateAvailable'
}

export const sortBoxes = (boxes: IBox[]): IBox[] => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base'
  })
  return boxes.sort(function (lhs, rhs) {
    // eslint-disable-next-line eqeqeq
    if (lhs.name == undefined) {
      return 1
    }
    // eslint-disable-next-line eqeqeq
    if (rhs.name == undefined) {
      return -1
    }
    return collator.compare(lhs.name, rhs.name)
  })
}

export const NVIDIA_DEVICES = new Set<DeviceType>()
NVIDIA_DEVICES.add(DeviceType.X64_NVIDIA)
NVIDIA_DEVICES.add(DeviceType.JETSON_NANO)
NVIDIA_DEVICES.add(DeviceType.JETSON_NX)
NVIDIA_DEVICES.add(DeviceType.JETSON_AGX)
NVIDIA_DEVICES.add(DeviceType.JETPACK_5)

export const DefaultBox: IBox = {
  id: '',
  name: undefined,
  tags: undefined,
  statusId: undefined,
  type: DeviceType.UNSPECIFIED,
  mode: undefined,
  online: undefined,
  boxStatus: undefined,
  metadata: undefined,
  updateStatus: EBoxUpdateStatus.UPDATE_APPLIED
}
