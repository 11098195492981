import {
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_REQUEST_FAILURE,
  DELETE_GROUP_REQUEST_SUCCESS,
  LIST_GROUPS_REQUEST,
  LIST_GROUPS_REQUEST_FAILURE,
  LIST_GROUPS_REQUEST_SUCCESS,
  SAVE_GROUP_REQUEST,
  SAVE_GROUP_REQUEST_FAILURE,
  SAVE_GROUP_REQUEST_SUCCESS
} from './actionTypes'
import { CALL_API, HTTP_METHOD, Schemas } from '../middleware/api'

import { IGroup } from '../../types/group'
import { log } from '../../services/log'

/**
 * ===========
 * SOLUTION GROUP ACTIONS
 * ===========
 */

/**
 * Creates or updates the group via API request.
 * @param group: The group to save.
 */
export const createUpdateGroup = (group: IGroup) => async (
  dispatch,
  getState
) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        SAVE_GROUP_REQUEST,
        SAVE_GROUP_REQUEST_SUCCESS,
        SAVE_GROUP_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.PUT,
      payload: group,
      endpoint: `solutions/groups/${group.id}`,
      schema: Schemas.GROUP
    }
  })
  if (result.type !== SAVE_GROUP_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}

/**
 * Deletes the group via API request.
 * @param group: The group to delete.
 *
 */
export const deleteGroup = (group: IGroup) => async (dispatch, getState) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        DELETE_GROUP_REQUEST,
        DELETE_GROUP_REQUEST_SUCCESS,
        DELETE_GROUP_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.DELETE,
      endpoint: `solutions/groups/${group.id}`,
      id: group.id
    }
  })
  if (result.type !== DELETE_GROUP_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}

/**
 * List all groups via API request.
 */
export const listGroups = () => async (dispatch, getState) => {
  const result = await dispatch({
    [CALL_API]: {
      types: [
        LIST_GROUPS_REQUEST,
        LIST_GROUPS_REQUEST_SUCCESS,
        LIST_GROUPS_REQUEST_FAILURE
      ],
      method: HTTP_METHOD.GET,
      endpoint: `solutions/groups`
    }
  })
  if (result.type !== LIST_GROUPS_REQUEST_SUCCESS) {
    let error = `API request [${result.type}] failed`
    log.error(error)
    throw Error(error)
  }
}
