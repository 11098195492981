import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ReduxStore } from '../../redux/store/configureStore'
import { callFetchBoxActionSchedule } from '../../redux/actions/boxActionSchedule'
import { IBoxActionSchedule } from '../../types/boxActionSchedule'

export interface IBoxActionScheduleState {
  boxActionSchedule: IBoxActionSchedule
  error: string
  loading: boolean
}

export const useBoxActionScheduleState = (boxId: string) => {
  const dispatch = useDispatch()

  const boxActionScheduleState: IBoxActionScheduleState = useSelector(
    (state: ReduxStore) => state.boxActionSchedule
  )

  useEffect(() => {
    if (
      boxActionScheduleState.boxActionSchedule?.boxId !== boxId &&
      !boxActionScheduleState.loading
    ) {
      dispatch(callFetchBoxActionSchedule(boxId))
    }
  }, [dispatch, boxId, boxActionScheduleState])

  return boxActionScheduleState
}
