import React, { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Button, Card, Dropdown, Menu, Row, Select, Space } from 'antd'
import {
  CarOutlined,
  ClockCircleOutlined,
  DashboardOutlined,
  ExportOutlined,
  ImportOutlined,
  LoginOutlined,
  PlusCircleOutlined,
  VerticalAlignMiddleOutlined
} from '@ant-design/icons'
import { EWidgetType } from '../../../../types/solution'
import SelectChartType from '../QueryBuilderComponents/SelectChartType'
import ChartRenderer from '../../../../datadiscovery/components/ChartRenderer'
import StreamSelection from '../../Helper/StreamSelection'
import { connect } from 'react-redux'
import CLSelection from '../../Helper/CLSelection'
import {
  listCrossinglinesInScene,
  listRuleTriggersInScene,
  listSpeedlinesInScene
} from '../../../../redux/actions/scenes'
import {
  ICrossinglineInfo,
  IRuleTriggerConditionInfo,
  IRuleTriggerInfo,
  IScene
} from '../../../../types/scene'
import GranularityFilter from '../QueryBuilderComponents/GranularityFilter'
import FilterData from '../QueryBuilderComponents/FilterData'
import { TCubeDimension } from '@cubejs-client/core'
import SegmentationFilter from '../QueryBuilderComponents/SegmentationFilter'
import {
  compareElements,
  getElementsByName,
  handleElementChange,
  updateFilter
} from './QueryBuilderUtility'
import {
  DEFAULT_DATE_RANGE,
  DEFAULT_GRANULARITY
} from '../../../../datadiscovery/components/QueryBuilder/StateChangeHeuristics'
import SpeedToggle from '../../Helper/SpeedToggle'
import {
  GeneralChartTypesByWidgetType,
  GeneralDataFilterByWidgetType,
  GeneralDefaultDimensionsByWidgetType,
  GeneralDimensionsByWidgetType,
  GeneralFilterByWidgetType,
  GeneralMeasureByWidgetType,
  GeneralSegmentsByWidgetType,
  GeneralTimeDimensionsByWidgetType,
  GeneralWidgetTypeIcons,
  GeneralWidgetTypes,
  getRuleTriggerWarning
} from './BaseQueryBuilder'
import { RuleSelection } from '../../Helper/RuleSelection'
import { EEventTriggerType } from '../../../../types/eventTrigger'
import JourneyTimeLocationWrapper from '../../Helper/JourneyTimeLocationWrapper'
import FormLabelWithTooltip from '../../../../components/FormLabelWithToolTip'
import PercentileMeasurementSelection from '../QueryBuilderComponents/PercentileMeasurementSelection'
import { AnprAlert } from '../QueryBuilderComponents/AnprAlert'

const WidgetTypes = [
  EWidgetType.TrafficCounting,
  EWidgetType.OriginDestinationAnalysis,
  EWidgetType.SpeedEstimation,
  EWidgetType.JourneyTimes,
  EWidgetType.JourneyDistributions,
  ...GeneralWidgetTypes
]

const WidgetTypeIcons = {
  [EWidgetType.TrafficCounting]: <VerticalAlignMiddleOutlined />,
  [EWidgetType.OriginDestinationAnalysis]: <LoginOutlined />,
  [EWidgetType.SpeedEstimation]: <DashboardOutlined />,
  [EWidgetType.JourneyTimes]: <ClockCircleOutlined />,
  [EWidgetType.JourneyDistributions]: <CarOutlined />,
  ...GeneralWidgetTypeIcons
}

const MeasureByWidgetType = {
  [EWidgetType.TrafficCounting]: ['CrossingEvents.count'],
  [EWidgetType.OriginDestinationAnalysis]: [
    'OriginDestinationEvents.count',
    'OriginDestinationEvents.originDestinationAverageDuration'
  ],
  [EWidgetType.SpeedEstimation]: 'CrossingEvents.averageSpeed',
  [EWidgetType.JourneyTimes]: [
    'JourneySegments.avgJourneyDuration',
    'JourneySegments.medianJourneyDuration'
  ],
  [EWidgetType.JourneyDistributions]: 'JourneySegments.count',
  ...GeneralMeasureByWidgetType
}

export const JourneyTimePercentileMeasurePrefix =
  'JourneySegments.JourneyDuration_percentile_'

const DataFilterByWidgetType = {
  [EWidgetType.TrafficCounting]: 'CrossingEvents.lineId',
  [EWidgetType.OriginDestinationAnalysis]: 'OriginDestinationEvents.streamId',
  [EWidgetType.SpeedEstimation]: 'CrossingEvents.lineId',
  [EWidgetType.JourneyTimes]: [
    'JourneySegments.startLocationId',
    'JourneySegments.endLocationId'
  ],
  [EWidgetType.JourneyDistributions]: [
    'JourneySegments.startLocationId',
    'JourneySegments.endLocationId'
  ],
  ...GeneralDataFilterByWidgetType
}

const FilterByWidgetType = {
  [EWidgetType.TrafficCounting]: [
    'CrossingEvents.classification',
    'CrossingEvents.subclass',
    'CrossingEvents.direction'
  ],
  [EWidgetType.OriginDestinationAnalysis]: [
    'OriginDestinationEvents.classification',
    'OriginDestinationEvents.subclass'
  ],
  ...GeneralFilterByWidgetType
}

const DimensionsByWidgetType = {
  [EWidgetType.TrafficCounting]: [
    'CrossingEvents.classification',
    'CrossingEvents.subclass',
    'CrossingEvents.direction',
    'CrossingEvents.directionName',
    'CrossingEvents.timestamp',
    'CrossingEvents.lineName',
    'CrossingEvents.speed'
  ],
  [EWidgetType.OriginDestinationAnalysis]: [
    'OriginDestinationEvents.classification',
    'OriginDestinationEvents.subclass',
    'OriginDestinationEvents.exitTimestamp',
    'OriginDestinationEvents.entryZone',
    'OriginDestinationEvents.exitZone'
  ],
  [EWidgetType.JourneyTimes]: [
    'JourneySegments.startLocationName',
    'JourneySegments.endLocationName',
    'JourneySegments.classification'
  ],
  [EWidgetType.JourneyDistributions]: [
    'JourneySegments.startLocationName',
    'JourneySegments.endLocationName',
    'JourneySegments.classification',
    'JourneySegments.subclass',
    'JourneySegments.numberPlateOrigin',
    'JourneySegments.numberPlateArea'
  ],
  [EWidgetType.SpeedEstimation]: ['CrossingEvents.lineName'],
  ...GeneralDimensionsByWidgetType
}

const DefaultDimensionsByWidgetType = {
  [EWidgetType.OriginDestinationAnalysis]: [
    'OriginDestinationEvents.entryZone',
    'OriginDestinationEvents.exitZone'
  ],
  [EWidgetType.JourneyTimes]: [
    'JourneySegments.startLocationName',
    'JourneySegments.endLocationName'
  ],
  [EWidgetType.JourneyDistributions]: [
    'JourneySegments.startLocationName',
    'JourneySegments.endLocationName'
  ],
  ...GeneralDefaultDimensionsByWidgetType
}

const ChartTypesByWidgetType = {
  [EWidgetType.TrafficCounting]: ['table', 'number', 'bar', 'pie'],
  [EWidgetType.JourneyTimes]: ['table', 'line'],
  [EWidgetType.JourneyDistributions]: ['heatmap', 'table', 'pie'],
  [EWidgetType.OriginDestinationAnalysis]: ['table', 'chord', 'bar'],
  [EWidgetType.SpeedEstimation]: ['line', 'table', 'number'],
  ...GeneralChartTypesByWidgetType
}

const TimeDimensionsByWidgetType = {
  [EWidgetType.TrafficCounting]: 'CrossingEvents.timestamp',
  [EWidgetType.JourneyTimes]: 'JourneySegments.timestamp',
  [EWidgetType.JourneyDistributions]: 'JourneySegments.timestamp',
  [EWidgetType.OriginDestinationAnalysis]:
    'OriginDestinationEvents.exitTimestamp',
  [EWidgetType.SpeedEstimation]: 'CrossingEvents.timestamp',
  ...GeneralTimeDimensionsByWidgetType
}

const SegmentsByWidgetType = {
  ...GeneralSegmentsByWidgetType
}

interface ITrafficQueryBuilder {
  crossingLinesInScene: ICrossinglineInfo[]
  speedLinesInScene: ICrossinglineInfo[]
  rulesInScene: IRuleTriggerInfo[]
  listCrossinglinesInScene: Function
  listSpeedlinesInScene: Function
  listRuleTriggersInScene: Function
  scene: IScene
  isValidQuery: boolean
  setValidQuery: Function
  widgetType: EWidgetType | undefined
  setWidgetType: Function
  isQueryPresent: any
  validatedQuery: any
  timeDimensions: any
  availableTimeDimensions: any
  updateTimeDimensions: any
  dimensions: any
  availableDimensions: any
  updateDimensions: any
  measures: any
  availableMeasures: any
  updateMeasures: any
  chartType: any
  updateChartType: any
  timeFilter: string
  segments: any
  availableSegments: any
  updateSegments: any
  filters: any
  updateFilters: any
  updateQuery: any
}

const TrafficQueryBuilder: React.FC<ITrafficQueryBuilder> = (props) => {
  const { t } = useTranslation()

  let [clFilter, setClFilter] = useState<string[]>([])
  let [anprClFilter, setAnprClFilter] = useState<string[]>([])
  let [speedlineFilter, setSpeedlineFilter] = useState<string[]>([])
  let [ruleFilter, setRuleFilter] = useState([])
  let [anprWarning, setAnprWarning] = useState(false)
  let [newDimensions, setNewDimensions] = useState<string[]>([])
  let [streamFilterFromOrSingle, setStreamFilterFromOrSingle] = useState<
    string[]
  >([])
  let [streamFilterTo, setStreamFilterTo] = useState<string[]>([])
  let [newMeasure, setNewMeasure] = useState<string[]>([])

  const {
    listCrossinglinesInScene,
    listSpeedlinesInScene,
    listRuleTriggersInScene,
    scene,
    isQueryPresent,
    validatedQuery,
    isValidQuery,
    setValidQuery,
    widgetType,
    chartType,
    setWidgetType
  } = props

  useEffect(() => {
    listCrossinglinesInScene(scene)
    listSpeedlinesInScene(scene)
    listRuleTriggersInScene(scene)
  }, [
    listCrossinglinesInScene,
    listSpeedlinesInScene,
    listRuleTriggersInScene,
    scene,
    scene.id
  ])

  useEffect(() => {
    let isValidQuery =
      widgetType && isQueryPresent && Object.keys(validatedQuery).length > 0

    if (
      (widgetType && widgetType === EWidgetType.JourneyTimes) ||
      widgetType === EWidgetType.JourneyDistributions
    ) {
      isValidQuery =
        isValidQuery &&
        streamFilterFromOrSingle.length > 0 &&
        streamFilterTo.length > 0
    }
    setValidQuery(isValidQuery)
  }, [
    widgetType,
    isQueryPresent,
    validatedQuery,
    setValidQuery,
    streamFilterFromOrSingle.length,
    streamFilterTo.length
  ])

  useEffect(() => {
    // set current filters if widget is edited
    props.filters.forEach((filter) => {
      if (widgetType === EWidgetType.TrafficCounting) {
        if (
          filter.member ===
            DataFilterByWidgetType[EWidgetType.TrafficCounting] &&
          clFilter !== filter.values
        ) {
          setClFilter(filter.values)
        }
      } else if (widgetType === EWidgetType.OriginDestinationAnalysis) {
        if (
          filter.member ===
            DataFilterByWidgetType[EWidgetType.OriginDestinationAnalysis] &&
          streamFilterFromOrSingle !== filter.values[0]
        ) {
          setStreamFilterFromOrSingle(filter.values[0])
        }
      } else if (
        widgetType === EWidgetType.JourneyTimes ||
        widgetType === EWidgetType.JourneyDistributions
      ) {
        if (
          filter.member === 'JourneySegments.endLocationId' &&
          !(
            streamFilterTo.length === filter.length &&
            streamFilterTo.every(function (element) {
              return filter.values.includes(element)
            })
          )
        ) {
          setStreamFilterTo(filter.values)
        }
        if (
          filter.member === 'JourneySegments.startLocationId' &&
          !(
            streamFilterFromOrSingle.length === filter.length &&
            streamFilterFromOrSingle.every(function (element) {
              return filter.values.includes(element)
            })
          )
        ) {
          setStreamFilterFromOrSingle(filter.values)
        }
      } else if (widgetType === EWidgetType.SpeedEstimation) {
        if (
          filter.member ===
            DataFilterByWidgetType[EWidgetType.SpeedEstimation] &&
          speedlineFilter !== filter.values
        ) {
          setSpeedlineFilter(filter.values)
        }
      } else if (widgetType === EWidgetType.LicensePlates) {
        if (
          filter.member === DataFilterByWidgetType[EWidgetType.LicensePlates] &&
          anprClFilter !== filter.values
        ) {
          setAnprClFilter(filter.values)
        }
      } else if (widgetType === EWidgetType.RuleTrigger) {
        if (
          filter.member === DataFilterByWidgetType[EWidgetType.RuleTrigger] &&
          ruleFilter !== filter.values
        ) {
          setRuleFilter(filter.values)
        }
      }
    })

    // set current measurements if widget is edited
    if (newMeasure && !compareElements(props.measures, newMeasure)) {
      setNewMeasure(
        props.measures.map((item) => {
          return item.name
        })
      )
    }

    // set current dimensions if widget is edited
    if (newDimensions && !compareElements(props.dimensions, newDimensions)) {
      setNewDimensions(
        props.dimensions.map((item) => {
          return item.name
        })
      )
    }
  }, [
    props.filters,
    props.dimensions,
    props.measures,
    newDimensions,
    newMeasure,
    widgetType,
    clFilter,
    streamFilterFromOrSingle,
    streamFilterTo,
    speedlineFilter,
    anprClFilter,
    ruleFilter
  ])

  // reset chart type if current type is not available
  useEffect(() => {
    if (
      widgetType &&
      !ChartTypesByWidgetType[widgetType].includes(props.chartType)
    ) {
      props.updateChartType(ChartTypesByWidgetType[widgetType][0])
    }
  })

  useEffect(() => {
    if (
      (widgetType && widgetType === EWidgetType.LicensePlates) ||
      chartType === 'pie'
    ) {
      props.timeDimensions.forEach((timeDimension) => {
        if (timeDimension.granularity !== undefined) {
          props.updateTimeDimensions.update(timeDimension, {
            ...timeDimension,
            granularity: undefined
          })
        }
      })
    }
  })

  const handleNewClFilterChange = (data) => {
    setClFilter(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return (
        dimension.name === DataFilterByWidgetType[EWidgetType.TrafficCounting]
      )
    })
    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data
    })
  }

  const handleNewSpeedLineFilterChange = (data) => {
    setSpeedlineFilter(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return (
        dimension.name === DataFilterByWidgetType[EWidgetType.SpeedEstimation]
      )
    })

    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data
    })
  }

  const handleNewAnprClFilterChange = (data) => {
    setAnprClFilter(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return (
        dimension.name === DataFilterByWidgetType[EWidgetType.LicensePlates]
      )
    })

    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data
    })
  }

  const handleNewJourneySegmentMeasureChange = (data) => {
    let updatedMeasures = getElementsByName(data, props.availableMeasures)

    props.updateMeasures.replaceAll(
      updatedMeasures.map((measureName) => ({ name: measureName }))
    )
    setNewMeasure(updatedMeasures)
  }

  const handleNewRuleFilterChange = (data) => {
    setRuleFilter(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return dimension.name === DataFilterByWidgetType[EWidgetType.RuleTrigger]
    })

    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data
    })
  }

  const handleSpeedToggleChange = (checked, event) => {
    let data
    if (!checked) {
      data = MeasureByWidgetType[EWidgetType.TrafficCounting]
    } else {
      data = ['CrossingEvents.count', 'CrossingEvents.averageSpeed']
    }
    handleElementChange(
      props.availableMeasures,
      props.measures,
      props.updateMeasures,
      data
    )
  }

  const handleAverageDurationSelectChange = (data, event) => {
    let updatedMeasures = getElementsByName(data, props.availableMeasures)
    props.updateMeasures.replaceAll(
      updatedMeasures.map((measureName) => ({ name: measureName }))
    )
    setNewMeasure(updatedMeasures)
  }

  const handleWidgetTypeChange = (item) => {
    if (item === EWidgetType.LicensePlates) {
      let anprEnabled = scene.boxStreams.some((item) => item.hasAnpr)
      setAnprWarning(!anprEnabled)
      if (!anprEnabled) {
        return
      }
    } else {
      anprWarning && setAnprWarning(false)
    }

    let measures = MeasureByWidgetType[item]
    let times = TimeDimensionsByWidgetType[item]
    let dimensions = DefaultDimensionsByWidgetType[item]
    let segments = SegmentsByWidgetType[item]

    props.updateQuery({
      measures: getElementsByName(measures, props.availableMeasures),
      dimensions: getElementsByName(dimensions, props.availableDimensions),
      segments: getElementsByName(segments, props.availableSegments),
      filters: [],
      timeDimensions: times
        ? [
            {
              dimension: times,
              granularity: DEFAULT_GRANULARITY,
              dateRange: DEFAULT_DATE_RANGE
            }
          ]
        : []
    })

    setWidgetType(item)
    setNewDimensions([])
    setNewMeasure(measures)
  }

  const handleLocationFilterChange = (dataFrom, dataTo) => {
    setStreamFilterFromOrSingle(dataFrom)
    setStreamFilterTo(dataTo)
    let locationFilters = props.availableDimensions.filter((dimension) => {
      return DataFilterByWidgetType[EWidgetType.JourneyTimes].includes(
        dimension.name
      )
    })

    props.updateFilters.replaceAll([
      {
        operator: 'equals',
        dimension: locationFilters[0],
        values: dataFrom ? dataFrom : []
      },
      {
        operator: 'equals',
        dimension: locationFilters[1],
        values: dataTo ? dataTo : []
      }
    ])
  }

  const handleNewStreamFilterChange = (data) => {
    setStreamFilterFromOrSingle(data)
    let dimension = props.availableDimensions.find((dimension) => {
      return (
        dimension.name ===
        DataFilterByWidgetType[EWidgetType.OriginDestinationAnalysis]
      )
    })

    updateFilter(props.filters, props.updateFilters, {
      operator: 'equals',
      dimension: dimension,
      values: data ? [data] : []
    })
  }

  const handleNewDimensionChange = (data) => {
    setNewDimensions(data)
    handleElementChange(
      props.availableDimensions,
      props.dimensions,
      props.updateDimensions,
      data
    )
  }

  const filteredDimensions: TCubeDimension[] =
    widgetType &&
    props.availableDimensions.filter(
      (dim) =>
        FilterByWidgetType[widgetType] &&
        FilterByWidgetType[widgetType].includes(dim.name)
    )

  const segmentationDimensions: TCubeDimension[] =
    widgetType &&
    props.availableDimensions.filter(
      (dim) =>
        DimensionsByWidgetType[widgetType] &&
        DimensionsByWidgetType[widgetType].includes(dim.name)
    )

  const typeFilteredFilters =
    widgetType &&
    props.filters.filter(
      (filter) =>
        DataFilterByWidgetType[widgetType] !== filter.member &&
        filter.member.split('.')[1] !== 'streamId'
    )

  const dimensionsSelected = !!(
    (widgetType === EWidgetType.TrafficCounting && clFilter.length) ||
    (widgetType === EWidgetType.OriginDestinationAnalysis &&
      streamFilterFromOrSingle.length) ||
    ((widgetType === EWidgetType.JourneyTimes ||
      widgetType === EWidgetType.JourneyDistributions) &&
      streamFilterFromOrSingle.length &&
      streamFilterTo.length) ||
    (widgetType === EWidgetType.SpeedEstimation && speedlineFilter.length) ||
    (widgetType === EWidgetType.LicensePlates && anprClFilter.length) ||
    (widgetType === EWidgetType.RuleTrigger && ruleFilter.length)
  )

  let ruleTriggers: IRuleTriggerConditionInfo[] = []
  props.rulesInScene &&
    props.rulesInScene.forEach((rule) => {
      ruleTriggers.push(...rule.conditionInfo)
    })
  let ruleWarning = ruleTriggers.some((trigger) => {
    if (widgetType === EWidgetType.RuleTrigger) {
      return false
    } else if (
      trigger.triggerType === EEventTriggerType.originDestinationZone
    ) {
      return true
    } else if (clFilter.includes(trigger.triggerId)) {
      return true
    } else if (speedlineFilter.includes(trigger.triggerId)) {
      return true
    } else if (anprClFilter.includes(trigger.triggerId)) {
      return true
    }
    return false
  })

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Card title={t('solutions.scenes.overview.add.widgetType.title')}>
        <Dropdown
          overlay={
            <Menu>
              {WidgetTypes.map((item) => (
                <Menu.Item
                  key={item}
                  icon={WidgetTypeIcons[item]}
                  onClick={() => {
                    handleWidgetTypeChange(item)
                  }}
                >
                  {t(
                    `solutions.scenes.overview.add.widgetType.${item.toString()}`
                  )}
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomLeft"
          trigger={['click']}
        >
          {widgetType ? (
            <Button icon={WidgetTypeIcons[widgetType]}>
              {t(
                `solutions.scenes.overview.add.widgetType.${widgetType.toString()}`
              )}
            </Button>
          ) : (
            <Button icon={<PlusCircleOutlined />}>
              {t('solutions.scenes.overview.add.widgetType.placeholder')}
            </Button>
          )}
        </Dropdown>
      </Card>

      {anprWarning && <AnprAlert />}

      {widgetType && !anprWarning && (
        <>
          <Card title={t('solutions.scenes.overview.add.data.title')}>
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <p>
                {t(`solutions.scenes.overview.add.data.headline.${widgetType}`)}
              </p>
              {widgetType === EWidgetType.TrafficCounting && (
                <>
                  <CLSelection
                    crossingLines={props.crossingLinesInScene}
                    newCrossingLines={clFilter}
                    handleCrossingLinesChange={handleNewClFilterChange}
                    multi={true}
                  />

                  {((props.speedLinesInScene &&
                    props.speedLinesInScene.length > 0) ||
                    !!props.measures.find((entry) => {
                      return entry.name === 'CrossingEvents.averageSpeed'
                    })) && (
                    <SpeedToggle
                      measures={props.measures}
                      handleSpeedToggleChange={handleSpeedToggleChange}
                    />
                  )}
                </>
              )}
              {widgetType === EWidgetType.OriginDestinationAnalysis && (
                <>
                  <StreamSelection
                    placeholder={t('solutions.scenes.overview.add.data.stream')}
                    streams={scene.boxStreams}
                    newStreams={streamFilterFromOrSingle}
                    handleNewStreamsChange={handleNewStreamFilterChange}
                    multi={false}
                  />
                  {(chartType === 'table' || chartType === 'bar') && (
                    <>
                      <p>
                        {t(
                          `solutions.scenes.overview.add.data.originDestination.measureSelection`
                        )}
                      </p>
                      <Select
                        options={props.availableMeasures
                          .filter((measure) =>
                            MeasureByWidgetType[widgetType].includes(
                              measure.name
                            )
                          )
                          .map((measure) => ({
                            label: measure.title,
                            value: measure.name
                          }))}
                        mode="multiple"
                        placeholder={t(
                          'solutions.scenes.overview.add.data.originDestination.measureSelection'
                        )}
                        value={newMeasure}
                        onChange={handleAverageDurationSelectChange}
                        style={{ width: '100%' }}
                        allowClear={false}
                      />
                    </>
                  )}
                </>
              )}
              {widgetType === EWidgetType.JourneyTimes && (
                <>
                  {props.availableDimensions.length !== 0 && (
                    <JourneyTimeLocationWrapper
                      streams={scene.boxStreams}
                      selectedStreamsFrom={streamFilterFromOrSingle}
                      selectedStreamsTo={streamFilterTo}
                      handleSelectionChanged={handleLocationFilterChange}
                    />
                  )}
                  <div>
                    <ExportOutlined />
                    &nbsp;
                    <FormLabelWithTooltip id="solutions.scenes.overview.add.data.journeyTime.journeyTimeOrigins" />
                    <StreamSelection
                      placeholder={t(
                        'solutions.scenes.overview.add.data.stream'
                      )}
                      streams={scene.boxStreams}
                      newStreams={streamFilterFromOrSingle}
                      handleNewStreamsChange={(e) =>
                        handleLocationFilterChange(e, streamFilterTo)
                      }
                      multi={true}
                    />
                  </div>
                  <div>
                    <ImportOutlined />
                    &nbsp;
                    <FormLabelWithTooltip id="solutions.scenes.overview.add.data.journeyTime.journeyTimeDestinations" />
                    <StreamSelection
                      placeholder={t(
                        'solutions.scenes.overview.add.data.stream'
                      )}
                      streams={scene.boxStreams}
                      newStreams={streamFilterTo}
                      handleNewStreamsChange={(e) =>
                        handleLocationFilterChange(streamFilterFromOrSingle, e)
                      }
                      multi={true}
                    />
                  </div>
                  <PercentileMeasurementSelection
                    availableMeasurements={MeasureByWidgetType[widgetType]}
                    newMeasures={newMeasure}
                    allMeasures={props.availableMeasures}
                    handleFieldChange={handleNewJourneySegmentMeasureChange}
                    multi={true}
                    measures={props.measures}
                    percentilePrefix={JourneyTimePercentileMeasurePrefix}
                  ></PercentileMeasurementSelection>
                </>
              )}
              {widgetType === EWidgetType.JourneyDistributions && (
                <>
                  {props.availableDimensions.length !== 0 && (
                    <JourneyTimeLocationWrapper
                      streams={scene.boxStreams}
                      selectedStreamsFrom={streamFilterFromOrSingle}
                      selectedStreamsTo={streamFilterTo}
                      handleSelectionChanged={handleLocationFilterChange}
                    />
                  )}
                  <div>
                    <ExportOutlined />
                    &nbsp;
                    <FormLabelWithTooltip id="solutions.scenes.overview.add.data.journeyTime.journeyTimeOrigins" />
                    <StreamSelection
                      placeholder={t(
                        'solutions.scenes.overview.add.data.stream'
                      )}
                      streams={scene.boxStreams}
                      newStreams={streamFilterFromOrSingle}
                      handleNewStreamsChange={(e) =>
                        handleLocationFilterChange(e, streamFilterTo)
                      }
                      multi={true}
                    />
                  </div>
                  <div>
                    <ImportOutlined />
                    &nbsp;
                    <FormLabelWithTooltip id="solutions.scenes.overview.add.data.journeyTime.journeyTimeDestinations" />
                    <StreamSelection
                      placeholder={t(
                        'solutions.scenes.overview.add.data.stream'
                      )}
                      streams={scene.boxStreams}
                      newStreams={streamFilterTo}
                      handleNewStreamsChange={(e) =>
                        handleLocationFilterChange(streamFilterFromOrSingle, e)
                      }
                      multi={true}
                    />
                  </div>
                </>
              )}
              {widgetType === EWidgetType.SpeedEstimation && (
                <CLSelection
                  crossingLines={props.speedLinesInScene}
                  newCrossingLines={speedlineFilter}
                  handleCrossingLinesChange={handleNewSpeedLineFilterChange}
                  multi={true}
                />
              )}
              {widgetType === EWidgetType.LicensePlates &&
                props.crossingLinesInScene && (
                  <CLSelection
                    crossingLines={props.crossingLinesInScene.filter((cl) =>
                      scene.boxStreams.some(
                        (stream) =>
                          stream.hasAnpr && stream.streamId === cl.streamId
                      )
                    )}
                    newCrossingLines={anprClFilter}
                    handleCrossingLinesChange={handleNewAnprClFilterChange}
                    multi={true}
                  />
                )}
              {ruleWarning && getRuleTriggerWarning(t)}

              {widgetType === EWidgetType.RuleTrigger && (
                <>
                  <RuleSelection
                    rules={props.rulesInScene}
                    newRules={ruleFilter}
                    handleRuleTriggersChange={handleNewRuleFilterChange}
                  />
                </>
              )}
              {/*If Pie/chord chart or License Plate widget, we don't have granularity filters*/}
              {!(
                chartType === 'pie' ||
                chartType === 'chord' ||
                (widgetType && widgetType === EWidgetType.LicensePlates)
              ) && (
                <Row>
                  <GranularityFilter
                    timeDimensions={props.timeDimensions}
                    updateTimeDimension={props.updateTimeDimensions}
                  />
                </Row>
              )}
              {/*for heatmaps and chord we have fixed segmentations -> not choosable*/}
              {!(chartType === 'heatmap' || chartType === 'chord') && (
                <Row>
                  <SegmentationFilter
                    key={props.dimensions}
                    segmentationDimensions={segmentationDimensions}
                    dimensions={newDimensions}
                    handleDimensionChange={handleNewDimensionChange}
                  />
                </Row>
              )}
              <Row>
                <FilterData
                  filters={typeFilteredFilters}
                  updateFilters={props.updateFilters}
                  filteredDimensions={filteredDimensions}
                />
              </Row>
            </Space>
          </Card>

          <SelectChartType
            availableChartNames={ChartTypesByWidgetType[widgetType]}
            chartType={props.chartType}
            updateChartType={props.updateChartType}
          />
        </>
      )}

      {widgetType &&
        isValidQuery &&
        dimensionsSelected &&
        props.isQueryPresent &&
        Object.keys(props.validatedQuery).length > 0 && (
          <Card className="scc--solutions--add-widget-preview">
            <ChartRenderer
              vizState={{
                query: props.validatedQuery,
                chartType
              }}
              overrideTimeZone={props.scene?.location?.timezone}
            />
          </Card>
        )}
    </Space>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    crossingLinesInScene: state.scenes.clinesPerScene[ownProps.scene.id],
    speedLinesInScene: state.scenes.speedlinesPerScene[ownProps.scene.id],
    rulesInScene: state.scenes.rulesPerScene[ownProps.scene.id]
  }
}

export default connect(mapStateToProps, {
  listCrossinglinesInScene,
  listSpeedlinesInScene,
  listRuleTriggersInScene
})(withTranslation()(TrafficQueryBuilder))
