import { Config } from '../../../../services/config'
import { Alert } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const AnprAlert = () => {
  const { t } = useTranslation()
  return (
    <Alert
      message={t('solutions.scenes.overview.add.anprWarning.message')}
      description={
        <>
          <p>{t('solutions.scenes.overview.add.anprWarning.description')}</p>
          {!Config.EXCLUDE_SUPPORT && (
            <p
              dangerouslySetInnerHTML={{
                __html: t('solutions.scenes.overview.add.anprWarning.docs')
              }}
            />
          )}
        </>
      }
      type="warning"
      style={{ marginTop: '10px' }}
      showIcon
    />
  )
}
