import React, { Component } from 'react'

import BoxList from '../components/BoxList'
import { IBox, sortBoxes } from '../types/box'
import { connect } from 'react-redux'
import { loadBoxes } from '../redux/actions/boxes'
import { withRouter } from 'react-router-dom'

const LOCAL_STORAGE_AUTOREFRESH = 'deviceAutoRefresh'
const AUTOREFRESH_INTERVAL = 10000

interface IBoxesPageProps {
  children?
  boxes: IBox[]
  boxesFetched: boolean
  loadBoxes: Function
  loadBoxDetails: Function
}

interface IBoxesPageState {
  autoRefresh: boolean
}

class BoxesPage extends Component<IBoxesPageProps, IBoxesPageState> {
  private refresh: number | undefined

  constructor(props) {
    super(props)
    this.state = { autoRefresh: true }
  }

  componentDidMount() {
    this.props.loadBoxes()
    let storageautoRefresh = localStorage.getItem(
      `${LOCAL_STORAGE_AUTOREFRESH}`
    )
    if (storageautoRefresh === 'false') {
      this.setState({
        autoRefresh: false
      })
    } else {
      this.startAutoRefresh()
    }
  }

  componentWillUnmount() {
    this.stopAutoRefresh()
  }

  startAutoRefresh = () => {
    if (!this.refresh) {
      //on most browsers this automatically pauses when the element is hidden
      this.refresh = window.setInterval(() => {
        //but let's be safe, check for partial visibility and networking
        if (
          !document.hidden &&
          window.navigator.onLine &&
          window.location.pathname === '/boxes'
        ) {
          try {
            this.props.loadBoxes()
          } catch (e) {
            // swallow, why is this needed: window.navigator.online just will detect *any* kind of network connectivity.
            // i.e. not internet connectivity, so some request can and will be still send in that case.
            // however we don't need to propagate that to the user
          }
        }
      }, AUTOREFRESH_INTERVAL)
    }
  }

  stopAutoRefresh = () => {
    if (!this.refresh) {
      return
    }
    clearInterval(this.refresh)
    this.refresh = undefined
  }

  setAutoRefresh = (value) => {
    if (value) {
      this.startAutoRefresh()
    } else {
      this.stopAutoRefresh()
    }

    this.setState({
      autoRefresh: value
    })
    localStorage.setItem(`${LOCAL_STORAGE_AUTOREFRESH}`, value)
  }

  render() {
    const { boxes, boxesFetched } = this.props
    if (boxesFetched) {
      sortBoxes(boxes)
    }

    return (
      <BoxList
        boxes={boxes}
        boxesFetched={boxesFetched}
        autoRefresh={this.state.autoRefresh}
        setAutoRefresh={this.setAutoRefresh}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    boxes: state.boxes.allIds.map((id) => state.boxes.byIds[id]),
    boxesFetched: state.boxes.boxesFetched
  }
}

export default withRouter(
  connect(mapStateToProps, {
    loadBoxes
  })(BoxesPage)
)
