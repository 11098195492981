import React, { useEffect, useState } from 'react'
import { Card, Space } from 'antd'
import { EWidgetType } from '../../../../types/solution'
import SelectChartType from '../QueryBuilderComponents/SelectChartType'
import { IRuleTriggerInfo, IScene } from '../../../../types/scene'
import { useTranslation, withTranslation } from 'react-i18next'
import { TCubeDimension, TCubeMeasure } from '@cubejs-client/core'
import { connect } from 'react-redux'
import { listRuleTriggersInScene } from '../../../../redux/actions/scenes'
import ChartRenderer from '../../../../datadiscovery/components/ChartRenderer'
import GranularityFilter from '../QueryBuilderComponents/GranularityFilter'
import SegmentationFilter from '../QueryBuilderComponents/SegmentationFilter'
import FilterData from '../QueryBuilderComponents/FilterData'
import { compareElements, handleElementChange } from './QueryBuilderUtility'
import { getRuleTriggerWarning } from './BaseQueryBuilder'
import MeasurementSelection from '../QueryBuilderComponents/MeasurementSelection'

interface IGenericQueryBuilder {
  rulesInScene: IRuleTriggerInfo[]
  listRuleTriggersInScene: Function
  scene: IScene
  setValidQuery: Function
  widgetType: EWidgetType | undefined
  setWidgetType: Function
  isQueryPresent: any
  validatedQuery: any
  timeDimensions: any
  availableTimeDimensions: any
  updateTimeDimensions: any
  dimensions: any
  availableDimensions: any
  updateDimensions: any
  measures: any
  availableMeasures: any
  updateMeasures: any
  chartType: any
  updateChartType: any
  timeFilter: string
  segments: any
  availableSegments: any
  updateSegments: any
  filters: any
  updateFilters: any
  updateQuery: any
}

const GenericQueryBuilder: React.FC<IGenericQueryBuilder> = (props) => {
  const { t } = useTranslation()
  let [newDimensions, setNewDimensions] = useState<string[]>([])
  let [newMeasures, setNewMeasures] = useState<string[]>([])

  const {
    chartType,
    measures,
    availableMeasures,
    updateMeasures,
    dimensions,
    availableDimensions,
    updateDimensions,
    filters,
    updateFilters,
    timeDimensions,
    updateTimeDimensions,
    setValidQuery,
    isQueryPresent,
    validatedQuery,
    listRuleTriggersInScene,
    scene
  } = props

  useEffect(() => {
    setValidQuery(isQueryPresent && Object.keys(validatedQuery).length > 0)
  }, [isQueryPresent, validatedQuery, setValidQuery])

  useEffect(() => {
    listRuleTriggersInScene(scene)
  }, [listRuleTriggersInScene, scene])

  useEffect(() => {
    // set current dimensions if widget is edited
    if (newDimensions && !compareElements(dimensions, newDimensions)) {
      setNewDimensions(
        dimensions.map((item) => {
          return item.name
        })
      )
    }

    if (newMeasures && !compareElements(measures, newMeasures)) {
      setNewMeasures(
        measures.map((item) => {
          return item.name
        })
      )
    }
  }, [filters, measures, dimensions, newDimensions, newMeasures])

  const measuresGroups: string[] = measures.map((m) =>
    m.name.substr(0, m.name.indexOf('.'))
  )

  const measureFilterPredicate = (value: TCubeMeasure) => {
    if (
      value.name.includes('ParkingAnalyticsEvents') ||
      value.name.includes('ParkingUtilization') ||
      value.name.includes('JourneySegments')
    ) {
      return false
    }
    //if some measure has been selected already, only allow other roi measures for now
    return !(
      measuresGroups.length > 0 &&
      !measuresGroups.includes(value.name.substr(0, value.name.indexOf('.')))
    )
  }

  const filteredMeasures: TCubeMeasure[] = availableMeasures.filter(
    measureFilterPredicate
  )

  const filteredDimensions: TCubeDimension[] = availableDimensions.filter(
    (dim) => {
      return (
        measuresGroups.includes(dim.name.substr(0, dim.name.indexOf('.'))) &&
        !dim.name.endsWith('Id')
      )
    }
  )

  const handleFilterChange = (data) => {
    setNewMeasures(data)
    handleElementChange(availableMeasures, measures, updateMeasures, data)
  }

  const handleNewDimensionChange = (data) => {
    setNewDimensions(data)
    handleElementChange(availableDimensions, dimensions, updateDimensions, data)
  }

  const typeFilteredFilters = props.filters.filter(
    (filter) => filter.member.split('.')[1] !== 'streamId'
  )

  let previewQuery =
    props.isQueryPresent &&
    Object.keys(props.validatedQuery).length &&
    Object.assign({}, props.validatedQuery)

  // add scene stream filters to preview query
  if (previewQuery) {
    const streamFilters: any = previewQuery.filters || []
    measures.forEach((measure) => {
      let filterId = `${measure['name'].split('.')[0]}.streamId`
      if (
        streamFilters.find((item) => {
          return item.member === filterId
        })
      ) {
        return
      }
      streamFilters.push({
        member: filterId,
        operator: 'equals',
        values: props.scene.boxStreams.map((stream) => stream.streamId)
      })
    })
    previewQuery.filters = streamFilters
  }

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      {props.rulesInScene &&
        props.rulesInScene.length > 0 &&
        getRuleTriggerWarning(t)}
      <Card title={t('solutions.scenes.overview.add.measure.title')}>
        <MeasurementSelection
          availableMeasurements={filteredMeasures.map((member) => member.name)}
          measures={newMeasures}
          allMeasures={availableMeasures}
          handleFieldChange={handleFilterChange}
          multi={true}
          useLongTitles={true}
        />
      </Card>

      {measures.length > 0 && (
        <Card title={t('solutions.scenes.overview.add.data.title')}>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <p>
              {t('solutions.scenes.overview.add.data.dimensionexplanation')}
            </p>

            <GranularityFilter
              timeDimensions={timeDimensions}
              updateTimeDimension={updateTimeDimensions}
            />
            <SegmentationFilter
              key={dimensions}
              segmentationDimensions={filteredDimensions}
              dimensions={newDimensions}
              handleDimensionChange={handleNewDimensionChange}
            />
            <FilterData
              filters={typeFilteredFilters}
              updateFilters={updateFilters}
              filteredDimensions={filteredDimensions}
            />
          </Space>
        </Card>
      )}

      {measures.length > 0 && (
        <SelectChartType
          availableChartNames={[
            'table',
            'number',
            'chord',
            'bar',
            'pie',
            'line'
          ]}
          chartType={chartType}
          updateChartType={props.updateChartType}
        />
      )}

      {previewQuery && (
        <Card className="scc--solutions--add-widget-preview">
          <ChartRenderer
            vizState={{
              query: previewQuery,
              chartType
            }}
            overrideTimeZone={props.scene?.location?.timezone}
          />
        </Card>
      )}
    </Space>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    rulesInScene: state.scenes.rulesPerScene[ownProps.scene.id]
  }
}

export default connect(mapStateToProps, { listRuleTriggersInScene })(
  withTranslation()(GenericQueryBuilder)
)
